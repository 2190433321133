import React, {Component} from "react";
import {Header} from "../Header";
import {Footer} from "../Footer";
import step_1_img from "../../assets/images/step-1.svg";
import step_2_img from "../../assets/images/step-2.svg";
import step_3_img from "../../assets/images/step-3.svg";
import StepOneForm from "./StepOneForm";
import StepTwoForm from "./StepTwoForm";
import StepThreeForm from "./StepThreeForm";
import StepFourForm from "./StepFourForm";
import OrderCompleteModal from './order-complete-modal';
import InterestCompleteModal from './interest-lodged-modal';
import TrainingRequestModal from './training-request-modal';
import DiscountCodeModal from './discount-code-modal';
import {orderPageText as orderPageText_en} from "../../libs/en/texts-order-page-lib";
import {orderPageText as orderPageText_de} from "../../libs/de/texts-order-page-lib";
import {orderPageText as orderPageText_fr} from "../../libs/fr/texts-order-page-lib";
import {orderPageText as orderPageText_nl} from "../../libs/nl/texts-order-page-lib";
import {orderPageText as orderPageText_it} from "../../libs/it/texts-order-page-lib";
import {orderPageText as orderPageText_cz} from "../../libs/cz/texts-order-page-lib";
import {orderPageText as orderPageText_fi} from "../../libs/fi/texts-order-page-lib";
import {orderPageText as orderPageText_pl} from "../../libs/pl/texts-order-page-lib";
import {orderPageText as orderPageText_se} from "../../libs/se/texts-order-page-lib";
import {orderPageText as orderPageText_no} from "../../libs/no/texts-order-page-lib";
import {orderPageText as orderPageText_dk} from "../../libs/dk/texts-order-page-lib";
import {orderPageText as orderPageText_es} from "../../libs/es/texts-order-page-lib";
import { post } from "aws-amplify/api";
import { signOut, fetchAuthSession } from 'aws-amplify/auth';
import parse from "html-react-parser";
import {Helmet} from 'react-helmet';

// let costWithoutVat = {
//     monthly: 80,
//     sixMonthly: 450,
//     annual: 840
// };
//
// let costWithVat = {
//     monthly: 96,
//     sixMonthly: 540,
//     annual: 1008
// };
//
// let subscriptionModel = {
//     monthly: 'Monthly subscription',
//     sixMonthly: 'Six monthly subscription',
//     annual: 'Annual subscription'
// };

export class Orders extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stepCount: 1,
            isOrderComplete: false,
            notFromUnitedForm: {},
            noneTrainingForm: {},
            orderCompleteForm: {},
            stepTwoData: {question1: false, question2: false, question3: false, isValid: false},
            loading: true,
            isModalOpen: false,
            isModalTrainingOpen: false,
            responseSuccess: false,
            stepThreeFormInitialValues: {
                unitedKingdom: "",
                notFromUnitedFormFirstName: "",
                notFromUnitedFormLastName: "",
                notFromUnitedFormEmail: "",
                notFromUnitedFormConfirmEmail: "",
                notFromUnitedFormCountry: "",

                training: "",
                noneTraningFormFirstName: "",
                noneTraningFormLastName: "",
                noneTraningFormEmail: "",
                noneTraningFormConfirmEmail: "",
                camAPSUserExist: "",
                camAPSUserFormFirstName: "",
                camAPSUserFormLastName: "",
                camAPSUserFormEmail: "",
                camAPSUserFormConfirmEmail: "",
                referrence: "",
                serial1: "",
                serial2: "",
                serial3: "",
                confirmSerial1: "",
                confirmSerial2: "",
                confirmSerial3: "",
                exemptVat: "",
                exemptVatFormFirstName: "",
                exemptVatFormLastName: "",
                exemptVatFormAddress1: "",
                exemptVatFormAddress2: "",
                exemptVatFormAddress3: "",
                exemptVatFormPostalCode: "",
                sickness: "",
                signature: "",
                day: "",
                month: "",
                year: "",
            },

            language: this.props.language,
            orderPageText: orderPageText_en,
            prefix: '',
        };

        if (this.state.language === "DE") {
            this.state.orderPageText = orderPageText_de;
            this.state.prefix = '/de';
        }
        if (this.state.language === "EN-CA") {
            this.state.orderPageText = orderPageText_en;
            this.state.prefix = '/en-ca';
        }
        if (this.state.language === "FR") {
            this.state.orderPageText = orderPageText_fr;
            this.state.prefix = '/fr-ch';
        }
        if (this.state.language === "FR-CA") {
            this.state.orderPageText = orderPageText_fr;
            this.state.prefix = '/fr-ca';
        }
        if (this.state.language === "FR-FR") {
            this.state.orderPageText = orderPageText_fr;
            this.state.prefix = '/fr';
        }
        if (this.state.language === "NL") {
            this.state.orderPageText = orderPageText_nl;
            this.state.prefix = '/nl';
        }
        if (this.state.language === "IT") {
            this.state.orderPageText = orderPageText_it;
            this.state.prefix = '/it';
        }
        if (this.state.language === "CZ") {
            this.state.orderPageText = orderPageText_cz;
            this.state.prefix = '/cz';
        }
        if (this.state.language === "FI") {
            this.state.orderPageText = orderPageText_fi;
            this.state.prefix = '/fi';
        }
        if (this.state.language === "PL") {
            this.state.orderPageText = orderPageText_pl;
            this.state.prefix = '/pl';
        }
        if (this.state.language === "SE") {
            this.state.orderPageText = orderPageText_se;
            this.state.prefix = '/se';
        }
        if (this.state.language === "NO") {
            this.state.orderPageText = orderPageText_no;
            this.state.prefix = '/nb';
        }
        if (this.state.language === "DK") {
            this.state.orderPageText = orderPageText_dk;
            this.state.prefix = '/dk';
        }
        if (this.state.language === "ES") {
            this.state.orderPageText = orderPageText_es;
            this.state.prefix = '/es';
        }

        this.state.costWithoutVat = {
            monthly: this.state.orderPageText.costMonthly,
            sixMonthly: this.state.orderPageText.costSixMonthly,
            annual: this.state.orderPageText.costAnnual
        }

        this.state.costWithVat = {
            monthly: this.state.orderPageText.costMonthlyVAT,
            sixMonthly: this.state.orderPageText.costSixMonthlyVAT,
            annual: this.state.orderPageText.costAnnualVAT
        }

        this.state.subscriptionModel = {
            monthly: this.state.orderPageText.subscriptionMonthly,
            sixMonthly: this.state.orderPageText.subscriptionSixMonthly,
            annual: this.state.orderPageText.subscriptionAnnual
        }
    }

    async setLanguageVars() {
        if (this.state.language !== this.props.language) {
            if (this.props.language === "EN") {
                this.setState({
                    language: this.props.language,
                    orderPageText: orderPageText_en,
                    prefix: '',
                }, this.setSubscriptionModel);
            }
            if (this.props.language === "DE") {
                this.setState({
                    language: this.props.language,
                    orderPageText: orderPageText_de,
                    prefix: '/de'
                }, this.setSubscriptionModel);
            }
            if (this.props.language === "EN-CA") {
                this.setState({
                    language: this.props.language,
                    orderPageText: orderPageText_en,
                    prefix: '/en-ca'
                }, this.setSubscriptionModel);
            }
            if (this.props.language === "FR") {
                this.setState({
                    language: this.props.language,
                    orderPageText: orderPageText_fr,
                    prefix: '/fr-ch'
                }, this.setSubscriptionModel);
            }
            if (this.props.language === "FR-CA") {
                this.setState({
                    language: this.props.language,
                    orderPageText: orderPageText_fr,
                    prefix: '/fr-ca'
                }, this.setSubscriptionModel);
            }
            if (this.props.language === "FR-FR") {
                this.setState({
                    language: this.props.language,
                    orderPageText: orderPageText_fr,
                    prefix: '/fr'
                }, this.setSubscriptionModel);
            }
            if (this.props.language === "NL") {
                this.setState({
                    language: this.props.language,
                    orderPageText: orderPageText_nl,
                    prefix: '/nl'
                }, this.setSubscriptionModel);
            }
            if (this.props.language === "IT") {
                this.setState({
                    language: this.props.language,
                    orderPageText: orderPageText_it,
                    prefix: '/it'
                }, this.setSubscriptionModel);
            }
            if (this.props.language === "CZ") {
                this.setState({
                    language: this.props.language,
                    orderPageText: orderPageText_cz,
                    prefix: '/cz'
                }, this.setSubscriptionModel);
            }
            if (this.props.language === "FI") {
                this.setState({
                    language: this.props.language,
                    orderPageText: orderPageText_fi,
                    prefix: '/fi'
                }, this.setSubscriptionModel);
            }
            if (this.props.language === "PL") {
                this.setState({
                    language: this.props.language,
                    orderPageText: orderPageText_pl,
                    prefix: '/pl'
                }, this.setSubscriptionModel);
            }
            if (this.props.language === "SE") {
                this.setState({
                    language: this.props.language,
                    orderPageText: orderPageText_se,
                    prefix: '/se'
                }, this.setSubscriptionModel);
            }
            if (this.props.language === "NO") {
                this.setState({
                    language: this.props.language,
                    orderPageText: orderPageText_no,
                    prefix: '/nb'
                }, this.setSubscriptionModel);
            }
            if (this.props.language === "DK") {
                this.setState({
                    language: this.props.language,
                    orderPageText: orderPageText_dk,
                    prefix: '/dk'
                }, this.setSubscriptionModel);
            }
            if (this.props.language === "ES") {
                this.setState({
                    language: this.props.language,
                    orderPageText: orderPageText_es,
                    prefix: '/es'
                }, this.setSubscriptionModel);
            }
        }
    }

    setSubscriptionModel() {
        this.setState({
            costWithoutVat: {
                monthly: this.state.orderPageText.costMonthly,
                sixMonthly: this.state.orderPageText.costSixMonthly,
                annual: this.state.orderPageText.costAnnual
            },

            costWithVat: {
                monthly: this.state.orderPageText.costMonthlyVAT,
                sixMonthly: this.state.orderPageText.costSixMonthlyVAT,
                annual: this.state.orderPageText.costAnnualVAT
            },

            subscriptionModel: {
                monthly: this.state.orderPageText.subscriptionMonthly,
                sixMonthly: this.state.orderPageText.subscriptionSixMonthly,
                annual: this.state.orderPageText.subscriptionAnnual
            }
        });
    }

    componentDidUpdate() {
        this.setLanguageVars();
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>{parse(this.state.orderPageText.title)}</title>
                    <meta name="title" content={parse(this.state.orderPageText.titleMeta)}/>
                    <meta name="description"
                          content={parse(this.state.orderPageText.descriptionMeta)}/>
                    <meta name="keywords" content={this.state.orderPageText.keywords}/>
                    <meta property="og:locale" content={this.state.orderPageText.localeMeta}/>
                </Helmet>
                <Header props={this.props}/>
                {
                    this.state.stepCount === 1 && this.subscriptionHeader()
                }
                <section className="step-gradient-overlay">
                    {
                        this.state.stepCount !== 1 && (
                            <section className="step-chart-sec">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-md-12">
                                            <div className="step-chart-wrapper">
                                                <div
                                                    className={`step-col ${this.state.stepCount > 1 ? 'step-done' : ''} `}>
                                                    <h4 className={`${this.state.stepCount === 1 ? 'font-weight-bold' : ''}`}>
                                                        {parse(this.state.orderPageText.stage1)}</h4>
                                                    <p>{parse(this.state.orderPageText.subscriptionOption)}</p>{" "}
                                                    <span className="circle-step"></span>
                                                    <span className="circle-step-line"></span>
                                                </div>
                                                <div
                                                    className={`step-col ${this.state.stepCount > 2 ?
                                                        'step-done' : this.state.stepCount > 1 ? '' : 'step-disable'} `}>
                                                    <h4 className={`${this.state.stepCount === 2 ?
                                                        'font-weight-bold' : ''}`}>
                                                        {parse(this.state.orderPageText.stage2)}</h4>
                                                    <p>{parse(this.state.orderPageText.equipment)}</p> <span
                                                    className="circle-step"></span>
                                                    <span className="circle-step-line"></span>
                                                </div>
                                                <div
                                                    className={`step-col ${this.state.stepCount > 3 ? 'step-done' : this.state.stepCount > 2 ? '' : 'step-disable'} `}>
                                                    <h4 className={`${this.state.stepCount === 3 ?
                                                        'font-weight-bold' : ''}`}>
                                                        {parse(this.state.orderPageText.stage3)}</h4>
                                                    <p>{parse(this.state.orderPageText.personalInfo)}</p>{" "}
                                                    <span className="circle-step"></span>
                                                    <span className="circle-step-line"></span>
                                                </div>
                                                <div
                                                    className={`step-col ${this.state.stepCount > 4 ? 'step-done' :
                                                        this.state.stepCount > 3 ? '' : 'step-disable'} `}>
                                                    <h4 className={`${this.state.stepCount === 4 ?
                                                        'font-weight-bold' : ''}`}>
                                                        {parse(this.state.orderPageText.stage4)}</h4>
                                                    <p>{parse(this.state.orderPageText.orderConfirmation)}</p>{" "}
                                                    <span className="circle-step"></span>
                                                    <span className="circle-step-line"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )
                    }
                    {
                        this.state.stepCount === 1 && (
                            <StepOneForm
                                orderPageText={this.state.orderPageText}
                                handleNext={this.handleNext}
                                setSubscriptionPeriod={this.setSubscriptionPeriod}
                            />
                        )
                    }
                    {
                        this.state.stepCount === 2 && (
                            <StepTwoForm
                                orderPageText={this.state.orderPageText}
                                handleNext={this.handleNext}
                                handlePrevious={this.handlePrevious}
                                stepTwoData={this.state.stepTwoData}
                                self={this}
                            />
                        )
                    }
                    {
                        this.state.stepCount === 3 && (
                            <StepThreeForm
                                orderPageText={this.state.orderPageText}
                                handlePrevious={this.handlePreviousStepThree}
                                handleNext={this.handleNext}
                                initialValues={this.state.stepThreeFormInitialValues}
                                handleSubmit={this.handleSubmit}/>
                        )
                    }
                    {
                        this.state.stepCount === 4 && (
                            <StepFourForm
                                orderPageText={this.state.orderPageText}
                                handlePrevious={this.handlePrevious}
                                handleComplete={this.handleComplete}
                                handleDiscount={this.handleDiscount}
                                isDiscount={this.state.isDiscount}
                                orderSummary={this.state.orderCompleteForm}
                                getDiscountAsText={this.getDiscountAsText}
                                getSubscriptionPeriodAsText={this.getSubscriptionPeriodAsText}
                                getSubscriptionCost={this.getSubscriptionCost}
                            />
                        )
                    }
                    {
                        this.state.isOrderComplete && <OrderCompleteModal
                            orderPageText={this.state.orderPageText}
                            errorMessage={this.state.errorMessage}
                            closeModal={() => {
                                this.setState({isOrderComplete: false}, () => {
                                    this.state.responseSuccess ? this.props.history.push(this.state.prefix + '/home') :
                                        this.props.history.push(this.state.prefix + '/orders');
                                })
                            }} load={this.state.loading} response={this.state.responseSuccess}/>
                    }
                    {
                        this.state.isModalOpen && <InterestCompleteModal
                            orderPageText={this.state.orderPageText}
                            errorMessage={this.state.errorMessage}
                            closeModal={() => {
                                this.setState({isModalOpen: false}, () => {
                                    this.state.responseSuccess ? this.props.history.push(this.state.prefix + '/home') :
                                        this.props.history.push(this.state.prefix + '/orders');
                                })
                            }} load={this.state.loading} response={this.state.responseSuccess}/>
                    }
                    {
                        this.state.isModalTrainingOpen && <TrainingRequestModal
                            errorMessage={this.state.errorMessage}
                            closeModal={() => {
                                this.setState({isModalTrainingOpen: false}, () => {
                                    this.state.responseSuccess ? this.props.history.push(this.state.prefix + '/home') :
                                        this.props.history.push(this.state.prefix + '/orders');
                                })
                            }} load={this.state.loading} response={this.state.responseSuccess}/>
                    }
                    {
                        this.state.isModalDiscountOpen && <DiscountCodeModal
                            orderPageText={this.state.orderPageText}
                            errorMessage={this.state.errorMessage}
                            closeModal={() => {
                                this.setState({isModalDiscountOpen: false}, () => {
                                    // this.state.responseSuccess ? this.props.history.push('/home') :
                                    this.props.history.push(this.state.prefix + '/orders');
                                })
                            }} load={this.state.loading} response={this.state.responseSuccess}/>
                    }
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }


    /**
     * subscriptionHeader header method is used to return subscription div when step count is initial  or 1
     */
    subscriptionHeader = () => {
        return (
            <>
                <section className="inner-banner order-page-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10">
                                <h1>
                                    {parse(this.state.orderPageText.interested)}
                                </h1>
                                {/*<p>*/}
                                {/*    You will need to answer a few questions before submitting your*/}
                                {/*    order.*/}
                                {/*</p>*/}
                                <p>
                                    {parse(this.state.orderPageText.interestedPara)}
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blue-step-box">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-12">
                                <div className="step-box-heading">
                                    <h2>{parse(this.state.orderPageText.subscriptionProcess)}</h2>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="box-content">
                                    <div className="box-image">
                                        <img src={step_1_img} alt="icon"/>
                                    </div>
                                    <h4>{parse(this.state.orderPageText.choosePackage)}</h4>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="box-content">
                                    <div className="box-image">
                                        <img src={step_2_img} alt="icon"/>
                                    </div>
                                    <h4>{parse(this.state.orderPageText.answerQuestions)}</h4>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="box-content">
                                    <div className="box-image">
                                        <img src={step_3_img} alt="icon"/>
                                    </div>
                                    <h4>{parse(this.state.orderPageText.receiveTraining)}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    };

    /**
     * handlePrevious method is used to set count of previous step
     */
    handlePrevious = () => {
        if (this.state.stepCount > 1) {
            this.setState({
                stepCount: this.state.stepCount - 1
            });
        }
    };

    /**
     * handlePrevious method is used to set count of previous step
     */
    handlePreviousStepThree = (value) => {
        if (this.state.stepCount > 1) {
            this.setState({
                stepCount: this.state.stepCount - 1,
                stepThreeFormInitialValues: value
            });
        }
    };

    /**
     * Set subscription period
     * @param period monthly,sixMonthly, or annual subscription period
     */
    setSubscriptionPeriod = (period) => {
        this.setState({subscriptionPeriod: period});
    };

    /**
     * Gets subscription period as text
     * @returns {*}
     */
    getSubscriptionPeriodAsText = () => {
        return this.state.subscriptionModel[this.state.subscriptionPeriod];
    };

    /**
     * Gets discount as text
     * @returns {*}
     */
    getDiscountAsText = () => {
        const discount = this.state.discountRecord;
        const period = this.state.subscriptionPeriod;
        let discountDuration = discount.discountDurationMonths;
        if (period === "monthly") {
            discountDuration = 1;
        } else if (period === "sixMonthly") {
            discountDuration = Math.min(6, discountDuration);
        }
        return this.state.orderPageText.discountHeading + ': ' + discount.discountPercentage + '%' +
            this.state.orderPageText.discountOver + ' ' + discountDuration +
            (discountDuration === 1 ?
                ' ' + this.state.orderPageText.discountOneMonth :
                ' ' + this.state.orderPageText.discountTwoAndMoreMonths);
    };

    getSubscriptionCost = (vatExempt) => {
        let costLine;
        let cost;
        if (vatExempt === "true") {
            cost = this.state.costWithoutVat[this.state.subscriptionPeriod];
        } else {
            cost = this.state.costWithVat[this.state.subscriptionPeriod];
        }

        if (this.state.isDiscount) {
            const discount = this.state.discountRecord;
            const period = this.state.subscriptionPeriod;
            let periodNumber = 12;
            let discountDuration = discount.discountDurationMonths;
            if (period === "monthly") {
                discountDuration = 1;
                periodNumber = 1;
            } else if (period === "sixMonthly") {
                discountDuration = Math.min(6, discountDuration);
                periodNumber = 6;
            }
            const discountedPeriodCost = (cost * discountDuration * ((100 - discount.discountPercentage) / 100) +
                (cost * (periodNumber - discountDuration))) / periodNumber;

            costLine = this.state.orderPageText.currencySubscription +
                this.numberWithCommas(discountedPeriodCost.toFixed(2));
            if (vatExempt === "true") {
                costLine += ' ' + this.state.orderPageText.vatExempt;
            }
        } else {
            costLine = this.state.orderPageText.currencySubscription +
                this.numberWithCommas(cost.toFixed(2));
            if (vatExempt === "true") {
                costLine += ' ' + this.state.orderPageText.vatExempt;
            }
        }
        return costLine;
    };

    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    /**
     * handleNext method is used to set next count
     */
    handleNext = () => {
        if (this.state.stepCount < 5) {
            window.scrollTo(0, 0);
            this.setState({stepCount: this.state.stepCount + 1});
        }
    };

    onSubmitDiscount = async () => {
        const element = {};
        try {
            element.captchaToken = this.props.captchaToken;
            element.discountCode = this.state.discountCode;
            element.locale = this.state.language;

            const session = await fetchAuthSession();
            if (!session.userSub) {
                throw new Error("User not logged in");
            }
            const discountRecord = await this.getDiscount(element);
            await signOut();
            this.setState({
                discountRecord: discountRecord.Item,
                isModalDiscountOpen: true,
                responseSuccess: true,
                loading: false,
                isDiscount: true
            });
        } catch (e) {
            this.setState({
                isModalDiscountOpen: true,
                responseSuccess: false,
                loading: false,
                isDiscount: false,
                errorMessage: e
            });
        }
        this.props.getNewCaptchaToken();
    };

    async getDiscount(discountCode)  {
        const {body} = await post({
            apiName: "ordering", path: "/discount", options: {
                body: discountCode
            }
        }).response;
        return body.json();
    }

    onSubmitTrainingInterest = async () => {
        const element = {};
        try {
            element.captchaToken = this.props.captchaToken;
            element.emailCustomer = this.state.noneTrainingForm.email.toLowerCase();
            element.locale = this.state.language;

            element.firstName = this.state.noneTrainingForm.firstname;
            element.lastName = this.state.noneTrainingForm.lastname;
            const session = await fetchAuthSession();
            if (!session.userSub) {
                throw new Error("User not logged in");
            }
            await this.createTraining(element);
            await signOut();
            this.setState({
                isModalTrainingOpen: true,
                responseSuccess: true,
                loading: false
            });
        } catch (e) {
            this.setState({
                isModalTrainingOpen: true,
                responseSuccess: false,
                loading: false,
                errorMessage: e
            });
        }
        this.props.getNewCaptchaToken();
    };

    async createTraining(customer) {
        let attrib;
        for (attrib in customer) {
            if (Object.prototype.hasOwnProperty.call(customer, attrib)) {
                if ("" === attrib) {
                }
                attrib = null;
            }
        }

        const {body} = await post({
            apiName: "ordering", path: "/training", options: {
                body: customer
            }
        }).response;
        return body.json();

    }

    onSubmitInterest = async () => {
        const element = {};
        try {
            element.captchaToken = this.props.captchaToken;
            element.emailCustomer = this.state.notFromUnitedForm.email.toLowerCase();
            element.locale = this.state.language;

            element.firstName = this.state.notFromUnitedForm.firstname;
            element.lastName = this.state.notFromUnitedForm.lastname;
            element.country = this.state.notFromUnitedForm.country;
            const session = await fetchAuthSession();
            if (!session.userSub) {
                throw new Error("User not logged in");
            }
            await this.createInterest(element);
            await signOut();
            this.setState({
                isModalOpen: true,
                responseSuccess: true,
                loading: false
            });
        } catch (e) {
            this.setState({
                isModalOpen: true,
                responseSuccess: false,
                loading: false,
                errorMessage: e
            });
        }
        this.props.getNewCaptchaToken();
    };

    async createInterest(customer) {
        let attrib;
        for (attrib in customer) {
            if (Object.prototype.hasOwnProperty.call(customer, attrib)) {
                if ("" === attrib) {
                }
                attrib = null;
            }
        }

        const {body} = await post({
            apiName: "ordering", path: "/interest", options: {
                body: customer
            }
        }).response;
        return body.json();

    }

    /**
     * handleSubmit method is used to handle submit button and next of third form
     * it will check that order complete details are filled or not if then submit will fire else form will go to next
     */
    handleSubmit = async (value, tag) => {
        const obj = {};
        Object.keys(value).forEach((key) => {
            if (key.includes(tag)) {
                obj[key.substring(tag.length).toLocaleLowerCase()] = value[key];
            }
        });

        /**
         * when user select no from united kingdom in third step and he fills form and submit than state will set with following filled details
         */
        if (tag === 'notFromUnitedForm') {
            this.setState({
                notFromUnitedForm: obj,
                loading: true,
                isModalOpen: true,
            }, () => {
                this.onSubmitInterest();
            });

            /**
             * when user selects no training from any clinic in third step than form will be display and on submit state will be set
             */
        } else if (tag === 'noneTrainingForm') {
            this.setState({
                noneTrainingForm: obj,
                loading: true,
                isModalTrainingOpen: true,
            }, () => {
                this.onSubmitTrainingInterest();
            });

            /**
             * else when user complete third form completely and press next than order summary will be set and display to 4th form
             */
        } else {
            Object.keys(value).forEach((key) => {
                obj[key] = value[key];
            });
            const completeObj = {};
            let keys = ["training", "camAPSUserFormFirstName", "camAPSUserFormLastName",
                "camAPSUserFormEmail", "camAPSUserFormConfirmEmail", "referrence", "serial1",
                "serial2", "serial3", "confirmSerial1", "confirmSerial2", "confirmSerial3"];
            keys.forEach(k => {
                completeObj[k] = value[k]
            });
            obj['serialNumber'] = completeObj['serial1'] + completeObj['serial2'] + completeObj['serial3'];
            // console.log('====================================');
            // console.log(completeObj);
            // console.log('====================================');
            this.setState({
                stepThreeFormInitialValues: value,
                orderCompleteForm: obj
            }, () => {
                this.handleNext();
            })
        }
    };

    /**
     * handleDiscount method is used to handle apply code button of fourth form
     * it will get discount code and store it locally
     */
    handleDiscount = async (value) => {
        this.setState({
            discountCode: value,
            loading: true,
            isModalDiscountOpen: true,
        }, () => {
            this.onSubmitDiscount();
        });
    };

    /**
     * when user complete order complete than state is set and modal will open
     */
    handleComplete = async (values) => {
        this.setState({
            isOrderComplete: true,
            loading: true,
        }, () => {
            this.onSubmitComplete();
        })
    };

    onSubmitComplete = async () => {
        const element = {};
        const order = this.state.orderCompleteForm;
        try {
            element.captchaToken = this.props.captchaToken;
            element.emailCustomer = order.camAPSUserFormEmail.toLowerCase();
            element.locale = this.state.language;

            if (this.state.subscriptionPeriod === 'monthly') {
                element.subscription = this.state.orderPageText.emailOneMonth;
            } else if (this.state.subscriptionPeriod === 'sixMonthly') {
                element.subscription = this.state.orderPageText.emailSixMonths;
            } else {
                element.subscription = this.state.orderPageText.emailTwelveMonths;
            }

            element.firstName = order.camAPSUserFormFirstName;
            element.lastName = order.camAPSUserFormLastName;
            element.usingYourself = "true";

            element.heardFrom = order.referrence;
            element.pumpSN = order.serialNumber.toUpperCase();

            element.exemptFromVAT = order.exemptVat;

            element.firstNameVatExempt = order.exemptVatFormFirstName;
            element.lastNameVatExempt = order.exemptVatFormLastName;
            element.addressLine1VatExempt = order.exemptVatFormAddress1;
            element.addressLine2VatExempt = order.exemptVatFormAddress2;
            element.addressLine3VatExempt = order.exemptVatFormAddress3;
            element.postcodeVatExempt = order.exemptVatFormPostalCode;
            element.conditionVatExempt = order.sickness;
            element.signatureVatExempt = order.signature;

            element.trainingClinic = order.training;

            if (this.state.isDiscount) {
                element.discountCode = this.state.discountRecord.discountCodeId;
                element.discountDurationMonths = this.state.discountRecord.discountDurationMonths;
                element.discountPercentage = this.state.discountRecord.discountPercentage;
            }

            const session = await fetchAuthSession();
            if (!session.userSub) {
                throw new Error("User not logged in");
            }
            await this.createCustomer(element);
            await signOut();
            this.setState({
                isOrderComplete: true,
                responseSuccess: true,
                loading: false,
            });
        } catch (e) {
            this.setState({
                isOrderComplete: true,
                responseSuccess: false,
                loading: false,
                errorMessage: e
            });
        }
        this.props.getNewCaptchaToken();
    };

    async createCustomer(customer) {
        let attrib;
        for (attrib in customer) {
            if (Object.prototype.hasOwnProperty.call(customer, attrib)) {
                if ("" === attrib) {
                }
                attrib = null;
            }
        }

        const {body} = await post({
            apiName: "ordering", path: "/order", options: {
                body: customer
            }
        }).response;
        return body.json();

    }
}
