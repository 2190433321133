/**
 * Texts for Reviews page
 */
export const reviewsPage = {
    title: 'Reviews - CamAPS FX',
    titleMeta: 'Read reviews of CamAPS FX users',
    descriptionMeta: 'CamAPS FX is life changing for many users providing unprecedented glucose control and reduces burden of ' +
        'diabetes for users and their families.',
    keywords:
        'camaps reviews, camaps fx reviews, ' +
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ' +
        '',
    localeMeta: 'en_gb',

    shortReview1:
        '<h2>"We just wanted to let you know how much our daughter\'s and our lives have ' +
        'been improved since she got the CamAPS FX closed loop system." </h2>' +
        '<p>' +
        '    <span>Parents of a teenage girl</span>' +
        '</p>',

    shortReview2:
        '<p> ' +
        '   It is impossible to overstate how much it has changed our lives. Like magic, ' +
        '   almost all of those issues vanish, i.e. exhaustion because of disrupted nights, ' +
        '   constant checking during the day. It is no exaggeration to say ' +
        '   that CamAPS FX has given us our lives back. Yes we still have to count carbs, ' +
        '   yes we bolus 10 mins before meals, and yes we have to change pump cannulae, but that’s it. ' +
        '   CamAPS FX gives us the freedom to forget about diabetes for most of the day.' +
        '</p> ' +
        '    <p> ' +
        '        <span>David, father of a 9 year old girl</span> ' +
        '    </p>',
    shortReview3:
        '<h2>Thank you. Last night was the best night’s sleep I have had for ages.</h2>' +
        '<p>' +
        '    <span>60+ year old adult user</span>' +
        '</p>',

    shortReview4:
        '<p> ' +
        'Overall, we noticed the effect on our child’s life: he had a significant ' +
        'improvement in developing his walking & talking. It has made a ' +
        'massive difference to the last few months. My son has been a lot more confident, ' +
        'less angry, and generally happier.' +
        '    </p> ' +
        '    <p> ' +
        '        <span>Mother of a toddler</span> ' +
        '    </p>',


};
