/**
 * Common texts - what is CamAPS FX
 */
export const whatIsCamAPSFX = {

    linkFindOutMore: 'Få mere at vide',
    body:
        '<h2>Hvad er CamAPS FX?</h2> <p> CamAPS FX er en app til styring af glukoseniveauer hos mennesker med type 1-diabetes, via et avanceret adaptivt lukket hybridsystem. </p>'
};

/**
 * Common texts - what is CamAPS FX
 */
export const userReviewsIntro = {
    linkMoreReviews: 'Flere anmeldelser',
    readMore: 'Læs mere',
    header:
        'CamAPS FX-brugeranmeldelser',
    text:
        'Rigtige historier om rigtige mennesker, der bruger CamAPS FX-appen'
};