import React, {Component} from 'react';
import {Header} from './Header';
import {Footer} from './Footer';
import 'simplebar/dist/simplebar.min.css';
import SimpleBar from 'simplebar-react';
import {NavLink} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import phone_image from '../assets/images/phone-hero-image.png';
import service_ic from '../assets/images/adaptable-ic.svg';
import smartphone_ic from '../assets/images/android-ic.svg';
import family_ic from '../assets/images/family-ic.svg';
import tested_ic from '../assets/images/tested-ic.svg';
import file_ic from '../assets/images/file-ic.svg';
import down_arrow from '../assets/images/download-arrow.png';
import article_img01 from '../assets/images/article-sam-and-sofia-steve.jpg';
import article_img02 from '../assets/images/article-roger.jpg';
import {samReviewText as samReviewText_en} from "../libs/en/texts-review-messages-lib";
import {samReviewText as samReviewText_de} from "../libs/de/texts-review-messages-lib";
import {samReviewText as samReviewText_fr} from "../libs/fr/texts-review-messages-lib";
import {samReviewText as samReviewText_nl} from "../libs/nl/texts-review-messages-lib";
import {samReviewText as samReviewText_it} from "../libs/it/texts-review-messages-lib";
import {samReviewText as samReviewText_cz} from "../libs/cz/texts-review-messages-lib";
import {samReviewText as samReviewText_fi} from "../libs/fi/texts-review-messages-lib";
import {samReviewText as samReviewText_pl} from "../libs/pl/texts-review-messages-lib";
import {samReviewText as samReviewText_se} from "../libs/se/texts-review-messages-lib";
import {samReviewText as samReviewText_no} from "../libs/no/texts-review-messages-lib";
import {samReviewText as samReviewText_dk} from "../libs/dk/texts-review-messages-lib";
import {samReviewText as samReviewText_es} from "../libs/es/texts-review-messages-lib";
import {rogerReviewText as rogerReviewText_en} from "../libs/en/texts-review-messages-lib";
import {rogerReviewText as rogerReviewText_de} from "../libs/de/texts-review-messages-lib";
import {rogerReviewText as rogerReviewText_fr} from "../libs/fr/texts-review-messages-lib";
import {rogerReviewText as rogerReviewText_nl} from "../libs/nl/texts-review-messages-lib";
import {rogerReviewText as rogerReviewText_it} from "../libs/it/texts-review-messages-lib";
import {rogerReviewText as rogerReviewText_cz} from "../libs/cz/texts-review-messages-lib";
import {rogerReviewText as rogerReviewText_fi} from "../libs/fi/texts-review-messages-lib";
import {rogerReviewText as rogerReviewText_pl} from "../libs/pl/texts-review-messages-lib";
import {rogerReviewText as rogerReviewText_se} from "../libs/se/texts-review-messages-lib";
import {rogerReviewText as rogerReviewText_no} from "../libs/no/texts-review-messages-lib";
import {rogerReviewText as rogerReviewText_dk} from "../libs/dk/texts-review-messages-lib";
import {rogerReviewText as rogerReviewText_es} from "../libs/es/texts-review-messages-lib";
import {homePageText as homePageText_en} from "../libs/en/texts-home-page-lib";
import {homePageText as homePageText_en_ca} from "../libs/en-ca/texts-home-page-lib";
import {homePageText as homePageText_de} from "../libs/de/texts-home-page-lib";
import {homePageText as homePageText_fr} from "../libs/fr/texts-home-page-lib"
import {homePageText as homePageText_fr_ca} from "../libs/fr-ca/texts-home-page-lib"
import {homePageText as homePageText_nl} from "../libs/nl/texts-home-page-lib"
import {homePageText as homePageText_it} from "../libs/it/texts-home-page-lib"
import {homePageText as homePageText_cz} from "../libs/cz/texts-home-page-lib"
import {homePageText as homePageText_fi} from "../libs/fi/texts-home-page-lib"
import {homePageText as homePageText_pl} from "../libs/pl/texts-home-page-lib"
import {homePageText as homePageText_se} from "../libs/se/texts-home-page-lib"
import {homePageText as homePageText_no} from "../libs/no/texts-home-page-lib"
import {homePageText as homePageText_dk} from "../libs/dk/texts-home-page-lib"
import {homePageText as homePageText_es} from "../libs/es/texts-home-page-lib"
import {userReviewsIntro as userReviewsIntro_en} from "../libs/en/common-text-lib";
import {userReviewsIntro as userReviewsIntro_en_ca} from "../libs/en-ca/common-text-lib";
import {userReviewsIntro as userReviewsIntro_de} from "../libs/de/common-text-lib";
import {userReviewsIntro as userReviewsIntro_fr} from "../libs/fr/common-text-lib";
import {userReviewsIntro as userReviewsIntro_fr_ca} from "../libs/fr-ca/common-text-lib";
import {userReviewsIntro as userReviewsIntro_nl} from "../libs/nl/common-text-lib";
import {userReviewsIntro as userReviewsIntro_it} from "../libs/it/common-text-lib";
import {userReviewsIntro as userReviewsIntro_cz} from "../libs/cz/common-text-lib";
import {userReviewsIntro as userReviewsIntro_fi} from "../libs/fi/common-text-lib";
import {userReviewsIntro as userReviewsIntro_pl} from "../libs/pl/common-text-lib";
import {userReviewsIntro as userReviewsIntro_se} from "../libs/se/common-text-lib";
import {userReviewsIntro as userReviewsIntro_no} from "../libs/no/common-text-lib";
import {userReviewsIntro as userReviewsIntro_dk} from "../libs/dk/common-text-lib";
import {userReviewsIntro as userReviewsIntro_es} from "../libs/es/common-text-lib";
import parse from "html-react-parser";
import popup_img from '../assets/images/popup-img.jpg';
import video_ic from '../assets/images/video-ic.png';
// import ReactGA from "react-ga4";

export class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: this.props.language,
            samReviewText: samReviewText_en,
            rogerReviewText: rogerReviewText_en,
            userReviewsIntro: userReviewsIntro_en,
            homePageText: homePageText_en,
            isOpen: null,
            prefix: ''
        };
        if (this.props.language === "DE") {
            this.state.samReviewText = samReviewText_de;
            this.state.rogerReviewText = rogerReviewText_de;
            this.state.userReviewsIntro = userReviewsIntro_de;
            this.state.homePageText = homePageText_de;
            this.state.prefix = '/de'
        }
        if (this.props.language === "EN-CA") {
            this.state.samReviewText = samReviewText_en;
            this.state.rogerReviewText = rogerReviewText_en;
            this.state.userReviewsIntro = userReviewsIntro_en_ca;
            this.state.homePageText = homePageText_en_ca;
            this.state.prefix = '/en-ca'
        }
        if (this.props.language === "FR") {
            this.state.samReviewText = samReviewText_fr;
            this.state.rogerReviewText = rogerReviewText_fr;
            this.state.userReviewsIntro = userReviewsIntro_fr;
            this.state.homePageText = homePageText_fr;
            this.state.prefix = '/fr-ch'
        }

        if (this.props.language === "FR-CA") {
            this.state.samReviewText = samReviewText_fr;
            this.state.rogerReviewText = rogerReviewText_fr;
            this.state.userReviewsIntro = userReviewsIntro_fr_ca;
            this.state.homePageText = homePageText_fr_ca;
            this.state.prefix = '/fr-ca'
        }

        if (this.props.language === "FR-FR") {
            this.state.samReviewText = samReviewText_fr;
            this.state.rogerReviewText = rogerReviewText_fr;
            this.state.userReviewsIntro = userReviewsIntro_fr;
            this.state.homePageText = homePageText_fr;
            this.state.prefix = '/fr'
        }

        if (this.props.language === "NL") {
            this.state.samReviewText = samReviewText_nl;
            this.state.rogerReviewText = rogerReviewText_nl;
            this.state.userReviewsIntro = userReviewsIntro_nl;
            this.state.homePageText = homePageText_nl;
            this.state.prefix = '/nl'
        }
        if (this.props.language === "IT") {
            this.state.samReviewText = samReviewText_it;
            this.state.rogerReviewText = rogerReviewText_it;
            this.state.userReviewsIntro = userReviewsIntro_it;
            this.state.homePageText = homePageText_it;
            this.state.prefix = '/it'
        }

        if (this.props.language === "CZ") {
            this.state.samReviewText = samReviewText_cz;
            this.state.rogerReviewText = rogerReviewText_cz;
            this.state.userReviewsIntro = userReviewsIntro_cz;
            this.state.homePageText = homePageText_cz;
            this.state.prefix = '/cz'
        }
        if (this.props.language === "FI") {
            this.state.samReviewText = samReviewText_fi;
            this.state.rogerReviewText = rogerReviewText_fi;
            this.state.userReviewsIntro = userReviewsIntro_fi;
            this.state.homePageText = homePageText_fi;
            this.state.prefix = '/fi'
        }
        if (this.props.language === "PL") {
            this.state.samReviewText = samReviewText_pl;
            this.state.rogerReviewText = rogerReviewText_pl;
            this.state.userReviewsIntro = userReviewsIntro_pl;
            this.state.homePageText = homePageText_pl;
            this.state.prefix = '/pl'
        }
        if (this.props.language === "SE") {
            this.state.samReviewText = samReviewText_se;
            this.state.rogerReviewText = rogerReviewText_se;
            this.state.userReviewsIntro = userReviewsIntro_se;
            this.state.homePageText = homePageText_se;
            this.state.prefix = '/se'
        }
        if (this.props.language === "NO") {
            this.state.samReviewText = samReviewText_no;
            this.state.rogerReviewText = rogerReviewText_no;
            this.state.userReviewsIntro = userReviewsIntro_no;
            this.state.homePageText = homePageText_no;
            this.state.prefix = '/nb'
        }
        if (this.props.language === "DK") {
            this.state.samReviewText = samReviewText_dk;
            this.state.rogerReviewText = rogerReviewText_dk;
            this.state.userReviewsIntro = userReviewsIntro_dk;
            this.state.homePageText = homePageText_dk;
            this.state.prefix = '/dk'
        }
        if (this.props.language === "ES") {
            this.state.samReviewText = samReviewText_es;
            this.state.rogerReviewText = rogerReviewText_es;
            this.state.userReviewsIntro = userReviewsIntro_es;
            this.state.homePageText = homePageText_es;
            this.state.prefix = '/es'
        }
    }

    setLanguageVars() {
        if (this.state.language !== this.props.language) {
            if (this.props.language === "EN") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_en,
                    rogerReviewText: rogerReviewText_en,
                    userReviewsIntro: userReviewsIntro_en,
                    homePageText: homePageText_en,
                    prefix: ''
                });
            }
            if (this.props.language === "DE") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_de,
                    rogerReviewText: rogerReviewText_de,
                    userReviewsIntro: userReviewsIntro_de,
                    homePageText: homePageText_de,
                    prefix: '/de'
                });
            } else if (this.props.language === "EN-CA") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_en,
                    rogerReviewText: rogerReviewText_en,
                    userReviewsIntro: userReviewsIntro_en_ca,
                    homePageText: homePageText_en_ca,
                    prefix: '/en-ca'
                });
            } else if (this.props.language === "FR") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_fr,
                    rogerReviewText: rogerReviewText_fr,
                    userReviewsIntro: userReviewsIntro_fr,
                    homePageText: homePageText_fr,
                    prefix: '/fr-ch'
                });
            } else if (this.props.language === "FR-CA") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_fr,
                    rogerReviewText: rogerReviewText_fr,
                    userReviewsIntro: userReviewsIntro_fr_ca,
                    homePageText: homePageText_fr_ca,
                    prefix: '/fr-ca'
                });
            } else if (this.props.language === "FR-FR") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_fr,
                    rogerReviewText: rogerReviewText_fr,
                    userReviewsIntro: userReviewsIntro_fr,
                    homePageText: homePageText_fr,
                    prefix: '/fr'
                });
            } else if (this.props.language === "NL") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_nl,
                    rogerReviewText: rogerReviewText_nl,
                    userReviewsIntro: userReviewsIntro_nl,
                    homePageText: homePageText_nl,
                    prefix: '/nl'
                });
            } else if (this.props.language === "IT") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_it,
                    rogerReviewText: rogerReviewText_it,
                    userReviewsIntro: userReviewsIntro_it,
                    homePageText: homePageText_it,
                    prefix: '/it'
                });
            } else if (this.props.language === "CZ") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_cz,
                    rogerReviewText: rogerReviewText_cz,
                    userReviewsIntro: userReviewsIntro_cz,
                    homePageText: homePageText_cz,
                    prefix: '/cz'
                });
            } else if (this.props.language === "FI") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_fi,
                    rogerReviewText: rogerReviewText_fi,
                    userReviewsIntro: userReviewsIntro_fi,
                    homePageText: homePageText_fi,
                    prefix: '/fi'
                });
            } else if (this.props.language === "PL") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_pl,
                    rogerReviewText: rogerReviewText_pl,
                    userReviewsIntro: userReviewsIntro_pl,
                    homePageText: homePageText_pl,
                    prefix: '/pl'
                });
            } else if (this.props.language === "SE") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_se,
                    rogerReviewText: rogerReviewText_se,
                    userReviewsIntro: userReviewsIntro_se,
                    homePageText: homePageText_se,
                    prefix: '/se'
                });
            } else if (this.props.language === "NO") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_no,
                    rogerReviewText: rogerReviewText_no,
                    userReviewsIntro: userReviewsIntro_no,
                    homePageText: homePageText_no,
                    prefix: '/nb'
                });
            } else if (this.props.language === "DK") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_dk,
                    rogerReviewText: rogerReviewText_dk,
                    userReviewsIntro: userReviewsIntro_dk,
                    homePageText: homePageText_dk,
                    prefix: '/dk'
                });
            } else if (this.props.language === "ES") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_es,
                    rogerReviewText: rogerReviewText_es,
                    userReviewsIntro: userReviewsIntro_es,
                    homePageText: homePageText_es,
                    prefix: '/es'
                });
            }
        }
    }

    componentDidUpdate() {
        this.setLanguageVars();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.videoRef = React.createRef();
    }

    setOpen = (value) => {
        if (!this.state.isOpen) {
            // ReactGA.pageview("Play_video_" + this.language);
            // ReactGA.send({ hitType: "pageview", page: "/home/video/" + this.language,
            //     title: "Play_video_" + this.language });
        }
        this.setState(
            {
                isOpen: value,
            },
            () => {

                if (this.state.isOpen) {
                    this.videoRef.current.play();
                } else {
                    this.videoRef.current.pause();
                }
                // const element = document.getElementsByTagName('body')[0];
                // if (this.state.isOpen) {
                // 	if (element) {
                // 		element.classList.add('model-open');
                // 	}
                // } else {
                // 	element.classList.remove('model-open');
                // }
            }
        );
    };

    render() {
        return (
            <div>
                <Helmet>
                    <title> {parse(this.state.homePageText.homePageTitle)}</title>
                    <meta name="title" content={parse(this.state.homePageText.homePageTitleMeta)}/>
                    <meta name="description"
                          content={parse(this.state.homePageText.homePageDescriptionMeta)}/>
                    <meta name="keywords" content={this.state.homePageText.keywords}/>
                    <meta property="og:locale" content={this.state.homePageText.localeMeta}/>
                </Helmet>
                <Header props={this.props}/>
                <section className="main-banner">
                    <div className="banner-wrap">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-7 col-xl-6 banner-left">
                                    <h1>CamAPS<sup>&reg;</sup> <span>FX</span></h1>
                                    <p>{parse(this.state.homePageText.introPara1)}</p>
                                    {/*{(this.state.language === "EN") &&*/}
                                    {/*<p style={{fontSize:16}}>*/}
                                    {/*    <a*/}
                                    {/*        href="https://www.bbc.co.uk/iplayer/episode/m0011n7r/the-one-show-15112021"*/}
                                    {/*        title="BBC One Show"*/}
                                    {/*        target="_blank" rel="noopener noreferrer">*/}
                                    {/*        As featured on the BBC One Show</a>*/}
                                    {/*    <br />*/}
                                    {/*    Dominic Littlewood, also living with type 1 diabetes, interviews Maebh (aged 5)*/}
                                    {/*    to learn how the CamAPS FX hybrid closed-loop system helps her*/}
                                    {/*    to manage her type 1 diabetes more easily.*/}
                                    {/*</p>}*/}
                                    {(this.state.language === "XXX") &&
                                    <p style={{fontSize: 16}}>
                                        <a
                                            href="https://www.cam.ac.uk/stories/KidsArtificialPancreas"
                                            title="CamAPS FX in very young children"
                                            target="_blank" rel="noopener noreferrer">
                                            Top news: The life-changing artificial pancreas</a>
                                        <br/>
                                        CamAPS FX improves glucose control in very young children
                                        as shown in a study published in a leading medical journal, 20 January 2022.
                                    </p>}
                                    <NavLink to={this.state.prefix + "/faq"}
                                             className="btn">
                                        {parse(this.state.homePageText.findOutMore)}</NavLink>
                                </div>
                                <div className="col-12 col-md-5 col-xl-6 banner-right">
                                    <img src={phone_image} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='blue-content-box'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-md-6 left__blue__content'>
                                <h2>
                                    <span>{parse(this.state.homePageText.missionHeading)}</span>
                                </h2>
                                <p>
                                    {parse(this.state.homePageText.missionText)}
                                </p>
                            </div>
                            {(this.state.language !== "FR-FR") &&
                            <div className='col-12 col-md-6 popup__wrapper'>
                                <VideoPopup
                                    setOpen={this.setOpen}
                                    isOpen={this.state.isOpen}
                                    videoRef={this.videoRef}
                                    language={this.props.language}
                                />
                                {
                                    !this.state.isOpen &&
                                    <a href="#!" className="video-btn" onClick={(e) => {
                                        e.preventDefault();
                                        this.setOpen(true)
                                    }}><img src={video_ic} alt=""/></a>
                                }
                            </div>}
                        </div>
                    </div>
                </section>
                <section className="service-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 service-col">
                                <div className="icon-col">
                                    <span className="col-img"><img src={service_ic} alt="icon"/></span>
                                    <h4>{parse(this.state.homePageText.adaptiveHeading)}</h4>
                                    <p>{parse(this.state.homePageText.adaptiveText)} </p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 service-col">
                                <div className="icon-col">
                                    <span className="col-img"><img src={smartphone_ic} alt="icon"/></span>
                                    <h4>{parse(this.state.homePageText.runsHeading)}</h4>
                                    <p>{parse(this.state.homePageText.runsText)}</p>
                                    <p><small>{parse(this.state.homePageText.runsTextTrademark)}</small></p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 service-col">
                                <div className="icon-col">
                                    <span className="col-img"><img src={family_ic} alt="icon"/></span>
                                    <h4>{parse(this.state.homePageText.allAgesHeading)}</h4>
                                    <p>{parse(this.state.homePageText.allAgesText)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {(this.state.language !== "FR-FR") &&
                <section className="single-ic-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-7">
                                <div className="content-left">
                                    <h2>{parse(this.state.homePageText.testedHeading)}</h2>
                                    <p>{parse(this.state.homePageText.testedText_p1)}</p>
                                    <p>{parse(this.state.homePageText.testedText_p2)}</p>
                                    <p>{parse(this.state.homePageText.testedText_p3)}</p>
                                    <ol>
                                        <li><a href="https://doi.org/10.1016/S0140-6736(18)31947-0"
                                               title="">https://doi.org/10.1016/S0140-6736(18)31947-0</a></li>
                                        <li><a href="https://www.nejm.org/doi/10.1056/NEJMoa1509351"
                                               title="">https://doi.org/10.1056/NEJMoa1509351</a></li>
                                        <li><a href="https://www.nejm.org/doi/10.1056/NEJMoa1602494"
                                               title="">https://doi.org/10.1056/NEJMoa1602494</a></li>
                                        <li><a href="https://care.diabetesjournals.org/content/42/4/594.long"
                                               title="">https://doi.org/10.1056/10.2337/dc18-1881</a></li>
                                        <li><a href="https://journals.sagepub.com/doi/full/10.1177/1932296817702656"
                                               title="">https://doi.org/10.1177/1932296817702656</a></li>
                                        <li><a
                                            href="https://diabetes.medicinematters.com/artificial-pancreas-systems/type-1-diabetes/the-artificial-pancreas--what-s-on-the-horizon-/16939360"
                                            title="">https://diabetes.medicinematters.com/artificial-pancreas-systems/type-1-diabetes/the-artificial-pancreas--what-s-on-the-horizon-/16939360</a>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div className="col-12 col-md-5 image-right-wrap">
                                <div className="image-right">
                                    <img src={tested_ic} alt="icon"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>}
                {(this.state.language !== "FR-FR") &&
                <section className='home-article-sec'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-sm-12'>
                                <div className='home-article-main'>
                                    <div
                                        className='article-img'
                                        style={{backgroundImage: `url(${article_img01})`}}>
                                        {/* <img src={article_img01} alt='icon' /> */}
                                    </div>
                                    <div className='article-content'>
                                        {parse(this.state.samReviewText.shortIntroAndBody)}
                                        <NavLink
                                            to={this.state.prefix + '/reviews/sam-and-sofia-stevie'}
                                            title={parse(this.state.userReviewsIntro.readMore)}
                                            className='border-link'>
                                            {parse(this.state.userReviewsIntro.readMore)}
                                        </NavLink>
                                    </div>
                                </div>
                                <div className='home-article-main'>
                                    <div
                                        className='article-img'
                                        style={{backgroundImage: `url(${article_img02})`}}>
                                        {/* <img src={article_img02} alt='icon' /> */}
                                    </div>
                                    <div className='article-content'>
                                        {parse(this.state.rogerReviewText.shortIntroAndBody)}
                                        <NavLink
                                            to={this.state.prefix + '/reviews/roger'}
                                            title={parse(this.state.userReviewsIntro.readMore)}
                                            className='border-link'>
                                            {parse(this.state.userReviewsIntro.readMore)}
                                        </NavLink>
                                    </div>
                                </div>
                                <div className='article-btn'>
                                    <NavLink className='btn'
                                             to={this.state.prefix + '/reviews'}
                                             title={parse(this.state.userReviewsIntro.linkMoreReviews)}>
                                        {parse(this.state.userReviewsIntro.linkMoreReviews)}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>}
                <section className='content-sec'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-sm-12'>
                                <div className='content-inner'>
                                    <h2>{parse(this.state.homePageText.whatIsFXheading)}</h2>
                                    <p>{parse(this.state.homePageText.whatIsFXtext)}</p>
                                    <NavLink to={this.state.prefix + "/faq"}
                                             className="btn">
                                        {parse(this.state.homePageText.findOutMore)}</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {(this.state.language !== 'EN-CA') && (this.state.language !== 'FR-CA') &&
                <section className="two-col-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <h2>{parse(this.state.homePageText.pressReleaseTitle)}</h2>
                            </div>
                            <div className="col-12 col-md-7">
                                <div className="left-col">
                                    {/*<div className="right-content">*/}
                                    {/*    <h5>20 March 2020</h5>*/}
                                    {/*    <h3>CE marking and UK launch</h3>*/}
                                    {/*    <p>World’s first artificial pancreas app licensed for people with*/}
                                    {/*        type 1 diabetes in the UK and EU.</p>*/}
                                    {/*    <a href="https://s3-eu-west-1.amazonaws.com/admin.camdiab.com/press.release/Press+release+-+launch+16+March+2020.pdf"*/}
                                    {/*       title="Download" className="download-link"><img src={down_arrow}*/}
                                    {/*                                                       alt="ic"/>Download</a>*/}
                                    {/*</div>*/}
                                    <div className="ic-image">
                                        <img src={file_ic} alt="ic"/>
                                    </div>
                                    <div className="right-content">
                                        <h5>{parse(this.state.homePageText.dateFDAPressRelease)}</h5>
                                        <h3>{parse(this.state.homePageText.headingFDAPressRelease)}</h3>
                                        <p>{parse(this.state.homePageText.textFDAPressRelease)}</p>
                                        <a href="https://s3.eu-west-1.amazonaws.com/admin.camdiab.com/press.release/CamDiab+secures+FDA+approval+for+advanced+adaptive+hybrid+closed-loop+app+CamAPS+FX.pdf"
                                           title="Download" className="download-link"><img src={down_arrow}
                                                                                           alt="ic"/>Download</a>
                                        <p>&nbsp;</p>
                                        <p>&nbsp;</p>
                                    </div>
                                    <div className="ic-image">
                                        <img src={file_ic} alt="ic"/>
                                    </div>
                                    <div className="right-content">
                                        <h5>{parse(this.state.homePageText.dateLibrePressRelease)}</h5>
                                        <h3>{parse(this.state.homePageText.headingLibrePressRelease)}</h3>
                                        <p>{parse(this.state.homePageText.textLibrePressRelease)}</p>
                                        <a href="https://s3.eu-west-1.amazonaws.com/admin.camdiab.com/press.release/221125_Ypsomed_PressRelease_FreeStyle_Libre3_final (003).pdf"
                                           title="Download" className="download-link"><img src={down_arrow}
                                                                                           alt="ic"/>Download</a>
                                        <p>&nbsp;</p>
                                        <p>&nbsp;</p>
                                    </div>
                                    <div className="ic-image">
                                        <img src={file_ic} alt="ic"/>
                                    </div>
                                    <div className="right-content">
                                        <h5>{parse(this.state.homePageText.dateAdcPressRelease)}</h5>
                                        <h3>{parse(this.state.homePageText.headingAdcPressRelease)}</h3>
                                        <p>{parse(this.state.homePageText.textAdcPressRelease)}</p>
                                        <a href="https://s3.eu-west-1.amazonaws.com/admin.camdiab.com/press.release/ADC_CamDiab_Ypsomed_Joint_Press_Release_4.24.22.pdf"
                                           title="Download" className="download-link"><img src={down_arrow}
                                                                                           alt="ic"/>Download</a>
                                        <p>&nbsp;</p>
                                        <p>&nbsp;</p>
                                    </div>
                                    <div className="ic-image">
                                        <img src={file_ic} alt="ic"/>
                                    </div>
                                    <div className="right-content">
                                        <h5>{parse(this.state.homePageText.dateYpsoPressRelease)}</h5>
                                        <h3>{parse(this.state.homePageText.headingYpsoPressRelease)}</h3>
                                        <p>{parse(this.state.homePageText.textYpsoPressRelease)}</p>
                                        <a href="https://s3.eu-west-1.amazonaws.com/admin.camdiab.com/press.release/220322_Media_release_EN_final+Ypsomed.pdf"
                                           title="Download" className="download-link"><img src={down_arrow}
                                                                                           alt="ic"/>Download</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-5">
                                <SimpleBar className="custom-scrollbar" forceVisible="y" autoHide="false">
                                    <div className="right-col">
                                        {/*<h5>Date tag</h5>*/}
                                        {/*<p>Title of previous press release</p>*/}
                                        {/*<a href="/#" title="Download" className="download-link"><img src={down_arrow} alt="ic" />Download</a>*/}
                                        {/*<h5>Date tag</h5>*/}
                                        {/*<p>Title of previous press release</p>*/}
                                        {/*<a href="/#" title="Download" className="download-link"><img src={down_arrow} alt="ic" />Download</a>*/}
                                        {/*<h5>Date tag</h5>*/}
                                        {/*<p>Title of previous press release</p>*/}
                                        {/*<a href="/#" title="Download" className="download-link"><img src={down_arrow} alt="ic" />Download</a>*/}
                                        {/*<h5>Date tag</h5>*/}
                                        {/*<p>Title of previous press release</p>*/}
                                        {/*<a href="/#" title="Download" className="download-link"><img src={down_arrow} alt="ic" />Download</a>*/}
                                        {/*<h5>Date tag</h5>*/}
                                        {/*<p>Title of previous press release</p>*/}
                                        {/*<a href="/#" title="Download" className="download-link"><img src={down_arrow} alt="ic" />Download</a>*/}
                                        {/*<h5>Date tag</h5>*/}
                                        {/*<p>Title of previous press release</p>*/}
                                        {/*<a href="/#" title="Download" className="download-link"><img src={down_arrow} alt="ic" />Download</a>*/}
                                    </div>
                                </SimpleBar>
                            </div>
                        </div>
                    </div>
                </section>
                }
                <Footer props={this.props}/>
            </div>
        );
    }
}

const VideoPopup = (props) => {
    return (
        <>
            <div

                className='popup-img'>
                <video
                    preload='auto'
                    playsInline={true}
                    ref={props.videoRef}
                    controls
                    poster={popup_img}
                    type='video/mp4'
                    onPlay={(e) => {
                        e.preventDefault();
                        props.setOpen(true)
                    }}
                    onPause={(e) => {
                        e.preventDefault();
                        props.setOpen(false)
                    }}
                    src={
                        props.language === "DE" ? `${process.env.PUBLIC_URL}/video/video_10_de.mp4` :
                            (props.language === "FR") || (props.language === "FR-FR") || (props.language === "FR-CA")? `${process.env.PUBLIC_URL}/video/video_10_fr.mp4` :
                                (props.language === "CZ") ? `${process.env.PUBLIC_URL}/video/video_our_mission_subtitles_cs.mp4` :
                                    (props.language === "DK") ? `${process.env.PUBLIC_URL}/video/video_our_mission_subtitles_da.mp4` :
                                        (props.language === "ES") ? `${process.env.PUBLIC_URL}/video/video_our_mission_subtitles_es.mp4` :
                                            (props.language === "FI") ? `${process.env.PUBLIC_URL}/video/video_our_mission_subtitles_fi.mp4` :
                                                (props.language === "IT") ? `${process.env.PUBLIC_URL}/video/video_our_mission_subtitles_it.mp4` :
                                                    (props.language === "NL") ? `${process.env.PUBLIC_URL}/video/video_our_mission_subtitles_nl.mp4` :
                                                        (props.language === "PL") ? `${process.env.PUBLIC_URL}/video/video_our_mission_subtitles_pl.mp4` :
                                                            (props.language === "SE") ? `${process.env.PUBLIC_URL}/video/video_our_mission_subtitles_sv.mp4` :
                                                                (props.language === "NO") ? `${process.env.PUBLIC_URL}/video/video_our_mission_subtitles_no.mp4` :
                                                                `${process.env.PUBLIC_URL}/video/video_10.mp4`}
                >
                </video>
            </div>


            {/* <TransitionsModal open={props.isOpen} handleClose={props.setOpen}>
				<LazyLoad>
					<div className='popup__wrapper1'>
						<div className='modal-video1'>
							<div className='close-btn' onClick={props.setOpen}>
								X
							</div>
							<video controls autoPlay>
								<source
									src={`${process.env.PUBLIC_URL}/video/video_10.mp4`}
									type='video/mp4'
								/>
							</video>
						</div>
					</div>
				</LazyLoad>
			</TransitionsModal> */}
        </>
    );
};
