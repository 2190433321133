import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Amplify} from "aws-amplify";
import * as serviceWorker from './serviceWorker';
// import * as ReactGA from 'react-ga';
import ReactGA from "react-ga4";
import {CookiesProvider} from 'react-cookie';
import config from "./config";
import {BrowserRouter as Router} from "react-router-dom";

if (!localStorage.getItem('camdiab-cookie-denied')) {
    // ReactGA.initialize('UA-149448704-1');
    ReactGA.initialize('G-WBG2BT66CG');
}

Amplify.configure({
        Auth: {
            Cognito: {
                mandatorySignIn: false,
                region: config.cognito.REGION,
                identityPoolId: config.cognito.IDENTITY_POOL_ID,
            }
        },
        Storage: {
            region: config.s3.REGION,
            bucket: config.s3.BUCKET,
            identityPoolId: config.cognito.IDENTITY_POOL_ID
        },
        API: {
            REST: {
                ordering: {
                    endpoint: config.apiGateway.URL,
                    region: config.apiGateway.REGION
                }
            }
        }
});

ReactDOM.render(
    <CookiesProvider>
        <Router classname="router-app">
            <App/>,
        </Router>,
    </CookiesProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

