/**
 * Texts for Support page
 */
export const supportPageText = {
    title: 'Brukerstøtte – CamAPS FX',
    titleMeta: 'Vi gir omfattende støtte for bruken av CamAPS FX',
    descriptionMeta: 'Vi har støttemateriell, inkludert brukerhåndbøker, nedlastbare retningslinjer, webinarer og kundeservice via telefon og e-post.',
    keywords:
        'camaps support, camaps fx support, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'nb_no',

    needHelp: 'Trenger du hjelp?',
    needHelpResponse: 'Vi håper at du finner svaret på spørsmålene dine i støttematerialet som er tilgjengelig her. Hvis ikke er du hjertelig velkommen til å kontakte oss.',
    clinicalSupportHeader: 'Medisinsk hjelp',
    clinicalSupportText: 'Hvis du har spørsmål om helsen din, må du ta kontakt med diabetesteamet ditt.',
    techSupportHeader: 'Teknisk og opplæringsrelatert støtte',
    techSupportHeaderText: 'Her finner du støttemateriale og kontaktinformasjon hvis du trenger å komme i kontakt med supportteamet vårt.',
    techMaterialHeader: 'Teknisk materiell:',
    userManual: 'Brukerhåndbok for CamAPS FX (PDF-leser kreves)',
    userManualEnglish_mmol_l: 'Engelsk',
    userManualCzech_mmol_l: 'Česky',
    userManualDanish_mmol_l: 'Dansk',
    userManualGerman_mg_dl: 'Deutsch',
    userManualGerman_mmol_l: 'Deutsch',
    userManualSpanish_mg_dl: 'Español',
    userManualFrench_mmol_l: 'Français',
    userManualFrench_mg_dl: 'Français',
    userManualItalian_mg_dl: 'Italiano',
    userManualItalian_mmol_l: 'Italiano',
    userManualDutch_mg_dl: 'Nederland',
    userManualDutch_mmol_l: 'Nederland',
    userManualNorsk_mmol_l: 'Norsk',
    userManualPolish_mg_dl: 'Polski',
    userManualFinish_mmol_l: 'Suomi',
    userManualSwedish_mmol_l: 'Svenska',

    previousManualVersions: 'Tidligere versjoner',
    installationManualHeader: 'Installasjon av CamAPS FX (DANA)',
    installationManualEnglish: 'Installasjonsveiledning',
    installationManualGerman: 'Installationsanleitung',
    installationManualFrench: 'Guide d\'installation',
    educationalMaterial: 'Opplæringsmateriell:',
    onlineTraining: 'Nettbasert opplæring:',
    onlineTrainingText: 'Levert av Cambridge Diabetes Education Programme (CDEP)',
    onlineTrainingLink: 'https://camdiabtraining.com',
    onlineTrainingText_1: 'GRATIS sertifisert nettbasert opplæring',
    webinarsHeading: 'Webinarer',
    webinar_1: 'Introduksjon i CamAPS FX systemet',
    webinar_2: 'Å starte med CamAPS FX systemet',
    webinar_3: 'Optimalisering av innstillingene i CamAPS FX',
    webinar_4: 'Opplæring av lærere og annet skolepersonell',
    webinar_5: 'Finjustering for å forbedre tid i målområdet',
    webinar_6: 'Bruk av CamAPS FX under graviditet',
    webinar_7: 'Bruk av CamAPS FX hos spedbarn, småbarn og unge barn',
    webinar_8: 'Bruk av CamAPS FX hos unge voksne og voksne',
    webinar_9: 'Styring av fysisk aktivitet, måltider og andre livshendelser',
    webinar_10: 'Closed loop-insulinbehandling og livskvalitet',
    webinar_11: 'Closed loop-behandling og mangeårig diabetes type 1',
    webinar_footnote: '',

    contactDetailsHeader: 'Kontaktinformasjon:',
    contactDetailsText: 'Brukerstøtteteamet vårt har kontortid på mandag til fredag fra kl. 09.00 til kl. 17.00.',
    contactDetailsEmailTitle: 'Send oss en e-post',
    contactDetailsEmail: 'support@camdiab.com',
    contactDetailsEmailFull: 'mailto:support@camdiab.com',
    contactDetailsPhoneTitle: 'Kontakt oss',
    contactDetailsPhone: '020 3695 3780',
    contactDetailsPhoneFull: 'tlf.:+442036953780',

    eIfuAccess: 'Du kan be om trykte kopier av brukerhåndbøkene fra kundeservice. Trykte kopier er gratis og ankommer i løpet av 3 til 7 dager. For best resultat bør du bruke Adobe<sup>®</sup> Reader<sup>®</sup> til å se CamDiabs brukerhåndbøker.',

    statusPageText: 'Statusside',
    statusPageLink: 'https://camdiabltd.statuspage.io/',

    installationYpsoPumpHeader: 'Installasjon av CamAPS FX (YpsoPump)',
    installationYpsoPumpText: 'Google Play',
    installationYpsoPumpLink: 'https://play.google.com/store/search?q=camaps%20fx&c=apps',

    danaPumpSupportText: 'Brukere av Dana pumpe',
    danaPumpSupportTextUK: 'Dana pump users in the UK',
    danaPumpSupportLinkUK: 'https://advancedtherapeutics.org.uk/contact-us/',
    ypsoPumpSupportText: 'Brukere av YpsoPump',
    ypsoPumpSupportLink: 'https://www.mylife-diabetescare.com/en/services/customer-care-contact.html',
};
