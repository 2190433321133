/**
 * Common texts - what is CamAPS FX
 */
export const whatIsCamAPSFX = {

    linkFindOutMore: 'Para saber más',
    body:
        '<h2>¿Qué es CamAPS FX?</h2> <p> CamAPS FX es una aplicación móvil para controlar los niveles de glucosa en personas con diabetes de tipo 1. Se basa en un avanzado enfoque adaptativo de Loop híbrido cerrado. </p>'
};

/**
 * Common texts - what is CamAPS FX
 */
export const userReviewsIntro = {
    linkMoreReviews: 'Más opiniones',
    readMore: 'Leer más',
    header:
        'Opiniones de usuarios de CamAPS FX',
    text:
        'Historias reales de personas reales que utilizan la app CamAPS FX'
};