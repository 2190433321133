import React, {Component} from 'react';
import {Header} from './Header';
import {Footer} from './Footer';
import {Helmet} from "react-helmet";

export class PrivacyNO extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Personvern - CamAPS FX</title>
                    <meta name="title" content='Personvernerklæring'/>
                    <meta name="description"
                          content='Her kan du finne personvernerklæringen for CamDiab.'/>
                    <meta property="og:locale" content='nb'/>
                    <meta name="keywords" content='personvernerklæring'/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10">
                                <h1>Personvernerklæring</h1>
                                <h3>Versjon datert 4. desember 2024</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <h2>
                                    1. INNLEDNING
                                </h2>
                                <h3>
                                    1.1. Ansvarlig enhet
                                </h3>
                                <p>
                                    CamDiab Limited, registrert kontoradresse 20-22 Wenlock Road, London, N1 7GU,
                                    Storbritannia, stiftet og registrert i England og Wales med bedriftsnummeret
                                    11659211 (“CamDiab”), er den ansvarlige enheten under personvernbestemmelsene.
                                    CamDiab bestemmer formålet ved og måten brukernes personopplysninger
                                    (“<strong>Brukerdata</strong>”) blir behandlet, og er derfor ansvarlig for
                                    sikkerheten og overholdelse av gjeldende lovverk.
                                </p>
                                <p>
                                    1.1.2 Som den ansvarlige enheten er vi underlagt for eksempel informasjonskrav som
                                    vi ønsker å oppfylle i forbindelse med denne personvernmeldingen.
                                </p>
                                <h3>
                                    1.2. Struktur og samsvarskonsept
                                </h3>
                                <p>
                                    1.2.1 Denne personvernmeldingen informerer deg om formålene og omfanget av
                                    behandlingen av brukerdataene dine, samt dataoverføringer og alle rettighetene dine.
                                    Ettersom tilbudet vårt utelukkende er rettet mot personer med diabetes, gir bruken
                                    din oss vanligvis informasjon om helsetilstanden din. Vi behandler derfor bare
                                    brukerdata som helsedata med ditt samtykke. Vi skiller på følgende måte:
                                </p>
                                <p>
                                    1.2.1.1 “Nødvendig behandling” beskriver hvordan vi behandler brukerdataene som er
                                    nødvendige for å oppfylle kontrakten. Uten dette samtykket er det ikke mulig å bruke
                                    produktene våre fra et juridisk og reelt synspunkt, fordi tjenestene våre avhenger
                                    av denne behandlingen.
                                </p>
                                <p>
                                    1.2.1.2 “Behandling for produktforbedring” forklarer hvordan du hjelper oss og andre
                                    brukere ved at du med ditt samtykke lar oss bruke dataene dine til spesielt å
                                    forbedre algoritmene for behandlingsadministrasjon, produktforbedring osv. uten at
                                    vi kontakter deg.
                                </p>
                                <p>
                                    1.2.1.3 “Behandling for markedsføringsformål” beskriver hvordan vi, med samtykke fra
                                    deg, tar kontakt i forbindelse med markedsføring, f.eks. via e-post, varsler osv.
                                </p>
                                <p>
                                    1.2.1.4 Under “Generell informasjon” har vi samlet informasjonen som gjelder for
                                    alle de forskjellige samtykkene over, for å unngå repetisjon.
                                </p>
                                <p>
                                    De relevante kategoriene er beskrevet mer detaljert nedenfor. Du må gi det relevante
                                    samtykket når du registrerer deg eller når du bestiller produktet.
                                </p>
                                <p>
                                    1.2.2 Noen steder kan behandlingen finne sted uavhengig av samtykke på bakgrunn av
                                    lovbestemmelser (f.eks. forskrifter knyttet til medisinsk utstyr).
                                </p>
                                <h2>
                                    2. NØDVENDIG BEHANDLING
                                </h2>
                                <p>
                                    Hvis du samtykker til det, behandler vi brukerdataene oppført nedenfor, for å kunne
                                    gi deg tjenestene våre. Hvis du ikke gir samtykke til denne nødvendige behandlingen,
                                    kan du ikke bruke tjenestene fra CamDiab. Du kan gi samtykkene under
                                    registreringsprosessen eller når du bestiller produktet.
                                </p>
                                <h3>
                                    2.1. Nødvendige brukerdata
                                </h3>
                                <p>
                                    2.1.1 For å beskytte brukerdataene dine kan tjenestene våre bare brukes i sammenheng
                                    med en brukerkonto. For å opprette en brukerkonto trenger vi, og behandler vi,
                                    følgende brukerdata:
                                </p>
                                <ul type="disc">
                                    <li>
                                        E-postadresse
                                    </li>
                                    <li>
                                        Passord (vi lagrer bare en kontrollsum)
                                    </li>
                                    <li>
                                        Navn
                                    </li>
                                    <li>
                                        Erklæring om fritak fra merverdiavgift (navn, adresse, dato. For personer som
                                        betaler for pleien sin selv)
                                    </li>
                                    <li>
                                        Fødselsår
                                    </li>
                                    <li>
                                        Registreringsdato
                                    </li>
                                    <li>
                                        Samtykkestatus
                                    </li>
                                    <li>
                                        Enhets-ID, produsent, enhetstype, operativsystemversjon, appversjon
                                    </li>
                                    <li>
                                        Språk, land, tidssone.
                                    </li>
                                </ul>
                                <p>
                                    2.1.2 Annen informasjon som hentes inn i tillegg til en brukerregistrering,
                                    omfatter:
                                </p>
                                <p>
                                    <strong>Medisinske hoveddata</strong>
                                    <br/>
                                    Vekt, målområde for blodglukose, basalinnstillinger på pumpe, korreksjonsfaktorer,
                                    karbohydrat/insulin-forhold, insulinets virketid, målglukose, varselsinnstillinger,
                                    bolustrinn, karbohydratenhet, graviditetsstatus, estimert termindato.
                                </p>
                                <p>
                                    <strong>Bruks- og koblingsdata</strong>
                                    <br/>
                                    Serienummer på pumpe og sender, pumpe, sensor og handlingsmønster for closed loop /
                                    open loop, appkommandoflytmønster, hendelsesmønster, informasjon om følgere slik de
                                    er angitt i appen (telefonnummer, innloggingsinformasjon til
                                    diabetesdataportalkontoer), støtteforespørsler og informasjon om instruktør,
                                    diabetesklinikkens ID.
                                </p>
                                <p>
                                    <strong>Medisinske data</strong>
                                    <br/>
                                    Angivelser i appen, som dato/klokkeslett/tidssone/sted, matinntak/måltid, blod- og
                                    sensorglukosemålinger, endringshastighet for sensorglukose, total daglig
                                    insulindose, insulintilførsel (bolus og basal), midlertidig basaldose, closed
                                    loop-status, priming av pumpe og kanyle, varsler, boost- og ease-off-hendelser,
                                    appinnstillinger som visningsalternativer, statistiske sammendrag av glukose- og
                                    insulindata.
                                </p>
                                <p> 2.1.3 Appen kan kreve tilgang til din posisjon utelukkende for å aktivere Bluetooth
                                    Low Energy (BLE)-tilkobling, som påkrevd av operativsystemets tillatelser. Ingen
                                    posisjonsdata samles inn, lagres eller deles av appen.
                                </p>
                                <p>
                                    2.1.4 Hvis du nsker det, kan du oppgi en hvilken som helst e-postadresse som du
                                    oppretter spesielt for oss, men den må virke slik at vi kan sende deg viktige
                                    advarsler.
                                </p>
                                Vi behandler kun brukerdata du aktivt og frivillig oppgir til CamDiab eller som
                                innhentes fra glukosesensor og insulinpumpe du frivillig kobler deg til. For å kunne
                                bruke produktene våre i utstrakt grad, er det et krav å angi nødvendige brukerdata.
                                <h3>
                                    2.2. Nødvendige formål
                                </h3>
                                <p>
                                    2.2.1 Alle de nødvendige formålene med databehandlingen vår er knyttet til å
                                    tilveiebringe tjenestene våre:
                                </p>
                                <p>
                                    <strong>Kundeforespørsel</strong> fører til dataoppføringer av kontaktinformasjon.
                                </p>
                                <p>
                                    Hvis en person som betaler for pleien sin selv, <strong>bestiller</strong> appene
                                    våre, resulterer det i kontaktinformasjon og oppføringer av teknisk data, for
                                    eksempel enhets-ID.
                                </p>
                                <p>
                                    <strong>Installasjon</strong> av appene våre resulterer i tekniske og
                                    enhetsrelaterte dataoppføringer.
                                </p>
                                <p>
                                    <strong>Registrering</strong> fører til opprettelse av CamDiab kontoen din som
                                    bruker e-postadressen din og passordet ditt.
                                </p>
                                <p>
                                    <strong>Tilveiebringelsen av tjenesten vår</strong> krever at du frivillig og aktivt
                                    skriver inn eller gir data og hver funksjon beskriver formålet som krever dataene.
                                </p>
                                <p>
                                    Kommunikasjon fra CamDiab eller distributør med deg i appene våre eller via andre
                                    elektroniske meldingstjenester (f.eks. e-post, chat, telefon) der det er nødvendig
                                    for å gi brukerstøtte eller feilsøke produktene våre. Dette er måten vi behandler
                                    alle kommentarer og forespørsler du måtte ha på, via forskjellige
                                    kommunikasjonskanaler når du bruker CamDiab. Det viktigste eksemplet er
                                    brukerstøtte, som du kan kontakte via <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Tenk derfor på hva slags
                                    informasjon og data du ønsker å gi i den aktive kommunikasjonen med oss – det er
                                    helt opp til deg. For vår del kan det være nødvendig å kommunisere med brukere via
                                    e-post eller noe lignende. Det er på denne måten vi informerer deg om oppdateringer
                                    av produktene våre og gir deg viktige sikkerhetsråd, samt hjelp knyttet til bruken
                                    din. Denne brukerstøttekommunikasjonen, som er en viktig del av produktene våre,
                                    sendes brukere.
                                </p>
                                <p>
                                    <strong>Behandlingsenheter</strong> (f.eks. glukosesensor og insulinpumper)
                                    sammenkobles med enheten din, noe som gjør det mulig å sende data til appene våre.
                                </p>
                                <p>
                                    <strong>Diabetesdataportaler og datadeling</strong>, for eksempel fra mylife
                                    Cloud/Glooko, gjør det mulig for data å bli utvekslet med appene våre. Men
                                    synkronisering finner bare sted hvis du aktiverer dette i innstillingene i appene
                                    våre, hvis du bruker funksjonen. Personvernmelding fra produsenter av
                                    diabetesdataportaler gjelder for data som deles med slike
                                    tredjepartssamarbeidspartnere.
                                </p>
                                <p>
                                    2.2.2 <strong>Bruk</strong> av appene våre krever at du aktivt og frivillig skriver
                                    inn eller oppgir data. For å løse en feil i appen må vi for eksempel ha
                                    krasjrapporter som vi kan bruke til feilsøking for å fastslå omstendighetene rundt
                                    problemet. Dessuten innhenter vi viktig informasjon om enheten din og hvordan du
                                    bruker for å kunne oppfylle kravene i kontrakten vår. Dette innebærer hovedsakelig å
                                    skreddersy produktene våre til behovene dine, for eksempel ved å behandle personlig
                                    brukerdata basert på faktorer som posisjon (som også er viktig for søkefunksjonen).
                                    Det utføres en automatisert analyse av brukeratferden utelukkende for å tilpasse
                                    bruken din i forbindelse med å oppfylle kontrakten og når det ikke har noen juridisk
                                    innvirkning på deg.
                                </p>

                                <h2>
                                    3. BEHANDLING FOR PRODUKTFORBEDRING
                                </h2>
                                <p>
                                    Vi behandler også brukerdataene dine utover nødvendig bruk, beskrevet i del 2 over,
                                    for å forbedre produktene og tjenestene våre, slik det er beskrevet mer detaljert
                                    nedenfor.
                                </p>
                                <h3>
                                    3.1. Ytterligere data
                                </h3>
                                <p>
                                    Generelt sett bruker vi de samme brukerdataene som angitt i del 2, til å forbedre
                                    produktene våre. I tillegg kan CamDiab også lagre de følgende brukerdataene:
                                </p>
                                <p>
                                    <strong>Bruksdata</strong>
                                    <br/>
                                    Aktivitetshendelser som gjør oss i stand til å forstå hvordan du bruker produktene
                                    våre. Dette gjør oss i stand til å se hvordan produktene våre brukes og for eksempel
                                    hvor menydesign kan forbedres.
                                </p>
                                <h3>
                                    3.2. Formål med produktforbedring
                                </h3>
                                <p>
                                    Som et resultat av rask teknologisk framgang må vi hele tiden analysere, utvikle,
                                    teste og forbedre produktene våre og interaksjonene de gjør, for å sikre at
                                    innholdet vårt er nyttig for brukere på en så effektiv måte som mulig. For å oppnå
                                    dette kan det hende vi vurderer bruk, gjennomførbarhet, prognoser, kontroll og
                                    sikkerhet, og kunnskapen vi får, brukes i forbedrede nye versjoner av produktene
                                    dine, for eksempel appen. Disse forbedringene gjøres også via regelmessige
                                    oppdateringer.
                                </p>
                                <p>
                                    CamDiab er opptatt av vitenskapen bak alle aspekter av diabetes. Anonyme brukerdata
                                    kan derfor også brukes til forskning og statistikk (alltid i henhold til anerkjente
                                    etiske vitenskapelige standarder) og interne analyser. Dette kan omfatte å dele
                                    anonymiserte data med tredjepartssamarbeidspartnere.
                                </p>
                                <h2>
                                    4. BEHANDLING TIL MARKEDSFØRINGSFORMÅL
                                </h2>
                                <h3>
                                    4.1. Nyhetsbrev
                                </h3>
                                <p>
                                    4.1.1 Det kan hende vi sender deg informasjon om produktene og tjenestene våre
                                    utover omfanget av avtalen (inkludert informasjon fra nøye utvalgte partnere) og
                                    invitasjoner til å delta i spørreundersøkelser eller andre salgskampanjer og
                                    markedsføringsaktiviteter (“<strong>Nyhetsbrev</strong>”).
                                </p>
                                <p>
                                    4.1.2 Du kan velge om du ønsker å abonnere på nyhetsbrevet vårt eller ikke (opt-in).
                                    Du kan trekke tilbake samtykket når som helst i kontoinnstillingene.
                                </p>
                                <h3>
                                    4.2. Andre typer markedsføring
                                </h3>
                                <p>
                                    4.2.1 Andre samtykker, f.eks. for spørreundersøkelser, varsler eller tilpassede
                                    tilbud, innhentes etter behov. Vi forklarer deg alltid hvorfor vi trenger bestemte
                                    data og også hvordan du kan trekke tilbake samtykket.
                                </p>
                                <p>
                                    4.2.2 Vær oppmerksom på at vi kan vise deg tilbud i appen uten å behandle
                                    personopplysningene dine. Du kommer også til å se disse generelle reklamene hvis du
                                    ikke har gitt samtykket.
                                </p>
                                <h2>
                                    5. BRUK TIL LOVMESSIGE FORMÅL
                                </h2>
                                <h3>
                                    5.1. Vitenskapelig forskning og statistikk
                                </h3>
                                <p>
                                    CamDiab er opptatt av vitenskapen bak alle aspekter av diabetes. Anonyme brukerdata
                                    kan derfor også brukes til forskning og statistikk (alltid i henhold til anerkjente
                                    etiske vitenskapelige standarder) og interne analyser. Dette brukes hovedsakelig til
                                    å fastslå og forbedre effektiviteten av teknikker for kontroll og behandling av
                                    diabetes. Det juridiske grunnlaget for dette er artikkel 9 (2) j) EUs
                                    personvernforordning (GDPR).
                                </p>
                                <h3>
                                    5.2. Håndheving av rettigheter
                                </h3>
                                <p>
                                    Bruken av personopplysninger kan også være nødvendig for å forhindre misbruk av
                                    brukere eller for å hevde, utøve eller forsvare juridiske krav. Vi kan være tvunget
                                    til å utlevere informasjon på grunn av bindende lover, rettsavgjørelser eller
                                    offentlige bestemmelser og instruksjoner, etterforskning eller av allment hensyn. I
                                    slike tilfeller er lagring og behandling av opplysningene dine er tillatt ifølge
                                    loven uten ditt samtykke. Det juridiske grunnlaget for dette er artikkel 9 (2) f)
                                    GDPR.
                                </p>
                                <h3>
                                    5.3. I henhold til lovverk nyttet til medisinsk utstyr
                                </h3>
                                <p>
                                    Avslutningsvis, som produsenten eller distributøren av medisinsk utstyr, er vi
                                    underlagt strengere krav for overvåkning av produktets funksjoner. Dette
                                    aktsomhetssystemet, som er lovpålagt, kan også involvere behandling av
                                    personopplysninger. Det juridiske grunnlaget for dette er artikkel 9 (2) i) GDPR.
                                </p>
                                <h2>
                                    6. GENERELL INFORMASJON
                                </h2>
                                <h3>
                                    6.1. Formålsbegrensning og -sikkerhet
                                </h3>
                                <p>
                                    6.1.1 CamDiab bruker de personlige opplysningene dine utelukkende til formålene
                                    fastslått i denne personvernsmeldingen og de relevante samtykkene. Vi sikrer at hver
                                    behandling er begrenset til det omfanget som er nødvendig for formålet.
                                </p>
                                <p>
                                    6.1.2 Hver behandling garanterer alltid tilstrekkelig sikkerhet og konfidensialitet
                                    av de personlige opplysningene dine. Dette dekker beskyttelse mot uautorisert og
                                    ulovlig behandling, utilsiktet tap, utilsiktet ødeleggelse eller skade, ved hjelp av
                                    passende tekniske og organisatoriske tiltak. Vi bruker strenge interne prosesser,
                                    sikkerhetsfunksjoner og krypteringsmetoder.
                                </p>
                                <h3>
                                    6.2. Databehandlere
                                </h3>
                                <p>
                                    6.2.1 Produktene våre er underlagt kompliserte prosesser som vi må administrere og
                                    holde oppdatert. Det kan hende vi bruker tredjepartsleverandører til teknisk støtte
                                    (“<strong>Behandlere</strong>”) for å tilby deg en omfattende og optimal bruk av
                                    produktene våre.
                                </p>
                                <p>
                                    6.2.2 CamDiab overfører brukerdata til Behandlere utelukkende i henhold til
                                    rammeverket til denne personvernmeldingen og kun for å oppfylle formålene angitt i
                                    den. Behandlere arbeider i henhold til spesifikasjonene og instruksjonene våre. De
                                    har ikke lov til å bruke de personlige opplysningene til brukerne våre til egne
                                    eller andre formål.
                                </p>
                                <p>
                                    6.2.3 Vi bruker Behandlere som tilbyr tilstrekkelige garantier om at passende
                                    tekniske og organisatoriske tiltak utføres på en måte der behandlingen av
                                    personopplysninger overholder lovmessige krav og vår personvernmelding. Beskyttelsen
                                    av rettighetene til brukerne våre er sikret ved å inngå bindende kontrakter som
                                    oppfyller de strenge kravene i Personvernforordningen.
                                </p>
                                <h3>
                                    6.3. Kryptering, pseudonymisering og anonymisering
                                </h3>
                                <p>
                                    6.3.1 Hver dataoverføring, uten unntak og som standard, er kryptert under
                                    overføring. Ved hjelp av HTTPS (hypertext transfer protocol secure) eller
                                    tilsvarende, sikrer vi at dataene dine ikke fanges opp av uautoriserte tredjeparter.
                                </p>
                                <p>
                                    I tillegg, av datasikkerhets- og minimeringsformål, bruker vi også andre prosesser
                                    til kryptering og pseudonymisering av brukerdata. Dette avhenger selvfølgelig av
                                    typen, omfanget og formålet med den relevante databehandlingen. Vi tilgjengeliggjør
                                    for eksempel bare brukerdata som en Behandler trenger for å utføre oppgavene sine.
                                </p>
                                <p>
                                    6.3.2 Når et kontraktsforhold med en Behandler avsluttes, må en slik Behandler,
                                    etter CamDiabs skjønn, enten returnere alle våre brukeres data eller slette det hvis
                                    det ikke finnes noen lovmessige lagringsforpliktelser.
                                </p>
                                <p>
                                    6.3.3 Data som ikke krever noen personlig referanse for behandling (f.eks. for
                                    forskning og analyse) blir anonymisert. Dette forhindrer at finnes noen forbindelse
                                    til en bestemt bruker i alle tilfeller
                                </p>
                                <h3>
                                    6.4. EU og andre land
                                </h3>
                                <p>
                                    6.4.1 Vi søker å velge samarbeidspartnere som befinner seg i, eller som har servere
                                    som befinner seg i, EU eller EØS. Dataoverføring innenfor EU og EØS er akseptabel
                                    fordi Personvernforordningen gjelder i alle medlemsland.
                                </p>
                                <p>
                                    6.4.2 Under helt spesielle omstendigheter utnevner vi tredjepartsleverandører som
                                    befinner seg i EU, eller som har servere utenfor EU. Selv i slike tilfeller er
                                    personopplysningene underlagt strenge beskyttelsesnivåer i tråd med
                                    Personvernforordningen – enten gjennom en EU-tilstrekkelighetsbeslutning, som anser
                                    personvern i visse tredjepartsland som passende (f.eks. Sveits, Israel og New
                                    Zealand), eller gjennom visse standard kontraktklausuler godkjent av EU, som
                                    kontraktsforholdene med innleide databehandlere er basert på, eller gjennom
                                    tilsvarende juridiske instrumenter som er tillatt under Personvernforordningen.
                                </p>
                                <p>
                                    6.4.3 Dessuten sikrer vi at partnerne våre har ytterligere sikkerhetsstandarder på
                                    plass, for eksempel individuelle sikkerhetstiltak og personverntiltak eller
                                    -sertifiseringer under Personvernforordningen. Så hvis tredjepartsleverandører
                                    befinner seg i USA, for eksempel, er de underlagt Privacy Shield Framework
                                    (personvernrammeverk), godkjent av EU, eller tilsvarende internasjonalt anerkjente
                                    sikkerhetsstandarder.
                                </p>
                                <h3>
                                    6.5. Informasjonskapsler
                                </h3>
                                <p>
                                    CamDiab kan lagre informasjonskapsler for å tilby deg en omfattende rekke funksjoner
                                    og å gjøre bruken av nettsiden vår mer praktisk. Informasjonskapsler er små
                                    tekstfiler som lagres på enheten din av nettleseren din. Bortsett fra
                                    informasjonskapslene for bruksdata, som er nevnt i del 6.6, kan informasjonskapslene
                                    brukes til å drifte nettsiden. Hvis du ikke ønsker å bruke informasjonskapsler, kan
                                    du forhindre dem fra å bli lagret ved å bruke relevante innstillinger i nettleseren.
                                    De fleste informasjonskapslene våre blir enten slettet når besøket ditt er over
                                    eller når nettleseren lukkes (øktinformasjonskapsler). Hvis dette ikke er tilfelle,
                                    kan du kontrollere perioden for sletting i nettleseren din eller slette
                                    informasjonskapslene manuelt.
                                </p>
                                <h3>
                                    6.6. Bruksdata
                                </h3>
                                <p>
                                    Vi bruker Google Firebase Analytics i appen, en app- og nettanalysetjeneste fra
                                    Google Inc. (“Google”). Google er sertifisert under EU-US Privacy Shield.
                                </p>
                                <h3>
                                    6.7. Lagring og sletting
                                </h3>
                                <p>
                                    6.7.1 Brukerdataene dine er lagret på enheten. Disse dataene lagres også på servere.
                                    Vi bruker bare systemer som oppfyller kravene i Personvernforordningen.
                                </p>
                                <p>
                                    6.7.2 Dataene dine er lagret på servere i EU.
                                </p>
                                <p>
                                    6.7.3 Som prinsipp lagrer CamDiab personopplysningene dine bare så lenge kontrakten
                                    er gyldig. I helt spesielle tilfeller kan det være nødvendig å lagre opplysningene
                                    dine lenger for å oppfylle forpliktelser som gjelder etter kontraktsperioden, eller
                                    for å overholde lovbestemte lagringsforpliktelser eller utleveringsplikt, eller for
                                    å hevde, utøve eller forsvare juridiske krav (påtalefrist).
                                </p>
                                <h3>
                                    6.8. Mindreårige
                                </h3>
                                <p>
                                    Mindreårige, under seksten år, kan bare bruke produktene våre med samtykke fra en
                                    foresatt (<a href="/nb/terms">Generelle vilkår</a>). Dette gjelder også behandlingen
                                    av personopplysningene deres, som bare er lovlig hvis og i den grad samtykke har
                                    blitt innhentet gjennom den foresatte. Ellers er bruken av produktene våre ikke
                                    tillatt.
                                </p>
                                <h3>
                                    6.9. Personvernombudet
                                </h3>
                                <p>
                                    6.9.1 Personvernombudet vår kan svare på alle spørsmål om personvern via <a
                                    href="mailto:dpo@evalian.co.uk">dpo@evalian.co.uk</a>.
                                </p>
                                <p>
                                    6.9.2 Personvernombudet er i stor grad involvert i alle spørsmål knyttet til
                                    personvernet til brukerne våre. Som faglært ekspert overvåker vedkommende
                                    behandlingen vår av personopplysninger kontinuerlig for å sikre best mulig
                                    beskyttelse av brukerdataene dine.
                                </p>
                                <h2>
                                    7. RETTIGHETENE DINE
                                </h2>
                                <h3>
                                    7.1. Tilbaketrekking av samtykker
                                </h3>
                                <p>
                                    Hvis vi behandler brukerdataene dine basert på samtykket ditt, kan du trekke
                                    samtykket tilbake når som helst. Dette påvirker imidlertid ikke lovligheten av
                                    databehandlingen før samtykket ble trukket tilbake. Tjenestene våre vil fortsatt
                                    være tilgjengelige hvis de ikke er avhengige samtykket som ble trukket tilbake.
                                </p>
                                <h3>
                                    7.2. <strong>Informasjon, korrigering og begrensning</strong>
                                </h3>
                                <p>
                                    7.2.1 Hver bruker har rett til å be om informasjon om behandlingen av
                                    personopplysningene deres. Dette gjøres ved å kontakte oss når som helst via <a
                                    href="mailto:privacy@camdiab.com">privacy@camdiab.com</a>.
                                </p>
                                <p>
                                    7.2.2 Innsynsretten din dekker informasjon om formålene ved databehandlingen, data-
                                    og mottakerkategorier, lagringstid, opprinnelsen til opplysningene dine og
                                    rettighetene dine under personvernlovgivningen. Du finner all denne informasjonen i
                                    denne personvernmeldingen, og vi sender deg det gjerne i elektronisk format.
                                </p>
                                <p>
                                    7.2.3 Hvis det er feil i personopplysningene, kan du når som helst be om at
                                    informasjonen skal rettes opp eller fullstendiggjøres. Du har rett til å begrense
                                    databehandlingen mens en gjennomgang du har bedt om, blir gjennomført.
                                </p>
                                <h3>
                                    7.3. Sletting (“rett til å bli glemt”)
                                </h3>
                                <p>
                                    Hver bruker har rett til å be om at personopplysningene deres slettes. Dette gjøres
                                    ved å kontakte oss når som helst via <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    7.4. Klager
                                </h3>
                                <p>
                                    7.4.1 Hvis du føler at vi ikke beskytter personvernrettighetene dine i tilstrekkelig
                                    grad, kan du når som helst kontakte oss via <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a> eller kontakte
                                    personvernombudet direkte via <a
                                    href="mailto:dpo@evalian.co.uk">dpo@evalian.co.uk</a>. Vi behandler forespørselen
                                    din på passende måte.
                                </p>
                                <p>
                                    7.4.2 Enhver bruker har rett til å sende inn en klage til de relevante
                                    personvernmyndighetene som er ansvarlig for CamDiab, hvis de mener at behandlingen
                                    av personopplysningene deres ikke er i samsvar med personvernlovgivningen. Dessuten
                                    har brukeren rett til å klage til en tilsynsmyndighet i EU-medlemslandet de bor i,
                                    der arbeidsplassen deres befinner seg eller der det mistenkes at det har skjedd en
                                    overtredelse.
                                </p>

                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

