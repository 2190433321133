/**
 * Texts for History page
 */
export const historyPageText = {
    title: 'L\'aventure CamDiab',
    titleMeta: 'La recherche clinique en route vers CamAPS FX',
    descriptionMeta: 'Une chronologie de la façon dont l’équipe derrière CamAPS FX est venue ' +
        'aider les personnes atteintes de diabète avec notre algorithme de Cambridge',
    localeMeta: 'fr_fr',

    keywords:
        'camaps l’histoire, camaps fx l’histoire, ' +
        '',
    journeyHeading: 'L’aventure CamDiab',
    timelineHeading: 'Cette chronologie indique comment l’équipe derrière CamAPS FX ' +
        'est parvenue à aider les patients diabétiques grâce à notre algorithme de  Cambridge.',
    year2006_2010dates: '2006–2010',
    year2006_2010heading: 'Premiers essais en clinique',
    year2006_2010text: 'Début du projet de pancréas artificiel à Cambridge, ' +
        'avec des essais menés chez des enfants atteints de diabète de type 1. ' +
        'Adhésion au Consortium sur le pancréas artificiel de la Fondation de la recherche sur le diabète juvénile (FRDJ). ' +
        'Tests de résistance de l’algorithme de Cambridge suivant l\'effort et après ' +
        'consommation d’alcool chez des adultes présentant un diabète de type 1. ' +
        'Évaluation de l’algorithme de Cambridge chez des femmes enceintes présentant un diabète de type 1.',
    year2011_2014dates: '2011–2014',
    year2011_2014heading: 'Essais de faisabilité en situation nocturne et ' +
        'en situation d’autonomie à domicile',
    year2011_2014text: 'Utilisation nocturne de l’algorithme de Cambridge chez des enfants, ' +
        'des adultes et des femmes enceintes présentant un diabète de type 1. ' +
        'Utilisation de l’algorithme de Cambridge jour et nuit pendant sept jours chez des ' +
        'adultes présentant un diabète de type 1.',
    year2015_2016dates: '2015–2016',
    year2015_2016heading: 'Essais à court terme jour et nuit en situation d’autonomie à domicile',
    year2015_2016text: 'Utilisation de l’algorithme de Cambridge pendant quatre semaines ' +
        'chez des adultes présentant un diabète de type 1 bien maîtrisé. Utilisation de ' +
        'l’algorithme de Cambridge pendant trois semaines chez des adolescents présentant un diabète de type 1.',
    year2017_2018dates: '2017–2018',
    year2017_2018heading: 'Essais jour et nuit prolongés et chez le jeune enfant',
    year2017_2018text: 'Utilisation de l’algorithme de Cambridge pendant trois mois chez ' +
        'des enfants et des adultes présentant un diabète de type 1. Utilisation de ' +
        'l’algorithme de Cambridge pendant 2 ans chez des enfants et des adolescents dès ' +
        'la survenue du diabète. Utilisation de l’algorithme de Cambridge chez des enfants ' +
        'de 1 à 7 ans présentant un diabète de type 1.',
    year2019_2019dates: '2019',
    year2019_2019heading: 'Transition vers CamAPS FX ',
    year2019_2019text: 'Utilisation de l’application CamAPS FX chez des adultes ' +
        'âgés présentant un diabète de type 1. Utilisation de l’application CamAPS FX durant ' +
        'toute la grossesse chez des femmes enceintes présentant un diabète de type 1. ' +
        'Utilisation de l’application CamAPS FX chez des enfants de 1 à 7 ans présentant un diabète de type 1.',
    year2020_2020dates: 'À partir de 2020',
    year2020_2020heading: 'Autorisation réglementaire, commercialisation, essais cliniques',
    year2020_2020text: 'Marquage CE de l’application CamAPS FX pour son utilisation dans ' +
        'l’Union européenne et au Royaume-Uni. Poursuite des recherches pour ' +
        'évaluer les avantages de l’application et en soutenir le remboursement.',

};
