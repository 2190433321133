import React, {Component} from 'react';
import {Header} from './Header';
import {Footer} from './Footer';
import {Helmet} from "react-helmet";

export class PrivacyFRCA extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Privacy - CamAPS FX</title>
                    <meta name="title" content='Politique de confidentialité'/>
                    <meta name="description"
                          content='Vous trouverez ici la politique de confidentialité de CamDiab'/>
                    <meta property="og:locale" content='fr'/>
                    <meta name="keywords" content='Politique de confidentialité'/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10">
                                <h1>Politique de confidentialité</h1>
                                <h3>Version datée du 4 décembre 2024</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <h2>
                                    1. INTRODUCTION
                                </h2>
                                <h3>
                                    1.1. Entité responsable
                                </h3>
                                <p>
                                    CamDiab Limited, adresse du siège social 20-22 Wenlock Road, London, N1 7GU,
                                    Royaume-Uni, juridiquement constituée et enregistrée en Angleterre et au Pays de
                                    Galles sous le numéro d'entreprise 11659211 (« CamDiab »), est l'entité déclarée
                                    responsable dans le cadre des réglementations sur la protection des données. CamDiab
                                    décide de l'objectif et des moyens utilisés pour le traitement des données de nos
                                    utilisateurs (« <strong>Données d'Utilisateur</strong> ») et assume par conséquent
                                    la responsabilité de leur sécurité et de la conformité avec les lois applicables.
                                </p>
                                <p>
                                    1.1.2 En tant qu'entité responsable, nous sommes soumis, par exemple, à des
                                    exigences en matière d'information auxquelles nous souhaitons nous soumettre en lien
                                    avec cette déclaration sur la protection des données personnelles.
                                </p>
                                <h3>
                                    1.2. Structure et concept de consentement
                                </h3>
                                <p>
                                    1.2.1 Cette déclaration sur la protection des données personnelles vous informe sur
                                    les objectifs et le champ du traitement de vos Données d'Utilisateur, ainsi que sur
                                    les transferts de données et sur vos droits importants. Notre offre étant
                                    exclusivement destinée à des personnes atteintes de diabète, votre utilisation
                                    fournit déjà généralement des informations sur votre état de santé. Nous ne traitons
                                    par conséquent les Données d'Utilisateur en tant que données de santé qu'avec votre
                                    consentement. Nous faisons les différences suivantes :
                                </p>
                                <p>
                                    1.2.1.1 « Traitement nécessaire » décrit la manière dont nous traitons les Données
                                    d'Utilisateur nécessaires pour exécuter le contrat. Sans ce consentement,
                                    l'utilisation de nos produits n'est pas possible d’un point de vue juridique et
                                    factuel, car nos services dépendent de ce traitement.
                                </p>
                                <p>
                                    1.2.1.2 « Traitement pour l'amélioration des Produits » explique comment vous pouvez
                                    nous aider et aider d'autres utilisateurs, avec votre consentement, en nous
                                    permettant d'utiliser vos données plus particulièrement pour améliorer les
                                    algorithmes pour la prise en charge du traitement, améliorer le produit, etc., sans
                                    que nous vous contactions.
                                </p>
                                <p>
                                    1.2.1.3 « Traitement à des fins marketing » décrit la façon dont nous vous
                                    contactons à des fins de marketing, avec votre consentement, par exemple par
                                    courrier électronique, notifications, etc.
                                </p>
                                <p>
                                    1.2.1.4 Dans la rubrique « Informations générales », nous avons regroupé les
                                    informations s'appliquant à l'ensemble des consentements ci-dessus de manière à
                                    éviter les répétitions.
                                </p>
                                <p>
                                    Les catégories pertinentes sont décrites plus en détail ci-dessous. Vous devez
                                    impérativement donner le consentement approprié lors de votre inscription ou de la
                                    commande de votre produit.
                                </p>
                                <p>
                                    1.2.2 Dans certains cas, le traitement peut intervenir indépendamment du
                                    consentement sur la base des principes légaux (p. ex. règlements relatifs aux
                                    dispositifs médicaux).
                                </p>
                                <h2>
                                    2. TRAITEMENT NÉCESSAIRE
                                </h2>
                                <p>
                                    Si vous donnez votre consentement, nous traitons les Données d'Utilisateur énumérées
                                    ci-dessous pour pouvoir assurer nos services. Si vous ne consentez pas à ce
                                    traitement nécessaire, vous ne pourrez pas utiliser les services de CamDiab. Vous
                                    pouvez donner vos consentements pendant le processus d'inscription ou lors de la
                                    commande de votre produit.
                                </p>
                                <h3>
                                    2.1. Données d'Utilisateur nécessaires
                                </h3>
                                <p>
                                    2.1.1 Pour protéger vos Données d'Utilisateur, nos services ne peuvent être utilisés
                                    qu'en lien avec un compte utilisateur. Pour créer un compte utilisateur, nous
                                    demandons et traitons les Données d'Utilisateur suivantes :
                                </p>
                                <ul type="disc">
                                    <li>
                                        Adresse électronique
                                    </li>
                                    <li>
                                        Mot de passe (nous n'enregistrons qu'une somme de contrôle)
                                    </li>
                                    <li>
                                        Nom
                                    </li>
                                    <li>
                                        Déclaration d'exonération de TVA (nom, adresse, date ; pour les personnes
                                        finançant elles-mêmes leur produit)
                                    </li>
                                    <li>
                                        Année de naissance
                                    </li>
                                    <li>
                                        Date d'inscription
                                    </li>
                                    <li>
                                        Statut du consentement
                                    </li>
                                    <li>
                                        ID du dispositif, fabricant, type de dispositif, version du système
                                        d'exploitation, version de l'application
                                    </li>
                                    <li>
                                        Langue, pays, fuseau horaire.
                                    </li>
                                </ul>
                                <p>
                                    2.1.2 Les autres informations collectées en dehors de l'inscription d'un utilisateur
                                    incluent :
                                </p>
                                <p>
                                    <strong>Données médicales permanentes </strong>
                                    <br/>
                                    Poids, plage de glycémie cible, paramétrage basal de la pompe, facteurs de
                                    correction, rapport glucides / insuline, durée d'action de l'insuline, taux de
                                    glucose cible, paramétrage des alertes, incrément de bolus, unité des glucides,
                                    statut de grossesse, date d'accouchement estimée.
                                </p>
                                <p>
                                    <strong>Données sur l'usage et les liens</strong>
                                    <br/>
                                    Numéro de série de la pompe et de l'émetteur, pompe, capteur et trace des actions en
                                    boucle fermée/boucle ouverte, trace du flux de commandes de l'application, trace des
                                    événements, informations sur les suiveurs telles que saisies dans l'application
                                    (numéro de téléphone, identifiants des comptes sur le portail de données sur le
                                    diabète), demandes d'assistance, données sur le formateur, ID de la clinique du
                                    diabète.
                                </p>
                                <p>
                                    <strong>Données médicales</strong>
                                    <br/>
                                    Entrées de l'application telles que date/heure/fuseau horaire/lieu, prise
                                    d'aliments/repas, mesures du glucose sanguin et du capteur de glucose, vitesse de
                                    changement du glucose mesuré par le capteur, dose d'insuline totale quotidienne,
                                    administration d'insuline (bolus et insuline basale), débit basal temporaire, statut
                                    boucle fermée, amorçage de la pompe et de la canule, alertes, événements
                                    d'augmentation et de diminution, paramétrage de l'application comme par exemple les
                                    options d'affichage, les synthèses statistiques des données sur le glucose et
                                    l'insuline.
                                </p>
                                <p>
                                    2.1.3 L'application peut nécessiter l'accès à votre position uniquement pour
                                    permettre la connectivité Bluetooth Low Energy (BLE), comme l'exigent les
                                    autorisations du système d'exploitation. Cependant, aucune donnée de localisation
                                    n'est collectée, stockée ou partagée par l'application.
                                </p>
                                <p>
                                    2.1.4 Si vous le souhaitez, vous pouvez saisir toute adresse électronique que vous
                                    créez spécifiquement pour nous – elle doit toutefois impérativement fonctionner pour
                                    que nous puissions vous envoyer des avertissements importants.
                                </p>
                                Nous ne traitons que les Données d'Utilisateur que vous communiquez activement et
                                volontairement à CamDiab ou qui sont obtenues via le capteur de glucose et la pompe à
                                insuline auxquels vous vous connectez volontairement. La saisie des Données
                                d'Utilisateur nécessaires est toutefois indispensable pour l'utilisation complète de nos
                                produits.
                                <h3>
                                    2.2. Objectifs nécessaires
                                </h3>
                                <p>
                                    2.2.1 Tous les objectifs nécessaires de notre traitement sont associés à la
                                    prestation de nos services :
                                </p>
                                <p>
                                    Une <strong>demande du client </strong> entraîne l'enregistrement des coordonnées de
                                    contact.
                                </p>
                                <p>
                                    La <strong>commande </strong> de nos applications par les personnes finançant
                                    elles-mêmes leur produit entraîne l'enregistrement des coordonnées de contact et des
                                    données techniques telles que l'identifiant du dispositif.
                                </p>
                                <p>
                                    L'<strong>installation </strong> de nos applications entraîne l'enregistrement des
                                    données techniques et liées au dispositif.
                                </p>
                                <p>
                                    L'<strong>inscription </strong> entraîne la création de votre compte CamDiab en
                                    utilisant l'adresse électronique et le mot de passe.
                                </p>
                                <p>
                                    La <strong>prestation de nos services</strong> exige que vous saisissiez ou
                                    fournissiez volontairement et activement des données, et chaque fonction décrit
                                    pourquoi les données sont nécessaires.
                                </p>
                                <p>
                                    La communication de CamDiab ou du distributeur avec vous au sein de nos applications
                                    ou via d'autres services de messagerie électronique (p. ex. courriel, Messenger,
                                    téléphone) lorsque cela est nécessaire pour l'assistance ou le dépannage de nos
                                    produits. Voici comment nous traitons tous les commentaires et demandes que vous
                                    pourriez avoir via différents canaux de communication lorsque vous utilisez CamDiab.
                                    L'exemple le plus important est notre service d'assistance joignable à l'adresse <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Veuillez par conséquent
                                    réfléchir à quelles informations et données vous souhaitez fournir dans le cadre de
                                    votre communication active avec nous - cette décision vous revient exclusivement. De
                                    notre côté, la communication avec les utilisateurs peut être nécessaire par courriel
                                    ou moyen comparable. C'est ainsi que nous vous informons des mises à jour de nos
                                    produits et fournissons des conseils importants relatifs à la sécurité ainsi qu'une
                                    assistance en lien avec votre utilisation. Cette communication d'assistance - une
                                    partie essentielle de nos produits - est envoyée aux utilisateurs.
                                </p>
                                <p>
                                    Les <strong>dispositifs de traitement </strong> (p. ex. capteur de glucose et pompes
                                    à insuline) sont appariés avec votre dispositif, ce qui permet le transfert de vos
                                    données vers nos applications.
                                </p>
                                <p>
                                    Les <strong>portails de données sur le diabète et le partage des données</strong>,
                                    tels que ceux de mylife Cloud/Glooko, permettent l'échange des données avec nos
                                    applications. Cependant, la synchronisation n'intervient que si vous l'activez dans
                                    les paramètres de nos applications, c'est-à-dire si vous utilisez cette
                                    fonctionnalité. La déclaration sur la protection des données personnelles des
                                    fabricants des portails de données sur le diabète s'applique aux données partagées
                                    avec les tiers collaborateurs.
                                </p>

                                2.2.2 L'<strong>utilisation</strong> de nos applications exige que vous saisissiez ou
                                fournissiez volontairement et activement des données. Pour résoudre une erreur dans
                                l'application, il nous faut, par exemple, les rapports de plantage que nous pouvons
                                utiliser à des fins de diagnostic des pannes pour établir les circonstances du problème.
                                De plus, les données clés de votre dispositif et votre comportement d'utilisateur sont
                                enregistrés dans la mesure où notre exécution du contrat signifie, surtout, la
                                personnalisation de nos produits, c'est-à-dire le traitement des informations de chaque
                                utilisateur, par exemple en fonction de votre localisation (également pertinent pour la
                                fonctionnalité de recherche). Une analyse automatisée du comportement de l'utilisateur
                                est réalisée aux fins exclusives de personnaliser votre utilisation dans le cadre de
                                l'exécution du contrat et ne produit aucun effet légal pour vous.

                                <h2>
                                    3. TRAITEMENT POUR L'AMÉLIORATION DU PRODUIT
                                </h2>
                                <p>
                                    Nous traitons également vos Données d'Utilisateur au-delà de l'usage nécessaire
                                    décrit dans la section 2 ci-dessus pour améliorer nos produits et services comme
                                    nous le décrivons plus en détail ci-dessous.
                                </p>
                                <h3>
                                    3.1. Données supplémentaires
                                </h3>
                                <p>
                                    En général, nous utilisons les mêmes Données d'Utilisateur pour améliorer nos
                                    produits, comme mentionné dans la section 2. CamDiab peut en outre également
                                    enregistrer les Données d'Utilisateur suivantes :
                                </p>
                                <p>
                                    <strong>Données sur l'usage</strong>
                                    <br/>
                                    Évènements d'activité qui nous permettent de comprendre comment vous utilisez nos
                                    produits. Cela nous permet de voir comment nos produits sont utilisés et, par
                                    exemple, ce qui peut être optimisé dans la conception de nos menus.
                                </p>
                                <h3>
                                    3.2. Objectif d'amélioration du produit
                                </h3>
                                <p>
                                    Les progrès technologiques étant très rapides, nous devons en permanence analyser,
                                    développer, tester et améliorer nos produits ainsi que leurs interactions de manière
                                    à garantir que les utilisateurs bénéficient le plus efficacement possible de notre
                                    contenu. Pour y parvenir, nous pouvons procéder à des évaluations de l'usage, de la
                                    faisabilité, prédictives, du contrôle et de la sécurité, et les informations ainsi
                                    acquises sont intégrées dans les nouvelles versions améliorées de nos produits tels
                                    que les applications. Ces améliorations vous sont également fournies par le biais de
                                    mises à jour régulières.
                                </p>
                                <p>
                                    CamDiab se consacre à la science de tous les aspects du diabète. Par conséquent, les
                                    données anonymes des utilisateurs peuvent également être utilisées à des fins de
                                    recherche et de statistiques (toujours dans le respect des normes scientifiques
                                    éthiques reconnues) et d'analyses internes. Cela peut inclure le partage de données
                                    anonymes avec des collaborateurs tiers.
                                </p>
                                <h2>
                                    4. TRAITEMENT À DES FINS MARKETING
                                </h2>
                                <h3>
                                    4.1. Bulletin d'information
                                </h3>
                                <p>
                                    4.1.1 Il se peut que nous vous envoyions des informations sur les produits et
                                    services sortant de l'objet du contrat (y compris des informations de partenaires
                                    soigneusement sélectionnés) et des invitations à participer à des sondages ou à
                                    d'autres activités de promotion des ventes et de marketing (« <strong>Bulletin
                                    d'information</strong> »).
                                </p>
                                <p>
                                    4.1.2 Vous pouvez choisir de souscrire ou non à notre Bulletin d'information
                                    (adhésion). Vous pouvez révoquer votre consentement à tout moment dans les
                                    paramètres du compte.
                                </p>
                                <h3>
                                    4.2. Autres types de marketing
                                </h3>
                                <p>
                                    4.2.1 D'autres consentements, p. ex. pour les sondages, notifications ou offres
                                    personnalisées, sont obtenus le cas échéant. Nous vous expliquons toujours pourquoi
                                    nous avons besoin de certaines données ainsi que la manière de révoquer le
                                    consentement.
                                </p>
                                <p>
                                    4.2.2 Veuillez avoir à l'esprit que nous pouvons vous montrer des offres dans
                                    l'application sans traiter vos données personnelles. Vous verrez également ces
                                    publicités non personnalisées même si vous n'avez pas donné votre consentement.
                                </p>
                                <h2>
                                    5. USAGE À DES FINS LÉGALES
                                </h2>
                                <h3>
                                    5.1. Recherche scientifique et statistiques
                                </h3>
                                <p>
                                    CamDiab est attaché à la science de tous les aspects du diabète. Des Données
                                    d'Utilisateur anonymes peuvent par conséquent être également utilisées à des fins de
                                    recherche et de statistiques (toujours dans le respect des normes scientifiques et
                                    éthiques établies) et d'analyses internes. Elles sont essentiellement utilisées pour
                                    établir et améliorer l'efficacité des techniques de contrôle et de traitement du
                                    diabète. La base légale de ce point est l'article 9 (2) j) du RGPD.
                                </p>
                                <h3>
                                    5.2. Exécution des droits
                                </h3>
                                <p>
                                    L'utilisation des données personnelles peut également être nécessaire pour prévenir
                                    les abus d'utilisateurs ou pour faire valoir, exercer ou défendre des droits légaux.
                                    Il se peut que nous soyons forcés de divulguer des informations par des lois,
                                    décisions et instructions officielles ou de tribunaux contraignantes, des enquêtes
                                    criminelles ou dans l'intérêt public. Dans de tels cas, la conservation et le
                                    traitement de vos données sont autorisés par la loi sans votre consentement. La base
                                    légale de ce point est l'article 9 (2) f) du RGPD.
                                </p>
                                <h3>
                                    5.3. Conformément à la législation sur les dispositifs médicaux
                                </h3>
                                <p>
                                    Enfin, en tant que fabricant ou distributeur d'un dispositif médical, nous sommes
                                    soumis à des exigences plus fortes concernant la surveillance du caractère
                                    fonctionnel de notre produit. Ce système de surveillance nécessaire à des fins
                                    légales peut également impliquer le traitement des données personnelles. La base
                                    légale de ce point est l'article 9 (2) i) du RGPD.
                                </p>
                                <h2>
                                    6. INFORMATIONS GÉNÉRALES
                                </h2>
                                <h3>
                                    6.1. Limitation de la finalité et sécurité
                                </h3>
                                <p>
                                    6.1.1 CamDiab utilise vos données personnelles exclusivement aux fins établies dans
                                    cette déclaration sur la protection des données personnelles et les consentements
                                    correspondants. Nous garantissons que chaque traitement est limité au champ
                                    nécessaire à son objet.
                                </p>
                                <p>
                                    6.1.2 Chaque traitement garantit toujours une sécurité et une confidentialité
                                    appropriées de vos données personnelles. Cela englobe la protection contre le
                                    traitement non autorisé et illégal, la perte involontaire, la destruction ou la
                                    détérioration involontaire en employant des mesures techniques et organisationnelles
                                    appropriées. Nous employons des processus, des éléments de sécurité et des méthodes
                                    de cryptage internes stricts.
                                </p>
                                <h3>
                                    6.2. Sociétés assurant le traitement
                                </h3>
                                <p>
                                    6.2.1 Nos produits sont soumis à des processus complexes que nous devons prendre en
                                    charge et tenir à jour. Pour une assistance technique, il se peut que nous fassions
                                    appel à des tiers prestataires (« <strong>Sociétés de traitement</strong> ») pour
                                    vous offrir une utilisation complète et optimale de nos produits.
                                </p>
                                <p>
                                    6.2.2 CamDiab transfère les Données d'Utilisateur aux Sociétés de traitement dans le
                                    cadre exclusif de cette déclaration sur la protection des données personnelles et
                                    uniquement pour réaliser les objectifs qui y sont mentionnés. Ces sociétés
                                    travaillent conformément à nos spécifications et instructions ; elles ne sont pas
                                    autorisées à utiliser les données personnelles de nos utilisateurs à leurs propres
                                    fins ni à d'autres fins.
                                </p>
                                <p>
                                    6.2.3 Nous faisons appel à des Sociétés de traitement qui offrent suffisamment de
                                    garanties quant à la mise en œuvre de mesures techniques et organisationnelles de
                                    manière à assurer la conformité du traitement des données personnelles aux exigences
                                    légales et à notre déclaration sur la protection des données personnelles. La
                                    protection des droits de nos utilisateurs est assurée en passant des contrats
                                    juridiquement contraignants conformes aux exigences strictes du RGPD.
                                </p>
                                <h3>
                                    6.3. Cryptage, pseudonymisation et anonymisation
                                </h3>
                                <p>
                                    6.3.1 Chaque transfert de données, sans exception et par défaut, se fait sous forme
                                    cryptée. En utilisant le protocole HTTPS (hypertext transfer protocol secure) ou un
                                    format comparable, nous nous assurons que vos données ne sont pas interceptées par
                                    des tiers non autorisés.
                                </p>
                                <p>
                                    De plus, pour la sécurité et la minimisation des données, nous utilisons également
                                    d'autres processus pour le cryptage et la pseudonymisation des Données
                                    d'Utilisateur. Cela dépend bien entendu du type, de la portée et de l'objectif du
                                    traitement des données en question. Par exemple, nous ne communiquons que les
                                    Données d'Utilisateur dont une Société de traitement a besoin pour exécuter ses
                                    tâches.
                                </p>
                                <p>
                                    6.3.2 Lorsqu'une relation contractuelle d'une Société de traitement prend fin, la
                                    société en question doit impérativement, à la discrétion de CamDiab, soit nous
                                    renvoyer toutes nos Données d'Utilisateur soit les supprimer s'il n'y a pas
                                    d'obligations de conservation légales.
                                </p>
                                <p>
                                    6.3.3 Les données ne nécessitant aucune référence personnelle pour le traitement (p.
                                    ex. pour la recherche et l'analyse) sont anonymisées. Cela empêche dans tous les cas
                                    de les relier à un utilisateur spécifique.
                                </p>
                                <h3>
                                    6.4. UE et autres pays
                                </h3>
                                <p>
                                    6.4.1 Nous cherchons à sélectionner des partenaires qui sont basés ou dont les
                                    serveurs se trouvent dans l'Union européenne (UE) ou dans l'Espace économique
                                    européen (EEE). La transmission des données au sein de l'UE et de l'EEE est
                                    acceptable parce que le RGPD s'applique dans tous les États membres.
                                </p>
                                <p>
                                    6.4.2 Dans des circonstances exceptionnelles, nous faisons appel à des tiers
                                    prestataires qui sont basés ou dont les serveurs se trouvent hors de l'UE.
                                    Toutefois, même dans ces cas, vos données personnelles font l'objet d'un niveau de
                                    protection élevé conforme au RGPD – soit par le biais d'une décision d'adéquation de
                                    l'UE, qui considère que la protection des données dans certains pays tiers est
                                    appropriée (p. ex. Suisse, Israël et Nouvelle-Zélande), soit par le biais de
                                    certaines clauses contractuelles standard approuvées par l'UE, sur lesquelles
                                    reposent nos relations contractuelles avec les Sociétés de traitement, ou encore via
                                    des instruments juridiques comparables autorisés dans le cadre du RGPD.
                                </p>
                                <p>
                                    6.4.3 Nous nous assurons en outre que nos partenaires ont mis en place des normes de
                                    sécurité supplémentaires, telles que des mesures de sécurité individuelles et des
                                    dispositions ou certifications relatives à la protection des données dans le cadre
                                    du RGPD. Ainsi, par exemple, si les tiers prestataires sont basés aux États-Unis,
                                    ils doivent être soumis au Bouclier de protection des données approuvé par l'UE ou à
                                    des normes de sécurité comparables reconnues au niveau international.
                                </p>
                                <h3>
                                    6.5. Cookies
                                </h3>
                                <p>
                                    CamDiab peut stocker ce que l'on appelle des « cookies » pour vous offrir tout un
                                    ensemble de fonctionnalités et rendre l'utilisation de notre site Web plus pratique.
                                    Les cookies sont des petits fichiers textes conservés sur votre dispositif par votre
                                    navigateur. À l'exception des cookies destinés aux données sur l'usage mentionnés
                                    dans la section 6.6, nos cookies peuvent être utilisés pour faire fonctionner le
                                    site Web. Si vous ne souhaitez pas utiliser des cookies, vous pouvez empêcher leur
                                    conservation au moyen des paramètres correspondants dans le navigateur. La plupart
                                    de nos cookies sont supprimés à la fin de votre visite ou à la fermeture du
                                    navigateur (cookies de session). Si cela n'est pas le cas, vous pouvez vérifier le
                                    délai avant suppression dans votre navigateur ou supprimer les cookies manuellement.
                                </p>
                                <h3>
                                    6.6. Données sur l'usage
                                </h3>
                                <p>
                                    Nous utilisons, dans l'application, Google Firebase Analytics qui est un service
                                    d'analyse d'applications et de sites Web proposé par Google Inc. (« Google »).
                                    Google est certifié dans le cadre du Bouclier de protection des données UE-EU.
                                </p>
                                <h3>
                                    6.7. Conservation et suppression
                                </h3>
                                <p>
                                    6.7.1 Vos Données d'Utilisateur sont conservées sur votre dispositif. Ces données
                                    sont également conservées sur des serveurs. Nous n'utilisons que des systèmes
                                    conformes aux exigences du RGPD.
                                </p>
                                <p>
                                    6.7.2 Vos données sont conservées sur des serveurs au sein de l'Union européenne
                                    (UE).
                                </p>
                                <p>
                                    6.7.3 La règle est que CamDiab ne conserve vos données personnelles que pendant la
                                    durée du contrat. Dans des cas exceptionnels, une conservation plus longue peut être
                                    nécessaire pour l'exécution d'obligations post-contractuelles ou pour le respect
                                    d'obligations de conservation légales ou de divulgation, ou encore pour faire
                                    valoir, exercer ou défendre des droits légaux (délais de prescription).
                                </p>
                                <h3>
                                    6.8. Mineurs
                                </h3>
                                <p>
                                    Les mineurs âgés de moins de seize ans ne sont autorisés à utiliser nos produits
                                    qu'avec le consentement d'un parent/tuteur (<a href="/fr-ca/terms"> Conditions
                                    générales d'utilisation - CGU </a>). Cela s'applique également au traitement de
                                    leurs données personnelles qui n'est légal que si et dans la mesure où le
                                    consentement a été obtenu par et via le parent/tuteur. Dans le cas contraire,
                                    l'utilisation de nos produits est interdite.
                                </p>
                                <h3>
                                    6.9. Délégué à la protection des données personnelles
                                </h3>
                                <p>
                                    6.9.1 Notre délégué à la protection des données personnelles est disponible pour
                                    répondre à toutes les questions sur la protection des données à l'adresse <a
                                    href="mailto:dpo@evalian.co.uk">dpo@evalian.co.uk</a>.
                                </p>
                                <p>
                                    6.9.2 Le délégué à la protection des données personnelles est largement impliqué(e)
                                    dans toutes les questions associées à la protection des données personnelles de nos
                                    utilisateurs. En tant que spécialiste formé(e), elle/il surveille en permanence
                                    notre traitement pour garantir la meilleure protection possible de vos Données
                                    d'Utilisateur.
                                </p>
                                <h2>
                                    7. VOS DROITS
                                </h2>
                                <h3>
                                    7.1. Révocation des consentements
                                </h3>
                                <p>
                                    Si nous traitons vos Données d'Utilisateur sur la base de votre consentement, vous
                                    pouvez révoquer ce consentement à tout moment. Cela n'aura toutefois pas d'impact
                                    sur la légalité du traitement avant la révocation. Nous continuerons de fournir nos
                                    services s'ils ne dépendent pas du consentement révoqué.
                                </p>
                                <h3>
                                    7.2. <strong>Information, correction et restriction</strong>
                                </h3>
                                <p>
                                    7.2.1 Chaque utilisateur a le droit de demander des informations sur le traitement
                                    de ses données personnelles. Pour ce faire, merci de nous contacter à tout moment à
                                    l'adresse <a
                                    href="mailto:privacy@camdiab.com">privacy@camdiab.com</a>.
                                </p>
                                <p>
                                    7.2.2 Votre droit à l'information englobe les informations sur les objectifs du
                                    traitement, les catégories de données et de destinataires, la durée de conservation,
                                    l'origine de vos données et vos droits dans le cadre des réglementations sur la
                                    protection des données. Vous trouverez toutes ces informations dans la déclaration
                                    sur la protection des données personnelles et nous serons heureux de vous les
                                    communiquer sous forme électronique.
                                </p>
                                <p>
                                    7.2.3 Si certaines de vos données personnelles ne sont pas correctes, vous pouvez à
                                    tout moment demander à ce qu'elles soient corrigées ou complétées. Vous avez le
                                    droit de limiter le traitement des données à la durée du rapport d'investigation que
                                    vous avez demandé.
                                </p>
                                <h3>
                                    7.3. Suppression (« droit à l'oubli »)
                                </h3>
                                <p>
                                    Chaque utilisateur a le droit de demander la suppression de ses données
                                    personnelles. Pour ce faire, merci de nous contacter à tout moment à l'adresse <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    7.4. Réclamations
                                </h3>
                                <p>
                                    7.4.1 Si vous pensez que nous ne protégeons pas comme il convient vos droits en
                                    matière de protection des données, merci de nous contacter à tout moment à
                                    l'adresse <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a> ou de contacter notre le
                                    délégué à la protection des données personnelles directement à l'adresse <a
                                    href="mailto:dpo@evalian.co.uk">dpo@evalian.co.uk</a>. Nous traiterons votre demande
                                    comme il se doit.
                                </p>
                                <p>
                                    7.4.2 Tout utilisateur a le droit de faire une réclamation auprès de l'autorité
                                    compétente en matière de protection des données dont dépend CamDiab s'il pense que
                                    le traitement de ses données personnelles n'est pas conforme aux réglementations sur
                                    la protection des données. L'utilisateur a en outre le droit de déposer une plainte
                                    auprès d'une autorité de surveillance dans l'État membre de l'UE dans lequel il
                                    réside, dans lequel il travaille ou qui est le lieu d'une infraction soupçonnée.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
    );
    }
    }

