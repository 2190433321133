/**
 * Texts for Notifications page
 */
export const notificationsPageText = {
    title: 'De siste i-app-varslerne',
    titleMeta: 'Viser nylige varsler fra CamAPS FX i appen',
    descriptionMeta: 'Her finner du teksten til de siste i-app-varslingene for appoppdateringer, sikkerhetsvarsler og oppdateringer av brukermanualen.',
    keywords: 'Varsler' ,
    localeMeta: 'nb_no',

    heading: 'De siste i-app-varslerne',
    subheading: '',
	
	header_34:'',
    body_34:'',
	
	header_33:'Varsel om planlagt vedlikehold 05/12/2024',
    body_33:'På grunn av planlagt vedlikehold av CamAPS FX-tjenestens sky, vil Companion-funksjonen være utilgjengelig 7. desember fra kl. 10:00 GMT i omtrent tre timer. Vi beklager eventuelle ulemper dette måtte medføre.',
	
	header_32:'Oppdatering av brukerhåndboken 28/11/2024',
    body_32:'En oppdatert versjon av brukerhåndboken er tilgjengelig.',
	
	header_31:'Oppdatering av appen 17/11/2024',
    body_31:'tKjære bruker av mylife CamAPS FX, En ny versjon av appen vil være klar innen de neste 48 timene. For å velge foretrukket oppdateringstid, slå av automatisk oppdatering for CamAPS FX i Google Play Store. Hvis du har noen spørsmål, vennligst kontakt Ypsomed kundeservice.',
	
	header_30:'OS 8 & 9 11/10/2024',
    body_30:'Av cybersikkerhetsgrunner vil mylife CamAPS FX fra 1. januar 2025 kun støttes på sensor-kompatible telefoner som kjører Android-operativsystemer 10 og høyere. Brukere av operativsystemer under OS 10 vil ikke lenger kunne oppdatere eller installere mylife CamAPS FX på nytt etter denne datoen.',
	
	header_29:'Oppdatering av appen 09/10/2024',
    body_29:'tKjære bruker av mylife CamAPS FX, En ny versjon av appen vil være klar innen de neste 48 timene. For å velge foretrukket oppdateringstid, slå av automatisk oppdatering for CamAPS FX i Google Play Store. Hvis du har noen spørsmål, vennligst kontakt Ypsomed kundeservice.',
	
	header_28:'OS 8 & 9 08/07/2024',
    body_28:'Av cybersikkerhetsgrunner vil mylife CamAPS FX fra 1. januar 2025 kun støttes på sensor-kompatible telefoner som kjører Android-operativsystemer 10 og høyere. Brukere av operativsystemer under OS 10 vil ikke lenger kunne oppdatere eller installere mylife CamAPS FX på nytt etter denne datoen.',

    header_27:'',
    body_27:'',

    header_26:'',
    body_26:'',

    header_25:'',
    body_25:'',

    header_24:'',
    body_24:'',

    header_23:'',
    body_23:'',

	header_22:'',
    body_22:'',
	
	header_21:'',
    body_21:'',
	
	header_20:'',
    body_20:'',
	
	header_19:'',
    body_19:'',

    header_18:'',
    body_18:'',

    header_17:'',
    body_17:'',

    header_16:'',
    body_16:'',

    header_15:'',
    body_15:'',

    header_14:'',
    body_14:'',

    header_13:'',
    body_13:'',
    body_13_link_text:'',
    body_13_end: '',
    body_13_link:'',

    header_12:'',
    body_12:'',

    header_11:'',
    body_11:'',

    header_10:'',
    body_10:'',

    header_9:'',
    body_9:'',

    header_8:'',
    body_8:'',

    header_7:'',
    body_7:'',

    header_6:'',
    body_6:'',

    header_5:'',
    body_5:'',

    header_4:'',
    body_4:'',

    header_3:'',
    body_3:'',

    header_2:'',
    body_2:'',

    header_1:'',
    body_1:'',
};
