/**
 * Texts for Support page
 */
export const supportPageText = {
    title: 'Soutien - CamAPS FX',
    titleMeta: 'Nous fournissons un large support pour l\'utilisation de CamAPS FX',
    descriptionMeta: 'Nous fournissons du matériel d’assistance, notamment des manuels d’utilisation, ' +
        'des directives de téléchargement, des webinaires et une assistance client par ' +
        'téléphone et par courrier électronique.',
    keywords:
        'prise en charge de camaps fx, prise en charge de camaps, ' +
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ' +
        '',
    localeMeta: 'fr_fr',

    needHelp: 'Besoin d’aide?',
    needHelpResponse: 'Nous espérons que le matériel de soutien offert ici saura répondre aux que vous pourriez avoir. Si vous ne trouvez pas la réponse à votre problème, veuillez communiquer avec nous.',
    clinicalSupportHeader: 'Soutien clinique',
    clinicalSupportText: 'Si vous avez des questions au sujet de vos soins de santé, veuillez contacter votre équipe spécialisée en diabétologie.',
    techSupportHeader: 'Soutien technique et éducatif',
    techSupportHeaderText: 'Vous trouverez ici du matériel technique et éducatif ainsi que les coordonnées de notre équipe de soutien en cas de besoin.',
    techMaterialHeader: 'Matériel technique :',
    userManual: 'Manuel de l\'utilisateur de CamAPS FX (lecteur PDF requis)',
    userManualEnglish_mmol_l: 'English',
    userManualCzech_mmol_l: 'Česky',
    userManualDanish_mmol_l: 'Dansk',
    userManualGerman_mg_dl: 'Deutsch',
    userManualGerman_mmol_l: 'Deutsch',
    userManualSpanish_mg_dl: 'Español',
    userManualFrench_mmol_l: 'Français',
    userManualFrench_mg_dl: 'Français',
    userManualItalian_mg_dl: 'Italiano',
    userManualItalian_mmol_l: 'Italiano',
    userManualDutch_mg_dl: 'Nederland',
    userManualDutch_mmol_l: 'Nederland',
    userManualNorsk_mmol_l: 'Norsk',
    userManualPolish_mg_dl: 'Polski',
    userManualFinish_mmol_l: 'Suomi',
    userManualSwedish_mmol_l: 'Svenska',

    previousManualVersions: 'Versions précédentes',
    installationManualHeader: 'Installer l’application CamAPS FX (DANA) ',
    installationManualEnglish: 'Installation guide',
    installationManualGerman: 'Installationsanleitung',
    installationManualFrench: 'Guide d’installation',
    educationalMaterial: 'Matériel éducatif :',
    onlineTraining: 'Formation en ligne :',
    onlineTrainingText: 'Fournie par le Programme de Formation au Diabète de Cambridge',
    onlineTrainingLink: 'https://fr.camdiabtraining.com/',
    onlineTrainingText_1: 'Formation en ligne certifiée GRATUITE',
    webinarsHeading: 'Séminaires en ligne* :',
    webinar_1: 'Présentation du système CamAPS FX',
    webinar_2: 'Démarrer le système CamAPS FX',
    webinar_3: 'Optimiser les réglages de CamAPS FX',
    webinar_4: 'Formation pour les formateurs et les équipes de soutien à l’éducation du patient',
    webinar_5: 'Réglages de précision pour améliorer le temps dans la cible',
    webinar_6: 'Utiliser CamAPS FX pendant la grossesse',
    webinar_7: 'Utiliser CamAPS FX chez le nourrisson, le tout-petit et le jeune enfant',
    webinar_8: 'Utiliser CamAPS FX chez le jeune adulte et l’adulte',
    webinar_9: 'Gestion de l’activité physique, des repas et des autres événements de la vie',
    webinar_10: 'Insulinothérapie en boucle fermée et qualité de vie',
    webinar_11: 'Thérapie en boucle fermée et diabète de type 1 de longue date',
    webinar_footnote: '<br>* Des sous-titres en français sont disponibles via l’option Paramètres>Sous-titres>Traduire automatiquement>Français de YouTube, sans garantie d’exactitude (YouTube est une marque déposée de Google LLC).',
    contactDetailsHeader: 'Coordonnées :',
    contactDetailsText: 'Notre équipe d’assistance est disponible pendant les heures ouvrables, du ' +
        'lundi au vendredi, de 10 h à 18 h (HNEC).',
    contactDetailsEmailTitle: 'Nous écrire',
    contactDetailsEmail: 'support@camdiab.com',
    contactDetailsEmailFull: 'mailto:support@camdiab.com',
    contactDetailsPhoneTitle: 'Nous téléphoner',
    contactDetailsPhone: '+44 20 3695 3780 (Seulement anglais)',
    contactDetailsPhoneFull: 'tel:+442036953780',

    eIfuAccess: 'Des exemplaires papier des manuels d’utilisation sont disponibles auprès ' +
        'du Service clientèle. ' +
        'Pour de meilleurs résultats, ' +
        'veuillez utiliser Adobe<sup>®</sup> Reader<sup>®</sup> pour afficher les manuels d’utilisation CamDiab.',

    statusPageText: 'Page d’état',
    statusPageLink: 'https://camdiabltd.statuspage.io/',

    installationYpsoPumpHeader: 'Installer l’application CamAPS FX',
    installationYpsoPumpText: 'Google Play',
    installationYpsoPumpLink: 'https://play.google.com/store/search?q=camaps%20fx&c=apps',

    danaPumpSupportText: 'Utilisateurs de pompes Dana',
    danaPumpSupportTextUK: 'Dana pump users in the UK',
    danaPumpSupportLinkUK: 'https://advancedtherapeutics.org.uk/contact-us/',
    ypsoPumpSupportText: 'Utilisateurs de pompes YpsoPump',
    ypsoPumpSupportLink: 'https://www.mylife-diabetescare.com/en/services/customer-care-contact.html',
};
