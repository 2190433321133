/**
 * Texts for FAQs page
 */
export const faqPageText = {
    title: 'Questions fréquentes - CamAPS FX',
    titleMeta: 'Vous avez une question sur CamAPS&nbsp;FX?',
    descriptionMeta: 'Voici les réponses à certaines de vos questions fréquemment posées sur les appareils compatibles, ' +
        'l’approbation réglementaire, la disponibilité et la recherche clinique',
    keywords:
        'camaps Questions fréquentes, camaps fx Questions fréquentes, ' +
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ' +
        '',
    localeMeta: 'fr_fr',

    haveQuestion: 'Vous avez une question sur CamAPS&nbsp;FX?',
    hereAnswers: 'Vous trouverez ici les réponses à la plupart des questions fréquemment posées.',
    whatIsCamAPS: 'Qu’est-ce que CamAPS&nbsp;FX?',
    whatIsCamAPSAnswer: 'CamAPS FX est une application mobile perfectionnée et facile d’emploi, destinée à gérer le taux de glucose ' +
        'chez les patients âgés de 2 ans et plus présentant un diabète de type 1, à l\'aide d\'un système sophistiqué en boucle fermée hybride qui s’adapte aux besoins en insuline..',
    isApproved: 'L’utilisation de CamAPS FX est-elle autorisée?',
    isApprovedAnswer: 'L’application a été scrupuleusement testée dans le cadre d\'essais cliniques, et son utilisation ' +
        'a été autorisée au Canada et dans de nombreux autres pays.',
    isAvailable: 'CamAPS FX est-il disponible près de chez moi?',
    isAvailableAnswer1: 'CamAPS FX est disponible dans certains services de diabétologie ' +
        'ou directement auprès de mylife Diabetescare Canada (lien). Veuillez vérifier la disponibilité auprès de votre service de diabétologie ou ' +
        'communiquez avec le Service à la clientèle de mylife Diabetescare à l\'adresse info@ypsomed.ca.',
    isAvailableAnswer2: '',
    isAvailableAnswer3: '',
    cost: 'Combien coûte CamAPS&nbsp;FX?',
    costAnswer1: 'Pour les détails tarifaires, veuillez consulter la section',
    costAnswer2: 'du site.',
    compatibleCgm: 'Quels sont les sytèmes de mesure du glucose en continu (MGC) compatibles avec CamAPS&nbsp;FX?',
    compatibleCgmAnswer: 'CamAPS FX est compatible avec le',
    compatibleCgmAnswer1: '',
    compatibleCgmAnswer2: '',
    whatPumps: 'Quelles sont les pompes à insuline compatibles avec CamAPS&nbsp;FX?',
    whatPumpsAnswer1: 'CamAPS FX est compatible avec la pompe à insuline mylife YpsoPump.',
    whatPumpsAnswer2: '',
    whatPumpsAnswer3: '',
    whatPumpsAnswerHtml: 'https://advancedtherapeutics.org.uk/shop/dana-diabecare-insulin-pump/dana-i-insulin-pump ',
    whatPhone: 'Quels sont les téléphones intelligents compatibles avec CamAPS&nbsp;FX?',
    whatPhoneAnswer: 'CamAPS FX fonctionne sur les téléphones Android. <p><p>',

    whatPhoneAnswer1: 'Veuillez',
    whatPhoneAnswer2: 'cliquer',
    whatPhoneAnswer3: 'sur ce lien pour obtenir la liste des appareils et systèmes d’exploitation pris en charge.',
    whatPhoneAnswerDexcom: '',
    whatPhoneAnswerLibre3: '',
    whatPhoneAnswerDexcomHtml:'https://www.dexcom.com/compatibility',
    whatPhoneAnswerLibreHtml:'https://s3.eu-west-1.amazonaws.com/camdiab.user.manual/supported_devices_register_ca.pdf',

    whereUpload: 'Où puis-je téléverser mes données à partir de CamAPS&nbsp;FX?',
    whereUploadAnswer: 'Actuellement, les données de CamAPS FX peuvent être téléversées sur mylife Cloud et Glooko.',
    whatLanguage: 'Quelles sont les langues prises en charge par CamAPS&nbsp;FX?',
    whatLanguageAnswer: 'CamAPS  FX est disponible en français et en anglais pour les utilisateurs canadiens.',
    whereResults: 'Où puis-je trouver les résultats de vos études cliniques?',
    whereResultsAnswer1: 'Les publications concernant l’utilisation de l’algorithme de contrôle de Cambridge sont disponibles en téléchargement',
    whereResultsAnswer2: 'ici',
    whereResultsAnswer3: '. Les articles clés ont été publiés dans',
    whereResultsAnswer4: 'et',

};
