/**
 * Common texts - what is CamAPS FX
 */
export const whatIsCamAPSFX = {

    linkFindOutMore: 'Pour en savoir plus',
    body:
        '<h2>Qu’est-ce que CamAPS&nbsp;FX?</h2> ' +
        '<p> ' +
        '    CamAPS FX est une application Android destinée à gérer le taux de glucose ' +
        '    chez les patients présentant un diabète de type 1, à l\'aide d\'un système sophistiqué ' +
        'en boucle fermée hybride qui s\'adapte aux besoins en insuline. ' +
        '</p>'

};

/**
 * Common texts - what is CamAPS FX
 */
export const userReviewsIntro = {
    linkMoreReviews: 'Plus de témoignages',
    readMore: 'Lire la suite',
    header:
        'Témoignages d’utilisateurs de CamAPS FX',
    text:
        'Histoires vécues d’utilisateurs de l’application CamAPS FX'
};