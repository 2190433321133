/**
 * Texts for header
 */
export const headerText = {
    cookiesIntroText: 
        'Nous utilisons des témoins uniquement pour faire le suivi des visites sur notre site web et ne conservons aucune donnée personnelle.',
    cookiesAccept: 'Accepter les témoins',
    cookiesDeny: 'Refuser les témoins',
    tabFAQs: 'Questions',
    tabSupport: 'Soutien',
    tabHistory: 'L’aventure',
    tabReviews: 'Témoignages',
    tabTraining: 'Formation',
    // tabOrders: 'Commander',
    tabOrders: '',
    tabNotifications: 'Notifications',

};
