import React, {Component} from 'react';
import {Header} from './Header';
import {Footer} from './Footer';
import {Helmet} from "react-helmet";

export class PrivacyDK extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Databeskyttelsespolitik - CamAPS FX</title>
                    <meta name="title" content='Databeskyttelsespolitik'/>
                    <meta name="description"
                          content='Her kan du finde CamDiabs databeskyttelsespolitik'/>
                    <meta property="og:locale" content='da_dk'/>
                    <meta name="keywords" content='databeskyttelsespolitik'/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10">
                                <h1>Databeskyttelsespolitik</h1>
                                <h3>Version dateret 4. december 2024</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <h2>
                                    1. Introduktion
                                </h2>
                                <h3>
                                    1.1. Ansvarlig enhed
                                </h3>
                                <p>
                                    CamDiab Limited, registreret kontoradresse 20-22 Wenlock Road, London, N1 7GU, UK,
                                    stiftet og registreret i England og Wales med virksomhedsnummer 11659211
                                    (“CamDiab”), er dataansvarlig i forhold til databeskyttelsesreglerne. CamDiab
                                    beslutter formålet med behandlingen af vores brugeres persondata
                                    (“<strong>brugerdata</strong>”) og den måde, de behandles på, og er derfor ansvarlig
                                    for sikkerheden og overholdelse af gældende lovgivning.
                                </p>
                                <p>
                                    1.1.2 Som dataansvarlig er vi f.eks. underlagt informationskrav, som vi ønsker at
                                    opfylde i forbindelse med denne databeskyttelseserklæring.
                                </p>
                                <h3>
                                    1.2. Struktur og samtykkekoncept
                                </h3>
                                <p>
                                    1.2.1 Denne databeskyttelseserklæring informerer dig om formålet med og omfanget af
                                    behandlingen af dine brugerdata samt dataoverførsler og dine omfattende rettigheder.
                                    Da vores tilbud udelukkende er henvendt til mennesker med diabetes, giver din brug
                                    typisk allerede oplysninger om din helbredstilstand. Vi behandler derfor kun
                                    brugerdata som sundhedsdata med dit samtykke. Vi skelner på følgende måde:
                                </p>
                                <p>
                                    1.2.1.1 “Nødvendig behandling” beskriver, hvordan vi behandler de brugerdata, som er
                                    nødvendige for at opfylde kontrakten. Uden dette samtykke er det hverken lovligt
                                    eller praktisk muligt at anvende vores produkter, da vores tjenester er afhængige af
                                    denne behandling.
                                </p>
                                <p>
                                    1.2.1.2 “Behandling med henblik på produktforbedring” forklarer, hvordan du kan
                                    hjælpe os og andre brugere ved med dit samtykke at give os lov til at bruge dine
                                    data især til at forbedre algoritmer til behandlingsstyring, forbedre produktet
                                    osv., uden at vi kontakter dig.
                                </p>
                                <p>
                                    1.2.1.3 “Behandling med henblik på markedsføring” beskriver, hvordan vi med dit
                                    samtykke kontakter dig med henblik på markedsføring, f.eks. pr. e-mail, via
                                    notifikationer osv.
                                </p>
                                <p>
                                    1.2.1.4 I “Generelle oplysninger” har vi samlet de oplysninger, der gælder for alle
                                    ovenstående samtykker, for at undgå gentagelser.
                                </p>
                                <p>
                                    De forskellige kategorier er beskrevet nærmere nedenfor. Du skal give det relevante
                                    samtykke ved registrering, eller når du bestiller vores produkt.
                                </p>
                                <p>
                                    1.2.2 I nogle tilfælde kan behandling ske uden samtykke på grundlag af lovgivning
                                    (f.eks. forordninger om medicinsk udstyr).
                                </p>
                                <h2>
                                    2. NØDVENDIG BEHANDLING
                                </h2>
                                <p>
                                    Hvis du giver samtykke til det, behandler vi de brugerdata, som er anført nedenfor,
                                    for at kunne levere vores tjenester. Hvis du ikke giver samtykke til denne
                                    nødvendige databehandling, kan du ikke benytte CamDiabs tjenester. Du kan give dine
                                    samtykker under registreringsprocessen, eller når du bestiller dit produkt.
                                </p>
                                <h3>
                                    2.1. Nødvendige brugerdata
                                </h3>
                                <p>
                                    2.1.1 For at beskytte dine brugerdata kan vores tjenester kun anvendes i forbindelse
                                    med en brugerkonto. Når du opretter en brugerkonto, beder vi om og behandler
                                    følgende brugerdata:
                                </p>
                                <ul type="disc">
                                    <li>
                                        E-mailadresse
                                    </li>
                                    <li>
                                        Adgangskode (vi gemmer kun en checksum)
                                    </li>
                                    <li>
                                        Navn
                                    </li>
                                    <li>
                                        Erklæring om momsfritagelse (navn, adresse, dato; for selvbetalere)
                                    </li>
                                    <li>
                                        Fødselsår
                                    </li>
                                    <li>
                                        Registreringsdato
                                    </li>
                                    <li>
                                        Samtykkestatus
                                    </li>
                                    <li>
                                        Enheds-id, fremstiller, enhedstype, operativsystemversion, appversion
                                    </li>
                                    <li>
                                        Sprog, land, tidszone.
                                    </li>
                                </ul>
                                <p>
                                    2.1.2 Andre oplysninger, som indsamles ud over brugerregistreringen:
                                </p>
                                <p>
                                    <strong>Medicinske stamdata </strong>
                                    <br/>
                                    Vægt, blodsukkermålområde, pumpes basalindstillinger, korrektionsfaktorer,
                                    kulhydrat/insulin-forhold, aktiv insulinvarighed, målblodsukker,
                                    advarselsindstillinger, bolustrin, kulhydratenhed, graviditetsstatus, forventet
                                    terminsdato.
                                </p>
                                <p>
                                    <strong>Brugs- og samkøringsdata</strong>
                                    <br/>
                                    Pumpes og senders serienummer, spor for pumpe-, sensor- og lukket/åbent
                                    system-handlinger, spor for appkommandoflow, hændelsesspor, følgeroplysninger som
                                    indtastet i appen (telefonnummer, legitimationsoplysninger til
                                    diabetesdataportalkonti), supportforespørgsler, vejlederoplysninger, diabetesklinik
                                    ID.
                                </p>
                                <p>
                                    <strong>Medicinske data</strong>
                                    <br/>
                                    Appindtastninger som dato/klokkeslæt/tidszone/sted, fødeindtag/måltid, blodsukker-
                                    og sensorglukosemålinger, sensorglukoseændringshastighed, samlet daglig
                                    insulindosis, insulinindgivelse (bolus og basal), midlertidig basaldosis, lukket
                                    system-status, pumpe- og kanylepriming, advarsler, boost- og ease-off-hændelser,
                                    appindstillinger som visningsmuligheder, statistiske resuméer af glukose og
                                    insulindata.
                                </p>
                                <p>
                                    2.1.3 Appen kan kræve adgang til din placering udelukkende for at aktivere Bluetooth
                                    Low Energy (BLE)-forbindelse, som krævet af operativsystemets tilladelser. Ingen
                                    lokalitetsdata indsamles, gemmes eller deles af appen.
                                </p>
                                <p>
                                    2.1.4 Hvis du ønsker det, kan du indtaste en e-mailadresse, som du opretter specielt
                                    til os – men den skal virke, så vi kan sende dig vigtige advarsler.
                                </p>
                                Vi behandler kun de brugerdata, du aktivt og frivilligt opgiver til CamDiab, eller som
                                indhentes fra glukosesensoren og insulinpumpen, som du frivilligt kobler dig til. Det er
                                imidlertid et krav, at du indtaster nødvendige brugerdata for at kunne anvende vores
                                produkter fuldt ud.
                                <h3>
                                    2.2. Nødvendige formål
                                </h3>
                                <p>
                                    2.2.1 Alle de nødvendige formål med vores behandling er knyttet til levering af
                                    vores tjenester:
                                </p>
                                <p>
                                    <strong>Kundeforespørgsel </strong> fører til registrering af data i form af
                                    kontaktoplysninger.
                                </p>
                                <p>
                                    <strong>Bestilling </strong> af vores apps af selvbetalere fører til registrering af
                                    data i form af kontaktoplysninger og tekniske data som enheds-id.
                                </p>
                                <p>
                                    <strong>Installation </strong> af vores app fører til registrering af tekniske og
                                    enhedsrelaterede data.
                                </p>
                                <p>
                                    <strong>Registrering </strong> fører til oprettelse af din CamDiab-konto med
                                    e-mailadresse og adgangskode.
                                </p>
                                <p>
                                    <strong>Levering af vores tjenester</strong> kræver, at du frivilligt og aktivt
                                    indtaster eller afgiver data, og hver funktion beskriver det formål, dataene skal
                                    bruges til.
                                </p>
                                <p>
                                    Kommunikation fra CamDiab eller forhandler med dig i vores apps eller via andre
                                    elektroniske beskedtjenester (f.eks. e-mail, messenger, telefon), hvor det er
                                    nødvendigt for at supportere eller fejlfinde på vores produkter. Det er sådan, vi
                                    behandler de kommentarer og forespørgsler, du måtte have, via forskellige
                                    kommunikationskanaler, når du bruger CamDiab. Det vigtigste eksempel er vores
                                    supporttjenester, som du kan tilgå på <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Tænk derfor over, hvilke
                                    oplysninger og data du vil afgive i din aktive kommunikation med os – det er helt op
                                    til dig. Det kan være nødvendigt for os at kunne kommunikere med brugerne pr. e-mail
                                    eller tilsvarende. Det er sådan, vi informerer dig om opdateringer af vores
                                    produkter og giver vigtig sikkerhedsrådgivning samt hjælp i forbindelse med din
                                    brug. Denne supportkommunikation – som en essentiel del af vores produkter – sendes
                                    til brugerne.
                                </p>
                                <p>
                                    <strong>Behandlingsenheder </strong> (f.eks. glukosesensor og insulinpumper) parres
                                    med din enhed, hvilket gør det muligt at overføre data til vores apps.
                                </p>
                                <p>
                                    <strong>Diabetesdataportaler og datadeling</strong>, f.eks. fra Glooko, gør det
                                    muligt at udveksle data med vores apps. Men synkronisering kan kun ske, hvis du
                                    aktiverer det i indstillingerne i vores apps, dvs. hvis du bruger funktionen.
                                    Databeskyttelseserklæringer fra producenter af diabetesdataportaler gælder for data,
                                    som deles med sådanne tredjepartssamarbejdspartnere.
                                </p>

                                2.2.2 <strong>Brug</strong> af vores apps kræver, at du aktivt og frivilligt indtaster
                                eller oplyser data. Hvis vi skal rette en fejl i appen, skal vi f.eks. bruge
                                nedbrudsrapporter, som vi kan bruge til fejlfinding for at opklare omstændighederne
                                omkring problemet. Desuden registreres vigtige data for din enhed og din brugsadfærd, da
                                vores opfyldelse af kontrakten frem for alt betyder, at vi tilpasser vores produkter,
                                dvs. behandler individuelle brugeroplysninger, som f.eks. afhænger af, hvor du er (også
                                relevant for søgefunktionen). Der udføres en automatisk analyse af brugeradfærd alene
                                med henblik på at tilpasse din brug, når vi opfylder kontrakten, og det har ingen
                                juridiske konsekvenser for dig.

                                <h2>
                                    3. BEHANDLING MED HENBLIK PÅ PRODUKTFORBEDRING
                                </h2>
                                <p>
                                    Vi behandler også dine brugerdata ud over den nødvendige brug som beskrevet i
                                    paragraf 2 ovenfor for at forbedre vores produkter og tjenester som beskrevet
                                    nærmere nedenfor.
                                </p>
                                <h3>
                                    3.1. Andre data
                                </h3>
                                <p>
                                    Generelt bruger vi de samme brugerdata til at forbedre vores produkter, som er
                                    anført i paragraf 2. Derudover kan CamDiab også registrere følgende brugerdata:
                                </p>
                                <p>
                                    <strong>Brugsdata</strong>
                                    <br/>
                                    Aktivitetshændelser, som gør det muligt for os at forstå, hvordan du bruger vores
                                    produkter. Det sætter os i stand til at se, hvordan vores produkter bliver brugt, og
                                    hvor vi f.eks. kan optimere menudesignet.
                                </p>
                                <h3>
                                    3.2. Formål med produktforbedring
                                </h3>
                                <p>
                                    På grund af hurtige teknologiske fremskridt skal vi hele tiden analysere, udvikle,
                                    teste og forbedre vores produkter og deres interaktion for at kunne sikre, at vores
                                    indhold er til gavn for brugerne på den mest effektive måde. For at kunne det kan vi
                                    udføre brugs-, gennemførligheds-, prædiktive, kontrol- og sikkerhedsvurderinger, og
                                    den viden, vi indsamler, indarbejdes i nye forbedrede versioner af vores produkter
                                    som f.eks. appen. Disse forbedringer leveres også til dig via jævnlige opdateringer.
                                </p>
                                <p>
                                    CamDiab er engageret i videnskaben om alle aspekter af diabetes. Derfor kan anonyme
                                    brugerdata også bruges til forskning og statistik (altid under overholdelse af de
                                    anerkendte etiske videnskabelige standarder) og interne analyser. Dette kan omfatte
                                    deling af anonymiserede data med samarbejdspartnere fra tredjeparter.
                                </p>
                                <h2>
                                    4. BEHANDLING MED HENBLIK PÅ MARKEDSFØRING
                                </h2>
                                <h3>
                                    4.1. Nyhedsbrev
                                </h3>
                                <p>
                                    4.1.1 Vi kan sende dig information om produkter og tjenester ud over det, vi skal i
                                    henhold til kontrakten (herunder information fra nøje udvalgte partnere), og
                                    invitationer til at deltage i undersøgelser eller salgskampagner og
                                    markedsføringsaktiviteter (“<strong>nyhedsbrev</strong>”).
                                </p>
                                <p>
                                    4.1.2 Du kan vælge, om du vil abonnere på vores nyhedsbrev (aktivt tilvalg). Du kan
                                    til enhver tid trække dit samtykke tilbage i kontoindstillingerne.
                                </p>
                                <h3>
                                    4.2. Andre former for markedsføring
                                </h3>
                                <p>
                                    4.2.1 Andre samtykker, f.eks. til undersøgelser, notifikationer eller skræddersyede
                                    tilbud, indhentes efter behov. Vi forklarer altid, hvorfor vi har brug for bestemte
                                    data, og også, hvordan du kan trække dit samtykke tilbage.
                                </p>
                                <p>
                                    4.2.2 Husk, at vi kan vise dig tilbud i appen uden at behandle dine persondata. Du
                                    vil også se annoncer, som ikke er tilpasset dig personligt, hvis du ikke har givet
                                    samtykke.
                                </p>
                                <h2>
                                    5. BRUG TIL LOVPLIGTIGE FORMÅL
                                </h2>
                                <h3>
                                    5.1. Videnskabelig forskning og statistik
                                </h3>
                                <p>
                                    CamDiab er dybt engageret i forskning i alle aspekter af diabetes. Derfor kan
                                    anonyme brugerdata også blive brugt til forskning og statistik (altid med
                                    overholdelse af anerkendte etiske regler for forskning) og interne analyser. Det
                                    bruges hovedsageligt til at finde frem til og forbedre effektiviteten af teknikker
                                    til kontrol og behandling af diabetes. Det juridiske grundlag for dette er artikel
                                    9, stk. 2, litra j i GDPR.
                                </p>
                                <h3>
                                    5.2. Håndhævelse af rettigheder
                                </h3>
                                <p>
                                    Anvendelse af persondata kan også være nødvendig for at forhindre misbrug fra
                                    brugernes side eller for at håndhæve, udøve eller forsvare juridiske krav. Vi kan
                                    være tvunget til at videregive data af bindende lovgivning, af domstolene eller af
                                    officielle afgørelser og instruktioner, i forbindelse med efterforskning af
                                    kriminelle forhold eller i offentlighedens interesse. I sådanne tilfælde er
                                    opbevaring og behandling af dine data uden dit samtykke tilladt i lovgivningen. Det
                                    juridiske grundlag for dette er artikel 9, stk. 2, litra f i GDPR.
                                </p>
                                <h3>
                                    5.3. I overensstemmelse med lovgivningen vedrørende medicinsk udstyr
                                </h3>
                                <p>
                                    Endelig er vi som producent eller forhandler af medicinsk udstyr underlagt krav om
                                    overvågning af vores produkts funktionalitet. Dette overvågningssystem, som er
                                    pålagt os lovgivningsmæssigt, kan også involvere behandling af persondata. Det
                                    juridiske grundlag for dette er artikel 9, stk. 2, litra i i GDPR.
                                </p>
                                <h2>
                                    6. GENERELLE OPLYSNINGER
                                </h2>
                                <h3>
                                    6.1. Begrænsning af formål og sikkerhed
                                </h3>
                                <p>
                                    6.1.1 CamDiab bruger udelukkende dine persondata til de formål, som er anført i
                                    denne databeskyttelseserklæring og de relevante samtykker. Vi sikrer, at alle former
                                    for behandling er begrænset til det omfang, som er nødvendigt til formålet.
                                </p>
                                <p>
                                    6.1.2 Al behandling garanterer altid tilstrækkelig sikkerhed og fortrolighed af dine
                                    persondata. Dette dækker beskyttelse mod uautoriseret og ulovlig behandling,
                                    utilsigtet tab, utilsigtet destruktion eller skade med passende tekniske og
                                    organisatoriske foranstaltninger. Vi bruger strenge interne processer,
                                    sikkerhedsfunktioner og krypteringsmetoder.
                                </p>
                                <h3>
                                    6.2. Databehandlere
                                </h3>
                                <p>
                                    6.2.1 Vores produkter er underlagt komplekse processer, som vi skal styre og holde
                                    up to date. Til teknisk support kan vi benytte tredjepartsleverandører
                                    (“<strong>databehandlere</strong>”) for at kunne tilbyde dig omfattende og optimal
                                    brug af vores produkter.
                                </p>
                                <p>
                                    6.2.2 CamDiab overfører udelukkende brugerdata til databehandlere inden for rammerne
                                    af denne databeskyttelseserklæring og kun for at opfylde de formål, som er anført i
                                    den. Databehandlerne arbejder i henhold til vores specifikationer og instruktioner,
                                    og de må ikke anvende vores brugeres persondata til deres egne eller andre formål.
                                </p>
                                <p>
                                    6.2.3 Vi bruger databehandlere, som yder tilstrækkelige garantier for, at der er
                                    iværksat passende tekniske og organisatoriske foranstaltninger på en måde, således
                                    at behandlingen af persondata overholder de lovmæssige krav og vores
                                    databeskyttelseserklæring. Vores brugeres rettigheder er beskyttet ved hjælp af
                                    bindende kontrakter, som opfylder de strenge krav i GDPR.
                                </p>
                                <h3>
                                    6.3. Kryptering, pseudonymisering og anonymisering
                                </h3>
                                <p>
                                    6.3.1 Hver dataoverførsel er uden undtagelse og som standard krypteret under
                                    overførslen. Ved hjælp af HTTPS (hypertext transfer protocol secure) eller
                                    tilsvarende sikrer vi, at dine data ikke bliver opfanget af uautoriserede
                                    tredjeparter.
                                </p>
                                <p>
                                    Desuden bruger vi også andre processer til kryptering og pseudonymisering af data
                                    med henblik på datasikkerhed og -minimering. Det afhænger naturligvis af typen af,
                                    omfanget af og formålet med den pågældende databehandling. Vi videregiver f.eks. kun
                                    brugerdata, som en databehandler skal bruge til at udføre sine opgaver.
                                </p>
                                <p>
                                    6.3.2 Når et kontraktforhold med en databehandler ophører, skal den pågældende
                                    databehandler efter CamDiabs skøn enten returnere alle vores brugeres data eller
                                    slette dem, hvis der ikke er nogen lovfæstede forpligtelser til at opbevare dem.
                                </p>
                                <p>
                                    6.3.3 Data, som ikke kræver personlig reference for at kunne behandles (f.eks. til
                                    forskning og analyse), anonymiseres. Det forhindrer i alle tilfælde, at de bliver
                                    sat i forbindelse med en bestemt bruger.
                                </p>
                                <h3>
                                    6.4. EU og andre lande
                                </h3>
                                <p>
                                    6.4.1 Vi forsøger at udvælge samarbejdspartnere, som er baseret i, eller hvis
                                    servere er placeret i, Den Europæiske Union (EU) eller Det Europæiske Økonomiske
                                    Samarbejdsområde (EØS). Der kan ikke gøres indsigelse mod dataoverførsel inden for
                                    EU eller EØS, da GDPR gælder i alle medlemsstaterne.
                                </p>
                                <p>
                                    6.4.2 Under særlige omstændigheder udpeger vi tredjepartsleverandører, som er
                                    beliggende i, eller hvis servere befinder sig uden for, EU. I alle disse tilfælde er
                                    dine data underlagt en høj grad af beskyttelse på linje med GDPR – enten via en
                                    EU-beslutning om tilstrækkelighed, der anser databeskyttelsen i visse
                                    tredjepartslande for at være tilstrækkelig (f.eks. Schweiz, Israel og New Zealand),
                                    eller via særlige standardkontraktbestemmelser, som er godkendt af EU, og som det
                                    kontraktlige forhold med vores databehandlere er baseret på, eller via tilsvarende
                                    juridiske instrumenter, der er tilladt i henhold til GDPR.
                                </p>
                                <p>
                                    6.4.3 Derudover sørger vi for, at vores partnere har iværksat yderligere
                                    sikkerhedsforanstaltninger som f.eks. individuelle sikkerhedsforanstaltninger og
                                    databeskyttelsesbestemmelser eller -certificeringer i henhold til GDPR. Hvis
                                    tredjepartsleverandører f.eks. er beliggende i USA, skal de derfor være bundet af
                                    Privacy Shield Framework, som er godkendt af EU, eller tilsvarende internationalt
                                    anerkendte sikkerhedsstandarder.
                                </p>
                                <h3>
                                    6.5. Cookies
                                </h3>
                                <p>
                                    CamDiab kan sætte såkaldte “cookies” for at tilbyde dig et omfattende sæt funktioner
                                    og sikre, at vores hjemmeside er mere hensigtsmæssig. Cookies er små tekstfiler, som
                                    din browser gemmer på din enhed. Med undtagelse af de cookies til brugsdata, som er
                                    nævnt i paragraf 6.6, kan vores cookies bruges til at drive hjemmesiden. Hvis du
                                    ikke ønsker at bruge cookies, kan du forhindre, at de bliver gemt, ved at bruge de
                                    relevante indstillinger i browseren. De fleste af vores cookies bliver enten
                                    slettet, når du afslutter dit besøg, eller når browseren bliver lukket
                                    (sessionscookies). Hvis det ikke er tilfældet, kan du tjekke sletteperioden i din
                                    browser eller slette cookies manuelt.
                                </p>
                                <h3>
                                    6.6. Brugsdata
                                </h3>
                                <p>
                                    Vi bruger Google Firebase Analytics, en webanalysetjeneste fra Google Inc.
                                    (“Google”), i appen. Google er certificeret i henhold til EU-US Privacy Shield.
                                </p>
                                <h3>
                                    6.7. Opbevaring og sletning
                                </h3>
                                <p>
                                    6.7.1 Dine brugerdata opbevares på din enhed. Disse data gemmes også på servere. Vi
                                    bruger kun systemer, som opfylder kravene i GDPR.
                                </p>
                                <p>
                                    6.7.2 Dine data bliver gemt på servere i Den Europæiske Union (EU).
                                </p>
                                <p>
                                    6.7.3 CamDiab gemmer som regel kun dine persondata, så længe kontrakten løber. I
                                    undtagelsestilfælde kan længere opbevaring være nødvendig for at opfylde
                                    forpligtelser efter kontraktudløb eller for at overholde lovgivningsmæssige
                                    opbevarings- eller oplysningsforpligtelser eller for at gøre juridiske krav gældende
                                    eller udøve eller forsvare juridiske krav (forældelsesfrister).
                                </p>
                                <h3>
                                    6.8. Mindreårige
                                </h3>
                                <p>
                                    Mindreårige, som er under seksten år, må kun anvende vores produkter med samtykke
                                    fra en af forældrene eller en værge ( <a href="/dk/terms"> Generelle vilkår og
                                    betingelser </a> ). Dette gælder også behandling af deres persondata, som kun er
                                    lovlig, hvis og i det omfang der er indhentet samtykke fra og gennem
                                    forældrene/værgen. Ellers er brug af vores produkter forbudt.
                                </p>
                                <h3>
                                    6.9. Databeskyttelsesrådgiveren
                                </h3>
                                <p>
                                    6.9.1 Vores databeskyttelsesrådgiver er til rådighed for at besvare alle spørgsmål
                                    vedrørende databeskyttelse på <a
                                    href="mailto:dpo@evalian.co.uk">dpo@evalian.co.uk</a>.
                                </p>
                                <p>
                                    6.9.2 Databeskyttelsesrådgiveren er involveret i alle spørgsmål vedrørende
                                    beskyttelse af vores brugeres persondata. Han eller hun er uddannet ekspert og
                                    overvåger løbende vores behandling for at sikre den bedst mulige beskyttelse af dine
                                    brugerdata.
                                </p>
                                <h2>
                                    7. DINE RETTIGHEDER
                                </h2>
                                <h3>
                                    7.1. Tilbagetrækning af samtykker
                                </h3>
                                <p>
                                    Hvis vi behandler dine brugerdata på grundlag af dit samtykke, kan du til hver en
                                    tid trække det tilbage. Det påvirker dog ikke lovligheden af behandlingen før
                                    tilbagetrækningen. Vi fortsætter med at levere vores tjenester, hvis de ikke
                                    afhænger af det samtykke, der er blevet trukket tilbage.
                                </p>
                                <h3>
                                    7.2. <strong>Oplysning, berigtigelse og begrænsning</strong>
                                </h3>
                                <p>
                                    7.2.1 Alle brugere har ret til at anmode om oplysninger om behandlingen af deres
                                    persondata. Det gør du ved at kontakte os på <a
                                    href="mailto:privacy@camdiab.com">privacy@camdiab.com</a>.
                                </p>
                                <p>
                                    7.2.2 Din ret til oplysning dækker oplysning om behandlingsformål, data- og
                                    modtagerkategorier, opbevaringstid, hvor dine data kommer fra, og dine rettigheder i
                                    henhold til databehandlingsreglerne. Du kan finde alle disse oplysninger i denne
                                    databeskyttelseserklæring, og vi sender den gerne til dig i elektronisk form.
                                </p>
                                <p>
                                    7.2.3 Hvis nogle af dine persondata er forkerte, kan du til hver en tid anmode om,
                                    at dine data bliver rettet (berigtiget) eller ajourført. Du har ret til at begrænse
                                    databehandlingen under en undersøgelse, som du har bedt om.
                                </p>
                                <h3>
                                    7.3. Sletning (“retten til at blive glemt”)
                                </h3>
                                <p>
                                    Alle brugere har ret til at anmode om, at deres persondata bliver slettet. Det gør
                                    du ved at kontakte os på <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    7.4. Klager
                                </h3>
                                <p>
                                    7.4.1 Hvis du mener, at vi ikke beskytter dine persondata tilstrækkeligt, kan du til
                                    hver en tid kontakte os på <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a> eller kontakte vores
                                    databeskyttelsesrådgiveren direkte på <a
                                    href="mailto:dpo@evalian.co.uk">dpo@evalian.co.uk</a>. Vi håndterer din anmodning på
                                    passende vis.
                                </p>
                                <p>
                                    7.4.2 Alle brugere har ret til at indsende en klage til den relevante
                                    databeskyttelsesmyndighed, som er ansvarlig for CamDiab, hvis de mener, at
                                    behandlingen af deres persondata ikke overholder databeskyttelsesreglerne. Derudover
                                    har brugeren ret til at klage til en tilsynsmyndighed i det EU-medlemsland, hvor
                                    denne bor, hvor denne arbejder, eller hvor en formodet overtrædelse er sket.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

