/**
 * Common texts - what is CamAPS FX
 */
export const whatIsCamAPSFX = {

    linkFindOutMore: 'Find out more',
    body:
        '<h2>What is CamAPS FX?</h2> ' +
        '<p> ' +
        '    CamAPS FX is an app to manage glucose levels in ' +
        '    people with type 1 diabetes using ' +
        '    an advanced adaptive hybrid closed-loop approach. ' +
        '</p>'
};

/**
 * Common texts - what is CamAPS FX
 */
export const userReviewsIntro = {
    linkMoreReviews: 'More reviews',
    readMore: 'Read more',
    header:
        'CamAPS FX User Reviews',
    text:
        'Real stories about real people using the CamAPS FX app'
};