import React, {Component} from 'react';
import {Header} from './Header';
import {Footer} from './Footer';
import {Helmet} from "react-helmet";

export class PrivacyENCA extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Privacy - CamAPS FX</title>
                    <meta name="title" content='Privacy policy'/>
                    <meta name="description"
                          content='Here you can find CamDiab privacy policy'/>
                    <meta property="og:locale" content='en'/>
                    <meta name="keywords" content='privacy policy'/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10">
                                <h1>Privacy Policy</h1>
                                <h3>Version dated December 4, 2024</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">


                                <h2>
                                    1. INTRODUCTION
                                </h2>
                                <h3>
                                    1.1. Responsible entity
                                </h3>
                                <p>
                                    CamDiab Limited, registered office address 20-22 Wenlock Road, London, N1 7GU, UK,
                                    incorporated and registered in England and Wales with company number
                                    11659211 (“CamDiab”), is the stated responsible entity under the data
                                    protection regulations. CamDiab decides on the purpose and means of
                                    processing the personal data of our users (“<strong>User Data</strong>”)
                                    and is therefore responsible for its security and compliance with the
                                    applicable laws.
                                </p>
                                <p>
                                    1.1.2 As the responsible entity we are subject, for example, to information
                                    requirements that we wish to fulfil in connection with this privacy notice.
                                </p>
                                <h3>
                                    1.2. Structure and consent concept
                                </h3>
                                <p>
                                    1.2.1 This privacy notice informs you about the purposes and scope of
                                    processing your User Data, as well as data transfers, and your extensive
                                    rights. As our offer is exclusively aimed at persons with diabetes, your
                                    use typically already provides information on your health condition. We
                                    therefore only process User Data as health data with your consent. We
                                    differentiate as follows:
                                </p>
                                <p>
                                    1.2.1.1 “Necessary Processing” describes how we process the User Data
                                    required to fulfil the contract. Without this consent the use of our
                                    products is not possible from a legal and a factual point of view because
                                    our services depend on this processing.
                                </p>
                                <p>
                                    1.2.1.2 “Processing for Product Improvement” explains how you can help us
                                    and other users, with your consent, by allowing us to use your data in
                                    particular to improve algorithms for therapy management, improve the
                                    product and so forth without us contacting you.
                                </p>
                                <p>
                                    1.2.1.3 “Processing for Marketing Purposes” describes how we contact you
                                    for marketing purposes, with your consent, e.g. by email, notifications
                                    etc.
                                </p>
                                <p>
                                    1.2.1.4 In “General Information” we have assembled the information that
                                    applies to all of the above consents to avoid repetition.
                                </p>
                                <p>
                                    The relevant categories are described in more detail below. You must
                                    provide the relevant consent upon registration or when ordering your
                                    product.
                                </p>
                                <p>
                                    1.2.2 In some cases, the processing may take place independently of consent
                                    on the basis of statutory principles (e.g. medical device regulations).
                                </p>
                                <h2>
                                    2. NECESSARY PROCESSING
                                </h2>
                                <p>
                                    If you grant your consent, we process the User Data listed below in order
                                    to be able to provide our services. If you do not consent to this necessary
                                    processing, you cannot use the services of CamDiab. You may provide your
                                    consents during the registration process or when ordering your product.
                                </p>
                                <h3>
                                    2.1. Necessary User Data
                                </h3>
                                <p>
                                    2.1.1 In order to protect your User Data, our services can only be used in
                                    connection with a user account. To create a user account we require and
                                    process the following User Data:
                                </p>
                                <ul type="disc">
                                    <li>
                                        Email address
                                    </li>
                                    <li>
                                        Password (we only save a checksum)
                                    </li>
                                    <li>
                                        Name
                                    </li>
                                    <li>
                                        VAT exemption declaration (name, address, date; for self-funders)
                                    </li>
                                    <li>
                                        Year of birth
                                    </li>
                                    <li>
                                        Registration date
                                    </li>
                                    <li>
                                        Status of consent
                                    </li>
                                    <li>
                                        Device ID, manufacturer, device type, operating system version, app
                                        version
                                    </li>
                                    <li>
                                        Language, country, time zone.
                                    </li>
                                </ul>
                                <p>
                                    2.1.2 Other information collected outside a user registration include:
                                </p>
                                <p>
                                    <strong>Medical Master Data </strong>
                                    <br/>
                                    Weight, blood glucose target range, pump basal settings, correction
                                    factors, carbs / insulin ratio, insulin action duration, target glucose,
                                    alert settings, bolus step, carbohydrate unit, pregnancy status, estimated due date.
                                </p>
                                <p>
                                    <strong>Usage and Linkage Data</strong>
                                    <br/>
                                    Pump and transmitter serial number, pump, sensor and closed-loop/open-loop
                                    actions trail, app command-flow trail, event trail, followers information
                                    as entered into the app (phone number, credentials of diabetes data portal
                                    accounts), support queries, trainer details, diabetes clinic ID.
                                </p>
                                <p>
                                    <strong>Medical Data</strong>
                                    <br/>
                                    App entries such as date/time/time zone/place, food intake/meal, blood and
                                    sensor glucose measurements, sensor glucose rate of change, total daily
                                    insulin dose, insulin delivery (bolus and basal), temporary basal rate,
                                    closed-loop status, pump and cannula priming, alerts, boost and ease-off
                                    events, app settings such as display options, statistical summaries of
                                    glucose and insulin data.
                                </p>
                                <p>
                                    2.1.3 App may require access to your location solely to enable Bluetooth Low Energy
                                    (BLE) connectivity, as mandated by operating system permissions. However, no
                                    location data is collected, stored, or shared by the app.
                                </p>
                                <p>
                                    2.1.4 If you wish, you can enter any email address that you set up
                                    especially for us – however it must work so that we can send you important
                                    warnings.
                                </p>


                                We only process the User Data that you actively and voluntarily provide to
                                CamDiab or which are obtained from glucose sensor and insulin pump you
                                voluntarily connect to. The entry of necessary User Data is however a
                                requirement for the comprehensive use of our products.
                                <h3>
                                    2.2. Necessary purposes
                                </h3>
                                <p>
                                    2.2.1 All the necessary purposes of our processing are associated with
                                    providing our services:
                                </p>
                                <p>
                                    <strong>Customer enquiry </strong>
                                    leads to contact details data recordings.
                                </p>
                                <p>
                                    <strong>Ordering </strong>
                                    of our apps by self-funders leads to contact details and technical data
                                    recordings such as device ID.
                                </p>
                                <p>
                                    <strong>Installation </strong>
                                    of our apps leads to technical and device-related data recordings.
                                </p>
                                <p>
                                    <strong>Registration </strong>
                                    leads to the creation of your CamDiab account using the email address and
                                    password.
                                </p>
                                <p>
                                    The <strong>provision of our services</strong> requires you to voluntarily
                                    and actively enter or provide data and each function describes the purpose
                                    for which the data is needed.
                                </p>
                                <p>
                                    Communication from CamDiab or distributor with you within our apps or via
                                    other electronic messaging services (e.g. email, messenger, telephone)
                                    where this is required to support or troubleshoot our products. This is how
                                    we process any comments and queries that you may have via various
                                    communication channels when using CamDiab. The most important example is
                                    our support service, which you can access at <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Please
                                    therefore think about which information and data you want to give in your
                                    active communication with us - this is solely your decision. For our part,
                                    communication with users may be necessary by email or similar. This is how
                                    we inform you about updates to our products and provide important security
                                    advice as well as assistance associated with your usage. This support
                                    communication - as an essential part of our products - is sent to users.
                                </p>
                                <p>
                                    <strong>Therapy devices </strong>
                                    (e.g. glucose sensor and insulin pumps) are paired with your device which
                                    enables data to be transferred to our apps.
                                </p>
                                <p>
                                    <strong>Diabetes data portals and data sharing</strong>
                                    , such as those by Glooko/mylife Cloud enable data to be exchanged with our
                                    apps. But synchronization only takes place if you activate this in the
                                    settings of our apps, namely, if you use the function. Privacy Notice of
                                    manufacturers of diabetes data portals applies for data shared with such
                                    third-party collaborators.
                                </p>

                                2.2.2 <strong>Use</strong> of our apps requires you to actively and
                                voluntarily enter or provide data. To resolve an error in the app we
                                require, for example, crash reports that we can use for troubleshooting
                                purposes to determine the circumstances of the problem. In addition, the
                                key data of your device and your usage behaviour are recorded as our
                                contractual fulfilment, above all, means customizing our products i.e.
                                processing individual user information, for example, depending on your
                                location (also relevant for the search function). An automated analysis of
                                user behaviour is performed exclusively for the purpose of customizing your
                                use when fulfilling the contract and has no legal effect for you.

                                <h2>
                                    3. PROCESSING FOR PRODUCT IMPROVEMENT
                                </h2>
                                <p>
                                    We also process your User Data beyond the necessary usage described in
                                    section 2 above to improve our products and services as described in more
                                    detail below.
                                </p>
                                <h3>
                                    3.1. Additional data
                                </h3>
                                <p>
                                    In general, we use the same User Data to improve our products as stated in
                                    section 2. In addition, CamDiab may also record the following User Data:
                                </p>
                                <p>
                                    <strong>Usage Data</strong>
                                    <br/>
                                    Activity events that allow us to understand how you use our products. This
                                    enables us to see how our products are used and for example where menu
                                    designs can be optimized.
                                </p>
                                <h3>
                                    3.2. Purpose of product improvement
                                </h3>
                                <p>
                                    As a result of fast-moving technological progress, we have to continually
                                    analyse, develop, test, and improve our products and their interactions, in
                                    order to ensure that our content benefits users in the most effective way.
                                    To achieve this, we may conduct usage, feasibility, predictive, control and
                                    security assessments and the knowledge gained is incorporated into improved
                                    new versions of our products such as the app. These improvements are also
                                    provided to you via regular updates.
                                </p>
                                <p>
                                    CamDiab is committed to the science of all aspects of diabetes. Therefore,
                                    anonymous User Data may also be used for the purposes of research and statistics
                                    (always whilst complying with the recognized ethical scientific standards) and
                                    internal analyses. This may include sharing anonymised data with third-party
                                    collaborators.
                                </p>
                                <h2>
                                    4. PROCESSING FOR MARKETING PURPOSES
                                </h2>
                                <h3>
                                    4.1. Newsletter
                                </h3>
                                <p>
                                    4.1.1 We may send you information on products and services in addition to
                                    the contractual scope (including information from carefully selected
                                    partners) and invitations to participate in surveys or other sales
                                    promotions and marketing activities (“<strong>Newsletter</strong>”).
                                </p>
                                <p>
                                    4.1.2 You can select whether you want to subscribe to our Newsletter
                                    (opt-in). You can revoke your consent at any time in account settings.
                                </p>
                                <h3>
                                    4.2. Other types of marketing
                                </h3>
                                <p>
                                    4.2.1 Other consents, e.g. for surveys, notifications, or customized
                                    offers, are obtained as required. We always explain to you why we need
                                    certain data and also how you can revoke the consent.
                                </p>
                                <p>
                                    4.2.2 Please be aware that we may show you offers within the app without
                                    processing your personal data. You will also see these non-customized
                                    advertisements if you have not provided your consent.
                                </p>
                                <h2>
                                    5. USAGE FOR STATUTORY PURPOSES
                                </h2>
                                <h3>
                                    5.1. Scientific research and statistics
                                </h3>
                                <p>
                                    CamDiab is committed to the science of all aspects of diabetes. Therefore,
                                    anonymous User Data may also be used for the purposes of research and
                                    statistics (always whilst complying with the recognized ethical scientific
                                    standards) and internal analyses. This is used mainly to determine and
                                    improve the effectiveness of techniques for controlling and treating
                                    diabetes. The legal basis for this is Article 9 (2) j) General Data Protection
                                    Regulation (GDPR).
                                </p>
                                <h3>
                                    5.2. Enforcement of rights
                                </h3>
                                <p>
                                    The use of personal data may also be necessary to prevent abuse by users or
                                    to assert, exercise, or defend legal claims. We may be forced into
                                    disclosure due to binding laws, court or official decisions and
                                    instructions, criminal investigation, or in the public interest. In such
                                    cases, the storage and processing of your data are permitted by law without
                                    your consent. The legal basis for this is Article 9 (2) f) GDPR.
                                </p>
                                <h3>
                                    5.3. In accordance with medical device legislation
                                </h3>
                                <p>
                                    Finally, as the manufacturer or distributor of a medical device, we are
                                    subject to increased requirements for monitoring the functionality of our
                                    product. This vigilance system required for regulatory purposes may also
                                    involve the processing of personal data. The legal basis for this is
                                    Article 9 (2) i) GDPR.
                                </p>
                                <h2>
                                    6. GENERAL INFORMATION
                                </h2>
                                <h3>
                                    6.1. Purpose limitation and security
                                </h3>
                                <p>
                                    6.1.1 CamDiab uses your personal data exclusively for the purposes
                                    determined in this privacy notice and the relevant consents. We ensure that
                                    each processing is restricted to the extent necessary for its purpose.
                                </p>
                                <p>
                                    6.1.2 Each processing always guarantees adequate security and
                                    confidentiality of your personal data. This covers protection from
                                    unauthorized and illegal processing, unintentional loss, unintentional
                                    destruction or damage using appropriate technical and organizational
                                    measures. We use strict internal processes, security features, and
                                    encryption methods.
                                </p>
                                <h3>
                                    6.2. Processors
                                </h3>
                                <p>
                                    6.2.1 Our products are subject to complex processes that we have to manage
                                    and keep up-to-date. For technical support we may use third-party suppliers
                                    (“<strong>Processors</strong>”) in order to offer you a comprehensive and
                                    optimal use of our products.
                                </p>
                                <p>
                                    6.2.2 CamDiab transfers User Data to Processors exclusively within the
                                    framework of this privacy notice and only to fulfil the purposes stated in
                                    it. Processors work according to our specifications and instructions; they
                                    are not permitted to use the personal data of our users for their own or
                                    other purposes.
                                </p>
                                <p>
                                    6.2.3 We use Processors offering sufficient guarantees that suitable
                                    technical and organizational measures are undertaken in a way that the
                                    processing of personal data complies with the statutory requirements and
                                    our privacy notice. The protection of the rights of our users is ensured by
                                    concluding binding contracts that meet the strict requirements of GDPR.
                                </p>
                                <h3>
                                    6.3. Encryption, pseudonymization, and anonymization
                                </h3>
                                <p>
                                    6.3.1 Each data transfer, without exception and by default, is encrypted
                                    during transfer. Using HTTPS (hypertext transfer protocol secure) or
                                    similar we ensure that your data is not intercepted by unauthorized third
                                    parties.
                                </p>
                                <p>
                                    In addition, for the purposes of data security and minimization, we also
                                    use other processes for the encryption and pseudonymization of User Data.
                                    Of course this depends on the type, scope, and purpose of the relevant data
                                    processing. For example, we only disclose User Data that a Processor
                                    requires to carry out his tasks.
                                </p>
                                <p>
                                    6.3.2 When a contractual relationship with a Processor is terminated, such
                                    Processor must, at CamDiab’s discretion, either return all our User’s Data
                                    or delete it if there are no statutory storage obligations.
                                </p>
                                <p>
                                    6.3.3 Data that requires no personal reference for processing (e.g. for
                                    research and analysis) is subject to anonymization. This prevents a
                                    connection to a specific user being made in all cases
                                </p>
                                <h3>
                                    6.4. EU and other countries
                                </h3>
                                <p>
                                    6.4.1 We seek to select cooperation partners who are based in or whose
                                    servers are located in the European Union (EU) or European Economic Area
                                    (EEA). Data transmission within the EU and EEA is unobjectionable because
                                    the GDPR applies in all member states.
                                </p>
                                <p>
                                    6.4.2 In exceptional circumstances we appoint third-party suppliers who are
                                    located in or who have servers outside the EU. However, even in these cases
                                    your personal data is subject to a high protection level in line with the
                                    GDPR – either through an EU adequacy decision, which considers data
                                    protection in certain third-party countries to be appropriate (e.g.
                                    Switzerland, Israel, and New Zealand), or through certain standard
                                    contractual clauses approved by the EU, which the contractual relationships
                                    with our contracted data processors are based on, or through comparable
                                    legal instruments permitted under the GDPR.
                                </p>
                                <p>
                                    6.4.3 In addition, we ensure that our partners have additional security
                                    standards in place, such as individual security measures and data
                                    protection provisions or certifications under the GDPR. So, for example, if
                                    third-party suppliers are located in the USA they should be subject to the
                                    Privacy Shield Framework approved by the EU or comparable internationally
                                    recognized security standards.
                                </p>
                                <h3>
                                    6.5. Cookies
                                </h3>
                                <p>
                                    CamDiab may store so-called “cookies” to offer you a comprehensive range of
                                    functions and make the use of our website more convenient. Cookies are
                                    small text files that are stored on your device by your browser. Except for
                                    the cookies for usage data mentioned in section 6.6, our cookies may be
                                    used to operate the website. If you do not want to use cookies you can
                                    prevent them from being stored using the relevant settings in the browser.
                                    Most of our cookies are either deleted at the end of your visit or when the
                                    browser is closed (session cookies). If this is not the case, you can check
                                    the deletion period in your browser or delete the cookies manually.
                                </p>
                                <h3>
                                    6.6. Usage data
                                </h3>
                                <p>
                                    We use Google Firebase Analytics in the app, an app and web analysis
                                    service by Google Inc. (“Google”). Google is certified under the EU-US
                                    Privacy Shield.
                                </p>
                                <h3>
                                    6.7. Storage and deletion
                                </h3>
                                <p>
                                    6.7.1 Your User Data is stored on your device. This data is also stored on
                                    servers. We only use systems that meet GDPR requirements.
                                </p>
                                <p>
                                    6.7.2 Your data are stored on servers in the European Union (EU).
                                </p>
                                <p>
                                    6.7.3 As a rule, CamDiab only stores your personal data for the duration of
                                    the contract. In exceptional cases, longer storage may be required in order
                                    to fulfil post-contractual obligations or to comply with statutory storage
                                    obligations or disclosure duties, or to assert, exercise, or defend legal
                                    claims (limitation periods).
                                </p>
                                <h3>
                                    6.8. Minors
                                </h3>
                                <p>
                                    Minors, below the age of sixteen are only permitted to use our products
                                    with the consent of a parent/guardian (
                                    <a href="/en-ca/terms">
                                        General Terms and Conditions - T&amp;Cs
                                    </a>
                                    ). This also applies to processing their personal data, which is only legal
                                    if and to the extent to which the consent has been obtained by and through
                                    the parent/guardian. Otherwise use of our products is prohibited.
                                </p>
                                <h3>
                                    6.9. Data protection officer
                                </h3>
                                <p>
                                    6.9.1 Our data protection officer is available to answer all data
                                    protection questions at <a href="mailto: dpo@evalian.co.uk"> dpo@evalian.co.uk</a>.
                                </p>
                                <p>
                                    6.9.2 The data protection officer is widely involved in all questions
                                    associated with protecting the personal data of our users. As a trained
                                    expert, she/he monitors our processing on an ongoing basis to ensure the
                                    best possible protection of your User Data.
                                </p>
                                <h2>
                                    7. YOUR RIGHTS
                                </h2>
                                <h3>
                                    7.1. Revocation of consents
                                </h3>
                                <p>
                                    If we process your User Data based on your consent, you may revoke the
                                    consent at any time. However, this will not affect the lawfulness of the
                                    processing before the revocation. We will continue to provide our services
                                    if they do not depend on the consent that has been revoked.
                                </p>
                                <h3>
                                    7.2. <strong>Information, correction, and restriction</strong>
                                </h3>
                                <p>
                                    7.2.1 Each user has the right to request information on the processing of
                                    their personal data. To do so, please contact us at any time at <a
                                    href="mailto: dpo@evalian.co.uk"> dpo@evalian.co.uk</a>.
                                </p>
                                <p>
                                    7.2.2 Your right to information covers information on the processing
                                    purposes, data and recipient categories, storage time, origin of your data,
                                    and your rights under the data protection regulations. You can find all of
                                    this information in this privacy notice and we are happy to provide it to
                                    you in an electronic form.
                                </p>
                                <p>
                                    7.2.3 Should some of your personal data be incorrect, you can request that
                                    your data is corrected or completed at any time. You have the right to
                                    restrict data processing for the duration of any investigation review that
                                    you have requested.
                                </p>
                                <h3>
                                    7.3. Deletion (“right to be forgotten”)
                                </h3>
                                <p>
                                    Each user has the right to request the deletion of their personal data. To
                                    do so, please contact us at any time at <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    7.4. Complaints
                                </h3>
                                <p>
                                    7.4.1 If you feel we are not protecting your data protection rights
                                    adequately, please contact us at any time at <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a> or contact our
                                    data protection officer directly at <a
                                    href="mailto:privacy@camdiab.com">privacy@camdiab.com</a>. We will
                                    handle your request appropriately.
                                </p>
                                <p>
                                    7.4.2 Any user has the right to submit a complaint with the appropriate
                                    Data Protection Authority responsible for CamDiab if they believe that the
                                    processing of their personal data is not in compliance with data protection
                                    regulations. In addition, the user has a right to complain to a supervisory
                                    authority in the EU member state in which they are resident, in which their
                                    workplace is located, or which is the location of a suspected infringement.
                                </p>


                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

