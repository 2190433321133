/**
 * Texts for FAQs page
 */
export const faqPageText = {
    title: 'Was ist CamAPS FX',
    titleMeta: 'Haben Sie eine Frage zu CamAPS FX?',
    descriptionMeta: 'Hier sind die Antworten auf einige Ihrer häufig gestellten Fragen ' +
        'zu kompatiblen Geräten, behördlicher Zulassung, Verfügbarkeit und klinischer Forschung',
    keywords:
        'camaps faqs deutschland, camaps fx faqs deutschland, ' +
        // 'closed loop, closed loop system, loop deutsch, eine autoimmunerkrankung kommt selten allein, glukose im blut, closed de, open loop closed loop, loop diabetes, was ist ein loop, hba1c zu niedrig, blutglukose, closed loop diabetes, dexcom app, insulin berechnen app, closed loop insulinpumpe, diabetes zimt therapie, glukose zu hoch, stopp diabetes, dexcom g6 closed loop, diabetes kohlenhydrate app, hybrid closed loop diabetes, loop dexcom g6, welche insulinpumpen gibt es, diabetes loop system, kohlenhydrate app android, kur diabetes typ 1, was heißt loop, mutter kind kur für diabetiker,  ' +
        '',
    localeMeta: 'de_de',

    haveQuestion: 'Haben Sie eine Frage zu CamAPS FX?',
    hereAnswers: 'Hier sind die Antworten zu einigen häufig gestellten Fragen.',
    whatIsCamAPS: 'Was ist CamAPS FX?',
    whatIsCamAPSAnswer: 'CamAPS FX ist eine über einen Zeitraum von mehr als 10 Jahren entwickelte, benutzerfreundliche Applikation zur automatischen Insulindosierung (Hybrid Closed Loop) bei Menschen mit Typ-1-Diabetes (ab dem 1. Lebensjahr).',
    isApproved: 'Ist CamAPS FX zugelassen?',
    isApprovedAnswer: 'Die App wurde umfassend in klinischen Studien geprüft und ist für den Einsatz in UK und in der EU zugelassen.',
    isAvailable: 'Gibt CamAPS FX in meiner Region?',
    isAvailableAnswer1: 'CamAPS FX ist in ausgewählten Diabeteskliniken verfügbar und für Privatzahler, die ein Online-Training absolvieren. Bitte halten Sie Rücksprache mit Ihrer Klinik oder gehen Sie zu',
    isAvailableAnswer2: 'Bestellung',
    isAvailableAnswer3: 'der App.',
    cost: 'Was kostet CamAPS FX?',
    costAnswer1: 'Für Details zu den Kosten gehen Sie bitte zu',
    costAnswer2: 'Bereich der Webseite.',
    compatibleCgm: 'Welche CGMs sind kompatibel mit CamAPS FX?',
    compatibleCgmAnswer: 'CamAPS FX ist kompatibel mit dem',
    compatibleCgmAnswer1: 'und dem',
    compatibleCgmAnswer2: '. Bitte klicken Sie auf die Links, um kompatible Smartphone und Betriebssysteme anzuzeigen.',
    whatPumps: 'Welche Insulinpumpen sind kompatibel mit CamAPS FX?',
    whatPumpsAnswer1: 'CamAPS FX ist kompatibel mit',
    whatPumpsAnswer2: 'mylife YpsoPump, DANA Diabecare RS und DANA-i',
    whatPumpsAnswer3: 'Insulinpumpen.',
    whatPumpsAnswerHtml: 'https://www.ime-dc.de/de/insulintherapie/insulinpumpen/insulinpumpe-dana-rs',
    whatPhone: 'Welche Smartphones sind kompatibel mit CamAPS FX?',
    whatPhoneAnswer: 'CamAPS FX läuft auf einem Android-Telefon und ist mit Android-Smartphone-Modellen kompatibel, die von Ihrem CGM-Hersteller unterstützt werden.',

    whatPhoneAnswer1: 'Bitte',
    whatPhoneAnswer2: 'klicken',
    whatPhoneAnswer3: 'Sie auf diesen Link, um eine Liste der Geräte und Betriebssysteme anzuzeigen, die mit',
    whatPhoneAnswerDexcom: 'dem Dexcom G6 Sensor und der Dexcom G6 App kompatibel sind.',
    whatPhoneAnswerLibre3: 'dem FreeStyle Libre 3 Sensor und der FreeStyle Libre 3 App kompatibel sind.' +
        '<p><p>' +
        'Wird ab dem 1. Januar 2025 CamAPS FX nur noch auf sensor-kompatiblen Handys mit Android-Betriebssystemen 10 und höher unterstützt. Benutzer von Betriebssystemen unter OS 10 werden CamAPS FX nach diesem Datum nicht mehr aktualisieren oder neu installieren können.',
    whatPhoneAnswerDexcomHtml:'https://www.dexcom.com/compatibility',
    whatPhoneAnswerLibreHtml:'https://www.diabetescare.abbott/support/manuals.html',

    whereUpload: 'Wohin kann ich meine Daten von der CamAPS FX hochladen?',
    whereUploadAnswer: 'Derzeit können Daten von der CamAPS FX hochgeladen werden nach Glooko.',
    whatLanguage: 'In welchen Sprachen steht CamAPS FX zur Verfügung?',
    whatLanguageAnswer: 'CamAPS FX steht auf Englisch, Tschechisch, Dänisch, Deutsch, Spanisch, Französisch, Italienisch, Niederländisch, Polnisch, Finnisch und Schwedisch zur Verfügung.',
    whereResults: 'Wo kann ich die Resultate der klinischen Studien finden?',
    whereResultsAnswer1: 'Publikationen zum Einsatz des Cambridge Control Algorithmus stehen zum Download zur Verfügung',
    whereResultsAnswer2: 'hier.',
    whereResultsAnswer3: 'Zentrale Publikationen wurden veröffentlicht in',
    whereResultsAnswer4: 'und',
};

