/**
 * Common texts - what is CamAPS FX
 */
export const whatIsCamAPSFX = {

    linkFindOutMore: 'Mehr erfahren',
    body:
        '<h2>Was ist CamAPS FX?</h2> ' +
        '<p> ' +
        'CamAPS FX ist eine App zum Management von Glukosewerten bei Menschen mit Typ-1-Diabetes, die einen fortschrittlichen, anpassungsfähigen Hybrid-Closed-Loop Ansatz verwendet.' +
        '</p>'
};

/**
 * Common texts - what is CamAPS FX
 */
export const userReviewsIntro = {
    linkMoreReviews: 'Mehr Bewertungen',
    readMore: 'Mehr lesen',
    header:
        'CamAPS FX Anwenderbewertungen',
    text:
        'Echte Berichte über echte Menschen, die die CamAPS FX App verwenden'
};

