/**
 * Texts for Notifications page
 */
export const notificationsPageText = {
    title: 'Viimeisimmät sovelluksen sisäiset ilmoitukset',
    titleMeta: 'Näytetään viimeisimmät sovelluksen sisäiset ilmoitukset CamAPS FX',
    descriptionMeta: 'Tässä on teksti viimeisimmistä sovelluksen sisäisistä ilmoituksista, jotka koskevat sovelluspäivityksiä, turvallisuusilmoituksia ja käyttöopaspäivityksiä',
    keywords: 'sovelluksen' ,
    localeMeta: 'fi_fi',

    heading: 'Viimeisimmät sovelluksen sisäiset ilmoitukset',
    subheading: '',
	
	header_34:'',
    body_34:'',
	
	header_33:'Ilmoitus suunnitellusta huollosta 05/12/2024',
    body_33:'CamAPS FX -palvelun pilvipalvelimen suunnitellun huollon vuoksi Companion-toiminto ei ole käytettävissä 7. joulukuuta klo 10:00 GMT alkaen noin kolmen tunnin ajan. Pahoittelemme mahdollisia haittoja, joita tämä saattaa aiheuttaa.',
	
	header_32:'Käyttöoppaan päivitys 28/11/2024',
    body_32:'Käyttöoppaasta on saatavilla päivitetty versio.',
	
	header_31:'Sovelluksen päivitys 17/11/2024',
    body_31:'Arvoisa mylife CamAPS FX -käyttäjä, Uusi sovellusversio on valmis seuraavan 48 tunnin aikana. Valitse haluamasi päivitysaika poistamalla automaattinen päivitys CamAPS FX:lle Google Play -kaupassa. Jos sinulla on kysyttävää, ota yhteyttä Ypsomedin asiakaspalveluun.',
	
	header_30:'OS 8 & 9 11/10/2024',
    body_30:'Kyberturvallisuussyistä mylife CamAPS FX ä tuetaan 1. tammikuuta 2025 alkaen vain anturiyhteensopivilla puhelimilla, joissa on Android-käyttöjärjestelmät 10 ja uudemmat. Käyttöjärjestelmien käyttäjät, joiden versio on alle OS 10, eivät enää tämän päivämäärän jälkeen voi päivittää tai asentaa uudelleen mylife CamAPS FX ä.',
	
	header_29:'Sovelluksen päivitys 09/10/2024',
    body_29:'Arvoisa mylife CamAPS FX -käyttäjä, Uusi sovellusversio on valmis seuraavan 48 tunnin aikana. Valitse haluamasi päivitysaika poistamalla automaattinen päivitys CamAPS FX:lle Google Play -kaupassa. Jos sinulla on kysyttävää, ota yhteyttä Ypsomedin asiakaspalveluun.',
	
	header_28:'OS 8 & 9 08/07/2024',
    body_28:'Kyberturvallisuussyistä mylife CamAPS FX ä tuetaan 1. tammikuuta 2025 alkaen vain anturiyhteensopivilla puhelimilla, joissa on Android-käyttöjärjestelmät 10 ja uudemmat. Käyttöjärjestelmien käyttäjät, joiden versio on alle OS 10, eivät enää tämän päivämäärän jälkeen voi päivittää tai asentaa uudelleen mylife CamAPS FX ä.',
	
	header_27:'',
    body_27:'',
	
	header_26:'Käyttöoppaan päivitys 11/05/2024',
    body_26:'Käyttöoppaasta on saatavilla päivitetty versio.',
	
	header_25:'',
    body_25:'',
	
	header_24:'',
    body_24:'',
	
	header_23:'Sovelluksen päivitys',
    body_23:'Arvoisa mylife CamAPS FX -käyttäjä, Uusi sovellusversio on valmis seuraavan 24 tunnin aikana. Valitse haluamasi päivitysaika poistamalla automaattinen päivitys CamAPS FX:lle Google Play -kaupassa. Jos sinulla on kysyttävää, ota yhteyttä Ypsomedin asiakaspalveluun.',
	
	header_22:'Käyttöoppaan päivitys 21/04/2024',
    body_22:'Käyttöoppaasta on saatavilla päivitetty versio.',
	
	header_21:'',
    body_21:'',
	
	header_20:'',
    body_20:'',
	
	header_19:'',
    body_19:'',

    header_18:'',
    body_18:'',

    header_17:'',
    body_17:'',

	header_16:'Käyttöoppaan päivitys 04/03/2024',
    body_16:'Käyttöoppaasta on saatavilla päivitetty versio.',

	header_15:'Käyttöoppaan päivitys 04/01/2024',
    body_15:'Käyttöoppaasta on saatavilla päivitetty versio.',
	
	header_14:'Android OS 14. 22/11/2023',
    body_14:'Muistutus: Taataksesi katkeamattoman käytön mylife CamAPS FX -sovellukselle, tarkista valmistajan anturin verkkosivusto varmistaaksesi yhteensopivuuden ennen päivittämistä Android OS 14:ään. Huomio: Automaattiset päivitykset saattavat olla oletusarvoisesti päällä puhelimesi asetuksissa.OS 14:ään.',
	
	header_13:'KENTTÄTURVALLISUUSILMOITUS 14/11/2023',
    body_13:'KENTTÄTURVALLISUUSILMOITUS ',
    body_13_link_text:'Lue tärkeä neuvoa-antava huomautus',
    body_13_end: '. Siirry sovellusvalikkoon -> Turvallisuusilmoitukset.',
    body_13_link:'https://s3.eu-west-1.amazonaws.com/camaps.safety.notices/Field+Safety+Notice+ET5604+Mylife+Users+final+-+FI.pdf',

	header_12:'Käyttöoppaan päivitys 10/11/2023',
    body_12:'Käyttöoppaasta on saatavilla päivitetty versio.',
	
	header_11:'Android OS 14. 12/09/2023',
    body_11:'Taataksesi katkeamattoman käytön mylife CamAPS FX -sovellukselle, tarkista valmistajan anturin verkkosivusto varmistaaksesi yhteensopivuuden ennen päivittämistä Android OS 14:ään. Huomio: Automaattiset päivitykset saattavat olla oletusarvoisesti päällä puhelimesi asetuksissa.Varmista, että käytät mylife CamAPS FX -sovelluksen versiota 1.4(173) ennen päivitystä Android OS 14:ään.',
	
	header_10:'KENTTÄTURVALLISUUSILMOITUS 15/08/2023',
    body_10:'KENTTÄTURVALLISUUSILMOITUS Lue tärkeä neuvoa-antava huomautus. Siirry sovellusvalikkoon -> Turvallisuusilmoitukset.',
	
	header_9:'Käyttöoppaan päivitys 27/07/2023',
    body_9:'Käyttöoppaasta on saatavilla päivitetty versio.',
	
	header_8:'Glooko-yhteensopivuus 21/07/2023',
    body_8:'Kuten aiemmin ilmoitettiin, diasend lopetetaan 28. heinäkuuta. Jos haluat jatkaa datan jakamista diabetestiimisi kanssa, päivitä diasend-tilisi Glookoon tai luo uusi Glooko-tili. Päivitä uusimpaan mylife CamAPS FX -versioon, syötä uusi Glooko-käyttäjätunnus ja salasana jakovalikossa ja käynnistä puhelimesi uudelleen. Diasend pysyy käytettävissä historiatietojen tarkasteluun loppuvuoden ajan.',
	
	header_7:'Samsung A-sarjan 14/07/2023',
    body_7:'Samsung A-sarjan mallien käyttäjät (esim. A53, A33) ovat saattaneet kokea signaalin menetyksen CGM-anturilleen Samsungin uusimman One UI 5.1 -päivityksen jälkeen. Samsung on tunnistanut ongelman ja kehittää parhaillaan ratkaisua. Samsung aikoo julkaista One UI -päivityksen 24. heinäkuuta (riippuen alueestasi), joka ratkaisee signaalin menetyksen ongelman. On suositeltavaa asentaa päivitys heti kun se tulee saataville. Jos et ole vielä päivittänyt One UI 5.1:een, suosittelemme odottamaan seuraavaa Samsung One UI-päivitystä.',

    header_6:'Glooko-yhteensopivuus (Vain Ypsopump) 14/06/2023',
    body_6:'mylife CamAPS FX on yhteensopiva Glookon kanssa. Päivitä mylife CamAPS FX -sovelluksesi versioon 1.4(172). Jos jaat tietoja klinikan kanssa, sinulle ilmoitetaan olemassa olevalla diasend-tililläsi, kun klinikka päivittää Glookoon. Kun päivitys on valmis, syötä uusi Glooko-käyttäjätunnuksesi ja salasanasi jakovalikkoon ja käynnistä älypuhelimesi uudelleen. Päivitä Glookoon ennen 28. heinäkuuta 2023. Diasend on käytettävissä historiatietojen tarkastelemiseen loppuvuoden ajan.',

    header_5:'',
    body_5:'',

    header_4:'Käyttöoppaan päivitys 27/05/2023',
    body_4:'Käyttöoppaasta on saatavilla päivitetty versio.',

    header_3:'SMS-ilmoitukset (Vain Ypsopump) 11/05/2023',
    body_3:'Seuraavan päivityksen 1.4(171) myötä toukokuun puolivälissä voit aktivoida jopa 5 SMS-seuraajaa, JA käyttää samanaikaisesti Kumppani-tilaa. Kumppani-tilan käyttäjällä tulee olla internetyhteys datan jakamista varten. Käyttäjän puhelimessa tulee olla myös SIM-kortti, jotta hän voi lähettää tekstiviestejä puhelimestaan. Jos käytät jotain näistä vaihtoehdoista, suosittelemme tarkistamaan matkapuhelinoperaattorisi liittymäsopimuksen tekstiviestien ja/datan osalta.',

    header_2:'Cybersecurity-päivitys (Vain Ypsopump) 11/05/2023',
    body_2:'mylife CamAPS FX -sovelluksen turvallisuus on erittäin tärkeää. Cybersecurity-päivitys, joka tulee Google Play Storesta, otetaan käyttöön toukokuun puolivälissä. Voit testata puhelimesi etukäteen menemällä ohjevalikkoon ja tarkistamalla.',

    header_1:'',
    body_1:'',
};
