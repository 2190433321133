/**
 * Texts for footer
 */
export const footerText = {
    txtFAQs: 'FAQs',
    txtSupport: 'Support',
    txtHistory: 'History',
    txtTrainingHtml: 'https://www.camdiabtraining.com/account-sign-in.html',
    txtTraining: 'Online training',
    txtClinic: 'CamAPS FX Clinic',
    terms: 'Terms and conditions',
    privacy: 'Privacy policy',
    txtFooterBottom: 'CamAPS is a registered trademark of CamDiab Ltd. Other trademarks are the ' +
        'property of their respective owners. No use of any CamDiab trademark, trade name, or trade ' +
        'dress in this site may be made without the prior written authorization of CamDiab, ' +
        'except to identify the product or services of the company. The product images ' +
        'are for illustrative purposes only.',
    rightsReserved: 'CamDiab Ltd. All rights reserved.',

    statusPageFooterText: 'Status page',
    statusPageFooterLink: 'https://camdiabltd.statuspage.io/',
};
