/**
 * Texts for FAQs page
 */
export const faqPageText = {
    title: 'Vanlige spørsmål – CamAPS FX',
    titleMeta: 'Har du et spørsmål om CamAPS FX?',
    descriptionMeta: 'Her er svarene på noen vanlige spørsmål om kompatible enheter, godkjenning fra myndighetene, tilgjengelighet og klinisk forskning',
    keywords:
        'camaps faqs, camaps fx faqs, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'nb_no',

    haveQuestion: 'Har du et spørsmål om CamAPS FX?',
    hereAnswers: 'Her er svarene på noen vanlige spørsmål.',
    whatIsCamAPS: 'Hva er CamAPS FX?',
    whatIsCamAPSAnswer: 'CamAPS FX er en avansert, brukervennlig app for styring av glukoseverdier hos personer med diabetes type 1, som er 1 år eller eldre, ved hjelp av en avansert hybrid closed loop-løsning.',
    isApproved: 'Er CamAPS FX godkjent for bruk?',
    isApprovedAnswer: 'Appen har blitt grundig testet i kliniske forsøk og godkjent for bruk i blant annet Storbritannia og EU.',
    isAvailable: 'Er CamAPS FX tilgjengelig der jeg bor?',
    isAvailableAnswer1: 'CamAPS FX er tilgjengelig ved utvalgte diabetesklinikker eller for personer som betaler pleien sin selv og gjennomgår nettbasert opplæring. Spør der du får behandling, eller gå til',
    isAvailableAnswer2: 'Bestilling',
    isAvailableAnswer3: 'for å bestille appen.',
    cost: 'Hvor mye koster CamAPS FX?',
    costAnswer1: 'Du finner informasjon om priser på',
    costAnswer2: 'på nettstedet vårt.',
    compatibleCgm: 'Hvilke vevsglukosemålere er kompatible med CamAPS FX?',
    compatibleCgmAnswer: 'CamAPS FX er kompatibel med',
    compatibleCgmAnswer1: 'og',
    compatibleCgmAnswer2: '. Klikk på linkene for å se kompatible smarttelefoner og operativsystemer.',
    whatPumps: 'Hvilke insulinpumper er kompatible med CamAPS FX?',
    whatPumpsAnswer1: 'CamAPS FX er kompatibel med',
    whatPumpsAnswer2: 'mylife YpsoPump, DANA Diabecare RS og DANA-i',
    whatPumpsAnswer3: 'insulinpumper.',
    whatPumpsAnswerHtml: 'https://advancedtherapeutics.org.uk/shop/dana-diabecare-insulin-pump/dana-i-insulin-pump',
    whatPhone: 'Hvilke smarttelefoner er kompatible med CamAPS FX?',

    whatPhoneAnswer: 'CamAPS FX kjører på en Android-telefon og er kompatibel med Android-smarttelefonmodeller som støttes av produsenten av vevsglukosemåleren din.',
    whatPhoneAnswer1: 'Klikk på denne',
    whatPhoneAnswer2: 'linken',
    whatPhoneAnswer3: 'for en liste over enheter og operativsystemer som er kompatible med',
    whatPhoneAnswerDexcom: 'Dexcom G6 sensoren og Dexcom G6 appen.',
    whatPhoneAnswerLibre3: 'FreeStyle Libre 3 sensoren og FreeStyle Libre 3 appen.' +
        '<p><p></p>' +
        'CamAPS FX fra 1. januar 2025 kun støttes på sensor-kompatible telefoner som kjører Android-operativsystemer 10 og høyere. Brukere av operativsystemer under OS 10 vil ikke lenger kunne oppdatere eller installere CamAPS FX på nytt etter denne datoen.',
    whatPhoneAnswerDexcomHtml:'https://www.dexcom.com/compatibility',
    whatPhoneAnswerLibreHtml:'https://www.diabetescare.abbott/support/manuals.html',

    whereUpload: 'Hvor kan jeg laste opp dataene mine fra CamAPS FX?',
    whereUploadAnswer: 'For øyeblikket kan data fra CamAPS FX lastes opp til Glooko.',
    whatLanguage: 'Hvilke språk er tilgjengelige i CamAPS FX?',
    whatLanguageAnswer: 'CamAPS FX er tilgjengelig på engelsk, tjekkisk, dansk, tysk, spansk, fransk, italiensk, nederlandsk, polsk, finsk og svensk.',
    whereResults: 'Hvor finner jeg resultatene fra de kliniske forsøkene deres?',
    whereResultsAnswer1: 'Publikasjoner knyttet til bruken av Cambridge-kontrollalgoritmen er tilgjengelig for nedlasting',
    whereResultsAnswer2: 'her.',
    whereResultsAnswer3: 'Viktige publikasjoner ble offentliggjort i',
    whereResultsAnswer4: 'og',
};
