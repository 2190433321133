/**
 * Texts for footer
 */
export const footerText = {
    txtFAQs: 'Questions',
    txtSupport: 'Soutien',
    txtHistory: 'L’aventure',
    txtTrainingHtml: 'https://fr.camdiabtraining.com',
    txtTraining: 'Formation en ligne',
    txtClinic: 'CamAPS FX en clinique',
    terms: 'Conditions générales',
    privacy: 'Politique de confidentialité',
    txtFooterBottom: 'CamAPS est une marque déposée de CamDiab Ltd. Toutes les autres ' +
        'marques citées sont la propriété de leurs propriétaires respectifs. Toute utilisation ' +
        'de marques déposées de CamDiab, de leur nom commercial ou d’éléments visuels de ce site ' +
        'est interdite sans autorisation préalable écrite de la part de CamDiab, sauf pour ' +
        'identifier le produit ou les services de l’entreprise. ' +
        'Les images de produits sont fournies uniquement à titre d’information. ' +
        '<br><br>Ce site internet n’est pas destiné aux personnes résidant en France.',
    rightsReserved: 'CamDiab Ltd. Tous droits réservés.',

    statusPageFooterText: 'Page d’état',
    statusPageFooterLink: 'https://camdiabltd.statuspage.io/',
};
