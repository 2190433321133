/**
 * Texts for History page
 */
export const historyPageText = {
    title: 'CamDiab journey',
    titleMeta: 'Clinical research on the way to CamAPS FX',
    descriptionMeta: 'A timeline of how the team behind CamAPS FX came to help those with ' +
        'diabetes with our Cambridge algorithm.',
    localeMeta: 'en_gb',

    keywords:
        'camaps history, camaps fx history, ' +
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ' +
        '',
    journeyHeading: 'Our CamDiab journey',
    timelineHeading: 'A timeline of how the team behind CamAPS FX came to help those with ' +
        'diabetes with our Cambridge algorithm.',
    year2006_2010dates: '2006–2010',
    year2006_2010heading: 'Early in-clinic trials',
    year2006_2010text: 'Start of Artificial Pancreas project at Cambridge with trials in children with ' +
        'type 1 diabetes. Joined the JDRF Artificial Pancreas Consortium. ' +
        'Cambridge algorithm stress-tested following exercise and alcohol consumption ' +
        'in adults with type 1 diabetes. Cambridge algorithm evaluated in pregnant ' +
        'women with type 1 diabetes.',
    year2011_2014dates: '2011–2014',
    year2011_2014heading: 'Night-only and free-living feasibility trials',
    year2011_2014text: 'Overnight use of Cambridge algorithm evaluated by children, adults and ' +
        'pregnant women with type 1 diabetes. Cambridge algorithm used day-and-night over seven days ' +
        'by adults with type 1 diabetes.',
    year2015_2016dates: '2015–2016',
    year2015_2016heading: 'Short day-and-night free-living trials',
    year2015_2016text: 'Cambridge algorithm used by well-controlled adults with type 1 diabetes over four weeks. ' +
        'Cambridge algorithm used by adolescents with type 1 diabetes over three weeks.',
    year2017_2018dates: '2017–2018',
    year2017_2018heading: 'Longer day-and-night and young-children trials',
    year2017_2018text: 'Cambridge algorithm used over three months by children and adults with type 1 ' +
        'diabetes. Cambridge algorithm used over two years by children and adolescents from onset of diabetes. ' +
        'Cambridge algorithm used in young children one to seven years old with type 1 diabetes.',
    year2019_2019dates: '2019',
    year2019_2019heading: 'Transition to CamAPS FX ',
    year2019_2019text: 'CamAPS FX app used by older adults with type 1 diabetes. CamAPS FX app used ' +
        'throughout pregnancy by pregnant women with type 1 diabetes. CamAPS FX app used in ' +
        'children aged one to seven years old with type 1 diabetes.',
    year2020_2020dates: '2020 onwards',
    year2020_2020heading: 'Regulatory approval, commercialisation, clinical trialling',
    year2020_2020text: 'CamAPS FX app CE-marked for use in the EU and the UK. Research ' +
        'continues to assess benefits and support reimbursement.',
};
