/**
 * Texts for header
 */
export const headerText = {
    cookiesIntroText: 'We use cookies to track website visits, but we do not store any personal information.',
    cookiesAccept: 'Accept cookies',
    cookiesDeny: 'Decline cookies',
    tabFAQs: 'FAQs',
    tabSupport: 'Support',
    tabHistory: 'History',
    tabReviews: 'Reviews',
    tabTraining: 'Training',
    // tabOrders: 'Orders',
    tabOrders: '',
    tabNotifications: 'Notifications',
};
