import React from "react";
import {Route, Redirect} from "react-router-dom";
import {withTracker} from "../libs/withTracker";


// import {Helmet} from "react-helmet";

function querystring(name, url = window.location.href) {
    name = name.replace(/[[]]/g, "\\$&");

    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
    const results = regex.exec(url);

    if (!results) {
        return null;
    }
    if (!results[2]) {
        return "";
    }
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

// eslint-disable-next-line import/no-anonymous-default-export
export default ({component: C, props: cProps, ...rest}) => {
    const redirect = querystring("redirect");
    const isEnglishCanada = rest.path.toLowerCase().startsWith('/en-ca');
    const isGerman = rest.path.toLowerCase().startsWith('/de');
    const isFrench = rest.path.toLowerCase().startsWith('/fr-ch');
    const isFrenchCanada = rest.path.toLowerCase().startsWith('/fr-ca');
    const isFrenchFrance = rest.path.toLowerCase().startsWith('/fr');
    const isDutch = rest.path.toLowerCase().startsWith('/nl');
    const isItalian = rest.path.toLowerCase().startsWith('/it');
    const isCzech = rest.path.toLowerCase().startsWith('/cz');
    const isFinnish = rest.path.toLowerCase().startsWith('/fi');
    const isPolish = rest.path.toLowerCase().startsWith('/pl');
    const isSwedish = rest.path.toLowerCase().startsWith('/se');
    const isNorwegian = rest.path.toLowerCase().startsWith('/nb')
        && (!rest.path.toLowerCase().startsWith('/notifications'));
    const isDanish = rest.path.toLowerCase().startsWith('/dk');
    const isSpanish = rest.path.toLowerCase().startsWith('/es');

    if (isGerman) {
        if (cProps.language !== 'DE') {
            cProps.language = 'DE';
            cProps.setSiteLanguage('DE')
        }
    } else if (isEnglishCanada) {
        if (cProps.language !== 'EN-CA') {
            cProps.language = 'EN-CA';
            cProps.setSiteLanguage('EN-CA')
        }
    } else if (isFrench) {
        if (cProps.language !== 'FR') {
            cProps.language = 'FR';
            cProps.setSiteLanguage('FR')
        }
    } else if (isFrenchCanada) {
        if (cProps.language !== 'FR-CA') {
            cProps.language = 'FR-CA';
            cProps.setSiteLanguage('FR-CA')
        }
    } else if (isFrenchFrance) {
        if (cProps.language !== 'FR-FR') {
            cProps.language = 'FR-FR';
            cProps.setSiteLanguage('FR-FR')
        }
    } else if (isDutch) {
        if (cProps.language !== 'NL') {
            cProps.language = 'NL';
            cProps.setSiteLanguage('NL')
        }
    } else if (isItalian) {
        if (cProps.language !== 'IT') {
            cProps.language = 'IT';
            cProps.setSiteLanguage('IT')
        }
    } else if (isCzech) {
        if (cProps.language !== 'CZ') {
            cProps.language = 'CZ';
            cProps.setSiteLanguage('CZ')
        }
    } else if (isFinnish) {
        if (cProps.language !== 'FI') {
            cProps.language = 'FI';
            cProps.setSiteLanguage('FI')
        }
    } else if (isPolish) {
        if (cProps.language !== 'PL') {
            cProps.language = 'PL';
            cProps.setSiteLanguage('PL')
        }
    } else if (isSwedish) {
        if (cProps.language !== 'SE') {
            cProps.language = 'SE';
            cProps.setSiteLanguage('SE')
        }
    }  else if (isNorwegian) {
        if (cProps.language !== 'NO') {
            cProps.language = 'NO';
            cProps.setSiteLanguage('NO')
        }
    } else if (isDanish) {
        if (cProps.language !== 'DK') {
            cProps.language = 'DK';
            cProps.setSiteLanguage('DK')
        }
    } else if (isSpanish) {
        if (cProps.language !== 'ES') {
            cProps.language = 'ES';
            cProps.setSiteLanguage('ES')
        }
    } else {
        if (cProps.language !== 'EN') {
            cProps.language = 'EN';
            cProps.setSiteLanguage('EN')
        }
    }
    withTracker(rest.path + '_' + cProps.language);
    return (
        <div>
            {/*<Helmet>*/}
            {/*    {isGerman ?*/}
            {/*        <link rel="canonical" href="https://camdiab.com/de"/> :*/}
            {/*        <link rel="canonical" href="https://camdiab.com"/>*/}
            {/*    }*/}
            {/*</Helmet>*/}

            <Route
                {...rest}
                render={props =>
                    !cProps.isAuthenticated
                        ? <C {...props} {...cProps} />
                        : <Redirect
                            to={redirect === "" || redirect === null ? "/" : redirect}
                        />}
            />
        </div>
    );
};