/**
 * Texts for FAQs page
 */
export const faqPageText = {
    title: 'FAQ - CamAPS FX',
    titleMeta: 'Vous avez une question sur CamAPS&nbsp;FX&nbsp;?',
    descriptionMeta: 'Voici les réponses à certaines de vos questions fréquemment posées sur les appareils compatibles, ' +
        'l’approbation réglementaire, la disponibilité et la recherche clinique',
    keywords:
        'camaps FAQ, camaps fx FAQ, ' +
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ' +
        '',
    localeMeta: 'fr_fr',

    haveQuestion: 'Vous avez une question sur CamAPS&nbsp;FX&nbsp;?',
    hereAnswers: 'Vous trouverez ici les réponses à la plupart des questions fréquemment posées.',
    whatIsCamAPS: 'Qu’est-ce que CamAPS&nbsp;FX&nbsp;?',
    whatIsCamAPSAnswer: 'CamAPS FX est un dispositif médical de gestion du diabète.',
    isApproved: 'L’utilisation de CamAPS FX est-elle autorisée&nbsp;?',
    isApprovedAnswer: 'L’application a été scrupuleusement testée en essais cliniques et son utilisation ' +
        'a été autorisée au Royaume-Uni et dans l’Union Européenne.',
    isAvailable: 'Est-ce que CamAPS FX est disponible près de chez moi&nbsp;?',
    isAvailableAnswer1: 'CamAPS FX est disponible dans les services de diabétologie sélectionnés et aussi pour ceux ' +
        'qui suivent une formation en ligne en l’autofinançant. Veuillez vérifier la disponibilité auprès de votre service de diabétologie ou allez sur',
    isAvailableAnswer2: 'Commander',
    isAvailableAnswer3: 'pour commander l’application.',
    cost: 'Combien coûte CamAPS&nbsp;FX&nbsp;?',
    costAnswer1: 'Pour les détails tarifaires, veuillez consulter la section',
    costAnswer2: 'du site.',
    compatibleCgm: 'Quels sont les systèmes de Mesure en Continu du Glucose (MCG) compatibles avec CamAPS&nbsp;FX&nbsp;?',
    compatibleCgmAnswer: 'CamAPS FX est compatible avec le',
    compatibleCgmAnswer1: 'et le',
    compatibleCgmAnswer2: '. Veuillez cliquer sur les liens pour afficher les smartphones et les systèmes d’exploitation compatibles.',
    whatPumps: 'Quelles sont les pompes à insuline compatibles avec CamAPS&nbsp;FX&nbsp;?',
    whatPumpsAnswer1: 'En France, CamAPS est disponible avec la pompe d\'Ypsomed.',
    whatPumpsAnswer2: '',
    whatPumpsAnswer3: '',
    whatPumpsAnswerHtml: 'https://advancedtherapeutics.org.uk/shop/dana-diabecare-insulin-pump/dana-i-insulin-pump ',
    whatPhone: 'Quels sont les smartphones compatibles avec CamAPS&nbsp;FX&nbsp;?',
    whatPhoneAnswer: 'CamAPS FX fonctionne sur un téléphone Android et est compatible avec les modèles de smartphones Android pris en charge par le fabricant de votre MCG.',

    whatPhoneAnswer1: 'Veuillez',
    whatPhoneAnswer2: 'cliquer',
    whatPhoneAnswer3: 'sur ce lien pour obtenir une liste des appareils et des systèmes d\'exploitation compatibles avec',
    whatPhoneAnswerDexcom: 'le capteur Dexcom G6 et l\'application Dexcom G6.',
    whatPhoneAnswerLibre3: 'le capteur FreeStyle Libre 3 et l\'application FreeStyle Libre 3.' +
        '<p><p></p>' +
        'À partir du 1er janvier 2025, CamAPS FX ne sera pris en charge que sur les téléphones compatibles avec les capteurs fonctionnant sous les systèmes d\'exploitation Android 10 et supérieurs. Les utilisateurs de systèmes d\'exploitation inférieurs à OS 10 ne pourront plus mettre à jour ou réinstaller CamAPS FX après cette date.',
    whatPhoneAnswerDexcomHtml:'https://www.dexcom.com/compatibility',
    whatPhoneAnswerLibreHtml:'https://www.diabetescare.abbott/support/manuals.html',

    whereUpload: 'Où puis-je uploader mes données à partir de CamAPS&nbsp;FX&nbsp;?',
    whereUploadAnswer: 'En France, les données de traitement de CamAPS FX peuvent être synchronisées avec la plateforme de télémédecine Gloko XT.',
    whatLanguage: 'Quelles sont les langues prises en charge par CamAPS&nbsp;FX&nbsp;?',
    whatLanguageAnswer: 'CamAPS  FX est disponible en anglais, tchèque, danois, allemand, espagnol, français, italien, néerlandais, polonais, finnois et suédois.',
    whereResults: 'Où puis-je trouver les résultats de vos études cliniques&nbsp;?',
    whereResultsAnswer1: 'Les publications concernant l’utilisation de l’algorithme de contrôle de Cambridge sont disponibles en téléchargement',
    whereResultsAnswer2: 'ici.',
    whereResultsAnswer3: 'Les articles clés ont été publiés dans',
    whereResultsAnswer4: 'et',

};
