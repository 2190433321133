/**
 * Texts for FAQs page
 */
export const faqPageText = {
    title: 'Często zadawane pytania — aplikacja CamAPS FX',
    titleMeta: 'Masz pytanie dotyczące aplikacji CamAPS FX?',
    descriptionMeta: 'Poniżej przedstawiono odpowiedzi na często zadawane pytania dotyczące kompatybilnych urządzeń, zatwierdzenia urzędu regulacyjnego, dostępności i badań klinicznych',
    keywords:
        'Często zadawane pytania dotyczące camaps, często zadawane pytania dotyczące camaps fx,',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'pl_pl',

    haveQuestion: 'Masz pytanie dotyczące aplikacji CamAPS FX?',
    hereAnswers: 'Poniżej przedstawiono odpowiedzi na niektóre z często zadawanych pytań.',
    whatIsCamAPS: 'Czym jest aplikacja CamAPS FX?',
    whatIsCamAPSAnswer: 'CamAPS FX to zaawansowana, łatwa w użytkowaniu aplikacja mobilna, która służy do kontrolowania stężenia glukozy we krwi u osób chorujących na cukrzycę typu 1 w wieku 1 roku lub starszych za pomocą zaawansowanego, adaptacyjnego rozwiązania wykorzystującego hybrydowy system pętli zamkniętej.',
    isApproved: 'Czy aplikacja CamAPS FX jest dopuszczona do stosowania?',
    isApprovedAnswer: 'Aplikacja została dokładnie przetestowana w badaniach klinicznych i dopuszczona do stosowania, m.in. w Wielkiej Brytanii i Unii Europejskiej.',
    isAvailable: 'Czy aplikacja CamAPS FX jest dostępna w moim regionie?',
    isAvailableAnswer1: 'Aplikacja CamAPS FX jest dostępna w wybranych klinikach diabetologicznych lub dla osób, które samodzielnie zakupią aplikację i odbędą szkolenie online. Aby zamówić aplikację, skontaktuj się ze swoją kliniką lub przejdź',
    isAvailableAnswer2: 'do sekcji Zamówienia',
    isAvailableAnswer3: '.',
    cost: 'Ile kosztuje aplikacja CamAPS FX?',
    costAnswer1: 'Aby zapoznać się z cennikiem, należy odwiedzić',
    costAnswer2: 'odpowiednią sekcję strony internetowej.',
    compatibleCgm: 'Jakie systemy CGM są kompatybilne z aplikacją CamAPS FX?',
    compatibleCgmAnswer: 'Aplikacja CamAPS FX jest kompatybilna z',
    compatibleCgmAnswer1: 'i',
    compatibleCgmAnswer2: '. Proszę kliknąć na linki, aby zobaczyć kompatybilne smartfony i systemy operacyjne.',
    whatPumps: 'Jakie pompy insulinowe są kompatybilne z aplikacją CamAPS FX?',
    whatPumpsAnswer1: 'Aplikacja CamAPS FX jest kompatybilna z',
    whatPumpsAnswer2: 'pompami insulinowymi mylife YpsoPump, Dana Diabecare RS i DANA-i',
    whatPumpsAnswer3: '.',
    whatPumpsAnswerHtml: 'https://advancedtherapeutics.org.uk/shop/dana-diabecare-insulin-pump/dana-rs-insulin-pump-2018',
    whatPhone: 'Jakie smartfony są kompatybilne z aplikacją CamAPS FX?',
    whatPhoneAnswer: 'CamAPS FX działa na telefonie z systemem Android i jest kompatybilny z modelami smartfonów z systemem Android obsługiwanymi przez producenta CGM.',

    whatPhoneAnswer1: '',
    whatPhoneAnswer2: 'Kliknij',
    whatPhoneAnswer3: 'to łącze, aby wyświetlić listę urządzeń i systemów operacyjnych zgodnych z',
    whatPhoneAnswerDexcom: 'czujnikiem Dexcom G6 i aplikacją Dexcom G6.',
    whatPhoneAnswerLibre3: 'czujnikiem FreeStyle Libre 3 i aplikacją FreeStyle Libre 3.' +
        '<p><p></p>' +
        'Od 1 stycznia 2025 roku, CamAPS FX będzie obsługiwany tylko na telefonach kompatybilnych z czujnikami z systemem Android 10 i nowszym. Użytkownicy systemów operacyjnych poniżej OS 10 nie będą mogli aktualizować ani ponownie instalować CamAPS FX po tej dacie.',
    whatPhoneAnswerDexcomHtml:'https://www.dexcom.com/compatibility',
    whatPhoneAnswerLibreHtml:'https://www.diabetescare.abbott/support/manuals.html',

    whereUpload: 'Gdzie mogę przesłać swoje dane z aplikacji CamAPS FX?',
    whereUploadAnswer: 'Obecnie dane z aplikacji CamAPS FX można przesłać do Glooko.',
    whatLanguage: 'Jakie języki są dostępne w aplikacji CamAPS FX?',
    whatLanguageAnswer: 'Aplikacja CamAPS FX jest dostępna w języku angielskim, czeskim, duńskim, niemieckim, hiszpańskim, francuskim, włoskim, holenderskim, polskim, fińskim i szwedzkim.',
    whereResults: 'Gdzie mogę znaleźć wyniki badań klinicznych?',
    whereResultsAnswer1: 'Publikacje związane z użytkowaniem algorytmu Cambridge do kontrolowania stężenia glukozy we krwi są dostępne do pobrania',
    whereResultsAnswer2: 'tutaj.',
    whereResultsAnswer3: 'Kluczowe opracowania zostały opublikowane w czasopismach branżowych',
    whereResultsAnswer4: 'oraz',
};
