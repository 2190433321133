/**
 * Texts for Support page
 */
export const supportPageText = {
    title: 'Support - CamAPS FX',
    titleMeta: 'We provide wide support for the use of CamAPS FX',
    descriptionMeta: 'We provide supporting material including user manuals, downloading guidelines, webinars and ' +
        'telephone and email customer support.',
    keywords:
        'camaps support, camaps fx support, ' +
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ' +
        '',
    localeMeta: 'en_gb',

    needHelp: 'Need some help?',
    needHelpResponse: 'We hope the support material provided here will help you clarify any issues you ' +
        'might have. If you cannot find an answer to your problem, please get in touch.',
    clinicalSupportHeader: 'Clinical Support',
    clinicalSupportText: 'If you have any questions about your healthcare, please contact your diabetes care team.',
    techSupportHeader: 'Technical and Educational Support',
    techSupportHeaderText: 'Here you can find support material and contact details if you need to get in touch ' +
        'with our support team.',
    techMaterialHeader: 'Technical materials:',
    userManual: 'CamAPS FX user manual (PDF reader required)',
    userManualEnglish_mmol_l: 'English',
    userManualCzech_mmol_l: 'Česky',
    userManualDanish_mmol_l: 'Dansk',
    userManualGerman_mg_dl: 'Deutsch',
    userManualGerman_mmol_l: 'Deutsch',
    userManualSpanish_mg_dl: 'Español',
    userManualFrench_mmol_l: 'Français',
    userManualFrench_mg_dl: 'Français',
    userManualItalian_mg_dl: 'Italiano',
    userManualItalian_mmol_l: 'Italiano',
    userManualDutch_mg_dl: 'Nederland',
    userManualDutch_mmol_l: 'Nederland',
    userManualNorsk_mmol_l: 'Norsk',
    userManualPolish_mg_dl: 'Polski',
    userManualFinish_mmol_l: 'Suomi',
    userManualSwedish_mmol_l: 'Svenska',

    previousManualVersions: 'Previous versions',
    installationManualHeader: 'Installing CamAPS FX (DANA)',
    installationManualEnglish: 'Installation guide',
    installationManualGerman: 'Installationsanleitung',
    installationManualFrench: 'Guide d\'installation',
    educationalMaterial: 'Educational material:',
    onlineTraining: 'Online training:',
    onlineTrainingText: 'Provided by the Cambridge Diabetes Education Programme (CDEP)',
    onlineTrainingLink: 'https://www.camdiabtraining.com/account-sign-in.html',
    onlineTrainingText_1: 'FREE certified online training',
    webinarsHeading: 'Webinars',
    webinar_1: 'Introducing the CamAPS FX system',
    webinar_2: 'Starting the CamAPS FX system',
    webinar_3: 'Optimizing the CamAPS FX settings',
    webinar_4: 'Training for teachers and education support staff',
    webinar_5: 'Fine-tuning to improve time in range',
    webinar_6: 'Using CamAPS FX during pregnancy',
    webinar_7: 'Using CamAPS FX in infants, toddlers, and young children',
    webinar_8: 'Using CamAPS FX in young adults and adults',
    webinar_9: 'Managing physical activity, meals and other life events',
    webinar_10: 'Closed-loop insulin therapy and quality of life',
    webinar_11: 'Closed-loop therapy & long-standing type 1 diabetes',
    webinar_footnote: '',

    contactDetailsHeader: 'Contact details:',
    contactDetailsText: 'Our support team is available during business hours of Monday–Friday, 9am–5pm.',
    contactDetailsEmailTitle: 'Mail Us',
    contactDetailsEmail: 'support@camdiab.com',
    contactDetailsEmailFull: 'mailto:support@camdiab.com',
    contactDetailsPhoneTitle: 'Contact Us',
    contactDetailsPhone: '020 3695 3780',
    contactDetailsPhoneFull: 'tel:+442036953780',

    eIfuAccess: 'Printed copies of the user manuals may be requested from Customer Support. ' +
        'For best results, use Adobe<sup>®</sup> Reader<sup>®</sup> to view CamDiab user manuals.',

    statusPageText: 'Status page',
    statusPageLink: 'https://camdiabltd.statuspage.io/',

    installationYpsoPumpHeader: 'Installing CamAPS FX',
    installationYpsoPumpText: 'Google Play',
    installationYpsoPumpLink: 'https://play.google.com/store/search?q=camaps%20fx&c=apps',

    danaPumpSupportText: 'Dana pump users',
    danaPumpSupportTextUK: 'Dana pump users',
    danaPumpSupportLinkUK: 'https://advancedtherapeutics.org.uk/contact-us/',
    ypsoPumpSupportText: 'YpsoPump users',
    ypsoPumpSupportLink: 'https://www.mylife-diabetescare.com/en/services/customer-care-contact.html',
};
