import React, {Component} from 'react';
import {Header} from '../Components/Header';
import {Footer} from '../Components/Footer';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

export class TermsENCA extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Terms and conditions - CamAPS FX</title>
                    <meta name="title" content='Terms and conditions'/>
                    <meta name="description"
                          content='Here you can find CamDiab terms and conditions'/>
                    <meta property="og:locale" content='en'/>
                    <meta name="keywords" content='terms and conditions'/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10">
                                <h1>Terms and conditions</h1>
                                <h3>Version dated June 24, 2024</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <h2>
                                    1. SCOPE
                                </h2>
                                <h3>
                                    1.1. Our products
                                </h3>
                                <p>
                                    1.1.1. We
                                </p>
                                <p>
                                    CamDiab Ltd
                                    <strong>
                                        <br/>
                                        Registered office address<br/>
                                        20-22 Wenlock Road<br/>
                                        London, N1 7GU, UK
                                        <br/>
                                    </strong>
                                    support@camdiab.com
                                    <br/>
                                    company number 11659211 England and Wales
                                    <br/>
                                    <br/>
                                </p>
                                <p>
                                    (“<strong>CamDiab</strong>”), develop and operate mobile application(s)
                                    (apps) for closed-loop insulin delivery and linked data infrastructure for
                                    persons with diabetes and health care professionals. CamDiab is a digital
                                    health company and wants to offer persons with diabetes improved glucose
                                    control and reduce the burden of diabetes self-care.
                                </p>
                                <p>
                                    1.1.2 Our Products guide and support closed-loop insulin delivery to the
                                    very best of our knowledge. Our Products and services cannot replace
                                    consultation with and diagnosis by a healthcare professional. For questions
                                    on the illness and therapy, we recommend contacting a healthcare
                                    professional. The data provided must only be analysed by you and your
                                    healthcare professional. No content – no matter whether provided by CamDiab
                                    itself, our collaborators, distributors, contractors, or users – can be
                                    understood as supplementing or replacing information from a healthcare
                                    professional or pharmacist.
                                </p>
                                <p>
                                    1.1.3 CamDiab may offer a range of Products (apps) and services as well as
                                    allowing data sharing to support managing your diabetes. All current and
                                    future apps and services (including new versions, updates, and other
                                    improvements) that CamDiab provides in any way, are subsequently referred
                                    to jointly as “<strong>Products</strong>” or individually as a
                                    “ <strong>Product</strong>”. Each person who acquires and uses one or more of
                                    our Products is referred to jointly as “<strong>Users</strong>” or
                                    individually as “<strong>User</strong>”. If these T&amp;Cs refer to natural
                                    persons using the male form, they apply equally to all people.
                                </p>
                                <p>
                                    1.1.4 In connection with these T&amp;Cs, the following term definitions
                                    apply to individual Products:
                                </p>
                                <p>
                                    <strong>CamAPS FX </strong>
                                    is an independent mobile application (app) for your smartphone
                                    offering hybrid closed-loop insulin delivery for people with type 1
                                    diabetes. The app links to a third party glucose sensor and a third party
                                    insulin pump. You may decide to share data collected by the app with
                                    CamDiab or third party diabetes portal(s) for data viewing. Restrictions
                                    may apply which smartphone model may be used based on third party
                                    pump and glucose sensor.
                                </p>
                                <p>
                                    <strong>CamAPS HX </strong>
                                    is a similar app to CamAPS FX but offering fully closed-loop in non type 1 diabetes.
                                </p>
                                <p>
                                    1.1.5 Our user manual(s) (accessible via our apps or via our website) and
                                    the relevant special provisions emphasize any peculiarities for the
                                    individual Products.
                                </p>
                                <h3>
                                    1.2. Scope of our T&amp;Cs
                                </h3>
                                <p>
                                    1.2.1 We exclusively offer our Products on the basis of the current version
                                    of these General Terms and Conditions (T&amp;Cs), this applies globally.
                                    CamDiab explicitly opposes contradictory business or other usage terms.
                                </p>
                                <p>
                                    1.2.2 Please note that before using our Products for the first time you
                                    must consent to all of the declarations and agreements referred to and
                                    accept them during any further usage. The registration and use of our
                                    Products is prohibited without your consent to our T&amp;Cs.
                                </p>
                                <p>
                                    1.2.3 We explicitly reserve the right to make future changes and factually
                                    justified modifications to our T&amp;Cs. Changes may be necessary to meet
                                    statutory requirements, correspond to technical and economic requirements,
                                    or meet the interests of our Users. Such changes are possible at any time
                                    and will be published in an appropriate manner. If they are not opposed
                                    within a month, the ongoing use of our Products is subject to the relevant
                                    new T&amp;Cs.
                                </p>
                                <h3>
                                    1.3. Third-party suppliers
                                </h3>
                                <p>
                                    1.3.1 You may find our Products in the app store(s). In general, the
                                    business terms of the relevant third-party supplier (over which we have no
                                    influence) apply there; these are not part of our T&amp;Cs nor our
                                    contractual relationship with you as the User of our Products. This also
                                    applies to the business terms and conditions of your mobile device or
                                    service supplier.
                                </p>
                                <p>
                                    1.3.2 We work with various partners to ensure the optimal use of our
                                    Products. These include cooperation partners that supply our Users directly
                                    with certain Products (e.g. insulin pump, continuous glucose monitor). In
                                    these cases, we emphasize the applicable business terms of a third-party
                                    supplier as you must accept such business terms before you can start using
                                    our Products.
                                </p>
                                <h2>
                                    2. DATA PROTECTION
                                </h2>
                                <p>
                                    <strong>
                                        We are aware of the major responsibility that comes with your use of
                                        our Products. Not only do you provide us with general personal data but
                                        also with data on your health. In our
                                    </strong>
                                    <Link to="/en-ca/privacy">
                                        Privacy policy </Link>
                                    <strong>
                                        we inform you about the use and protection of your data in connection
                                        with using our Products and on your consent required for this.
                                    </strong>
                                </p>
                                <p>
                                    <strong>
                                        WITHOUT YOUR PRIVACY CONSENT FOR THE NECESSARY DATA PROCESSING, USE OF
                                        THE PRODUCTS IS NOT POSSIBLE IN LEGAL OR ACTUAL TERMS.
                                    </strong>
                                </p>
                                <p>
                                    <strong>Our </strong>
                                    <Link to="/en-ca/privacy"> Privacy policy </Link>
                                    <strong>
                                        is not part of these T&amp;Cs but rather is only used to provide you
                                        with information under the GDPR on the basis of which you issue your
                                        consent for the necessary data processing.
                                    </strong>
                                </p>
                                <h2>
                                    3. CONCLUDING THE CONTRACT AND REGISTRATION
                                </h2>
                                <h3>
                                    3.1. Purchase of and payment for our products
                                </h3>
                                <p>
                                    3.1.1 Our products can be purchased by various means:
                                </p>
                                <h3>
                                    Apps
                                </h3>
                                <p>
                                    <strong>App stores</strong>
                                    (such as Google Play and Amazon Appstore) offer our products. Please note that to download
                                    (not use) our products, the separate business terms of the app store apply
                                    and these may require a user account.
                                </p>
                                <p>
                                    <strong>Our website</strong>
                                    may provide direct access to our products and allows our products to be
                                    ordered.
                                </p>
                                <p>
                                    <strong>Voucher codes</strong>
                                    for our products may be in circulation and can be redeemed on our website.
                                </p>
                                <p>
                                    3.1.2 You can order our chargeable Products on our website or through our distributors. They are paid
                                    for via payment service provider, our distributors or other stipulated
                                    payment options. The distributor acts either as an agent and payment
                                    service provider for CamDiab or as a direct seller. If you experience
                                    problems with downloading or payment, please contact <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a> any time.
                                </p>
                                <p>
                                    3.1.3 Usage fees or purchase prices (including statutory value-added tax)
                                    must be paid – depending on the offer and selection – once or repeatedly
                                    (e.g. on a monthly, 6-monthly, or annual basis). The current fees are
                                    provided on our website, by distributor or displayed in our app or the app
                                    stores and are due for payment in advance. If payment is not processed
                                    directly via the app store, payment provider or distributor can send you
                                    invoices and payment reminders by email. In the event of culpable, late, or
                                    incomplete payment, we are entitled to block your access to our Products.
                                    Your usage fee payment obligation remains unaffected by this.
                                </p>
                                <p>
                                    3.1.4 CamDiab can also make special and free offers that are subject to
                                    additional conditions or restrictions. In this regard we explicitly refer
                                    to any variances from these T&amp;Cs while otherwise the full content of
                                    these T&amp;Cs remain applicable. Special or free offers cannot be
                                    transferred to other Users.
                                </p>
                                <h3>
                                    3.2. Registration and activation
                                </h3>
                                <p>
                                    3.2.1 The use of our Products requires your registration with CamDiab in
                                    our apps. As soon as you have activated our Product for the first time, you
                                    must register using an email address and password (CamDiab ID). Each User
                                    may only create one CamDiab ID and each CamDiab ID can only be used by one
                                    User.
                                </p>
                                <p>
                                    3.2.2 By registering, the User agrees to his binding offer to conclude a
                                    contract with CamDiab for the use of our Products on the basis of the
                                    applicable version of these T&amp;Cs. We explicitly accept such offer, at
                                    the latest by activating your CamDiab ID.
                                </p>
                                <p>
                                    3.2.3 The activation is confirmed once your login details are accepted and
                                    you have undergone appropriate training. We reserve the right to reject
                                    individual Users or their offer to conclude a contract without stating a
                                    reason. In such a case any payments already made will be reimbursed and
                                    User data provided deleted immediately.
                                </p>
                                <p>
                                    3.2.4 Upon registration, the User confirms that he is at least sixteen
                                    years old and that applicable law permits him to conclude contracts. Only
                                    people with legal capacity are permitted to register. Minors are only
                                    permitted with the explicit agreement of a parent/guardian. With the
                                    registration, the parent/guardian declares they are entitled to submit
                                    legally binding declarations in the name of the minor.
                                </p>
                                <p>
                                    3.2.5 On registration, the User confirms their acknowledgement and
                                    unrestricted acceptance of the contents of these T&amp;Cs and that their
                                    information is true, correct, up-to-date, and complete. We reserve the
                                    right to contact Users at any time to verify registration data and usage
                                    information.
                                </p>
                                <p>
                                    3.2.6 If the User provides false, incorrect, out-of-date, or incomplete
                                    information or we have just cause for believing that information is false,
                                    incorrect, out-of-date, or incomplete, CamDiab is entitled to block the
                                    relevant CamDiab ID with immediate effect without prior notice and prohibit
                                    the use of our Products without being obliged to repay the User for the
                                    costs incurred.
                                </p>
                                <p>
                                    3.2.7 The User must protect their registration data from unauthorised
                                    access by third parties, abuse, or use with fraudulent intent. If there is
                                    even a suspicion that the CamDiab ID has been exposed to such a risk, this
                                    must be notified without delay to <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. We have the
                                    right to block the CamDiab ID of any User if it is used for wrongful or
                                    fraudulent intentions.
                                </p>
                                <h2>
                                    4. RIGHT OF WITHDRAWAL AND REVOCATION
                                </h2>
                                <h3>
                                    4.1. Information on exercising revocation right
                                </h3>
                                <p>
                                    4.1.1 If you do not use our Products either for commercial or for
                                    professional purposes, as a consumer you are entitled to revoke the
                                    contract concluded with CamDiab under the following conditions:
                                </p>
                                <p>
                                    <strong>REVOCATION RIGHT</strong>
                                </p>
                                <p>
                                    <strong><u>Revocation right</u></strong>
                                </p>
                                <p>
                                    <strong>
                                        You have the right to revoke this contract within fourteen days without
                                        stating a reason.
                                    </strong>
                                </p>
                                <p>
                                    <strong>
                                        The revocation period is fourteen days from the date the contract was
                                        concluded (digital content activation).
                                    </strong>
                                </p>
                                <p>
                                    <strong>
                                        To exercise your revocation right, you must inform us of your decision
                                        to revoke the contract using a clear declaration to distributor or
                                        CamDiab, email
                                    </strong>
                                    <strong>
                                        <a href="mailto:support@camdiab.com">support@camdiab.com</a>
                                    </strong>
                                    <strong> (e.g. by email or post). </strong>
                                </p>
                                <p>
                                    <strong>
                                        To maintain the revocation period, it is sufficient for you to send the
                                        notification of exercising the revocation right before the end of the
                                        revocation period.
                                    </strong>
                                </p>
                                <p>
                                    <strong><u>Consequences of revocation</u></strong>
                                </p>
                                <p>
                                    <strong>
                                        If you revoke this contract, distributor or we must repay all payments
                                        that we have received from you, without delay and at the latest within
                                        fourteen days of the date when the notification of your revocation of
                                        this contract was received by us. Where possible, we use the same
                                        payment method for this repayment as you used for the original
                                        transaction unless we have explicitly agreed something different; under
                                        no circumstances are charges incurred due to this repayment.
                                    </strong>
                                </p>
                                <h2>
                                    5. USE OF OUR PRODUCTS
                                </h2>
                                <h3>
                                    5.1. Usage term and termination
                                </h3>
                                <p>
                                    5.1.1 Your right to use our Products starts with registration and
                                    activation by CamDiab.
                                </p>
                                <p>
                                    5.1.2 Our chargeable Products can be purchased in the form of monthly,
                                    6-monthly, or annual subscriptions, depending on the current offer they are
                                    also available for a shorter or longer usage period (hereinafter “ <strong>subscription
                                    period</strong>”).
                                </p>
                                <p>
                                    5.1.3 Both the User and CamDiab are entitled to terminate the contract
                                    ordinarily at any time by giving notice of one month with effect at the end
                                    of the month. You can either do this by contacting your distributor or by
                                    written notification to us at <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Please note
                                    that fees already paid for an unused subscription period are only
                                    reimbursed in the event of ordinary termination by CamDiab or automatic
                                    ending (section 5.1.5 below).
                                </p>
                                <p>
                                    5.1.4 If there is no termination or termination is late, the subscription
                                    period is extended automatically by another period corresponding to the
                                    previously selected subscription period. The relevant standard price at the
                                    time of the extension (not the prices of any special or free offers)
                                    applies.
                                </p>
                                <p>
                                    5.1.5 In the event of the User's death or the revocation of the privacy
                                    consent, the usage relationship ends automatically with immediate effect.
                                    CamDiab is not permitted to process your health data without your consent.
                                </p>
                                <p>
                                    5.1.6 If you have questions on your usage term or its ending, please
                                    contact <a href="mailto:support@camdiab.com">support@camdiab.com</a> at any
                                    time.
                                </p>
                                <h3>
                                    5.2. Lock out and exclusion
                                </h3>
                                <p>
                                    5.2.1 We reserve the right to temporarily lock out Users at any time for
                                    any important reason (at our discretion) or to permanently exclude them
                                    from using our Products. In the event of such a contract ending without a
                                    notice period, the reimbursement of usage fees is excluded.
                                </p>
                                <p>
                                    5.2.2 An important reason occurs, in particular, for serious infringements
                                    by a User against the provisions of these T&amp;Cs, e.g. infringements of
                                    section 3.1 (Payment) after setting a two-week grace period, section 3.2
                                    (Registration data), section 5.3 (License) or section 6 (“Your behaviour”).
                                </p>
                                <h3>
                                    5.3. License and the granting of rights
                                </h3>
                                <p>
                                    5.3.1 With your registration and activation, you acquire the non-exclusive,
                                    non-transferable but geographically unrestricted right to save and use a
                                    copy of our app for your own purposes within the framework of these
                                    T&amp;Cs. For chargeable Products, this license is restricted to the
                                    duration of your contractual subscription period.
                                </p>
                                <p>
                                    5.3.2 The software, code, methods, and systems as well as the content of
                                    our Products are protected by copyright and competition law and may
                                    exclusively be used by CamDiab. Our Products or parts thereof may not be
                                    copied, modified, reproduced, republished, posted, transferred, sold,
                                    offered for sale, resold, or used in any other way without our prior,
                                    written consent. Users are not permitted to use brands, logos, other
                                    commercial property rights or trademark rights of CamDiab. Unless otherwise
                                    provided by these T&amp;Cs, all of the usage and exploitation rights are
                                    owned exclusively by CamDiab and there is no licensing of any kind for our
                                    Products.
                                </p>
                                <p>
                                    5.3.3 If this is required for the technical provision of our Products, each
                                    User grants CamDiab a non-exclusive, revocable but free, transferable
                                    exploitation and usage right, unrestricted in terms of time and location,
                                    to the content that they generate, transfer, store, or publish within our
                                    Products. Usage or exploitation is however excluded if this
                                    disproportionally adversely affects the legitimate interests of the User
                                    (e.g. privacy rights). In the event of usage outside our Products, if
                                    appropriate, we will indicate that the content comes from the User. CamDiab
                                    does not claim any ownership of created content and will not assume any
                                    supervisory function with regard to content created by Users.
                                </p>
                                <h3>
                                    5.4. Availability of our Products
                                </h3>
                                <p>
                                    5.4.1 We provide our Products in each case in accordance with the existing
                                    technical, economic, operational, and organizational possibilities. CamDiab
                                    cannot exclude any interruptions, disturbances, delays, deletions,
                                    incorrect transmissions, or storage failures in connection with using our
                                    Products or communication with Users. We partly offer our Products in
                                    cooperation with third-party distributor(s) and suppliers and are therefore
                                    also dependent on the technical provision of third-party services.
                                    Therefore CamDiab accepts no responsibility, guarantee, liability, or
                                    obligation to provide our Products online at all times without
                                    interruptions.
                                </p>
                                <p>
                                    5.4.2 This also applies to restrictions in using our Products due to force
                                    majeure, strikes, lockouts, and official instructions or due to technical
                                    modifications or maintenance work on the CamDiab systems. In the event of
                                    disruptions or failures of our Products, please contact <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a> any time.
                                </p>
                                <h2>
                                    6. YOUR BEHAVIOR
                                </h2>
                                <h3>
                                    6.1. General information
                                </h3>
                                <p>
                                    6.1.1 Each User is entitled and obliged to use our Products at their own
                                    risk and expense whilst deploying suitable technical equipment. The devices
                                    and operating systems that are compatible with the Products can be
                                    requested at any time from <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <p>
                                    6.1.2 You are also obliged to use our Products exclusively in compliance
                                    with these T&amp;Cs and the statutory provisions as well as avoiding all
                                    wrongful use. Please inform us immediately if you discover any breaches of
                                    the duties stated in this section at <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    6.2. Abuse
                                </h3>
                                <p>
                                    6.2.1 Each User is obliged to state their registration data truthfully, to
                                    keep it up-to-date and complete as well as not to pass it on to any third
                                    party. Personal data must be treated confidentially, managed carefully and
                                    backed up under one’s own responsibility. CamDiab accepts no liability or
                                    other guarantees for lost or damaged data or content if this is not our
                                    fault.
                                </p>
                                <p>
                                    6.2.2 Content such as photos, images, texts, videos, or other depictions
                                    may only be stored, published, transferred, or distributed in connection
                                    with our Products if the User has the right to transfer or use them. In all
                                    cases the use of racist, offensive, discriminatory, defamatory, sexual,
                                    pornographic, violent, or other illegal content of all kinds is always
                                    prohibited.
                                </p>
                                <p>
                                    6.2.3 Furthermore it is prohibited to decrypt, reverse engineer, decompile,
                                    or disassemble our Products or use our Products on rooted smartphones. Each
                                    User is obliged not to undertake any disruptive interferences by technical
                                    or electronic means in our Products (in particular hacking attempts, brute
                                    force attacks, introducing viruses, worms, trojan horses, other malicious
                                    software) nor any type of attempted disturbance that might affect the
                                    software or hardware of the Products and systems of CamDiab. Any tampering
                                    with our Products may result in our Products not performing according to
                                    intended use.
                                </p>
                                <h2>
                                    7. OUR RESPONSIBILITY
                                </h2>
                                <h3>
                                    7.1. Guarantee and liability
                                </h3>
                                <p>
                                    7.1.1 Unless otherwise provided by these T&amp;Cs, CamDiab is liable and
                                    provides guarantees as laid down by the statutory provisions.
                                </p>
                                <p>
                                    7.1.2 With regard to Users who are not consumers, liability for financial
                                    losses, consequential damages, lost profit, and damages resulting from
                                    third-party claims is excluded. If our liability is limited or excluded in
                                    these T&amp;Cs, this applies equally to vicarious agents and affiliated
                                    companies of CamDiab.
                                </p>
                                <p>
                                    7.1.3 Outside the application scope of product liability legislation, the
                                    liability of CamDiab is limited to deliberate acts and gross negligence –
                                    with the exception of personal injury. The liability for slight negligence
                                    is excluded when faced with consequential objective justifications.
                                </p>
                                <p>
                                    7.1.4 CamDiab provides no guarantee for our Products being completely
                                    available without interruption and without errors or that the required
                                    software and hardware operates without errors. We can also not exclude the
                                    possibility that data could be tracked, recorded, or falsified by third
                                    parties during data transfer via third-party systems, in particular the
                                    Internet and other telecommunication networks.
                                </p>
                                <p>
                                    7.1.5 CamDiab accepts no guarantee for downloaded content or material that
                                    Users have received as a result of using our Products. The User is solely
                                    liable for all damage that could be created in their IT system or devices
                                    or for the loss of data as a result of downloading materials associated
                                    with our Products.
                                </p>
                                <p>
                                    7.1.6 Furthermore CamDiab is not liable for third-party content such as
                                    external links, banners, other information, or advertising offers from
                                    third parties that can be placed as part of our Products. If we enable
                                    access to the offers of third parties via notifications or links, CamDiab
                                    is not responsible for the information contained therein. Resulting legal
                                    transactions with third parties lead exclusively to contractual
                                    relationships between the User and the relevant third party. We accept no
                                    guarantee or other liability for the services of third parties.
                                </p>
                                <h3>
                                    7.2. Disclaimer and indemnification
                                </h3>
                                <p>
                                    7.2.1 The use of our Products does not replace consultation with a
                                    healthcare professional or other medical advice and is undertaken
                                    exclusively at the User's own risk. This applies to any use of the data
                                    provided as part of our Products by the User including blood and sensor
                                    glucose results, calculations, and any recommendations. The User explicitly
                                    acknowledges that such data may be defective and CamDiab accepts no
                                    responsibility for it being correct.
                                </p>
                                <p>
                                    7.2.2 To the extent required by applicable medical devices law governing
                                    the use of the Products, the Products may only be operated or used
                                    exclusively as per the purpose, specifications, and application areas as
                                    laid down in the offer and usage terms.
                                </p>
                                <p>
                                    7.2.3 The User will indemnify CamDiab against all third party claims
                                    arising against CamDiab as a result of the wrongful infringement of its
                                    rights by the User with regard to use of our Products. All other claims for
                                    damages by CamDiab against the User remain unaffected.
                                </p>
                                <p>
                                    7.2.4 The User accepts full liability for all damage and disputes in and
                                    out of court arising from conflicts with other Users. The User explicitly
                                    acknowledges that CamDiab is under no circumstances responsible for actions
                                    or omissions by other Users and any resulting damage.
                                </p>
                                <p>
                                    7.2.5 In the event of claims being raised against CamDiab by third parties,
                                    the User is obliged to immediately provide all of the information available
                                    to them truthfully and in full as is required to review, defend, and
                                    prevent claims by third parties. The User bears the costs of any necessary
                                    legal defense by CamDiab, including all court and legal costs in the
                                    statutory amount.
                                </p>
                                <h2>
                                    8. FINAL PROVISIONS
                                </h2>
                                <h3>
                                    8.1. Choice of law
                                </h3>
                                <p>
                                    These T&amp;Cs and the entire legal relationship between CamDiab and the
                                    User is subject exclusively to English law, with the exception of its
                                    reference provisions and the UN Sales Convention. However as a consumer the
                                    User enjoys the protection of mandatory provisions in the country in which
                                    they are located or habitually resident.
                                </p>
                                <h3>
                                    8.2. Place of jurisdiction
                                </h3>
                                <p>
                                    The place of fulfilment and sole court of jurisdiction for all disputes
                                    arising from these T&amp;Cs is agreed as the head office of CamDiab in
                                    Cambridge, UK. Mandatory conflicts of laws in favour of consumers remain
                                    unaffected.
                                </p>
                                <h3>
                                    8.3. Other
                                </h3>
                                <p>
                                    8.3.1 If notification or the written form is required by these T&amp;Cs
                                    (including for legally important declarations), transmission by email to
                                    the address stated on registration (CamDiab ID) is sufficient.
                                </p>
                                <p>
                                    8.3.2 Users are not entitled to transfer their CamDiab ID or rights and
                                    obligations from these T&amp;Cs in connection with the use of our Products
                                    to third parties. CamDiab can transfer their rights and obligations from
                                    these T&amp;Cs to another company or third party if such a transfer has no
                                    significant effects on the rights of the User from these T&amp;Cs.
                                </p>
                                <p>
                                    8.3.3 If individual provisions of these T&amp;Cs are or become ineffective,
                                    the other provisions of these T&amp;Cs not affected by the invalidity
                                    remain valid and applicable.
                                </p>
                                <p>
                                    8.3.4 These T&amp;Cs are provided in various languages. The version in the
                                    language of the country in which the consumer is located or habitually
                                    resident is key and definitive. If and to the extent to which there is no
                                    country-specific language version available, the English version is
                                    definitive.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

