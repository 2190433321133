import React, {Component} from 'react';
import {Header} from './Header';
import {Footer} from './Footer';
import {Helmet} from "react-helmet";

export class PrivacyES extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Política de privacidad - CamAPS FX</title>
                    <meta name="title" content='política de privacidad'/>
                    <meta name="description"
                          content='Aquí puede encontrar la política de privacidad de CamDiab'/>
                    <meta property="og:locale" content='es_es'/>
                    <meta name="keywords" content='política de privacidad'/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10">
                                <h1>Política de privacidad</h1>
                                <h3>Versión con fecha del 4 de diciembre de 2024</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <h2>
                                    1. INTRODUCCIÓN
                                </h2>
                                <h3>
                                    1.1. Entidad responsable
                                </h3>
                                <p>
                                    CamDiab Limited, domicilio social registrado 20-22 Wenlock Road, London, N1 7GU,
                                    Reino Unido, constituida y registrada en Inglaterra y Gales con el número de empresa
                                    11659211 ("CamDiab"), es la entidad responsable declarada en virtud de la normativa
                                    de protección de datos. CamDiab decide la finalidad y los medios de tratamiento de
                                    los datos personales de nuestros usuarios ("<strong>Datos de usuario</strong>") y,
                                    por tanto, es responsable de su seguridad y del cumplimiento de la legislación
                                    aplicable.
                                </p>
                                <p>
                                    1.1.2 Como entidad responsable estamos sujetos, por ejemplo, a requisitos de
                                    información que deseamos cumplir en relación con este aviso de privacidad.
                                </p>
                                <h3>
                                    1.2. Estructura y concepto de consentimiento
                                </h3>
                                <p>
                                    1.2.1 Este aviso de privacidad le informa sobre los fines y el alcance del
                                    tratamiento de sus datos de usuario, así como de las transferencias de datos, y de
                                    sus amplios derechos. Dado que nuestra oferta está dirigida exclusivamente a
                                    personas con diabetes, su uso suele proporcionar ya información sobre su estado de
                                    salud. Por lo tanto, solo tratamos los datos de usuario como datos de salud con su
                                    consentimiento. Distinguimos lo siguiente:
                                </p>
                                <p>
                                    1.2.1.1 "Tratamiento necesario" describe cómo tratamos los datos de usuario
                                    requeridos para cumplir el contrato. Sin este consentimiento, no se pueden usar
                                    nuestros productos desde un punto de vista legal y factual, puesto que nuestros
                                    servicios dependen de este tratamiento.
                                </p>
                                <p>
                                    1.2.1.2 "Tratamiento para la mejora del producto" explica cómo puede ayudarnos con
                                    su consentimiento a nosotros y a otros usuarios, permitiéndonos utilizar sus datos,
                                    en particular para mejorar los algoritmos destinados a gestionar el tratamiento,
                                    enriquecer el producto, etc., sin dirigirnos a usted.
                                </p>
                                <p>
                                    1.2.1.3 "Tratamiento con fines de marketing" describe cómo nos ponemos en contacto
                                    con usted con fines de marketing, con su consentimiento, p. ej., por correo
                                    electrónico, notificaciones, etc.
                                </p>
                                <p>
                                    1.2.1.4 Para evitar repeticiones, En "Información general" hemos reunido la
                                    información aplicable a todos los consentimientos anteriores.
                                </p>
                                <p>
                                    Las categorías correspondientes se describen con más detalle a continuación. Al
                                    registrarse o encargar su producto, debe proporcionar el consentimiento
                                    correspondiente.
                                </p>
                                <p>
                                    1.2.2 En determinados casos, el tratamiento puede tener lugar independientemente del
                                    consentimiento, con arreglo a principios jurídicos (p. ej., normativa sobre
                                    productos sanitarios).
                                </p>
                                <h2>
                                    2. TRATAMIENTO NECESARIO
                                </h2>
                                <p>
                                    Si da su consentimiento, tratamos los datos de usuario que se indican a continuación
                                    para poder prestar nuestros servicios. Si no consiente este tratamiento necesario,
                                    no podrá utilizar los servicios de CamDiab. Puede dar su consentimiento durante el
                                    proceso de registro o al encargar su producto.
                                </p>
                                <h3>
                                    2.1. Datos de usuario necesarios
                                </h3>
                                <p>
                                    2.1.1 Para proteger sus datos de usuario, nuestros servicios solo se pueden utilizar
                                    en relación con una cuenta de usuario. Para crear una cuenta de usuario necesitamos
                                    y procesamos los siguientes datos de usuario:
                                </p>
                                <ul type="disc">
                                    <li>
                                        Dirección de correo electrónico
                                    </li>
                                    <li>
                                        Contraseña (solo guardamos una suma de comprobación)
                                    </li>
                                    <li>
                                        Nombre
                                    </li>
                                    <li>
                                        Declaración de exención del IVA (nombre, dirección, fecha; para autofinanciados)
                                    </li>
                                    <li>
                                        Año de nacimiento
                                    </li>
                                    <li>
                                        Fecha de registro
                                    </li>
                                    <li>
                                        Estado del consentimiento
                                    </li>
                                    <li>
                                        ID del dispositivo, fabricante, tipo de dispositivo, versión del sistema
                                        operativo, versión de la aplicación
                                    </li>
                                    <li>
                                        Idioma, país, zona horaria.
                                    </li>
                                </ul>
                                <p>
                                    2.1.2 Otros datos recogidos aparte del registro de usuario incluyen:
                                </p>
                                <p>
                                    <strong>Datos maestros médicos </strong>
                                    <br/>
                                    Peso, intervalo de valores objetivo de la glucemia, ajustes basales de la bomba,
                                    factores de corrección, relación hidratos de carbono / insulina, duración de la
                                    acción de la insulina, glucosa objetivo, ajustes de alerta, paso de bolo, unidad de
                                    hidratos de carbono, estado de embarazo, fecha estimada de parto.
                                </p>
                                <p>
                                    <strong>Datos de uso y vinculación</strong>
                                    <br/>
                                    Número de serie de la bomba y del transmisor, seguimiento de las acciones de la
                                    bomba, del sensor y del Loop cerrado (automático)/abierto, seguimiento del flujo de
                                    órdenes de la aplicación, seguimiento de incidencias, información de los seguidores
                                    introducida en la app (número de teléfono, credenciales de las cuentas del portal de
                                    datos de diabetes), consultas de asistencia técnica, datos del formador,
                                    identificación de la clínica de diabetes.
                                </p>
                                <p>
                                    <strong>Datos médicos</strong>
                                    <br/>
                                    Entradas de la aplicación como fecha/hora/zona horaria/lugar, ingesta de
                                    alimentos/comida, mediciones de glucosa en sangre y del sensor, velocidad de cambio
                                    de la glucosa del sensor, dosis total de insulina diaria, administración de insulina
                                    (bolo y basal), tasa basal temporal, estado del Loop cerrado (automático), cebado de
                                    la bomba y de la cánula, alertas, incidencias de refuerzo y reducción, ajustes de la
                                    aplicación como opciones de visualización, resúmenes estadísticos de los datos de
                                    glucosa e insulina.
                                </p>
                                <p>
                                    2.1.3 La aplicación puede requerir acceso a tu ubicación únicamente para habilitar
                                    la conectividad Bluetooth Low Energy (BLE), según lo exigen los permisos del sistema
                                    operativo. Sin embargo, la aplicación no recopila, almacena ni comparte datos de
                                    ubicación.
                                </p>
                                <p>
                                    2.1.4 Si lo desea, puede introducir cualquier dirección de correo electrónico que
                                    haya configurado especialmente para nosotros. Eso sí: debe funcionar, para que
                                    podamos enviarle advertencias importantes.
                                </p>
                                Solo tratamos los datos de usuario que usted proporciona asidua y voluntariamente a
                                CamDiab o que se obtienen del sensor de glucosa y de la bomba de insulina a los que se
                                conecta motu proprio. No obstante, la introducción de los datos de usuario necesarios es
                                precisa para el uso integral de nuestros productos.
                                <h3>
                                    2.2. Finalidades necesarias
                                </h3>
                                <p>
                                    2.2.1 Todas las finalidades necesarias de nuestro tratamiento están asociadas a la
                                    prestación de nuestros servicios:
                                </p>
                                <p>
                                    La <strong>consulta del cliente </strong> generará registros de datos de contacto.
                                </p>
                                <p>
                                    El <strong>pedido </strong> de nuestras aplicaciones por parte de los
                                    autofinanciadores generará registros de datos de contacto y de datos técnicos, como
                                    el ID del dispositivo.
                                </p>
                                <p>
                                    La <strong>instalación </strong> de nuestras aplicaciones generará registros de
                                    datos técnicos y de datos relacionados con el dispositivo.
                                </p>
                                <p>
                                    El <strong>registro </strong> conllevará la creación de nuestra cuenta CamDiab
                                    utilizando la dirección de correo electrónico y la contraseña.
                                </p>
                                <p>
                                    La <strong>prestación de nuestros servicios</strong> requiere escribir o facilitar
                                    datos de forma voluntaria asidua, y cada función describe la finalidad para la que
                                    se necesitan los datos.
                                </p>
                                <p>
                                    Comunicación entre usted y CamDiab o el distribuidor con nuestras aplicaciones o
                                    mediante otros servicios de mensajería electrónica (p. ej., correo electrónico,
                                    mensajería, teléfono) cuando sea necesario, para recibir asistencia o resolver
                                    problemas de nuestros productos. Así es como procesamos, a través de los distintos
                                    canales de comunicación, los comentarios y consultas que puedan surgirle al utilizar
                                    CamDiab. El ejemplo más destacado es nuestro servicio de asistencia, al que puede
                                    acceder en <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Por lo tanto, reflexione
                                    sobre la información y los datos que desea proporcionar en su comunicación activa
                                    con nosotros, ya que es únicamente decisión suya. Por nuestra parte, la comunicación
                                    con los usuarios puede ser necesaria por correo electrónico o similar. Así es como
                                    le informamos sobre las actualizaciones de nuestros productos y le damos importantes
                                    consejos de seguridad, además de asistencia relacionada con su uso. Esta
                                    comunicación asistencial se envía a los usuarios como parte esencial de nuestros
                                    productos.
                                </p>
                                <p>
                                    Los <strong>dispositivos terapéuticos </strong> (p, ej., sensor de glucosa y bombas
                                    de insulina) se emparejan con el dispositivo, lo que permite transferir datos a
                                    nuestras aplicaciones.
                                </p>
                                <p>
                                    Los <strong>portales de datos sobre la diabetes y el intercambio de datos</strong>,
                                    como los de mylife Cloud/Glooko, permiten el intercambio de datos con nuestras
                                    aplicaciones. Ahora bien, solo habrá sincronización si la activa en los ajustes de
                                    nuestras aplicaciones, es decir, si utiliza la función. El aviso de privacidad de
                                    los fabricantes de portales de datos sobre la diabetes se aplica a los datos
                                    compartidos con esos colaboradores externos.
                                </p>

                                2.2.2 El <strong>uso</strong> de nuestras aplicaciones requiere escribir o suministrar
                                datos activa y voluntariamente. Para resolver un error en la aplicación necesitamos, por
                                ejemplo, informes de fallos útiles para resolver problemas y determinar las
                                circunstancias de estos. Además, se registran los datos clave de su dispositivo y su
                                comportamiento de uso, ya que nuestro cumplimiento contractual implica, sobre todo, la
                                personalización de nuestros productos, es decir, el procesamiento de información
                                individual del usuario, por ejemplo, dependiendo de su ubicación (también relevante para
                                la función de búsqueda). El único fin del análisis automatizado del comportamiento del
                                usuario es personalizar su uso en cumplimiento del contrato. A usted no le supone efecto
                                legal alguno.

                                <h2>
                                    3. PROCESAMIENTO PARA MEJORAR EL PRODUCTO
                                </h2>
                                <p>
                                    También procesamos sus datos de usuario, trascendiendo el uso necesario descrito en
                                    el apartado 2 anterior, a fin de mejorar nuestros productos y servicios, como se
                                    describe a continuación con más detalle.
                                </p>
                                <h3>
                                    3.1. Datos adicionales
                                </h3>
                                <p>
                                    En general, utilizamos los mismos datos de usuario para mejorar nuestros productos
                                    como se indica en el apartado 2. Además, CamDiab también puede registrar los
                                    siguientes datos de usuario:
                                </p>
                                <p>
                                    <strong>Datos de uso</strong>
                                    <br/>
                                    Incidencias de actividad que nos permiten entender cómo utiliza nuestros productos.
                                    Nos permiten ver el uso que se hace de nuestros productos y, por ejemplo, dónde
                                    optimizar los diseños de los menús.
                                </p>
                                <h3>
                                    3.2. Objetivo de la mejora del producto
                                </h3>
                                <p>
                                    Los ágiles avances tecnológicos nos obligan a analizar, desarrollar, poner a prueba
                                    y mejorar continuamente los productos y sus interacciones. Es el modo de garantizar
                                    que nuestros contenidos beneficien a los usuarios de la manera más eficaz. Para
                                    ello, podemos realizar evaluaciones de uso, viabilidad, predicción, control y
                                    seguridad. Los conocimientos adquiridos se incorporan a nuevas versiones mejoradas
                                    de nuestros productos, como la aplicación. Estas mejoras también se le facilitan
                                    mediante actualizaciones periódicas.
                                </p>
                                <p>
                                    CamDiab está comprometida con la ciencia de todos los aspectos de la diabetes. Por
                                    lo tanto, los Datos de Usuario anónimos también podrán utilizarse con fines de
                                    investigación y estadística (siempre respetando las normas éticas científicas
                                    reconocidas) y análisis internos. Esto puede incluir compartir datos anonimizados
                                    con terceros colaboradores.
                                </p>
                                <h2>
                                    4. PROCESAMIENTO CON FINES DE COMERCIALIZACIÓN
                                </h2>
                                <h3>
                                    4.1. Boletín informativo
                                </h3>
                                <p>
                                    4.1.1 Podemos enviarle información sobre productos y servicios, además de
                                    suministrarle los servicios contractuales (incluida la información de socios
                                    cuidadosamente seleccionados), e invitaciones para participar en encuestas u otras
                                    promociones de ventas y actividades de marketing (“<strong>Boletín
                                    informativo</strong>”).
                                </p>
                                <p>
                                    4.1.2 Puede seleccionar si desea suscribirse a nuestro boletín informativo
                                    (consentimiento). Puede revocar su consentimiento en cualquier momento, en los
                                    ajustes de la cuenta.
                                </p>
                                <h3>
                                    4.2. Otros tipos de marketing
                                </h3>
                                <p>
                                    4.2.1 En caso necesario, se obtendrán otros consentimientos, p. ej., para encuestas,
                                    notificaciones u ofertas personalizadas. En todos los casos, le explicamos por qué
                                    necesitamos determinados datos y también cómo revocar el consentimiento.
                                </p>
                                <p>
                                    4.2.2 Tenga en cuenta que podemos mostrarle ofertas dentro de la aplicación sin
                                    procesar sus datos personales. También verá estos anuncios no personalizados si no
                                    ha dado su consentimiento.
                                </p>
                                <h2>
                                    5. USO A EFECTOS LEGALES
                                </h2>
                                <h3>
                                    5.1. Investigación científica y estadística
                                </h3>
                                <p>
                                    CamDiab está volcada en la ciencia de todos los aspectos de la diabetes. Por lo
                                    tanto, los datos de usuario anónimos también se pueden utilizar para fines de
                                    investigación y estadística (respetando siempre las normas éticas científicas
                                    reconocidas) y análisis internos. Se utilizan principalmente para determinar y
                                    mejorar la eficacia de las técnicas de control y tratamiento de la diabetes. La base
                                    legal correspondiente es el Artículo 9 (2) j) del RGPD.
                                </p>
                                <h3>
                                    5.2. Ejecución de derechos
                                </h3>
                                <p>
                                    El uso de los datos personales también puede requerirse para evitar usos indebidos
                                    por parte de los usuarios o para hacer valer, ejercer o defender demandas legales.
                                    Podemos vernos obligados a revelar información debido a leyes vinculantes,
                                    decisiones e instrucciones judiciales u oficiales, investigaciones penales o en
                                    interés del público. En estos casos, la ley permite guardar y tratar sus datos sin
                                    su consentimiento. La base legal correspondiente es el Artículo 9 (2) f) del RGPD.
                                </p>
                                <h3>
                                    5.3. De acuerdo con la legislación sobre productos sanitarios
                                </h3>
                                <p>
                                    Por último, como fabricante o distribuidor de un producto sanitario, estamos sujetos
                                    a un mayor número de requisitos de control de la funcionalidad de nuestro producto.
                                    Este sistema de vigilancia exigido con fines normativos también puede implicar el
                                    tratamiento de datos personales. La base legal correspondiente es el Artículo 9 (2)
                                    i) del RGPD.
                                </p>
                                <h2>
                                    6. INFORMACIÓN GENERAL
                                </h2>
                                <h3>
                                    6.1. Limitación de la finalidad y seguridad
                                </h3>
                                <p>
                                    6.1.1 CamDiab utiliza sus datos personales exclusivamente para los fines
                                    determinados en este aviso de privacidad y en los consentimientos correspondientes.
                                    Nos aseguramos de que ningún tratamiento vaya más allá del propósito
                                    correspondiente.
                                </p>
                                <p>
                                    6.1.2 Cada tratamiento garantiza siempre la seguridad y confidencialidad adecuadas
                                    de sus datos personales. Esta cláusula abarca la protección contra el tratamiento no
                                    autorizado e ilegal, la pérdida no intencionada y la destrucción o el daño no
                                    intencionados por medio de medidas técnicas y organizativas apropiadas. Utilizamos
                                    procesos internos, elementos de seguridad y métodos de encriptación rigurosos.
                                </p>
                                <h3>
                                    6.2. Procesadores
                                </h3>
                                <p>
                                    6.2.1 Nuestros productos están sujetos a complejos procesos que debemos gestionar y
                                    mantener actualizados. Para la asistencia técnica podemos recurrir a proveedores
                                    externos ("<strong>Procesadores</strong>"), con el fin de ofrecerle un uso completo
                                    y óptimo de nuestros productos.
                                </p>
                                <p>
                                    6.2.2 CamDiab transfiere los datos de usuario a los procesadores exclusivamente en
                                    el marco de este aviso de privacidad y solo para cumplir con los fines que este
                                    especifica. Los procesadores trabajan de acuerdo con nuestras especificaciones e
                                    instrucciones; no están autorizados a utilizar los datos personales de nuestros
                                    usuarios para sus propios fines o los de otros.
                                </p>
                                <p>
                                    6.2.3 Recurrimos a procesadores que ofrecen garantías suficientes de adopción de las
                                    medidas técnicas y organizativas adecuadas para que el tratamiento de los datos
                                    personales se ajuste a los requisitos legales y a nuestro aviso de privacidad. La
                                    protección de los derechos de nuestros usuarios se garantiza mediante la conclusión
                                    de contratos vinculantes que cumplen los requisitos estrictos del RGPD.
                                </p>
                                <h3>
                                    6.3. Cifrado, seudonimización y anonimización
                                </h3>
                                <p>
                                    6.3.1 Cada transferencia de datos, sin excepción y de forma predeterminada, se
                                    codifica durante la transferencia. Mediante el uso de HTTPS (protocolo de
                                    transferencia de hipertexto seguro) o similar, nos aseguramos de que terceros no
                                    autorizados no intercepten sus datos.
                                </p>
                                <p>
                                    Además, a efectos de seguridad y minimización de datos, también utilizamos otros
                                    procesos para el cifrado y la seudonimización de los datos de usuario. Por supuesto,
                                    esto depende del tipo, el alcance y la finalidad del tratamiento de datos
                                    correspondiente. Por ejemplo, solo revelamos los datos de usuario que un procesador
                                    necesita para llevar a cabo sus tareas.
                                </p>
                                <p>
                                    6.3.2 Al concluir una relación contractual con un procesador, dicho procesador debe,
                                    a discreción de CamDiab, devolver todos nuestros datos de usuario o eliminarlos si
                                    no hay obligaciones legales de almacenamiento.
                                </p>
                                <p>
                                    6.3.3 Los datos que no requieren referencias personales para su tratamiento (p. ej.,
                                    para la investigación y el análisis) se anonimizan. Así se evita asociarlos a un
                                    usuario concreto en todos los casos.
                                </p>
                                <h3>
                                    6.4. UE y otros países
                                </h3>
                                <p>
                                    6.4.1 Nuestro deseo es seleccionar socios colaboradores que tengan su sede o cuyos
                                    servidores estén situados en la Unión Europea (UE) o en el Espacio Económico Europeo
                                    (EEE). La transmisión de datos en el seno de la UE y el EEE es inobjetable, dado que
                                    el RGPD se aplica en todos los Estados miembros.
                                </p>
                                <p>
                                    6.4.2 En circunstancias excepcionales, designamos a proveedores externos que están
                                    ubicados o tienen servidores en territorio extracomunitario. Sin embargo, incluso en
                                    estos casos sus datos personales están sujetos a un alto nivel de protección en
                                    línea con el RGPD ─ya sea a través de una decisión de adecuación de la UE, que
                                    considera que la protección de datos en determinados países terceros es apropiada
                                    (por ejemplo, Suiza, Israel y Nueva Zelanda), o a través de determinadas cláusulas
                                    contractuales estándar aprobadas por la UE, en las que se basan las relaciones
                                    contractuales con nuestros procesadores de datos contratados, o a través de
                                    instrumentos legales comparables permitidos por el RGPD.
                                </p>
                                <p>
                                    6.4.3 Además, nos aseguramos de que nuestros socios cuenten con normas de seguridad
                                    adicionales, como medidas de seguridad individuales y disposiciones de protección de
                                    datos o certificados en virtud del RGPD. Así, por ejemplo, si los proveedores
                                    externos están ubicados en los EE. UU., deben estar sujetos al marco del Escudo de
                                    la Privacidad aprobado por la UE o a normas de seguridad comparables reconocidas
                                    internacionalmente.
                                </p>
                                <h3>
                                    6.5. Cookies
                                </h3>
                                <p>
                                    CamDiab puede almacenar las llamadas "cookies" para ofrecerle una amplia gama de
                                    funciones y volver más práctica la navegación de nuestro sitio web. La cookies son
                                    pequeños archivos de texto que el navegador almacena en su dispositivo. A excepción
                                    de las cookies para datos de uso mencionadas en el apartado 6.6, nuestras cookies se
                                    pueden utilizar para el funcionamiento del sitio web. Si no desea utilizar cookies,
                                    puede evitar que se guarden, mediante la configuración correspondiente del
                                    navegador. La mayoría de nuestras cookies se eliminan al final de la visita o al
                                    cerrar el navegador (cookies de sesión). De lo contrario, puede comprobar el tiempo
                                    de eliminación en su navegador o eliminar las cookies manualmente.
                                </p>
                                <h3>
                                    6.6. Datos de uso
                                </h3>
                                <p>
                                    En la aplicación utilizamos Google Firebase Analytics, un servicio de análisis de
                                    aplicaciones y web de Google Inc. ("Google"). Google cuenta con la certificación del
                                    Escudo de la Privacidad UE-EE. UU.
                                </p>
                                <h3>
                                    6.7. Almacenamiento y eliminación
                                </h3>
                                <p>
                                    6.7.1 Sus datos de usuario se almacenan en su dispositivo. Estos datos también se
                                    almacenan en servidores. Solo utilizamos sistemas que cumplen los requisitos del
                                    RGPD.
                                </p>
                                <p>
                                    6.7.2 Sus datos se almacenan en servidores en la Unión Europea (UE).
                                </p>
                                <p>
                                    6.7.3 Por regla general, CamDiab solo almacena sus datos personales mientras dure el
                                    contrato. En casos excepcionales, puede ser necesario un almacenamiento más
                                    prolongado para cumplir las obligaciones poscontractuales, las obligaciones legales
                                    de almacenamiento o deberes de divulgación, o bien para hacer valer, ejercer o
                                    defender reclamaciones legales (plazos de prescripción).
                                </p>
                                <h3>
                                    6.8. Menores
                                </h3>
                                <p>
                                    Los menores de dieciséis años solo pueden utilizar nuestros productos con el
                                    consentimiento de un progenitor/tutor ( <a href="/es/terms"> Condiciones generales -
                                    CG </a> ). Esto también se aplica al tratamiento de sus datos personales, que solo
                                    será legal en la medida en que el consentimiento se haya obtenido por mediación del
                                    progenitor/tutor. De lo contrario, queda prohibido usar nuestros productos.
                                </p>
                                <h3>
                                    6.9. Responsable de protección de datos
                                </h3>
                                <p>
                                    6.9.1 Nuestro responsable de protección de datos está a su disposición para
                                    responder a todas las preguntas sobre protección de datos en <a
                                    href="mailto:dpo@evalian.co.uk">dpo@evalian.co.uk</a>.
                                </p>
                                <p>
                                    6.9.2 El responsable de protección de datos interviene asiduamente en todas las
                                    cuestiones relacionadas con la protección de los datos personales de nuestros
                                    usuarios. Como experto formado, nunca deja de supervisar nuestro tratamiento, para
                                    garantizar la mejor protección posible de sus datos de usuario.
                                </p>
                                <h2>
                                    7. SUS DERECHOS
                                </h2>
                                <h3>
                                    7.1. Revocación de consentimientos
                                </h3>
                                <p>
                                    Si tratamos sus datos de usuario basándonos en su consentimiento, puede revocar
                                    dicho consentimiento en cualquier momento. Sin embargo, esta acción no afectará la
                                    legalidad del tratamiento antes de la revocación. Seguiremos prestando nuestros
                                    servicios si no dependen del consentimiento revocado.
                                </p>
                                <h3>
                                    7.2. <strong>Información, corrección y restricción</strong>
                                </h3>
                                <p>
                                    7.2.1 Todo usuario tiene derecho a solicitar información sobre el tratamiento de sus
                                    datos personales. Para ello, escríbanos cuando lo desee a <a
                                    href="mailto:privacy@camdiab.com">privacy@camdiab.com</a>.
                                </p>
                                <p>
                                    7.2.2 Su derecho a la información abarca la información sobre los fines del
                                    tratamiento, las categorías de datos y destinatarios, el tiempo de almacenamiento,
                                    el origen de sus datos y sus derechos en virtud de la normativa de protección de
                                    datos. Encontrará toda esta información en este aviso de privacidad y será un placer
                                    proporcionársela en formato electrónico.
                                </p>
                                <p>
                                    7.2.3 Si hay algún dato personal incorrecto, puede solicitar que se corrija o
                                    complete en cualquier momento. Tiene derecho a restringir el tratamiento de datos
                                    mientras dure la revisión de investigación que ha solicitado.
                                </p>
                                <h3>
                                    7.3. Eliminación (“derecho al olvido”)
                                </h3>
                                <p>
                                    Todo usuario tiene derecho a solicitar la eliminación de sus datos personales. Si
                                    así lo desea, escríbanos en cualquier momento en <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    7.4. Reclamaciones
                                </h3>
                                <p>
                                    7.4.1 Si cree que no estamos protegiendo adecuadamente sus derechos de protección de
                                    datos, escríbanos cuando lo desee a <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a> o contacte con el delegado
                                    de protección de datos, cuya dirección es <a
                                    href="mailto:dpo@evalian.co.uk">dpo@evalian.co.uk</a>. Trataremos su solicitud
                                    debidamente.
                                </p>
                                <p>
                                    7.4.2 Todo usuario tiene derecho a presentar una reclamación ante la autoridad de
                                    protección de datos responsable de CamDiab si considera que el tratamiento de sus
                                    datos personales no se ajusta a la normativa de protección de datos. Además, el
                                    usuario tiene derecho a expresar sus reclamaciones ante una autoridad de control del
                                    Estado miembro de la UE donde resida, donde esté su lugar de trabajo o donde se
                                    produzca una presunta infracción.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

