import React, {Component} from 'react';
import {Header} from './Header';
import {Footer} from './Footer';
import {Helmet} from "react-helmet";

export class PrivacyFI extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Tietosuojaehdot - CamAPS FX</title>
                    <meta name="title" content='Tietosuojaehdot'/>
                    <meta name="description"
                          content='Täältä löydät CamDiab-tietosuojakäytännön'/>
                    <meta property="og:locale" content='fi_fi'/>
                    <meta name="keywords" content='tietosuojaehdot'/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10">
                                <h1>Tietosuojaehdot</h1>
                                <h3>Versio päivätty 4. joulukuuta 2024</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <h2>
                                    1. JOHDANTO
                                </h2>
                                <h3>
                                    1.1. Vastuullinen taho
                                </h3>
                                <p>
                                    CamDiab Limited, rekisteröity kotipaikka 20-22 Wenlock Road, London, N1 7GU, UK ja
                                    joka on perustettu ja rekisteröity Englannissa ja Walesissa yritystunnuksella
                                    11659211 (“CamDiab”), on ilmoitettu vastuullinen taho tietosuojamääräysten
                                    puitteissa. CamDiab päättää käyttäjiensä henkilötietojen
                                    (“<strong>käyttäjätiedot</strong>”) käsittelyn tarkoituksesta ja tavasta ja on siksi
                                    vastuussa tietojen turvallisuudesta ja sovellettavien lakien noudattamisesta.
                                </p>
                                <p>
                                    1.1.2 Vastuullisena tahona meitä koskevat esimerkiksi tietovaatimukset, jotka
                                    haluamme täyttää tämän tietosuojailmoituksen yhteydessä.
                                </p>
                                <h3>
                                    1.2. Rakenne ja suostumuskäsite
                                </h3>
                                <p>
                                    1.2.1 Tämä tietosuojailmoitus tiedottaa sinulle käyttäjätietojesi käsittelyn
                                    tarkoituksista ja laajuudesta sekä tiedonsiirroista ja kattavista oikeuksistasi.
                                    Koska tarjontamme on suunnattu ainoastaan diabetesta sairastaville henkilöille,
                                    käyttösi antaa meille tietoja terveydentilastasi. Käsittelemme käyttäjätietojasi
                                    siksi ainoastaan suostumuksellasi. Erittelemme käsittelyt seuraavasti:
                                </p>
                                <p>
                                    1.2.1.1 "Tarpeellinen käsittely" kuvaa, miten käytämme käyttäjätietoja täyttääksemme
                                    sopimusvelvoitteemme. Tuotteidemme käyttäminen ei ole mahdollista ilman suostumusta
                                    laillisesta tai faktuaalisesta näkökulmasta, koska palvelumme ovat riippuvaisia
                                    tästä käsittelystä.
                                </p>
                                <p>
                                    1.2.1.2 "Tuoteparanteluun tarkoitettu käsittely" kuvaa, miten voit auttaa meitä ja
                                    muita käyttäjiä suostumuksellasi, kun annat meidän käyttää tietojasi erityisesti
                                    parantamaan hoidonhallinnan algoritmeja, tuotetta jne. ilman, että otamme sinuun
                                    yhteyttä.
                                </p>
                                <p>
                                    1.2.1.3 "Markkinointiin tarkoitettu käsittely" kuvaa, miten otamme sinuun yhteyttä
                                    markkinointitarkoituksissa suostumuksellasi esim. sähköpostitse, ilmoituksilla jne.
                                </p>
                                <p>
                                    1.2.1.4 Välttyäksemme toistolta olemme koonneet Yleiset tiedot -osaan tietoja, jotka
                                    koskevat kaikkia edellä mainittuja suostumuksia.
                                </p>
                                <p>
                                    Asianmukaiset kategoriat kuvataan tarkemmin edempänä. Sinun on annettava
                                    asianmukainen suostumuksesi rekisteröityessäsi tai kun tilaat tuotteemme.
                                </p>
                                <p>
                                    1.2.2 Joissain tapauksissa käsittely voi tapahtua ilman suostumusta ja perustuu
                                    silloin lakiin (esim. lääkinnällisiä laitteita koskevat määräykset).
                                </p>
                                <h2>
                                    2. TARPEELLINEN KÄSITTELY
                                </h2>
                                <p>
                                    Jos annat suostumuksesi, käsittelemme alla lueteltuja henkilötietoja, jotta voimme
                                    tarjota palveluitamme. Jos et hyväksy tätä välttämätöntä menettelyä, et voi käyttää
                                    CamDiabin palveluita. Sinun on annettava asianmukainen suostumus rekisteröinnin
                                    aikana tai kun tilaat tuotteen.
                                </p>
                                <h3>
                                    2.1. Tarpeelliset käyttäjätiedot
                                </h3>
                                <p>
                                    2.1.1 Jotta voimme suojella käyttäjätietojasi, palveluitamme voi käyttää vain
                                    käyttäjätiliin liitettynä. Jotta käyttäjätili voidaan luoda, tarvitsemme ja
                                    käsittelemme seuraavia käyttäjätietoja:
                                </p>
                                <ul type="disc">
                                    <li>
                                        Sähköpostiosoite
                                    </li>
                                    <li>
                                        Salasana (tallennamme vain tarkistussumman)
                                    </li>
                                    <li>
                                        Nimi
                                    </li>
                                    <li>
                                        ALV-vapautusilmoitus (nimi, osoite, päivämäärä, omakustanteiset käyttäjät)
                                    </li>
                                    <li>
                                        Syntymävuosi
                                    </li>
                                    <li>
                                        Rekisteröintipäivä
                                    </li>
                                    <li>
                                        Suostumuksen tila
                                    </li>
                                    <li>
                                        Laitteen tunnus, valmistaja, laitteen tyyppi, käyttöjärjestelmän versio,
                                        sovelluksen versio
                                    </li>
                                    <li>
                                        Kieli, maa, aikavyöhyke.
                                    </li>
                                </ul>
                                <p>
                                    2.1.2 Muut käyttäjärekisteröinnin ulkopuolella kerätyt tiedot sisältävät mm:
                                </p>
                                <p>
                                    <strong>Lääketieteelliset ydintiedot </strong>
                                    <br/>
                                    Paino, verensokerin tavoitealue, insuliinipumpun perusinsuliinin asetukset,
                                    herkkyys, hiilihydraatti/insuliinisuhde, insuliinin toiminnan kesto,
                                    tavoiteglukoosi, varoitusasetukset, bolusväli, hiilihydraattiyksikkö, raskaustila,
                                    arvioitu laskettu aika.
                                </p>
                                <p>
                                    <strong>Käyttö- ja linkitystiedot</strong>
                                    <br/>
                                    Insuliinipumpun ja lähettimen sarjanumero, insuliinipumppu, sensori ja suljetun /
                                    avoimen järjestelmän toimet, sovellustoimintojen polku, tapahtumapolku, seuraajien
                                    tiedot siten kuin ne on annettu sovellukseen (puhelinnumero, käyttäjätunnukset
                                    diabetestietojen portaalin tileihin), tukipyynnöt, kouluttajien tiedot,
                                    diabetesklinikan tunnus.
                                </p>
                                <p>
                                    <strong>Lääketieteelliset tiedot</strong>
                                    <br/>
                                    Sovellusmerkinnät, kuten päivämäärä/aika/aikavyöhyke/paikka, ruokailu/ateria, veren
                                    ja sensorin glukoosimittaukset, sensorin glukoosipitoisuuden muutosnopeus,
                                    päivittäinen kokonaisinsuliiniannos, insuliinin annostelu (bolus ja perusinsuliini),
                                    väliaikainen perusnopeus, suljetun järjestelmän tila, insuliinipumpun ja kanyylin
                                    täyttö, varoitukset, boost- ja ease off -tapahtumat, sovellusasetukset, kuten
                                    näyttövaihtoehdot, tilastolliset yhteenvedot glukoosista ja insuliinitiedoista.
                                </p>
                                <p>
                                    2.1.3 Sovellus saattaa vaatia pääsyn sijaintiisi ainoastaan Bluetooth Low Energy
                                    (BLE) -yhteyden mahdollistamiseksi, kuten käyttöjärjestelmän käyttöoikeudet
                                    edellyttävät. Sovellus ei kuitenkaan kerää, tallenna tai jaa sijaintitietoja.
                                </p>
                                <p>
                                    2.1.4 Jos haluat, voit antaa minkä tahansa sähköpostin, jonka olet perustanut meitä
                                    varten, mutta sen täytyy toimia, jotta voimme lähettää sinulle tärkeitä varoituksia.
                                </p>
                                Käsittelemme vain käyttäjätietoja, jotka olet aktiivisesti ja vapaaehtoisesti antanut
                                CamDiabille tai jotka on saatu glukoosisensorista ja insuliinipumpusta, joihin olet
                                muodostanut vapaaehtoisesti yhteyden. Tarpeellisten käyttäjätietojen antaminen on
                                vaatimus sille, että voit kattavasti käyttää tuotteitamme.
                                <h3>
                                    2.2. Tarpeelliset tarkoitukset
                                </h3>
                                <p>
                                    2.2.1 Kaikki käsittelymme tarpeelliset tarkoitukset liittyvät palveluidemme
                                    tarjoamiseen:
                                </p>
                                <p>
                                    <strong>Asiakaskysely </strong> johtaa yhteystietojen tallentamiseen.
                                </p>
                                <p>
                                    Sovellustemme <strong>tilaaminen</strong> omakustanteisesti johtaa yhteystietojen ja
                                    teknisten tietojen, kuten laitteen tunnuksen, tallentamiseen.
                                </p>
                                <p>
                                    Sovellustemme <strong>asentaminen</strong> johtaa teknisten ja laitteeseen
                                    liittyvien tietojen tallentamiseen.
                                </p>
                                <p>
                                    <strong>Rekisteröityminen</strong> johtaa CamDiab-tilisi luomiseen
                                    sähköpostiosoitteella ja salasanalla.
                                </p>
                                <p>
                                    <strong>Palveluidemme toimittaminen</strong> edellyttää, että syötät tai toimitat
                                    tietoja vapaaehtoisesti ja aktiivisesti, ja jokainen toiminto kuvaa tarkoitusta,
                                    johon tietoja tarvitaan.
                                </p>
                                <p>
                                    CamDiabin tai jakelijan viestintä kanssasi sovelluksissamme tai muiden sähköisten
                                    viestipalvelujen (esim. sähköposti, Messenger, puhelin) kautta, jos se on tarpeen
                                    tuotteidemme tukemiseksi tai vianmääritykseksi. Käsittelemme näin kaikki kommentit
                                    ja kysymykset, joita sinulla voi olla useiden viestintäkanavien kautta, kun käytät
                                    CamDiabia. Tärkein esimerkki on tukemme, johon saat yhteyden osoitteesta <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Harkitse siis, mitä
                                    tietoja haluat antaa aktiivisessa viestinnässä kanssamme. Se on täysin sinun
                                    päätettävissäsi. Meidän osaltamme viestiminen käyttäjien kanssa sähköpostitse tai
                                    muutoin voi olla tarpeen. Näin ilmoitamme sinulle tuotteidemme päivityksistä ja
                                    tarjoamme tärkeitä turvallisuusneuvoja sekä käyttöösi liittyvää apua. Nämä
                                    tukiviestit lähetetään käyttäjillemme tärkeänä osana tuotteitamme.
                                </p>
                                <p>
                                    <strong>Hoitolaitteet </strong> (esim. glukoosisensorit ja insuliinipumput) on
                                    yhdistetty laitteeseesi, mikä mahdollistaa tietojen siirtämisen sovelluksiimme.
                                </p>
                                <p>
                                    <strong>Diabetestietoportaalit ja tietojen jakaminen</strong>, kuten mylife
                                    Cloud/Glooko, mahdollistavat tietojen vaihdon sovellustemme kanssa. Synkronointi
                                    tapahtuu vain, jos aktivoit sen sovellustemme asetuksissa eli jos käytät toimintoa.
                                    Diabetestietoportaalien valmistajien tietosuojailmoitus koskee tällaisten kolmansien
                                    osapuolten yhteistyökumppaneiden kanssa jaettuja tietoja.
                                </p>

                                2.2.2 Sovellustemme <strong>käyttö</strong> vaatii sen, että annat tiedot
                                vapaaehtoisesti ja aktiivisesti. Sovelluksen virheen ratkaisemiseksi tarvitsemme
                                esimerkiksi sovelluksen kaatumisraportteja, joita voimme käyttää vianmääritykseen
                                määrittääksemme ongelman olosuhteet. Lisäksi laitteesi keskeiset tiedot ja käyttötapasi
                                tallennetaan osana sopimusta, koska sopimuksemme täyttäminen tarkoittaa ennen kaikkea
                                tuotteidemme mukauttamista eli yksittäisten käyttäjätietojen käsittelyä esimerkiksi
                                sijainnistasi riippuen (tämä on tärkeää myös hakutoiminnon kannalta). Automaattinen
                                käyttäjän toiminta-analyysi suoritetaan, jotta käyttösi voidaan mukauttaa sopimusta
                                täytettäessä. Sillä ei ole sinulle laillisia vaikutuksia.

                                <h2>
                                    3. TUOTEPARANTELUUN TARKOITETTU KÄSITTELY
                                </h2>
                                <p>
                                    Käsittelemme myös käyttäjätietojasi yllä olevassa osiossa 2 kuvatun tarpeellisen
                                    käytön lisäksi tuotteidemme ja palveluidemme parantamiseksi, kuten alla on kuvattu
                                    tarkemmin.
                                </p>
                                <h3>
                                    3.1. Lisätiedot
                                </h3>
                                <p>
                                    Yleisesti ottaen käytämme samoja käyttäjätietoja tuotteidemme parantamiseen osiossa
                                    2 kuvatulla tavalla. Lisäksi CamDiab voi käyttää seuraavia käyttäjätietoja:
                                </p>
                                <p>
                                    <strong>Käyttötiedot</strong>
                                    <br/>
                                    Toimintatapahtumat auttavat meitä ymmärtämään, miten käytät tuotteitamme. Niiden
                                    avulla näemme, miten tuotteitamme käytetään ja miten esimerkiksi valikkojen
                                    suunnittelua voidaan parantaa.
                                </p>
                                <h3>
                                    3.2. Tuoteparantelun tarkoitus
                                </h3>
                                <p>
                                    Koska teknologia kehittyy nopeasti, meidän on jatkuvasti analysoitava, kehitettävä,
                                    testattava ja paranneltava tuotteitamme ja niiden keskinäistä toimintaa, jotta
                                    voimme varmistaa, että sisältömme on käyttäjille mahdollisimman tehokasta. Sen
                                    vuoksi voimme suorittaa käyttö-, toteutettavuus-, ennakoivuus-, hallinta- ja
                                    tietoturva-arviointeja, joista saatu tieto sisällytetään tuotteidemme parannettuihin
                                    uusiin versioihin, kuten sovellukseen. Nämä parannukset toimitetaan sinulle myös
                                    säännöllisten päivitysten kautta.
                                </p>
                                <p>
                                    CamDiab on sitoutunut tutkimaan diabeteksen kaikkia näkökohtia. Siksi anonyymejä
                                    käyttäjätietoja voidaan käyttää myös tutkimus- ja tilastointitarkoituksiin
                                    (noudattaen aina tunnustettuja eettisiä tieteellisiä standardeja) ja sisäisiin
                                    analyyseihin. Tähän voi sisältyä anonymisoitujen tietojen jakaminen kolmansien
                                    osapuolten yhteistyökumppaneiden kanssa.
                                </p>
                                <h2>
                                    4. MARKKINOINTIIN TARKOITETTU KÄSITTELY
                                </h2>
                                <h3>
                                    4.1. Uutiskirje
                                </h3>
                                <p>
                                    4.1.1 Voimme lähettää sinulle tietoja tuotteista ja palveluista sopimuksen
                                    soveltamisalan lisäksi (mukaan lukien tiedot huolellisesti valituilta kumppaneilta)
                                    ja voimme kutsua osallistumaan kyselyihin tai muihin myynninedistämis- ja
                                    markkinointitoimiin (“<strong>uutiskirje</strong>”).
                                </p>
                                <p>
                                    4.1.2 Voit valita, haluatko tilata uutiskirjeemme vai et. Voit peruuttaa
                                    suostumuksesi milloin tahansa tilisi asetuksissa.
                                </p>
                                <h3>
                                    4.2. Muu markkinointi
                                </h3>
                                <p>
                                    4.2.1 Muunlaiset luvat esimerkiksi kyselyihin, ilmoituksiin tai muokattuja
                                    tarjouksia varten pyydetään vaadittaessa. Selitämme aina sinulle, miksi tarvitsemme
                                    tiettyjä tietoja ja miten voit peruuttaa suostumuksen.
                                </p>
                                <p>
                                    4.2.2 Otathan huomioon, että voimme näyttää sinulle tarjouksia sovelluksessa ilman
                                    henkilötietojesi käsittelyä. Näet näitä mukauttamattomia mainoksia myös silloin, jos
                                    et ole antanut suostumustasi.
                                </p>
                                <h2>
                                    5. LAKIIN PERUSTUVA KÄSITTELY
                                </h2>
                                <h3>
                                    5.1. Tieteellinen tutkimus ja tilastot
                                </h3>
                                <p>
                                    CamDiab on sitoutunut kaikkiin diabeteksen tieteellisiin näkökohtiin. Siksi
                                    anonyymeja käyttäjätietoja saatetaan käyttää myös tutkimukseen ja tilastoihin (aina
                                    noudattaen tunnustettuja eettisiä tieteellisiä standardeja) ja sisäisiin
                                    analyyseihin. Niitä käytetään pääasiallisesti määrittämään ja parantamaan
                                    diabeteksen hallinta- ja hoitotekniikoiden tehoa. Niiden laillinen käsittelyperuste
                                    on yleisen tietosuoja-asetuksen artikla 9 (2) j) General Data Protection Regulation
                                    (GDPR).
                                </p>
                                <h3>
                                    5.2. Oikeuksien täytäntöönpano
                                </h3>
                                <p>
                                    Henkilötietojen käyttäminen voi olla tarpeellista myös käyttäjien väärinkäytösten
                                    estämiseksi tai oikeusvaateiden esittämiseksi, toteuttamiseksi tai puolustamiseksi.
                                    Meidät on mahdollista pakottaa paljastamaan tietoja sitovien lakien, tuomioistuimen
                                    tai virallisten päätösten ja ohjeiden, rikostutkinnan tai yleisen edun vuoksi.
                                    Tällaisissa tapauksissa tietojesi tallentaminen ja käsittely on lain mukaan
                                    sallittua ilman suostumustasi. Laillinen käsittelyperuste on yleisen
                                    tietosuoja-asetuksen artikla 9 (2) f).
                                </p>
                                <h3>
                                    5.3. Lääketieteellisiä laitteita koskevien lakien noudattaminen
                                </h3>
                                <p>
                                    Lopuksi, lääkinnällisen laitteen valmistajana tai jakelijana meille asetetaan
                                    korkeammat vaatimukset tuotteemme toimivuuden valvomiseksi. Tämä
                                    sääntelytarkoituksiin tarvittava tarkkailujärjestelmä voi sisältää myös
                                    henkilötietojen käsittelyn. Niiden laillinen käsittelyperuste on yleisen
                                    tietosuoja-asetuksen artikla 9 (2) i).
                                </p>
                                <h2>
                                    6. YLEISTÄ TIETOA
                                </h2>
                                <h3>
                                    6.1. Tarkoituksen rajaus ja turvallisuus
                                </h3>
                                <p>
                                    6.1.1 CamDiab käyttää henkilötietojasi ainoastaan tässä tietosuojassa määriteltyjen
                                    tarkoitusten ja asianmukaisten suostumusten mukaisesti. Varmistamme, että jokainen
                                    käsittely rajoittuu sen tarkoituksen mukaisiin tarpeellisiin tietoihin.
                                </p>
                                <p>
                                    6.1.2 Jokaisessa käsittelyssä taataan henkilötietojesi riittävä turvallisuus ja
                                    luottamuksellisuus. Tämä kattaa suojauksen luvattomalta ja laittomalta käsittelyltä,
                                    tahattomalta häviämiseltä, tahattomalta tuhoutumiselta tai vahingolta asianmukaisin
                                    teknisin ja organisatorisin toimenpitein. Käytämme tiukkoja sisäisiä prosesseja,
                                    suojausominaisuuksia ja salausmenetelmiä.
                                </p>
                                <h3>
                                    6.2. Käsittelijät
                                </h3>
                                <p>
                                    6.2.1 Tuotteemme ovat monimutkaisten prosessien alaisia, ja meidän on hallinnoitava
                                    niitä ja pidettävä ne ajan tasalla. Voimme käyttää kolmansia osapuolia
                                    (“<strong>käsittelijät</strong>”) teknistä tukea varten, jotta voimme tarjota
                                    sinulle kattavan ja parhaan mahdollisen tuotteidemme käyttökokemuksen.
                                </p>
                                <p>
                                    6.2.2 CamDiab siirtää käyttäjätietoja käsittelijöille ainoastaan tämän
                                    tietosuojailmoituksen puitteissa ja vain siinä mainittuihin tarkoituksiin.
                                    Käsittelijät toimivat meidän määritelmiemme ja ohjeidemme mukaisesti. Käsittelijät
                                    eivät saa käyttää henkilötietoja heidän omiin tarkoituksiinsa tai muihin
                                    tarkoituksiin.
                                </p>
                                <p>
                                    6.2.3 Käytämme käsittelijöitä, jotka antavat riittävät takeet siitä, että he
                                    käyttävät käsittelyssä asianmukaisia teknisiä ja organisatorisia toimenpiteitä niin,
                                    että henkilötietojen käsittely on lakisääteisten vaatimusten ja
                                    tietosuojailmoituksemme mukaista. Käyttäjiemme oikeuksien suojaaminen varmistetaan
                                    solmimalla sitovia sopimuksia, jotka täyttävät tiukat yleisen tietosuoja-asetuksen
                                    vaatimukset.
                                </p>
                                <h3>
                                    6.3. Salaus, pseudoanonymisointi ja anonymisointi
                                </h3>
                                <p>
                                    6.3.1 Jokainen tiedonsiirto poikkeuksetta ja oletusarvoisesti salataan siirron
                                    aikana. Varmistamme käyttämällä HTTPS-protokollaa (Hypertext Transfer Protocol
                                    Secure) tai vastaavaa, ettei tietojasi siepata luvattomien kolmansien osapuolten
                                    toimesta.
                                </p>
                                <p>
                                    Käytämme lisäksi tietoturva- ja minimointitarkoituksiin muita prosesseja
                                    käyttäjätietojen salaukseen ja pseudoanonymisointiin. Tämä riippuu tietenkin
                                    kyseessä olevan tiedonkäsittelyn tyypistä, laajuudesta ja tarkoituksesta. Jaamme
                                    esimerkiksi vain käyttäjätietoja, joita käsittelijä tarvitsee tehtävänsä
                                    suorittamiseen.
                                </p>
                                <p>
                                    6.3.2 Kun sopimussuhde henkilötietojen käsittelijän kanssa päättyy, kyseisen
                                    käsittelijän on CamDiabin harkinnan mukaan joko palautettava tai poistettava kaikki
                                    käyttäjiemme tiedot, jos lakisääteisiä säilytysvelvoitteita ei ole.
                                </p>
                                <p>
                                    6.3.3 Tiedot, jotka eivät vaadi henkilöviitteitä käsittelyyn (esim. tutkimus ja
                                    analyysi) anonymisoidaan. Näin estetään tietojen liittäminen tiettyyn henkilöön
                                    kaikissa tapauksissa.
                                </p>
                                <h3>
                                    6.4. EU ja muut maat
                                </h3>
                                <p>
                                    6.4.1 Pyrimme valitsemaan yhteistyökumppaneita, joiden palvelimet sijaitsevat
                                    Euroopan unionissa (EU) tai Euroopan talousalueella (ETA). EU:n ja ETA:n sisällä
                                    tapahtuvaa tiedonsiirtoa ei voi vastustaa, koska yleinen tietosuoja-asetus pätee
                                    kaikissa jäsenvaltioissa.
                                </p>
                                <p>
                                    6.4.2 Poikkeuksellisissa tilanteissa nimitämme kolmannen osapuolen tarjoajia, jotka
                                    sijaitsevat tai joiden palvelimet sijaitsevat EU:n ulkopuolella. Näissä tapauksissa
                                    henkilötietoihisi sovelletaan korkeaa tietosuojaa yleisen tietosuoja-asetuksen
                                    mukaisesti joko EU:n tietosuojan riittävyyttä koskevan päätöksen mukaan, jossa
                                    tietosuojaa pidetään asianmukaisena tietyissä kolmansien osapuolten maissa
                                    (esimerkiksi Sveitsi, Israel ja Uusi-Seelanti), tai tiettyjen EU:n hyväksymien
                                    vakiosopimuslausekkeiden mukaan, joihin sopimussuhteet tiedonkäsittelijöidemme
                                    kanssa perustuvat, tai yleisen tietosuoja-asetuksen nojalla sallittujen vastaavien
                                    oikeudellisten välineiden mukaan.
                                </p>
                                <p>
                                    6.4.3 Varmistamme lisäksi, että kumppaneillamme on käytössä muita
                                    turvallisuusstandardeja ja tietosuojaehtoja tai yleisen tietosuoja-asetuksen alaisia
                                    sertifikaatteja. Jos kolmannen osapuolen toimittajat sijaitsevat esimerkiksi
                                    Yhdysvalloissa, niihin sovellettaisiin EU:n hyväksymää Privacy Shield -järjestelyä
                                    tai vastaavia kansainvälisesti tunnustettuja turvallisuusstandardeja.
                                </p>
                                <h3>
                                    6.5. Evästeet
                                </h3>
                                <p>
                                    CamDiab voi tallentaa niin kutsuttuja "evästeitä" tarjotakseen sinulle kattavan
                                    valikoiman toimintoja ja jotta verkkosivustomme käyttäminen olisi kätevämpää.
                                    Evästeet ovat pieniä tekstitiedostoja, jotka selaimesi tallentaa laitteellesi.
                                    Lukuun ottamatta kohdassa 6.6 mainittuja käyttötietoevästeitä, evästeitämme voidaan
                                    käyttää verkkosivustomme ylläpitämiseen. Jos et halua käyttää evästeitä, voit estää
                                    niiden tallennuksen selaimen asetuksista käsin. Useimmat evästeistämme poistetaan
                                    vierailusi lopuksi tai kun selain suljetaan (istuntoevästeet). Jos näin ei tapahdu,
                                    voit tarkistaa poistoajan selaimestasi tai poistaa evästeet itse.
                                </p>
                                <h3>
                                    6.6. Käyttötiedot
                                </h3>
                                <p>
                                    Käytämme sovelluksessa Google Firebase Analyticsia, joka on Google Inc. -yhtiön
                                    (“Google”) sovellus- ja verkkoanalyysipalvelu. Google on EU-US Privacy Shield
                                    -sertifioitu.
                                </p>
                                <h3>
                                    6.7. Säilyttäminen ja poistaminen
                                </h3>
                                <p>
                                    6.7.1 Käyttäjätietosi säilytetään laitteellasi. Näitä tietoja säilytetään myös
                                    palvelimilla. Käytämme vain yleisen tietosuoja-asetuksen vaatimukset täyttäviä
                                    järjestelmiä.
                                </p>
                                <p>
                                    6.7.2. Tietosi tallennetaan Euroopan unionissa (EU) sijaitseville palvelimille.
                                </p>
                                <p>
                                    6.7.3 CamDiab tallentaa säännönmukaisesti henkilötietosi vain sopimuksen keston
                                    ajaksi. Poikkeustapauksissa voidaan tarvita pidempää säilytystä sopimuksen
                                    jälkeisten velvoitteiden täyttämiseksi tai lakisääteisten säilytysvelvoitteiden tai
                                    tiedonantovelvollisuuksien noudattamiseksi tai oikeudellisten vaatimusten
                                    esittämiseksi, toimittamiseksi tai puolustamiseksi (vanhentumisajat).
                                </p>
                                <h3>
                                    6.8. Alaikäiset
                                </h3>
                                <p>
                                    Alle 16-vuotiaat alaikäiset voivat käyttää tuotteitamme ainoastaan
                                    vanhemman/huoltajan suostumuksella ( <a href="/fi/terms"> yleiset käyttöehdot</a> ).
                                    Tämä pätee myös heidän henkilötietojensa käsittelyyn, mikä on laillista vain, jos ja
                                    siltä osin kuin suostumus on hankittu kyseessä olevan henkilön vanhemman/huoltajan
                                    kautta. Muutoin tuotteidemme käyttö on kielletty.
                                </p>
                                <h3>
                                    6.9. Tietosuojavastaava
                                </h3>
                                <p>
                                    6.9.1 Tietosuojavastaava on käytettävissä vastaamaan kaikkiin tietosuojakysymyksiin
                                    osoitteessa <a href="mailto:dpo@evalian.co.uk">dpo@evalian.co.uk</a>.
                                </p>
                                <p>
                                    6.9.2 Tietosuojavastaava on laajalti mukana kaikissa kysymyksissä, jotka liittyvät
                                    käyttäjien henkilötietojen suojelemiseen. Koulutettuna asiantuntijana hän valvoo
                                    tekemäämme käsittelyä jatkuvasti varmistaakseen parhaan mahdollisen suojan
                                    käyttäjätiedoillesi.
                                </p>
                                <h2>
                                    7. OIKEUTESI
                                </h2>
                                <h3>
                                    7.1. Suostumuksen peruuttaminen
                                </h3>
                                <p>
                                    Käsittelemme henkilötietojasi suostumuksesi perusteella, ja sinulla on oikeus
                                    peruuttaa suostumuksesi koska tahansa. Tämä ei kuitenkaan vaikuta aikaisemmin
                                    tapahtuneen käsittelyn lainmukaisuuteen. Jatkamme palvelujemme tarjoamista, jos ne
                                    eivät ole riippuvaisia peruutetusta suostumuksesta.
                                </p>
                                <h3>
                                    7.2. <strong>Tiedot, korjaaminen ja rajoittaminen</strong>
                                </h3>
                                <p>
                                    7.2.1 Jokaisella käyttäjällä on oikeus pyytää tietoja heidän henkilötietojensa
                                    käsittelystä. Voit tehdä niin ottamalla meihin yhteyttä milloin tahansa
                                    osoitteesta <a
                                    href="mailto:privacy@camdiab.com">privacy@camdiab.com</a>.
                                </p>
                                <p>
                                    7.2.2 Oikeutesi tietoon kattaa tiedot, jotka koskevat käsittelytarkoituksia, tieto-
                                    ja vastaanottajakategorioita, säilytysaikaa, tietojen alkuperää ja oikeuksiasi
                                    tietosuojamääräysten puitteissa. Löydät nämä tiedot tästä tietosuojailmoituksesta,
                                    ja toimitamme sen mielellämme sinulle sähköisessä muodossa.
                                </p>
                                <p>
                                    7.2.3 Jos jokin henkilötiedoistasi on virheellinen, voit pyytää milloin tahansa sen
                                    korjausta tai täydennystä. Sinulla on oikeus rajoittaa tietojesi käsittelyä minkä
                                    tahansa pyytämäsi tarkastuksen ajaksi.
                                </p>
                                <h3>
                                    7.3. Poistaminen ("oikeus tulla unohdetuksi")
                                </h3>
                                <p>
                                    Jokaisella käyttäjällä on oikeus pyytää henkilötietojensa poistamista. Voit tehdä
                                    niin ottamalla meihin yhteyttä milloin tahansa osoitteesta <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    7.4. Valitukset
                                </h3>
                                <p>
                                    7.4.1 Jos emme sinusta suojele tietosuojaoikeuksiasi riittävästi, ota meihin milloin
                                    tahansa yhteyttä osoitteesta <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a> tai ota yhteyttä
                                    tietosuojavastaavaan osoitteessa <a
                                    href="mailto:dpo@evalian.co.uk">dpo@evalian.co.uk</a>. Käsittelemme pyyntösi sen
                                    mukaisesti.
                                </p>
                                <p>
                                    7.4.2 Kaikilla käyttäjillä on oikeus tehdä valitus CamDiabista vastuussa olevalle
                                    soveltuvalle tietosuojavaltuutetulle, jos käyttäjä uskoo, että hänen
                                    henkilötietojensa käsittely ei noudata tietosuojalakeja. Käyttäjällä on myös oikeus
                                    valittaa sen EU-jäsenvaltion valvontaviranomaiselle, jossa käyttäjä asuu tai jossa
                                    hänen työpaikkansa sijaitsee tai jossa rikkomusta epäillään.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

