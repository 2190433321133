/**
 * Texts for Training page
 */
export const trainingPageText = {
    title: 'Training - CamAPS FX',
    titleMeta: 'We provide free online training for CamAPS FX users',
    descriptionMeta: 'Cambridge Diabetes Education Programme hosts free online certified training for poeple with type 1 diabetes, parents and guardians, ' +
        ' school staf and helath care professionals.',
    keywords:
        'camaps training, camaps fx training,  ' +
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ' +
        '',
    localeMeta: 'en_gb',

    trainingHeader: 'Certified training',
    trainingHeader_2: 'For users, healthcare professionals and school staff',
    trainingHeaderText: 'FREE online CamAPS FX training provided by the Cambridge ' +
        'Diabetes Education Programme (CDEP).',
    websiteLink: 'https://www.camdiabtraining.com/account-sign-in.html',
    websiteLinkHeading: 'CDEP training',
    getTrained: 'Get trained',

    trainingHeaderIat: 'CamAPS FX training',
    trainingHeaderTextIat: 'Complete the essential in-app training before using the CamAPS FX app.',
    trainingSubHeaderOneIat: 'Getting Started',
    trainingSubTextOneIat: 'Download the CamAPS FX app. <br>Create an account to begin training.',

    trainingSubHeaderTwoIat: 'Need help installing the app?',
    trainingParaTwoStartIat: 'Visit our',
    trainingParaTwoEndIat: 'section for guidance.',
    websiteLinkHeadingSupportIat: 'Support',

    trainingSubHeaderThreeIat: 'Additional Resources',
    animationsYpsoPumpText: 'Animations',
    animationsAndText: 'and',
    animationsDanaText: 'Animations (Dana pump)',
    animationsFurtherText: 'Learn through step-by-step visuals.',
    webinarsText: 'Webinars',
    webinarsFurtherText: 'Watch expert-led sessions on using the app.',
    websiteAnimationsYpsoPumpLink: 'https://www.camdiabtraining.com/view/27/mylife-YpsoPump-users.htm',
    websiteAnimationsDanaLink: 'https://www.camdiabtraining.com/view/25/Dana-insulin-pump-users.htm',
    websiteWebinarsLink: 'https://www.camdiabtraining.com/view/20/Webinars.htm',

};
