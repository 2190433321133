import React, {Component} from 'react';
import {Header} from './Header';
import {Footer} from './Footer';
import {Helmet} from "react-helmet";

export class PrivacyNL extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Gegevensbeschermingsbeleid - CamAPS FX</title>
                    <meta name="title" content='Privacybeleid'/>
                    <meta name="description"
                          content='Hier vindt u het privacybeleid van CamDiab'/>
                    <meta property="og:locale" content='nl'/>
                    <meta name="keywords" content='privacybeleid'/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10">
                                <h1>Gegevensbeschermingsbeleid</h1>
                                <h3>Versie van 4 december 2024</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <h2>
                                    1. INLEIDING
                                </h2>
                                <h3>
                                    1.1. Verantwoordelijke rechtspersoon
                                </h3>
                                <p>
                                    CamDiab Limited, geregistreerd kantooradres 20-22 Wenlock Road, London, N1 7GU,
                                    Verenigd Koninkrijk, als naamloze vennootschap gevestigd en geregistreerd in
                                    Engeland en Wales, met bedrijfsnummer 11659211 (‘CamDiab’) is de verantwoordelijke
                                    rechtspersoon op grond van de gegevensbeschermingsbepalingen. CamDiab besluit over
                                    het gebruiksdoel en de middelen voor de verwerking van de persoonsgegevens van onze
                                    gebruikers (‘<strong>gebruikersgegevens</strong>’) en is daarom verantwoordelijk
                                    voor de beveiliging daarvan en voor het voldoen aan de geldende wetgeving.
                                </p>
                                <p>
                                    1.1.2 Als de verantwoordelijke rechtspersoon zijn wij onder andere gehouden aan de
                                    informatievereisten waaraan wij in het kader van deze gegevensbeschermingsverklaring
                                    willen voldoen.
                                </p>
                                <h3>
                                    1.2. Structuur en toestemmingsconcept
                                </h3>
                                <p>
                                    1.2.1 In deze gegevensbeschermingsverklaring wordt u geïnformeerd over de doelen en
                                    omvang van de verwerking van uw gebruikersgegevens, de overdracht daarvan en over uw
                                    uitgebreide rechten. Aangezien ons aanbod uitsluitend gericht is op mensen met
                                    diabetes, geeft het feit dat u er gebruik van maakt al informatie over uw
                                    gezondheidstoestand. Wij verwerken gebruikersgegevens dan ook alleen met uw
                                    toestemming als gezondheidsgegevens. Wij maken het volgende onderscheid:
                                </p>
                                <p>
                                    1.2.1.1 ‘Noodzakelijke verwerking’ beschrijft hoe wij gebruikersgegevens verwerken
                                    om te voldoen aan de overeenkomst. Zonder deze toestemming is het gebruik van onze
                                    producten zowel uit wettelijk als feitelijk oogpunt niet mogelijk, aangezien onze
                                    diensten afhangen van de verwerking van deze gegevens.
                                </p>
                                <p>
                                    1.2.1.2 ‘Verwerking voor productverbetering’ staat voor uw mogelijkheid, ons en
                                    andere gebruikers te helpen door in te stemmen met het gebruik van uw gegevens door
                                    ons, in het bijzonder voor het verbeteren van de algoritmes voor therapiebeheer, de
                                    verbetering van het product etc., zonder dat wij daarvoor contact met u hoeven op te
                                    nemen.
                                </p>
                                <p>
                                    1.2.1.3 ‘Verwerking voor marketingdoeleinden’ beschrijft hoe wij met uw toestemming
                                    contact met u opnemen voor marketingdoeleinden, bijv. per e-mail, via meldingen,
                                    etc.
                                </p>
                                <p>
                                    1.2.1.4 Onder ‘Algemene informatie’ hebben wij de informatie verzameld die
                                    betrekking heeft op alle bovengenoemde vormen van toestemming, om herhalingen te
                                    voorkomen.
                                </p>
                                <p>
                                    Hieronder worden de relevante categorieën gedetailleerd omschreven. Bij uw
                                    aanmelding of het bestellen van uw product moet u de relevante toestemming verlenen.
                                </p>
                                <p>
                                    1.2.2 In sommige gevallen vindt de verwerking plaats ongeacht uw toestemming, op
                                    basis van de geldende wetgeving (bijv. de regelgeving op het gebied van medische
                                    hulpmiddelen).
                                </p>
                                <h2>
                                    2. NOODZAKELIJKE VERWERKING
                                </h2>
                                <p>
                                    Als u toestemming verleent, verwerken wij de onderstaande gebruikersgegevens, om zo
                                    onze diensten te kunnen aanbieden. Als u niet instemt met deze noodzakelijke
                                    verwerking, kunt u geen gebruik maken van de diensten van CamDiab. U kunt uw
                                    toestemming verlenen tijdens de aanmeldingsprocedure of bij het bestellen van uw
                                    product.
                                </p>
                                <h3>
                                    2.1. Noodzakelijke gebruikersgegevens
                                </h3>
                                <p>
                                    2.1.1 Om uw gebruikersgegevens te beschermen, kunnen onze diensten alleen worden
                                    gebruikt in combinatie met een gebruikersaccount. Om een gebruikersaccount aan te
                                    maken, worden de volgende gebruikersgegevens opgevraagd en verwerkt:
                                </p>
                                <ul type="disc">
                                    <li>
                                        E-mailadres
                                    </li>
                                    <li>
                                        Wachtwoord (wij slaan alleen een controlesom op)
                                    </li>
                                    <li>
                                        Naam
                                    </li>
                                    <li>
                                        BTW-vrijstellingsverklaring (naam, adres, datum; voor wie zelf betaald)
                                    </li>
                                    <li>
                                        Geboortejaar
                                    </li>
                                    <li>
                                        Aanmeldingsdatum
                                    </li>
                                    <li>
                                        Status toestemming
                                    </li>
                                    <li>
                                        Apparaat-identificatie, fabrikant, type apparaat, versie besturingssysteem,
                                        versie app
                                    </li>
                                    <li>
                                        Taal, land, tijdzone.
                                    </li>
                                </ul>
                                <p>
                                    2.1.2 Andere informatie, die los van een gebruikersaanmelding wordt verzameld, onder
                                    andere:
                                </p>
                                <p>
                                    <strong>Medische basisgegevens </strong>
                                    <br/>
                                    Gewicht, bloedglucosedoelbereik, basisinstellingen van de pomp, correctiefactoren,
                                    koolhydraten-insulineverhouding, duur van de actieve insuline, glucosedoelwaarde,
                                    meldingsinstellingen, bolusstappen, koolhydraateenheid, zwangerschapstoestand,
                                    geschatte uitgerekende datum.
                                </p>
                                <p>
                                    <strong>Gegevens over gebruik en koppeling</strong>
                                    <br/>
                                    Serienummer pomp en zender, pomp, sensor en registratie van
                                    closed-loop/open-loop-systeemacties, registratie van app-bevelen, registratie van
                                    gebeurtenissen, informatie over volgers die wordt ingevoerd in de app
                                    (telefoonnummer, aanmeldingsgegevens voor account van diabetesgegevensportaal),
                                    hulpvragen, trainersgegevens, diabeteskliniek ID.
                                </p>
                                <p>
                                    <strong>Medische gegevens</strong>
                                    <br/>
                                    In de app ingevoerde informatie, zoals datum/tijd/tijdzone/plaats, inname van
                                    voedsel/maaltijden, bloed- en sensorglucosemetingen, mate van verandering in
                                    sensorglucose, totale dagelijkse dosis insuline, insulinetoediening (bolus en
                                    basaal), tijdelijke basaalsnelheid, closed-loop-status, voorvullen pomp en canule,
                                    meldingen, verhogings- en verlagingsgebeurtenissen, app-instellingen zoals
                                    weergaveopties, statistische samenvattingen van glucose en insulinegegevens.
                                </p>
                                <p> 2.1.3 De app kan toegang tot uw locatie vereisen uitsluitend om Bluetooth Low Energy
                                    (BLE)-connectiviteit mogelijk te maken, zoals vereist door de machtigingen van het
                                    besturingssysteem. Er worden echter geen locatiegegevens verzameld, opgeslagen of
                                    gedeeld door de app.
                                </p>
                                <p>
                                    2.1.4 Als u dat wenst kunt een willekeurig e-mailadres invoeren dat speciaal is
                                    bestemd voor onze berichten – het adres moet echter wel werken, zodat wij u
                                    belangrijke waarschuwingen kunnen sturen.
                                </p>
                                Wij verwerken alleen de gebruikersgegevens die u actief en vrijwillig aan CamDiab
                                toevertrouwd, of die afkomstig zijn van de glucosesensor en insulinepomp waar u
                                vrijwillig verbinding mee maakt. Het invoeren van de noodzakelijke gebruikersgegevens is
                                echter wel een vereiste om onze producten volledig te kunnen gebruiken.
                                <h3>
                                    2.2. Noodzakelijke doeleinden
                                </h3>
                                <p>
                                    2.2.1 Alle noodzakelijke doeleinden van onze gegevensverwerking hangen samen met het
                                    aanbieden van onze diensten:
                                </p>
                                <p>
                                    <strong>Vragen van klanten </strong> leiden tot het vastleggen van contactgegevens.
                                </p>
                                <p>
                                    <strong>Bestellen </strong> van onze apps door personen die zelf betalen leidt tot
                                    het vastleggen van contactgegevens en technische gegevens, zoals de
                                    apparaat-identificatie.
                                </p>
                                <p>
                                    <strong>Installatie </strong> van onze apps leidt tot het vastleggen van technische
                                    gegevens en gegevens die met het apparaat van doen hebben.
                                </p>
                                <p>
                                    <strong>Aanmelding </strong> leidt tot het aanmaken van uw CamDiab-account, met
                                    gebruik van uw e-mailadres en wachtwoord.
                                </p>
                                <p>
                                    Voor het <strong>aanbieden van onze diensten</strong> dient u vrijwillig en actief
                                    gegevens in te voeren of ter beschikking te stellen. Bij iedere functie wordt
                                    omschreven voor welk doel de gegevens nodig zijn.
                                </p>
                                <p>
                                    Communicatie vanuit CamDiab of door de distributeur met u binnen onze apps of via
                                    andere vormen van elektronische berichtgeving (bijv. e-mail, sms, berichtendiensten,
                                    telefoon), indien dat nodig is ter ondersteuning van het gebruik van of het oplossen
                                    van problemen met onze producten. Op deze manier verwerken wij uw opmerkingen en
                                    vragen die bij het gebruik van CamDiab via verschillende communicatiekanalen
                                    binnenkomen. Het belangrijkste voorbeeld daarvan is onze afdeling ondersteuning,
                                    waar u terecht kunt via<a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Overweeg daarom welke
                                    informatie en gegevens u bij uw actieve communicatie met ons wilt delen - die
                                    beslissing ligt uitsluitend bij u. Van onze kant is mogelijk communicatie met onze
                                    gebruikers nodig per e-mail of een vergelijkbaar communicatiekanaal. Dat is de
                                    manier waarop wij u informeren over updates van onze producten en hoe wij
                                    belangrijke veiligheidsadviezen en ondersteuning bieden in verband met uw gebruik.
                                    De volgende ondersteuningscommunicatie wordt - als essentieel onderdeel van onze
                                    producten - naar onze gebruikers gestuurd.
                                </p>
                                <p>
                                    <strong>Therapie-apparaten </strong> (bijv. glucosesensor en insulinepomp) worden
                                    aan uw apparaat gekoppeld, waardoor er gegevens naar onze apps kunnen worden
                                    overgedragen.
                                </p>
                                <p>
                                    <strong>Portalen voor diabetesgegevens en het delen van gegevens</strong>, zoals
                                    mylife Cloud/Glooko, maken het mogelijk om gegevens uit te wisselen met onze apps.
                                    Maar er vindt alleen synchronisatie plaats als u dat in de instellingen van onze
                                    apps activeert, namelijk wanneer u deze functie gebruikt. Voor het delen van
                                    gegevens via portalen voor diabetesgegevens geldt de gegevensbeschermingsverklaring
                                    van de fabrikanten, als meewerkende derde partijen.
                                </p>

                                2.2.2 Voor het <strong>gebruik</strong> van onze apps dient u actief en vrijwillig
                                gegevens in te voeren of ter beschikking te stellen. Voor het oplossen van een fout in
                                de app hebben wij bijvoorbeeld crashrapporten nodig, om problemen te kunnen verhelpen
                                aan de hand van de omstandigheden waarin ze zijn opgetreden. Bovendien worden de
                                basisgegevens van uw apparaat en uw gebruiksgedrag vastgelegd, om aan onze contractuele
                                verplichtingen te kunnen voldoen, in het bijzonder voor het aanpassen van onze
                                producten, zoals bijv. uw locatie (ook belangrijk voor de zoekfunctie). Er wordt een
                                automatische analyse uitgevoerd van het gebruikersgedrag, uitsluitend bedoeld voor het
                                aanpassen van uw gebruik in het kader van het voldoen aan de contractuele
                                verplichtingen, zonder dat dit voor u juridische gevolgen heeft.

                                <h2>
                                    3. VERWERKING VOOR PRODUCTVERBETERING
                                </h2>
                                <p>
                                    Wij verwerken uw gebruikersgegevens ook verdergaand dan het noodzakelijke gebruik
                                    dat hierboven in hoofdstuk 2 beschreven staat. Wij doen dit ter verbetering van onze
                                    producten en diensten, zoals hieronder uitgebreider beschreven staat.
                                </p>
                                <h3>
                                    3.1. Extra gegevens
                                </h3>
                                <p>
                                    Over het algemeen gebruiken wij voor de verbetering van onze producten dezelfde
                                    gebruikersgegevens als in hoofdstuk 2 beschreven staan. Daarnaast kan CamDiab ook de
                                    volgende gebruikersgegevens vastleggen:
                                </p>
                                <p>
                                    <strong>Gebruiksgegevens</strong>
                                    <br/>
                                    Activiteitsgebeurtenissen waaruit wij kunnen afleiden hoe u onze producten gebruikt.
                                    Hiermee kunnen we zien hoe onze producten worden gebruikt en bijvoorbeeld ook waar
                                    de opzet van menu’s kan worden verbeterd.
                                </p>
                                <h3>
                                    3.2. Doel van productverbetering
                                </h3>
                                <p>
                                    Door de snelle technische vooruitgang is het van belang dat wij onze producten en de
                                    manier waarop ze samenwerken continu analyseren, doorontwikkelen, testen en
                                    verbeteren. Op die manier kunnen wij waarborgen dat onze content zo effectief
                                    mogelijk ten goede komt van onze gebruikers. Om dat te bereiken voeren wij mogelijk
                                    beoordelingen uit van het gebruik, de uitvoerbaarheid, voorspelbaarheid, besturing
                                    en veiligheid en integreren we de kennis die daarbij wordt opgedaan in nieuwe,
                                    verbeterde versies van onze producten, zoals de app. Deze verbeteringen worden aan u
                                    gemeld via regelmatige updates.
                                </p>
                                <p>
                                    CamDiab zet zich in voor de wetenschap van alle aspecten van diabetes. Daarom kunnen
                                    anonieme Gebruikersgegevens ook worden gebruikt voor onderzoek en statistiek (altijd
                                    met inachtneming van de erkende ethische wetenschappelijke normen) en interne
                                    analyses. Dit kan het delen van geanonimiseerde gegevens met derde partijen
                                    omvatten.
                                </p>
                                <h2>
                                    4. VERWERKING VOOR MARKETINGDOELEINDEN
                                </h2>
                                <h3>
                                    4.1. Nieuwsbrief
                                </h3>
                                <p>
                                    4.1.1 Wij sturen u, naast de contractueel verplichte informatie, mogelijk informatie
                                    over producten en diensten (waaronder informatie van zorgvuldig geselecteerde
                                    partners) en uitnodigingen om deel te nemen aan onderzoeken of andere verkoopacties
                                    en marketingactiviteiten (‘<strong>nieuwsbrief</strong>’).
                                </p>
                                <p>
                                    4.1.2 U kunt kiezen of u zich wilt abonneren op onze nieuwsbrief (‘opt-in’). U kunt
                                    uw toestemming op ieder moment intrekken via de instellingen in uw account.
                                </p>
                                <h3>
                                    4.2. Andere vormen van marketing
                                </h3>
                                <p>
                                    4.2.1 Indien nodig wordt er op andere manieren toestemming gevraagd, bijvoorbeeld
                                    voor onderzoeken, meldingen of aangepaste aanbiedingen. Wij leggen u altijd uit
                                    waarom wij bepaalde gegevens nodig hebben en ook hoe u uw toestemming kunt
                                    intrekken.
                                </p>
                                <p>
                                    4.2.2 Houd er rekening mee dat wij u in de app aanbiedingen kunnen tonen zonder dat
                                    wij daarvoor uw persoonsgegevens verwerken. U ziet deze niet-aangepaste advertenties
                                    ook als u geen toestemming heeft gegeven voor de verwerking van gegevens voor
                                    marketingdoeleinden.
                                </p>
                                <h2>
                                    5. GEBRUIK VOOR WETTELIJKE DOELEINDEN
                                </h2>
                                <h3>
                                    5.1. Wetenschappelijk onderzoek en statistieken
                                </h3>
                                <p>
                                    CamDiab zet zich in voor wetenschappelijk onderzoek naar alle aspecten van diabetes.
                                    Om die reden kunnen er geanonimiseerde gebruikersgegevens worden gebruikt voor
                                    wetenschappelijke doeleinden en statistisch onderzoek (waarbij steeds wordt voldaan
                                    aan de binnen de wetenschap erkende ethische standaarden), evenals ten behoeve van
                                    interne analyses. Deze gegevens worden hoofdzakelijk gebruikt voor het bepalen van
                                    de effectiviteit van technieken voor de beheersing en behandeling van diabetes en de
                                    verbetering daarvan. De wettelijke basis daarvoor is artikel 9 (2) j) AVG.
                                </p>
                                <h3>
                                    5.2. Uitoefening van rechten
                                </h3>
                                <p>
                                    Het gebruik van persoonsgegevens kan ook nodig zijn om misbruik door gebruikers
                                    tegen te gaan of om rechtsvorderingen te doen gelden, uit te oefenen of af te weren.
                                    Wij kunnen mogelijk worden verplicht tot openbaarmaking op grond van bindende
                                    wetgeving, rechterlijke of officiële besluitvorming of instructies, strafrechtelijk
                                    onderzoek of publiek belang. In dergelijke gevallen is het opslaan en verwerken van
                                    uw gegevens ook zonder uw toestemming wettelijk toegestaan. De wettelijke basis
                                    daarvoor is artikel 9 (2) f) AVG.
                                </p>
                                <h3>
                                    5.3. In overeenstemming met de wetgeving op het gebied van medische hulpmiddelen
                                </h3>
                                <p>
                                    Ten slotte gelden voor ons, als fabrikant resp. distributeur van een medisch
                                    hulpmiddel, zwaardere eisen ten aanzien van het bewaken van de functionaliteit van
                                    ons product. Dit vigilantiesysteem is noodzakelijk op grond van de geldende
                                    reguleringsvereisten en kan ook inhouden dat er persoonsgegevens moeten worden
                                    verwerkt. De wettelijke basis daarvoor is artikel 9 (2) i) AVG.
                                </p>
                                <h2>
                                    6. ALGEMENE INFORMATIE
                                </h2>
                                <h3>
                                    6.1. Doeleindenbeperking en bescherming
                                </h3>
                                <p>
                                    6.1.1 CamDiab gebruikt uw persoonsgegevens exclusief voor de doeleinden die in deze
                                    gegevensbeschermingsverklaring genoemd worden en volgens de relevante verleende
                                    toestemmingen. Wij garanderen dat de verwerking beperkt blijft tot de omvang die
                                    nodig is voor het doel.
                                </p>
                                <p>
                                    6.1.2 Bij verwerking wordt iedere keer gegarandeerd dat uw persoonsgegevens
                                    voldoende worden beschermd en vertrouwelijk worden behandeld. Hieronder valt de
                                    bescherming tegen ongeoorloofde en illegale verwerking, onbedoeld verlies en
                                    onbedoelde vernietiging of beschadiging, door toepassing van de juiste technische en
                                    organisatorische maatregelen. Wij maken gebruik van strikte interne processen,
                                    veiligheidsvoorzieningen en versleutelingsmethodes.
                                </p>
                                <h3>
                                    6.2. Verwerkers
                                </h3>
                                <p>
                                    6.2.1 Onze producten zijn het resultaat van complexe processen die door ons moeten
                                    worden gestuurd en up-to-date moeten worden gehouden. Daarbij maken wij voor
                                    technische ondersteuning gebruik van de diensten van derden
                                    (‘<strong>verwerkers</strong>’), om u in staat te stellen onze producten volledig en
                                    optimaal te gebruiken.
                                </p>
                                <p>
                                    6.2.2 CamDiab draagt gebruikersgegevens uitsluitend over aan verwerkers binnen het
                                    kader van deze gegevensbeschermingsverklaring en alleen ten behoeve van de
                                    doeleinden die daarin genoemd staan. Verwerkers werken volgens onze specificaties en
                                    instructies; het is hen niet toegestaan om de persoonsgegevens van onze gebruikers
                                    voor hun eigen doeleinden of voor andere doeleinden te gebruiken.
                                </p>
                                <p>
                                    6.2.3 Wij werken samen met verwerkers die ons voldoende waarborgen kunnen bieden dat
                                    er geschikte technische en organisatorische maatregelen worden getroffen, zodat de
                                    verwerking van persoonsgegevens voldoet aan zowel de wettelijke vereisten als aan
                                    onze gegevensbeschermingsverklaring. De bescherming van de gegevens van onze
                                    gebruikers is gewaarborgd door het sluiten van bindende overeenkomsten, die voldoen
                                    aan de strikte vereisten op grond van de AVG.
                                </p>
                                <h3>
                                    6.3. Versleuteling, pseudonimisering en anonimisering
                                </h3>
                                <p>
                                    6.3.1 De overdracht van gegevens verloopt op ieder moment, zonder uitzondering en
                                    standaardmatig, versleuteld. Door gebruik van HTTPS (wat staat voor ‘hypertext
                                    transfer protocol secure’) of vergelijkbare verzendingsprotocollen, waarborgen wij
                                    dat uw gegevens niet worden onderschept door onbevoegde derden.
                                </p>
                                <p>
                                    Daarnaast hanteren wij ten behoeve van gegevensbescherming en om de risico’s tot een
                                    minimum te beperken, ook andere processen voor de versleuteling en pseudonimisering
                                    van gebruikersgegevens. Vanzelfsprekend hangt dit ook af van het type, de omvang en
                                    het doel van de desbetreffende verwerking van gegevens. Wij stellen bijvoorbeeld
                                    alleen gebruikersgegevens beschikbaar die een verwerker nodig heeft om zijn taken
                                    uit te voeren.
                                </p>
                                <p>
                                    6.3.2 Als een contractuele relatie met een verwerker wordt beëindigd, moet de
                                    desbetreffende verwerker op verzoek van CamDiab alle gebruikersgegevens teruggeven
                                    of deze gegevens wissen als er geen wettelijke bewaarplicht meer voor bestaat.
                                </p>
                                <p>
                                    6.3.3 Gegevens waarvoor bij de verwerking geen verwijzing nodig is naar personen
                                    (bijv. voor onderzoeks- en analysedoeleinden) zullen worden geanonimiseerd. Op die
                                    manier wordt voorkomen dat er een verband kan worden gelegd met een specifieke
                                    gebruiker.
                                </p>
                                <h3>
                                    6.4. EU en andere landen
                                </h3>
                                <p>
                                    6.4.1 Wij kiezen bij voorkeur samenwerkingspartners die gevestigd zijn in, of
                                    waarvan de servers opgesteld staan in de Europese Unie (EU) of de Europese
                                    Economische Ruimte (EER). De gegevensoverdracht binnen de EU en de EER kent geen
                                    bezwaren, aangezien in alle lidstaten de AVG van toepassing is.
                                </p>
                                <p>
                                    6.4.2 In uitzonderlijke gevallen werken wij samen met derden/leveranciers die
                                    gevestigd zijn buiten de EU, of waarvan de servers buiten de EU staan opgesteld. In
                                    dergelijke gevallen zijn uw persoonsgegevens echter onderworpen aan een hoog
                                    beschermingsniveau, overeenkomend met dat van de AVG – ofwel door middel van een
                                    EU-adequaatheidsbesluit, een besluit waarbij het gegevensbeschermingsniveau in
                                    sommige derdenlanden adequaat wordt verklaard (bijv. Zwitserland, Israël en Nieuw
                                    Zeeland), of door bepaalde standaardclausules in contracten die door de EU zijn
                                    goedgekeurd en waarop onze contractuele relaties gebaseerd zijn met partners die
                                    optreden als verwerkers, of door middel van vergelijkbare wettelijke instrumenten
                                    die op grond van de AVG zijn goedgekeurd.
                                </p>
                                <p>
                                    6.4.3 Daarnaast waarborgen wij dat onze partners werken met extra
                                    veiligheidsstandaarden, zoals individueel toegepaste veiligheidsmaatregelen en
                                    gegevensbeschermingsvoorzieningen of certificeringen op grond van de AVG. Als
                                    derden/leveranciers bijvoorbeeld gevestigd zijn in de VS, dan dienen zij te voldoen
                                    aan de bepalingen op grond van het zogeheten ‘privacyschild’ (Privacy Shield
                                    Framework), zoals dat is goedgekeurd door de EU, of aan andere, vergelijkbare en
                                    internationaal erkende veiligheidsstandaarden.
                                </p>
                                <h3>
                                    6.5. Cookies
                                </h3>
                                <p>
                                    CamDiab slaat mogelijk zogeheten ‘cookies’ op om u een volledige reeks aan
                                    verschillende functies te kunnen bieden en om het gebruik van onze website
                                    gemakkelijker te maken. Cookies zijn kleine tekstbestandjes die door uw
                                    internetbrowser op uw apparaat worden opgeslagen. Behalve de cookies voor
                                    gebruiksgegevens, zoals die in hoofdstuk 6.6 worden genoemd, dienen onze cookies
                                    voor het gebruik van de website. Als u niet wilt dat er cookies worden gebruikt, dan
                                    kunt u voorkomen dat deze worden opgeslagen, door middel van de desbetreffende
                                    instellingen in uw browser. De meesten van onze cookies worden aan het eind van uw
                                    bezoek gewist of op het moment dat de browser gesloten wordt (zogeheten ‘session
                                    cookies’). Als de cookies niet worden gewist, dan kunt u in uw browser controleren
                                    wanneer ze worden gewist, of u kunt de cookies handmatig wissen.
                                </p>
                                <h3>
                                    6.6. Gebruiksgegevens
                                </h3>
                                <p>
                                    In de app maken wij gebruik van Google Firebase Analytics, een analyseservice voor
                                    apps en websites van Google Inc. (‘Google’). Google is gecertificeerd op grond van
                                    het privacyschild tussen de EU en VS (EU-US Privacy Shield).
                                </p>
                                <h3>
                                    6.7. Bewaren en wissen
                                </h3>
                                <p>
                                    6.7.1 Uw gebruikersgegevens worden opgeslagen op uw apparaat. Deze gegevens worden
                                    ook opgeslagen op servers. Wij maken alleen gebruik van systemen die voldoen aan de
                                    vereisten van de AVG.
                                </p>
                                <p>
                                    6.7.2 Uw gegevens worden opgeslagen op servers in de Europese Unie (EU).
                                </p>
                                <p>
                                    6.7.3 Over het algemeen bewaart CamDiab uw persoonsgegevens voor de duur van de
                                    overeenkomst. In uitzonderlijke gevallen is het mogelijk nodig om de gegevens langer
                                    op te slaan, om zo te kunnen voldoen aan verplichtingen na de contractperiode,
                                    wettelijke verplichtingen ten aanzien van bewaarperiodes of
                                    openbaarmakingsverplichtingen, of om rechtsvorderingen te doen gelden, uit te
                                    oefenen of af te weren (beperkingsperiodes).
                                </p>
                                <h3>
                                    6.8. Minderjarigen
                                </h3>
                                <p>
                                    Minderjarigen van jonger dan 16 jaar mogen onze producten alleen gebruiken met
                                    toestemming van een ouder/voogd (<a href="/nl/terms">Algemene voorwaarden</a>). Dit
                                    heeft ook betrekking op de verwerking van hun persoonsgegevens. Die is alleen legaal
                                    als daarvoor toestemming is verkregen van en in de omvang waarvoor er toestemming is
                                    verleend door de ouder/voogd. In andere gevallen is het gebruik van onze producten
                                    verboden.
                                </p>
                                <h3>
                                    6.9. De functionaris voor gegevensbescherming
                                </h3>
                                <p>
                                    6.9.1 Onze functionaris voor gegevensbescherming is beschikbaar voor al uw vragen
                                    over gegevensbescherming, via <a
                                    href="mailto:dpo@evalian.co.uk">dpo@evalian.co.uk</a>.
                                </p>
                                <p>
                                    6.9.2 De functionaris voor gegevensbescherming is uitgebreid betrokken bij alle
                                    vragen die te maken hebben met de bescherming van de persoonsgegevens van onze
                                    gebruikers. Hij/zij bewaakt als getrainde expert op dit gebied continu onze
                                    verwerking, om zo te zorgen dat uw gebruikersgegevens zo goed mogelijk worden
                                    beschermd.
                                </p>
                                <h2>
                                    7. UW RECHTEN
                                </h2>
                                <h3>
                                    7.1. Herroeping van toestemming
                                </h3>
                                <p>
                                    Als wij uw gebruikersgegevens verwerken op basis van uw toestemming, heeft u het
                                    recht om die toestemming op ieder moment in te trekken. Dat heeft echter geen
                                    invloed op de wettelijkheid van de verwerking die heeft plaatsgevonden voor de
                                    toestemming werd ingetrokken. Wij zullen onze diensten blijven aanbieden als zij
                                    niet afhankelijk zijn van de toestemming die is ingetrokken.
                                </p>
                                <h3>
                                    7.2. <strong>Informatie, correctie en restrictie</strong>
                                </h3>
                                <p>
                                    7.2.1 Iedere gebruiker heeft het recht om informatie op te vragen over de verwerking
                                    van de eigen persoonsgegevens. U kunt daarvoor op ieder moment contact met ons
                                    opnemen via <a
                                    href="mailto:privacy@camdiab.com">privacy@camdiab.com</a>.
                                </p>
                                <p>
                                    7.2.2 Uw recht op informatie omvat informatie over de verwerkingsdoeleinden, de
                                    gegevens die worden verzameld en de categorieën van ontvangers, de bewaartermijn, de
                                    oorsprong van uw gegevens en uw rechten op grond van de
                                    gegevensbeschermingsrichtlijnen. U vindt al die informatie in deze
                                    gegevensbeschermingsverklaring en wij stellen u de informatie op verzoek graag ter
                                    beschikking in elektronische vorm.
                                </p>
                                <p>
                                    7.2.3 Als uw persoonsgegevens gedeeltelijk onjuist zijn, dan kunt u op ieder moment
                                    verzoeken om uw gegevens te laten corrigeren of aanvullen. U heeft het recht om de
                                    verwerking van uw gegevens te beperken voor de duur van een door u aangevraagd
                                    herzieningsonderzoek.
                                </p>
                                <h3>
                                    7.3. Wissen (‘recht op vergetelheid’)
                                </h3>
                                <p>
                                    Iedere gebruiker heeft het recht om de eigen persoonsgegevens te laten wissen. U
                                    kunt daarvoor op ieder moment contact met ons opnemen via <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    7.4. Klachten
                                </h3>
                                <p>
                                    7.4.1 Als u van mening bent dat wij uw gegevensbeschermingsrechten niet op de juiste
                                    manier beschermen, dan kunt op ieder moment contact opnemen via <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a> of met onze functionaris
                                    gegevensbescherming, via <a
                                    href="mailto:dpo@evalian.co.uk">dpo@evalian.co.uk</a>. Wij nemen uw verzoek op de
                                    juiste manier in behandeling.
                                </p>
                                <p>
                                    7.4.2 Iedere gebruiker heeft het recht om een klacht in te dienen bij de
                                    gegevensbeschermingsautoriteit die verantwoordelijk is voor CamDiab, als hij of zij
                                    van mening is dat de verwerking van de eigen persoonsgegevens niet overeenkomst met
                                    de gegevensbeschermingsrichtlijnen. Daarnaast heeft de gebruiker het recht om een
                                    klacht in te dienen bij een toezichthouder in de EU-lidstaat waar de gebruiker
                                    gevestigd of werkzaam is of waar de vermeende schending heeft plaatsgevonden.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

