/**
 * Texts for Support page
 */
export const supportPageText = {
    title: 'Support - CamAPS FX',
    titleMeta: 'Vi erbjuder omfattande support för dig som använder CamAPS FX',
    descriptionMeta: 'Vi tillhandahåller både supportmaterial som bruksanvisningar och vägledning för att hämta appen, webbinarier och kundsupport via telefon och e-post.',
    keywords:
        'stöd för camps, stöd för camps fx,',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'sv_se',

    needHelp: 'Behöver du hjälp?',
    needHelpResponse: 'Vi hoppas att du ska hitta svar på dina frågor i supportmaterialet som vi tillhandahåller här. Om inte är du varmt välkommen att kontakta oss.',
    clinicalSupportHeader: 'Medicinsk hjälp',
    clinicalSupportText: 'Om du har frågor om din vård ska du kontakta ditt diabetesteam.',
    techSupportHeader: 'Teknisk och utbildningsrelaterad support',
    techSupportHeaderText: 'Här hittar du supportmaterial och kontaktuppgifter om du behöver komma i kontakt med vårt supportteam.',
    techMaterialHeader: 'Tekniskt material:',
    userManual: 'CamaPS FX bruksanvisning',
    userManualEnglish_mmol_l: 'English',
    userManualCzech_mmol_l: 'Česky',
    userManualDanish_mmol_l: 'Dansk',
    userManualGerman_mg_dl: 'Deutsch',
    userManualGerman_mmol_l: 'Deutsch',
    userManualSpanish_mg_dl: 'Español',
    userManualFrench_mmol_l: 'Français',
    userManualFrench_mg_dl: 'Français',
    userManualItalian_mg_dl: 'Italiano',
    userManualItalian_mmol_l: 'Italiano',
    userManualDutch_mg_dl: 'Nederland',
    userManualDutch_mmol_l: 'Nederland',
    userManualNorsk_mmol_l: 'Norsk',
    userManualPolish_mg_dl: 'Polski',
    userManualFinish_mmol_l: 'Suomi',
    userManualSwedish_mmol_l: 'Svenska',

    previousManualVersions: 'Tidigare versioner',
    installationManualHeader: 'Installera CamAPS FX (DANA)',
    installationManualEnglish: 'Installationsguide',
    installationManualGerman: 'Installationsanleitung',
    installationManualFrench: 'Guide d\'installation',
    educationalMaterial: 'Utbildningsmaterial:',
    onlineTraining: 'Webbaserad utbildning:',
    onlineTrainingText: 'Tillhandahålls av Cambridge Diabetes Education Programme',
    onlineTrainingLink: 'https://www.camdiabtraining.com/account-sign-in.html',
    onlineTrainingText_1: 'KOSTNADSFRI webbaserad utbildning med legitimerad utbildare',
    webinarsHeading: 'Webbinarier',
    webinar_1: 'Introduktion till CamAPS FX-systemet',
    webinar_2: 'Starta CamAPS FX-systemet',
    webinar_3: 'Optimera inställningarna för CamAPS FX',
    webinar_4: 'Utbildning för lärare och annan skolpersonal',
    webinar_5: 'Finjustering för att förbättra tid i målområdet',
    webinar_6: 'CamAPS FX under graviditet',
    webinar_7: 'CamAPS FX för barn i olika åldrar',
    webinar_8: 'CamAPS FX för ungdomar och vuxna',
    webinar_9: 'Hantera fysiska aktiviteter, måltider och andra händelser',
    webinar_10: 'Livskvalitet vid insulinbehandling med closed-loop',
    webinar_11: 'Behandling med closed-loop vid mångårig diabetes typ 1',
    webinar_footnote: '',

    contactDetailsHeader: 'Kontaktuppgifter:',
    contactDetailsText: 'Vårt supportteam kan nås under kontorstid måndag–fredag 10.00–18.00.',
    contactDetailsEmailTitle: 'Mejla till oss',
    contactDetailsEmail: 'support@camdiab.com',
    contactDetailsEmailFull: 'mailto:support@camdiab.com',
    contactDetailsPhoneTitle: 'Kontakta oss',
    contactDetailsPhone: '+44-20-3695 3780 (bara engelska)',
    contactDetailsPhoneFull: 'tel:+442036953780',

    eIfuAccess: 'Tryckta kopior av användarmanualerna kan begäras från kundsupport. ' +
        'Tryckta kopior är kostnadsfria och kommer inom cirka 3 till 7 dagar. För bästa ' +
        'resultat, använd Adobe<sup>®</sup> Reader<sup>®</sup> för att se CamDiabs användarmanualer.',

    statusPageText: 'Statussida',
    statusPageLink: 'https://camdiabltd.statuspage.io/',

    installationYpsoPumpHeader: 'Installera CamAPS FX (YpsoPump)',
    installationYpsoPumpText: 'Google Play',
    installationYpsoPumpLink: 'https://play.google.com/store/search?q=camaps%20fx&c=apps',

    danaPumpSupportText: 'Dana pump användare',
    danaPumpSupportTextUK: 'Dana pump users in the UK',
    danaPumpSupportLinkUK: 'https://advancedtherapeutics.org.uk/contact-us/',
    ypsoPumpSupportText: 'YpsoPump användare',
    ypsoPumpSupportLink: 'https://www.mylife-diabetescare.com/sv-SE/tjaenster/kundsupport-och-kontakt.html',
};
