/**
 * Texts for FAQs page
 */
export const faqPageText = {
    title: 'Ofte stillede spørgsmål (FAQ) – CamAPS FX',
    titleMeta: 'Har du et spørgsmål til CamAPS FX?',
    descriptionMeta: 'Her er svarene på nogle af de oftest stillede spørgsmål om kompatible enheder, myndighedsgodkendelse, tilgængelighed og klinisk forskning',
    keywords:
        'camps faqs, camps fx faqs, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'da_dk',

    haveQuestion: 'Har du et spørgsmål til CamAPS FX?',
    hereAnswers: 'Her er svarene på nogle af de oftest stillede spørgsmål.',
    whatIsCamAPS: 'Hvad er CamAPS FX?',
    whatIsCamAPSAnswer: 'CamAPS FX er en avanceret og brugervenlig app til styring af glukoseniveauer hos mennesker fra 1 år og opefter med type 1-diabetes via et avanceret, adaptivt lukket hybridsystem.',
    isApproved: 'Er CamAPS FX godkendt til brug?',
    isApprovedAnswer: 'Appen er blevet grundigt testet i kliniske forsøg og godkendt til brug i bl.a. Storbritannien og EU.',
    isAvailable: 'Er CamAPS FX tilgængelig i mit område?',
    isAvailableAnswer1: 'CamAPS FX er tilgængelig på udvalgte diabetesklinikker eller for personer, som betaler selv og gennemgår onlineoplæring. Spørg på din klinik, eller gå til',
    isAvailableAnswer2: 'Ordrer',
    isAvailableAnswer3: 'for at bestille appen.',
    cost: 'Hvad koster CamAPS FX?',
    costAnswer1: 'Oplysninger om priser kan findes',
    costAnswer2: 'på hjemmesiden.',
    compatibleCgm: 'Hvilke CGM er er kompatible med CamAPS FX?',
    compatibleCgmAnswer: 'CamAPS FX er kompatibel med',
    compatibleCgmAnswer1: 'og',
    compatibleCgmAnswer2: '. Klik på linkene for at se kompatible smartphones og operativsystemer.',
    whatPumps: 'Hvilke insulinpumper er kompatible med CamAPS FX?',
    whatPumpsAnswer1: 'CamAPS FX er kompatibel med',
    whatPumpsAnswer2: 'mylife YpsoPump-, Dana Diabecare RS- og DANA-i-',
    whatPumpsAnswer3: 'insulinpumper.',
    whatPumpsAnswerHtml: 'https://advancedtherapeutics.org.uk/shop/dana-diabecare-insulin-pump/dana-rs-insulin-pump-2018',
    whatPhone: 'Hvilke smartphones er kompatible med CamAPS FX?',
    whatPhoneAnswer: 'CamAPS FX kører på en Android-telefon og er kompatibel med Android-smartphone-modeller, der understøttes af din CGM-producent.',

    whatPhoneAnswer1: '',
    whatPhoneAnswer2: 'Klik',
    whatPhoneAnswer3: 'venligst på dette link for en liste over enheder og operativsystemer, der er kompatible',
    whatPhoneAnswerDexcom: 'med Dexcom G6-sensoren og Dexcom G6-appen.',
    whatPhoneAnswerLibre3: 'med FreeStyle Libre 3-sensoren og FreeStyle Libre 3-appen.' +
        '<p><p></p>' +
        'Af sikkerhedsmæssige årsager vil CamAPS FX fra den 1. januar 2025 kun blive understøttet på sensor-kompatible telefoner med Android-operativsystemer 10 og derover. Brugere af operativsystemer under OS 10 vil ikke længere kunne opdatere eller geninstallere CamAPS FX efter denne dato.',
    whatPhoneAnswerDexcomHtml:'https://www.dexcom.com/compatibility',
    whatPhoneAnswerLibreHtml:'https://www.diabetescare.abbott/support/manuals.html',

    whereUpload: 'Hvor kan jeg uploade mine data fra CamAPS FX?',
    whereUploadAnswer: 'I øjeblikket kan data fra CamAPS FX uploades til Glooko.',
    whatLanguage: 'Hvilke sprog er tilgængelige i CamAPS FX?',
    whatLanguageAnswer: 'CamAPS FX er tilgængelig på engelsk, tjekkisk, dansk, tysk, spansk, fransk, italiensk, hollandsk, polsk, finsk og svensk.',
    whereResults: 'Hvor kan jeg finde resultaterne af de kliniske forsøg?',
    whereResultsAnswer1: 'Publikationer vedrørende brug af Cambridge-kontrolalgoritmen kan downloades',
    whereResultsAnswer2: 'her.',
    whereResultsAnswer3: 'Vigtige publikationer er offentliggjort i',
    whereResultsAnswer4: 'og',
};
