/**
 * Texts for Notifications page
 */
export const notificationsPageText = {
    title: 'Latest in-app notifications',
    titleMeta: 'Shows recent in-app notifications from CamAPS FX',
    descriptionMeta: 'Here you will find the text of the latest in-app notifications for app updates, security notifications and user manual updates',
    keywords: 'Notifications' ,
    localeMeta: 'en_gb',

    heading: 'Latest in-app notifications',
    subheading: '',
	
	header_34:'App Update 09/12/2024',
    body_34:'Dear mylife CamAPS FX user, a new app version will be available in the next 48 hours. To choose your preferred update time, deactivate the automatic update for CamAPS FX in the Google Play Store. If you have any questions, please contact Ypsomed customer service',
	
	header_33:'Notice of Planned Maintenance 05/12/2024',
    body_33:'Due to scheduled maintenance of the CamAPS FX Service cloud, the Companion functionality will be unavailable on 7th December from 10:00 GMT for approximately three hours. We apologise for any inconvenience this may cause.',
	
	header_32:'User manual update 21/11/2024',
    body_32:'An updated version of the user manual is available.',
	
	header_31:'App Update 17/11/2024',
    body_31:'Dear mylife CamAPS FX user, a new app version will be available in the next 48 hours. To choose your preferred update time, deactivate the automatic update for CamAPS FX in the Google Play Store. If you have any questions, please contact Ypsomed customer service',
	
	header_30:'OS 8 & 9 11/10/2024',
    body_30:'For Cybersecurity reasons, from the 1st January 2025, mylife CamAPS FX will only be supported on sensor-compatible phones running Android operating systems 10 and above. Users of operating systems below OS 10, will no longer be able to update or reinstall mylife CamAPS FX after this date',

	header_29:'App Update 01/09/2024',
    body_29:'Dear mylife CamAPS FX user, a new app version will be available in the next 48 hours. To choose your preferred update time, deactivate the automatic update for CamAPS FX in the Google Play Store. If you have any questions, please contact Ypsomed customer service',
	
	header_28:'OS 8 & 9 08/07/2024',
    body_28:'For Cybersecurity reasons, from the 1st January 2025, mylife CamAPS FX will only be supported on sensor-compatible phones running Android operating systems 10 and above. Users of operating systems below OS 10, will no longer be able to update or reinstall mylife CamAPS FX after this date',
	
	header_27:'App Update 13/05/2024',
    body_27:'(UK Only) Dear mylife CamAPS FX user, a new app version will be available in the next 24 hours. To choose your preferred update time, deactivate the automatic update for CamAPS FX in the Google Play Store. If you have any questions, please contact Ypsomed customer service',
	
	header_26:'User manual update 11/05/2024',
    body_26:'An updated version of the user manual is available.',
	
	header_25:'',
    body_25:'',
	
	header_24:'App Update 30/04/2024',
    body_24:'(Australia only) Dear mylife CamAPS FX user, a new app version will be available in the next 24 hours. To choose your preferred update time, deactivate the automatic update for CamAPS FX in the Google Play Store. If you have any questions, please contact Ypsomed customer service.',
	
	header_23:'App Update 24/04/2024',
    body_23:'Dear mylife CamAPS FX user, We are sorry some users were still experiencing difficulties with the latest update.  A new app version 1.4(181) will be made available in the next 24 hours.  To choose your preferred time to update, disable auto-updating in the Google Play Store. If you have any questions, please contact Ypsomed customer service. ',
	
	header_22:'User manual update 13/04/2024',
    body_22:'An updated version of the user manual is available.',
	
	header_21:'App Update 28/03/2024',
    body_21:'Dear mylife CamAPS FX user, With the Easter break approaching for many of our valued users, we want to take this opportunity to inform that we are working on the next app update and will provide further details on the progress and expected release date after Easter. Wishing you a wonderful Easter celebration! Your CamDiab team.',
	
	header_20:'App Update 25/03/2024',
    body_20:'Dear mylife CamAPS FX user,  We know some of you are still experiencing issues with the latest update. We are currently extending the testing of a fix to a wider audience. As soon as it\'s ready to be deployed to the Google Play Store we will let you know.  Thank you for your patience, Your CamDiab team',
	
	header_19:'App Update 21/03/2024',
    body_19:'Some users are still experiencing issues with the current mylife CamAPS FX version, which is why we are finalising the bug fix release 1.4(180). You will be notified very soon with the planned update release, and we apologise for any inconvenience this may cause.',
	
	header_18:'App Update 17/03/2024',
    body_18:'We are sorry for the continued problems some users may be experiencing with the latest release. A new update (1.4(179)) will be available on Monday. To choose your preferred time to update, disable auto-updating in the Google Play Store. Please re-link your Glooko account in the share menu if you experience a loss of data in Glooko.',
	
	header_17:'App update 11/03/2024',
    body_17:'We are sorry for the continued problems some users may be experiencing with the latest release. A new update (1.4(178)) will be available tomorrow to resolve these issues. To choose your preferred time to update, disable auto-updating in the Google Play Store.',
	
	header_16:'User manual update 04/03/2024',
    body_16:'An updated version of the user manual is available.',
	
	header_15:'User manual update 04/01/2024',
    body_15:'An updated version of the user manual is available.',
	
	header_14:'Android OS 14 22/11/2023',
    body_14:'Reminder: To ensure uninterrupted use of the mylife CamAPS FX application, ' +
        'please check the sensor manufacturer\'s website to verify smartphone and operating system compatibility ' +
        'before updating to Android OS 14. Note: Automatic updates might be turned on in your phone\'s settings by default.',
	
	header_13:'IMPORTANT SAFETY INFORMATION 14/11/2023',
    body_13:'Please read and confirm reading of important safety notice',
    body_13_link_text:'N-069',
    body_13_end: '. Go to app Menu -> Safety notices.',
    body_13_link:'https://s3.eu-west-1.amazonaws.com/camaps.safety.notices/Field+Safety+Notice+ET5604+mylife+Users+final.pdf',

	header_12:'User manual update 10/11/2023',
    body_12:'An updated version of the user manual is available.',

	header_11:'Android OS 14 12/09/2023',
    body_11:'To ensure uninterrupted use of the CamAPS FX application, please check the sensor manufacturer\'s website to verify smartphone and operating system compatibility before updating to Android OS 14. Note: Automatic updates might be turned on in your phone\'s settings by default. Please ensure you are running CamAPS FX app version 1.4(173).',
	
	header_10:'IMPORTANT SAFETY INFORMATION 15/08/2023',
    body_10:'Please read and confirm reading of important safety notice N-065. Go to app Menu -> Safety notices.',
	
	header_9:'User manual update 27/07/2023',
    body_9:'An updated version of the user manual is available.',
	
	header_8:'Compatability with Glooko 21/07/2023',
    body_8:'As announced earlier this year, diasend will be discontinued on the 28th July – If you wish to keep sharing data with your diabetes team, please upgrade your diasend account to Glooko or create a new Glooko account. Please update to the latest CamAPS FX version, enter your new Glooko username and password in the share menu and restart your smartphone. Diasend will remain available for viewing historical data for the remainder of the year..',
	
	header_7:'Samsung A-Series phones 14/07/2023',
    body_7:'Users of Samsung A-Series models (i.e. A53, A33) might have experienced signal loss to your CGM-Sensor, following the latest Samsung One UI 5.1 update. Samsung has identified the problem and a solution is in development. Samsung plans to release an update to One UI on July 24th (depending on your region), which will resolve the signal loss problem. It is recommended to install the update when it becomes available. If you have not yet updated to One UI 5.1, we recommend waiting for the next Samsung One UI update..',

    header_6:'',
    body_6:'',

    header_5:'Compatability with Glooko 21/06/2023',
    body_5:'CamAPS FX is compatible with Glooko. Please update your CamAPS FX app to 1.4(172). If you share data with a clinic, you will be notified in your existing diasend account when your clinic upgrades to Glooko. Once the upgrade is complete, enter your new Glooko username and password in the share menu and restart your smartphone. Please upgrade to Glooko before July 28, 2023. Diasend will be available for the rest of the year to view historical data.',

    header_4:'User manual update 27/05/2023',
    body_4:'An updated version of the user manual is available.',

    header_3:'SMS Warning (Ypsomed only) 11/05/2023',
    body_3:'You asked, we listened You can now have up to 5 SMS-Followers active AND use the Companion mode at the same time. To use Companion mode, the users phone must have an internet connection to share data.  Users must have a SIM-Card to allow SMS to be sent from their phone. We advise you to check your mobile plan includes SMS coverage.',

    header_2:'Cybersecurity Update (Ypsopump only) 24/04/2023',
    body_2:'Security of the mylife CamAPS FX app is of paramount importance. From mid-May, there will be a Google Play Store enforced cybersecurity update. Following this update, a small proportion of phones may fail the cybersecurity integrity check. You can test your phone in advance by visiting the help menu and completing the check. If your phone fails this check try uninstalling and reinstalling the app.',

    header_1:'Compatability with Glooko 14/04/2023',
    body_1:'mylife CamAPS FX is now compatible with Glooko. Please update your mylife CamAPS FX app. Log in to your Diasend account on a web browser and follow the upgrade process as Diasend is merging with Glooko. Once successfully completed, please restart your phone to automatically send your data to Glooko. Diasend will be available for the rest of the year to view previous data. Please upgrade to Glooko before June 14, 2023 if you are using Diasend. Note: If you change your password during the upgrade process, you will also need to update the password in mylife CamAPS FX in the "Share" menu.',
};
