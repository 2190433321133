/**
 * Texts for FAQs page
 */
export const faqPageText = {
    title: 'FAQ - CamAPS FX',
    titleMeta: 'Hai una domanda su CamAPS FX?',
    descriptionMeta: 'Ecco qui le risposte ad alcune delle domande più frequenti sui dispositivi compatibili, sull’approvazione regolatoria, sulla disponibilità e sulla ricerca clinica',
    keywords:
        'camaps hai una domanda, camaps fx hai una domanda, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'it',

    haveQuestion: 'Hai una domanda su CamAPS FX?',
    hereAnswers: 'Ecco qui le risposte ad alcune delle domande più frequenti.',
    whatIsCamAPS: 'Che cos’è CamAPS FX?',
    whatIsCamAPSAnswer: 'CamAPS FX è un’app mobile elaborata e facile dk usare, per gestire i livelli della glicemia nelle persone con diabete di tipo 1, a partire dk 1 anno di età, basata su un approccio terapeutico ibrido a sistema chiuso, avanzato e adattativo.',
    isApproved: 'CamAPS FX è approvata per l’uso?',
    isApprovedAnswer: 'L’app è stata ampiamente testata in studi clinici e approvata per l’uso nel Regno Unito e nella UE.',
    isAvailable: 'CamAPS FX è disponibile nella mia zona?',
    isAvailableAnswer1: 'CamAPS FX è disponibile presso centri diabetologici selezionati oppure per soggetti autopaganti che eseguono l’addestramento online. Ti invitiamo a consultare il tuo centro clinico oppure ad accedere alla sezione',
    isAvailableAnswer2: 'Ordini',
    isAvailableAnswer3: 'per ordinare l’app.',
    cost: 'Quanto costa CamAPS FX?',
    costAnswer1: 'Per i costi, visita la sezione',
    costAnswer2: 'del sito web.',
    compatibleCgm: 'Quali sistemi CGM sono compatibili con CamAPS FX?',
    compatibleCgmAnswer: 'CamAPS FX è compatibile con il',
    compatibleCgmAnswer1: 'e l’',
    compatibleCgmAnswer2: '. Fare clic sui link per visualizzare gli smartphone e i sistemi operativi compatibili.',
    whatPumps: 'Quali microinfusori sono compatibili con CamAPS FX?',
    whatPumpsAnswer1: 'CamAPS FX è compatibile con',
    whatPumpsAnswer2: 'i microinfusori mylife YpsoPump, Dana Diabecare RS e DANA-i.',
    whatPumpsAnswer3: ' ',
    whatPumpsAnswerHtml: 'https://advancedtherapeutics.org.uk/shop/dana-diabecare-insulin-pump/dana-i-insulin-pump',
    whatPhone: 'Quali smartphone sono compatibili con CamAPS FX?',
    whatPhoneAnswer: 'CamAPS FX funziona su un telefono Android ed è compatibile con i modelli di smartphone Android supportati dal produttore del CGM.',

    whatPhoneAnswer1: 'Fare',
    whatPhoneAnswer2: 'clic',
    whatPhoneAnswer3: 'su questo collegamento per un elenco di dispositivi e sistemi operativi compatibili con',
    whatPhoneAnswerDexcom: 'il sensore DexcomG6 e l\'app Dexcom G6.',
    whatPhoneAnswerLibre3: 'il sensore FreeStyle Libre 3 e l\'app FreeStyle Libre 3.' +
        '<p><p></p>' +
        'Dal 1º gennaio 2025, CamAPS FX sarà supportato solo su telefoni in lista di compatibilità del sensore e con sistemi operativi Android 10 e superiori. Gli utenti dei sistemi operativi inferiori a OS 10 non potranno più aggiornare o reinstallare CamAPS FX dopo questa data.',
    whatPhoneAnswerDexcomHtml:'https://www.dexcom.com/compatibility',
    whatPhoneAnswerLibreHtml:'https://www.diabetescare.abbott/support/manuals.html',

    whereUpload: 'Dove posso caricare i miei dati di CamAPS FX?',
    whereUploadAnswer: 'Attualmente, i dati di CamAPS FX possono essere caricati in Glooko.',
    whatLanguage: 'Quali lingue sono supportate dk CamAPS FX?',
    whatLanguageAnswer: 'CamAPS FX è disponibile in inglese, ceco, danese, tedesco, spagnolo, francese, italiano, olandese, polacco, finlandese e svedese.',
    whereResults: 'Dove posso trovare i risultati dei vostri studi clinici?',
    whereResultsAnswer1: 'Le pubblicazioni riguardanti l’uso dell’algoritmo di controllo Cambridge possono essere scaricate',
    whereResultsAnswer2: 'qui.',
    whereResultsAnswer3: 'Le pubblicazioni principali sono state pubblicate nelle riviste',
    whereResultsAnswer4: 'e',
};
