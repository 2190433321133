import React, {Component} from 'react';
import {Header} from './Header';
import {Footer} from './Footer';
import {Helmet} from "react-helmet";

export class PrivacyPL extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Polityka prywatności - CamAPS FX</title>
                    <meta name="title" content='Polityka prywatności'/>
                    <meta name="description"
                          content='Tutaj znajdziesz politykę prywatności CamDiab'/>
                    <meta property="og:locale" content='pl_pl'/>
                    <meta name="keywords" content='polityka prywatności'/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10">
                                <h1>Polityka prywatności</h1>
                                <h3>Wersja z dnia 4 grudnia 2024</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <h2>
                                    1. WPROWADZENIE
                                </h2>
                                <h3>
                                    1.1. Podmiot odpowiedzialny
                                </h3>
                                <p>
                                    Wskazanym podmiotem odpowiedzialnym zgodnie z przepisami dotyczącymi ochrony danych
                                    jest firma CamDiab Limited, zarejestrowany adres siedziby: 20-22 Wenlock Road,
                                    London, N1 7GU, Wielka Brytania, zarejestrowana w Anglii i Walii pod numerem
                                    11659211 (zwana dalej „CamDiab”). Firma CamDiab podejmuje decyzje w sprawie celu i
                                    środków przetwarzania danych osobowych użytkowników („<strong>Dane
                                    Użytkowników</strong>”) i w związku z tym ponosi odpowiedzialność za ich
                                    bezpieczeństwo i zgodność z obowiązującymi przepisami prawa.
                                </p>
                                <p>
                                    1.1.2 Jako podmiot odpowiedzialny, firma CamDiab podlega, na przykład, wymogom
                                    informacyjnym, które zamierza spełnić w związku z niniejszą informacją o polityce
                                    prywatności.
                                </p>
                                <h3>
                                    1.2. Struktura i pojęcie zgody
                                </h3>
                                <p>
                                    1.2.1 Niniejsza informacja o polityce prywatności informuje o celu i zakresie
                                    przetwarzania Danych Użytkownika, a także o przekazywaniu danych oraz szerokich
                                    uprawnieniach Użytkownika. Jako że nasza oferta jest skierowana wyłącznie do osób z
                                    cukrzycą, korzystanie z niej zwykle już powoduje przekazanie informacji na temat
                                    stanu zdrowia Użytkownika. Dlatego przetwarzamy Dane Użytkownika wyłącznie jako dane
                                    o stanie zdrowia za jego zgodą. Wyróżniamy następujące pojęcia:
                                </p>
                                <p>
                                    1.2.1.1 „Niezbędne przetwarzanie” — opisuje sposób przetwarzania Danych Użytkowania
                                    wymaganych do realizacji umowy. Bez tej zgody korzystanie z naszych produktów nie
                                    będzie możliwe z punktu widzenia prawnego i faktycznego, ponieważ świadczenie usług
                                    opiera się na tym przetwarzaniu.
                                </p>
                                <p>
                                    1.2.1.2 „Przetwarzanie w celu ulepszenia Produktu” — wyjaśnia, w jaki sposób zgoda
                                    Użytkownika może pomóc firmie i innym użytkownikom, umożliwiając wykorzystanie
                                    danych Użytkownika w szczególności do ulepszenia algorytmów zarządzania leczeniem,
                                    ulepszenia produktu itp. bez konieczności kontaktowania się z Użytkownikiem.
                                </p>
                                <p>
                                    1.2.1.3 „Przetwarzanie dla celów marketingowych” — opisuje sposób, w jaki firma
                                    będzie kontaktować się z Użytkownikiem w celach marketingowych, za zgodą
                                    Użytkownika, np. przez e-mail, powiadomienia itp.
                                </p>
                                <p>
                                    1.2.1.4 „Informacje ogólne” — zbiór informacji dotyczących wszystkich powyższych
                                    zgód, mający na celu uniknięcie powtórzeń.
                                </p>
                                <p>
                                    Poszczególne kategorie omówiono bardziej szczegółowo poniżej. Podczas rejestracji
                                    lub zamówienia produktu Użytkownik zobowiązany jest wyrazić odpowiednią zgodę.
                                </p>
                                <p>
                                    1.2.2 W niektórych przypadkach przetwarzanie może odbywać się niezależnie od zgody
                                    na zasadach ustawowych (np. na podstawie przepisów dotyczących wyrobów medycznych).
                                </p>
                                <h2>
                                    2. NIEZBĘDNE PRZETWARZANIE
                                </h2>
                                <p>
                                    W przypadku udzielenia zgody przez Użytkownika, przetwarzamy Dane Użytkownika podane
                                    poniżej w celu świadczenia usług. Wyrażenie zgody na niezbędne przetwarzanie danych
                                    jest wymagane do korzystania z usług firmy CamDiab. Użytkownik może wyrazić różne
                                    zgody w trakcie procesu rejestracji lub zamawiania produktu.
                                </p>
                                <h3>
                                    2.1. Niezbędne Dane Użytkownika
                                </h3>
                                <p>
                                    2.1.1 W celu zapewnienia ochrony Danych Użytkownika, korzystanie z naszych usług
                                    możliwe jest wyłącznie w powiązaniu z kontem użytkownika. Aby utworzyć konto
                                    użytkownika, wymagamy podania następujących Danych Użytkownika, które przetwarzamy:
                                </p>
                                <ul type="disc">
                                    <li>
                                        Adres e-mail
                                    </li>
                                    <li>
                                        Hasło (zapisujemy wyłącznie sumę kontrolną)
                                    </li>
                                    <li>
                                        Imię i nazwisko
                                    </li>
                                    <li>
                                        Oświadczenie o zwolnieniu z podatku VAT (imię i nazwisko, adres, data, w
                                        przypadku samofinansowania)
                                    </li>
                                    <li>
                                        Rok urodzenia
                                    </li>
                                    <li>
                                        Data rejestracji
                                    </li>
                                    <li>
                                        Stan zgody
                                    </li>
                                    <li>
                                        Identyfikator urządzenia, producent, rodzaj urządzenia, wersja systemu
                                        operacyjnego, wersja aplikacji
                                    </li>
                                    <li>
                                        Język, kraj, strefa czasowa.
                                    </li>
                                </ul>
                                <p>
                                    2.1.2 Inne informacje gromadzone poza rejestracją użytkownika obejmują:
                                </p>
                                <p>
                                    <strong>Podstawowe dane medyczne </strong>
                                    <br/>
                                    Masa ciała, docelowy zakres stężenia glukozy we krwi, ustawienia podstawowe pompy,
                                    współczynniki korekcji, stosunek węglowodanów do insuliny, czas działania insuliny,
                                    docelowe stężęnie glukozy, ustawienia alarmów, krok bolusa, jednostka węglowodanowa,
                                    status ciąży, przewidywana data porodu.
                                </p>
                                <p>
                                    <strong>Dane o użytkowaniu i połączeniu</strong>
                                    <br/>
                                    Numer seryjny pompy i nadajnika, ścieżka działania pompy, glukometru i pętli
                                    zamkniętej/otwartej, ścieżka przepływu poleceń aplikacji, ścieżka zdarzeń,
                                    informacje o osobach obserwujących wprowadzone do aplikacji (numer telefonu,
                                    poświadczenie kont w portalach dotyczących danych o cukrzycy), zapytania o wsparcie,
                                    dane trenera, identyfikator kliniki diabetologicznej.
                                </p>
                                <p>
                                    <strong>Dane medyczne</strong>
                                    <br/>
                                    Wpisy w aplikacji, takie jak data / godzina / strefa czasowa / miejsce, spożycie
                                    pokarmów/posiłki, pomiary stężenia glukozy we krwi i z glukometru, tempo zmiany
                                    stężenia glukozy z sensora oraz we krwi, całkowita dzienna dawka insuliny, podanie
                                    insuliny (bolus i baza), zmiana tymczasowej dawki podstawowej, stan pętli
                                    zamkniętej, wypełnienie zbiornika pompy i kaniuli, alarmy, zdarzenia zwiększania i
                                    zmniejszania, ustawienia aplikacji, takie jak opcje wyświetlania, statystyczne
                                    podsumowanie danych dotyczących glukozy i insuliny.
                                </p>
                                <p>
                                    2.1.3 Aplikacja może wymagać dostępu do Twojej lokalizacji wyłącznie w celu
                                    umożliwienia łączności Bluetooth Low Energy (BLE), zgodnie z wymaganiami uprawnień
                                    systemu operacyjnego. Jednak żadne dane o lokalizacji nie są zbierane, przechowywane
                                    ani udostępniane przez aplikację.
                                </p>
                                <p>
                                    2.1.4 Użytkownik może wprowadzić adres e-mail, założony specjalnie do kontaktu z
                                    firmą, musi on jednak być prawidłowy, by możliwe było wysyłanie ważnych ostrzeżeń.
                                </p>
                                Przetwarzamy wyłącznie Dane Użytkownika, które zostały z inicjatywy Użytkownika i
                                dobrowolnie podane firmie CamDiab lub które uzyskano z glukometru i pompy insulinowej, z
                                którą dobrowolnie utworzono połączenie. Wprowadzenie niezbędnych Danych Użytkownika jest
                                jednak wymogiem umożliwiającym kompleksowe korzystanie z naszych produktów.
                                <h3>
                                    2.2. Niezbędne cele
                                </h3>
                                <p>
                                    2.2.1 Wszelkie niezbędne cele przetwarzania powiązane są ze świadczeniem usług:
                                </p>
                                <p>
                                    <strong>Zapytanie klienta </strong> prowadzi do rejestrowania szczegółowych danych
                                    do kontaktu.
                                </p>
                                <p>
                                    <strong>Zamawianie </strong> aplikacji przez osoby samofinansujące prowadzi do
                                    rejestrowania danych do kontaktu oraz danych technicznych, np. identyfikatora
                                    urządzenia.
                                </p>
                                <p>
                                    <strong>Instalacja </strong> aplikacji prowadzi do rejestrowania danych technicznych
                                    i powiązanych z urządzeniem.
                                </p>
                                <p>
                                    <strong>Rejestracja </strong> prowadzi do utworzenia konta CamDiab z użyciem adresu
                                    e-mail i hasła.
                                </p>
                                <p>
                                    <strong>Świadczenie usług</strong> wymaga od Użytkownika dobrowolnego wprowadzenia
                                    lub przekazania danych, a każda funkcja opisuje cel, dla którego dane są wymagane.
                                </p>
                                <p>
                                    Komunikacja od firmy CamDiab lub dystrybutora w ramach aplikacji lub za
                                    pośrednictwem innych usług komunikacji elektronicznej (np. e-mail, komunikator,
                                    telefon), jeśli jest to konieczne dla wsparcia produktów i rozwiązywania problemów.
                                    W ten sposób przetwarzane są uwagi i zapytania Użytkownika przesyłane przez różne
                                    kanały komunikacji podczas korzystania z produktów firmy CamDiab. Najważniejszym
                                    przykładem jest nasza usługa wsparcia, która jest dostępna pod adresem <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Prosimy zatem zastanowić
                                    się nad tym, jakie informacje i dane Użytkownik chce podawać w aktywnej komunikacji
                                    z firmą - jest to wyłączna decyzja Użytkownika. Z naszej strony, konieczna może być
                                    komunikacja z użytkownikami za pomocą wiadomości e-maila lub w podobnej formie. W
                                    ten sposób informujemy Użytkownika o aktualizacjach naszych produktów i przekazujemy
                                    ważne informacje dotyczące bezpieczeństwa i wsparcie w zakresie użytkowania. Ta
                                    komunikacja wspierająca, stanowiąca istotną część naszych produktów, wysyłana jest
                                    do użytkowników.
                                </p>
                                <p>
                                    <strong>Urządzenia lecznicze </strong> (np. glukometr i pompy insulinowe) są
                                    parowane z urządzeniem, co umożliwia przesyłanie danych do aplikacji.
                                </p>
                                <p>
                                    <strong>Portale danych o cukrzycy i udostępnianie danych</strong>, np. prowadzone
                                    przez mylife Cloud/Glooko umożliwiają wymianę danych z naszymi aplikacjami. Jednak
                                    synchronizacja odbywa się wyłącznie wtedy, gdy Użytkownik włączy tę opcję w
                                    ustawieniach aplikacji, czyli jeśli korzysta z tej funkcji. Dane udostępniane
                                    współpracownikom będącym stronami trzecimi podlegają informacji o polityce
                                    prywatności producentów portali danych o cukrzycy.
                                </p>

                                2.2.2 <strong>Korzystanie</strong> z aplikacji wymaga, aby Użytkownik dobrowolnie
                                wprowadził lub podał dane. Aby usunąć błąd w aplikacji, wymagamy na przykład raportów z
                                awarii, których możemy użyć do rozwiązania problemu i określenia okoliczności jego
                                wystąpienia. Ponadto kluczowe dane urządzenia i sposób użytkowania są rejestrowane,
                                ponieważ nasze zobowiązanie umowne polega przede wszystkim na dostosowywaniu naszych
                                produktów, tj. przetwarzania danych poszczególnych użytkowników, np. w zależności od
                                lokalizacji (co ma także znaczenie dla funkcji wyszukiwania). Automatyczna analiza
                                zachowania użytkownika wykonywana jest wyłącznie w celu dostosowania sposobu użytkowania
                                w ramach realizacji umowy i nie wywiera prawnego wpływu na Użytkownika.

                                <h2>
                                    3. PRZETWARZANIE W CELU ULEPSZENIA PRODUKTU
                                </h2>
                                <p>
                                    Przetwarzamy także Dane Użytkownika, poza niezbędnym użytkowaniem opisanym w pkt 2
                                    powyżej, w celu ulepszenia produktów i usług zgodnie z poniższym, bardziej
                                    szczegółowym opisem.
                                </p>
                                <h3>
                                    3.1. Dodatkowe dane
                                </h3>
                                <p>
                                    Ogólnie rzecz biorąc, korzystamy z Danych Użytkownika do ulepszenia naszych
                                    produktów określonych w pkt. 2. Ponadto firma CamDiab może także rejestrować
                                    następujące Dane Użytkownika:
                                </p>
                                <p>
                                    <strong>Dane dotyczące użytkowania</strong>
                                    <br/>
                                    Zdarzenia związane z aktywnością, które pozwalają zrozumieć, jak użytkownicy
                                    korzystają z naszych produktów. Dzięki temu widzimy, jak użytkowane są nasze
                                    produkty i, na przykład, w których miejscach można poprawić projekt menu.
                                </p>
                                <h3>
                                    3.2. Cel ulepszenia produktu
                                </h3>
                                <p>
                                    W wyniku szybkiego postępu technologicznego musimy stale analizować, rozwijać, badać
                                    i ulepszać nasze produkty oraz interakcje między nimi, w celu zapewnienia, że nasze
                                    treści służą użytkownikom w najlepszy możliwy sposób. Aby to osiągnąć, możemy
                                    prowadzić oceny użytkowania, wykonalności, przewidywalności, kontroli i
                                    bezpieczeństwa, a uzyskana w ich wyniku wiedza zostanie wykorzystana w nowych
                                    wersjach naszych produktów, np. aplikacji. Ulepszenia te będą udostępniane
                                    Użytkownikom za pośrednictwem regularnych aktualizacji.
                                </p>
                                <p>
                                    CamDiab jest zaangażowany w naukę o wszystkich aspektach cukrzycy. Dlatego anonimowe
                                    Dane Użytkownika mogą być również wykorzystywane do celów badawczych i
                                    statystycznych (zawsze przy zachowaniu uznanych etycznych standardów naukowych) oraz
                                    analiz wewnętrznych. Może to obejmować udostępnianie zanonimizowanych danych
                                    współpracownikom stron trzecich.
                                </p>
                                <h2>
                                    4. PRZETWARZANIE DLA CELÓW MARKETINGOWYCH
                                </h2>
                                <h3>
                                    4.1. Biuletyn
                                </h3>
                                <p>
                                    4.1.1 Oprócz zakresu umownego możemy wysyłać Użytkownikom informacje dotyczące
                                    produktów i usług (w tym informacje od starannie wybranych partnerów) oraz
                                    zaproszenia do wzięcia udziału w ankietach i innych promocjach sprzedaży i
                                    działaniach marketingowych („<strong>Biuletyn</strong>”).
                                </p>
                                <p>
                                    4.1.2 Użytkownik może zdecydować, czy chce subskrybować nasz Biuletyn. Zgodę można
                                    wycofać w dowolnym momencie w ustawieniach konta.
                                </p>
                                <h3>
                                    4.2. Inne rodzaje marketingu
                                </h3>
                                <p>
                                    4.2.1 Inne zgody, np. na ankiety, powiadomienia lub dostosowane oferty, uzyskiwane
                                    będą w zależności od potrzeb. Zawsze objaśniamy Użytkownikom do czego potrzebne są
                                    nam określone dane i jak można wycofać zgodę.
                                </p>
                                <p>
                                    4.2.2 Należy pamiętać, że możemy wyświetlać oferty w aplikacji bez przetwarzania
                                    danych osobowych. Jeśli Użytkownik nie wyraził zgody, wyświetlane będą reklamy,
                                    które nie zostały dopasowane do Użytkownika.
                                </p>
                                <h2>
                                    5. UŻYTKOWANE W CELACH STATUTOWYCH
                                </h2>
                                <h3>
                                    5.1. Badania naukowe i statystyki
                                </h3>
                                <p>
                                    Firma CamDiab zaangażowana jest w działalność naukową dotyczącą wszystkich aspektów
                                    cukrzycy. Dlatego też dla celów badawczo-rozwojowych i statystycznych mogą być
                                    wykorzystywane anonimowe dane (zawsze zgodnie z uznanymi naukowymi standardami
                                    etycznym) oraz analizy wewnętrzne. Są one stosowane głównie w celu określenia i
                                    poprawy skuteczności technik kontroli i leczenia cukrzycy. Podstawę prawną stanowi
                                    art. 9 ust. 2 lit. j) RODO.
                                </p>
                                <h3>
                                    5.2. Egzekwowanie praw
                                </h3>
                                <p>
                                    Korzystanie z danych osobowych może być także niezbędne w celu zapobieżenia
                                    nadużyciom przez użytkowników lub w celu wszczęcia, wykonania i obrony roszczeń
                                    prawnych. Możemy zostać zmuszeni do ujawnienia informacji zgodnie z obowiązującymi
                                    przepisami, postanowieniami sądu, decyzjami urzędowymi oraz w związku z dochodzeniem
                                    w sprawach kryminalnych, albo w interesie publicznym. W takich przypadkach
                                    przechowywanie i przetwarzanie Danych Użytkownika dozwolone jest prawem bez zgody
                                    Użytkownika. Podstawę prawną stanowi art. 9 ust. 2 lit. j) RODO.
                                </p>
                                <h3>
                                    5.3. Zgodność z przepisami prawa dotyczącymi wyrobów medycznych
                                </h3>
                                <p>
                                    Wreszcie, jako producent lub dystrybutor wyrobu medycznego, podlegamy zaostrzonym
                                    wymogom w zakresie monitorowania funkcjonalności produktu. Ten system kontroli
                                    wymagany dla celów określonych w przepisach prawa może także wiązać się z
                                    przetwarzaniem danych osobowych. Podstawę prawną stanowi art. 9 ust. 2 lit. i) RODO.
                                </p>
                                <h2>
                                    6. INFORMACJE OGÓLNE
                                </h2>
                                <h3>
                                    6.1. Ograniczenie celu i bezpieczeństwo
                                </h3>
                                <p>
                                    6.1.1 Firma CamDiab wykorzystuje dane osobowe użytkowników wyłącznie w celach
                                    określonych w niniejszej informacji o polityce prywatności i odpowiednich zgodach.
                                    Zapewniamy, by każde przetwarzanie ograniczało się do zakresu niezbędnego dla danego
                                    celu.
                                </p>
                                <p>
                                    6.1.2 Każde przetwarzanie zawsze gwarantuje odpowiednie bezpieczeństwo i poufność
                                    danych osobowych Użytkownika. Obejmuje to ochronę przed nieuprawnionym i nielegalnym
                                    przetwarzaniem, nieumyślną utratą, zniszczeniem lub uszkodzeniem, przy użyciu
                                    odpowiednich środków technicznych i organizacyjnych. Stosujemy rygorystyczne procesy
                                    wewnętrzne, zabezpieczenia i metody szyfrowania.
                                </p>
                                <h3>
                                    6.2. Administratorzy
                                </h3>
                                <p>
                                    6.2.1 Nasze produkty podlegają złożonym procesom, które wymagają zarządzania i
                                    aktualizacji. W celu uzyskania wsparcia technicznego możemy korzystać z pomocy
                                    usługodawców będących stronami trzecimi („<strong>Administratorzy</strong>”), aby
                                    zapewnić kompleksowe i optymalne korzystanie z naszych produktów.
                                </p>
                                <p>
                                    6.2.2 Firma CamDiab przekazuje Dane Użytkownika Administratorom wyłącznie w ramach
                                    niniejszej informacji o polityce prywatności oraz do realizacji określonych w niej
                                    celów. Administratorzy działają zgodnie z naszymi specyfikacjami i instrukcjami, nie
                                    wolno im wykorzystywać danych osobowych użytkowników dla celów własnych ani innych.
                                </p>
                                <p>
                                    6.2.3. Korzystamy z usług Administratorów dających odpowiednie gwarancje, że
                                    podejmowane są odpowiednie środki techniczne i organizacyjne, w sposób taki, że
                                    przetwarzanie danych osobowych jest zgodne z wymogami ustawowymi i niniejszą
                                    polityką prywatności. Ochrona praw użytkowników realizowana jest poprzez zawieranie
                                    wiążących umów spełniających rygorystyczne wymogi RODO.
                                </p>
                                <h3>
                                    6.3. Szyfrowanie, pseudonimizacja i anonimizacja
                                </h3>
                                <p>
                                    6.3.1 Każdy transfer danych, bez wyjątku i automatycznie, jest szyfrowany w trakcie
                                    transferu. Korzystając z protokołu HTTPS (hypertext transfer protocol secure) lub
                                    podobnego rozwiązania, uniemożliwiamy przechwycenie danych Użytkownika przez
                                    nieuprawnione strony trzecie.
                                </p>
                                <p>
                                    Ponadto dla celów bezpieczeństwa i ograniczenia ilości zbieranych danych, korzystamy
                                    także z innych procesów do szyfrowania i pseudonimizacji Danych Użytkowników.
                                    Oczywiście, zależy to od rodzaju, zakresu i celu danego przetwarzania danych. Na
                                    przykład, ujawniamy tylko te Dane Użytkownika, których Administrator wymaga do
                                    wykonania swoich zadań.
                                </p>
                                <p>
                                    6.3.2 W przypadku rozwiązania stosunku umownego z Administratorem, Administrator
                                    zobowiązany jest, według uznania firmy CamDiab, zwrócić wszystkie Dane Użytkownika
                                    lub usunąć je, jeśli nie występują ustawowe obowiązki przechowywania.
                                </p>
                                <p>
                                    6.3.3 Dane, które nie wymagają odniesienia do konkretnej osoby w celu ich
                                    przetwarzania (np. na potrzeby badań i analizy) podlegają anonimizacji. Uniemożliwia
                                    to, w każdym przypadku, powiązanie z konkretnym użytkownikiem.
                                </p>
                                <h3>
                                    6.4. UE i inne kraje
                                </h3>
                                <p>
                                    6.4.1 Staramy się wybierać partnerów współpracy, których siedziba lub serwery
                                    znajdują się w Unii Europejskiej lub Europejskim Obszarze Gospodarczym (EOG).
                                    Przekazywanie danych na terenie UE i EOG nie jest sprzeczne z żadnym przepisami,
                                    ponieważ RODO obowiązuje we wszystkich państwach członkowskich.
                                </p>
                                <p>
                                    6.4.2 W wyjątkowych okolicznościach powołujemy dostawców będących stroną trzecią,
                                    których siedziba lub serwery znajdują się poza UE. Jednak nawet w takim przypadku
                                    dane osobowe podlegają wysokiemu poziomowi ochrony zgodnie z RODO, albo w drodze
                                    decyzji UE stwierdzającej odpowiedni poziom ochrony, która uznaje ochronę danych w
                                    określonych państwach trzecich za odpowiednią (np. Szwajcaria, Izrael i Nowa
                                    Zelandia) albo przez określone standardowe klauzule umowne zatwierdzone przez UE, na
                                    których opierają się stosunki umowne z administratorami danych, albo w drodze
                                    porównywalnych prawnych instrumentów dozwolonych przez RODO.
                                </p>
                                <p>
                                    6.4.3 Ponadto zapewniamy, by nasi partnerzy mieli wdrożone dodatkowe standardy
                                    bezpieczeństwa, np. indywidualne środki bezpieczeństwa i przepisy dotyczące ochrony
                                    danych lub posiadali certyfikaty zgodnie z RODO. Zatem, na przykład, jeśli dostawcy
                                    będący stronami trzecimi znajdują się w USA, powinni podlegać Ramowej tarczy
                                    prywatności (Privacy Shield Framework) zatwierdzonej przez UE lub porównywalnym,
                                    uznanym w skali międzynarodowej standardom bezpieczeństwa.
                                </p>
                                <h3>
                                    6.5. Pliki cookie
                                </h3>
                                <p>
                                    Firma CamDiab może przechowywać tzw. pliki cookie, aby móc oferować Użytkownikowi
                                    możliwie najszerszy zakres funkcji i sprawić, aby korzystanie z naszej witryny było
                                    jeszcze wygodniejsze. Pliki cookie to niewielkie pliki tekstowe przechowywane na
                                    urządzeniu przez przeglądarkę. Z wyjątkiem plików cookie do danych dotyczących
                                    użytkowania, określonych w pkt 6.6, nasze pliki cookie mogą być stosowane do obsługi
                                    witryny internetowej. Jeśli użytkownik nie chce korzystać z plików cookie, może
                                    uniemożliwić ich przechowywanie, odpowiednio zmieniając ustawienia przeglądarki.
                                    Większość naszych plików cookie jest usuwanych na koniec wizyty użytkownika albo po
                                    zamknięciu przeglądarki (pliki cookie sesji). W innych przypadkach można sprawdzić
                                    okres usunięcia w przeglądarce lub usunąć pliki cookie ręcznie.
                                </p>
                                <h3>
                                    6.6. Dane dotyczące użytkowania
                                </h3>
                                <p>
                                    W aplikacji korzystamy z Google Firebase Analytics, usługi firmy Google Inc.
                                    („Google”) do analizowania witryn i aplikacji. Firma Google uzyskała certyfikat w
                                    ramach tarczy prywatności EU-US Privacy Shield.
                                </p>
                                <h3>
                                    6.7. Przechowywanie i usuwanie danych
                                </h3>
                                <p>
                                    6.7.1 Dane Użytkownika są przechowywane na jego urządzeniu. Dane są także
                                    przechowywane na serwerach. Korzystamy wyłącznie z systemów spełniających wymogi
                                    RODO.
                                </p>
                                <p>
                                    6.7.2 Dane Użytkownika przechowywane są na serwerach w Unii Europejskiej (UE).
                                </p>
                                <p>
                                    6.7.3 Co do zasady, firma CamDiab przechowuje dane osobowe wyłącznie przez okres
                                    obowiązywania umowy. W wyjątkowych sytuacjach wymagane może być dłuższe
                                    przechowywanie w celu wypełnienia zobowiązań wprowadzonych po zawarciu umowy albo
                                    zobowiązań do ujawnienia, lub w celu wniesienia, wykonania i obrony roszczeń
                                    prawnych (okresy przedawnienia).
                                </p>
                                <h3>
                                    6.8. Osoby małoletnie
                                </h3>
                                <p>
                                    Osoby małoletnie poniżej szesnastego roku życia mogą korzystać z naszych produktów
                                    za zgodą rodzica/opiekuna (<a href="/pl/terms">Ogólne warunki</a>). Dotyczy to także
                                    przetwarzania ich danych osobowych, co jest zgodne z prawem wyłącznie w przypadku
                                    uzyskania zgody wyrażonej przez rodzica/opiekuna oraz w zakresie, w jakim zgoda
                                    została wyrażona. W przeciwnym wypadku stosowanie naszych produktów jest zabronione.
                                </p>
                                <h3>
                                    6.9. Inspektor ochrony danych
                                </h3>
                                <p>
                                    6.9.1 Inspektor ochrony danych w firmie odpowie na wszystkie pytania związane z
                                    ochroną danych. Pytania takie należy kierować na adres <a
                                    href="mailto:dpo@evalian.co.uk">dpo@evalian.co.uk</a>.
                                </p>
                                <p>
                                    6.9.2 Inspektor ochrony danych jest w dużym stopniu zaangażowany we wszystkie sprawy
                                    związane z ochroną danych osobowych użytkowników. Jako wyszkolony specjalista,
                                    inspektor monitoruje przetwarzanie danych na bieżąco, aby zapewnić najlepszą ochronę
                                    Danych Użytkownika.
                                </p>
                                <h2>
                                    7. PRAWA UŻYTKOWNIKA
                                </h2>
                                <h3>
                                    7.1. Wycofanie zgód
                                </h3>
                                <p>
                                    Jeśli Dane Użytkownika przetwarzane są na podstawie zgody, Użytkownik może w
                                    dowolnym momencie zgodę tę wycofać. Jednak nie będzie to miało wpływu na zgodność z
                                    prawem przetwarzania przed wycofaniem zgody. Będziemy kontynuować świadczenie usług,
                                    które nie są uzależnione od wycofanej zgody.
                                </p>
                                <h3>
                                    7.2. <strong>Informacje, poprawienie, ograniczenie</strong>
                                </h3>
                                <p>
                                    7.2.1 Każdemu użytkownikowi przysługuje prawo żądania informacji o przetwarzaniu
                                    jego danych osobowych. Aby uzyskać takie informacje, użytkownik może skontaktować
                                    się w dowolnym momencie z firmą, pisząc na adres <a
                                    href="mailto:privacy@camdiab.com">privacy@camdiab.com</a>.
                                </p>
                                <p>
                                    7.2.2 Prawo do uzyskania informacji obejmuje informacje o celach przetwarzania,
                                    kategoriach danych i odbiorców, czasu przechowywania, pochodzenia danych oraz praw
                                    użytkownika wynikających z przepisów o ochronie danych. Wszystkie te informacje
                                    można znaleźć w niniejszej informacji o polityce prywatności i chętnie przekażemy je
                                    użytkownikowi w formie elektronicznej.
                                </p>
                                <p>
                                    7.2.3 Jeśli niektóre z danych osobowych użytkownika okażą się niepoprawne, można
                                    zwrócić się o skorygowanie lub uzupełnienie danych w dowolnym momencie.
                                    Użytkownikowi przysługuje prawo ograniczenia przetwarzania danych na czas
                                    wnioskowanego dochodzenia przeglądowego.
                                </p>
                                <h3>
                                    7.3. Usunięcie („prawo do bycia zapomnianym”)
                                </h3>
                                <p>
                                    Każdemu użytkownikowi przysługuje prawo żądania usunięcia jego danych osobowych. Aby
                                    skorzystać z tego prawa, użytkownik może skontaktować się w dowolnym momencie z
                                    firmą, pisząc na adres <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    7.4. Skargi
                                </h3>
                                <p>
                                    7.4.1 Jeśli użytkownik uważa, że nie chronimy jego danych osobowych w odpowiedni
                                    sposób, prosimy o kontakt w dowolnym momencie pod adresem <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>, albo bezpośrednio z
                                    inspektorem danych osobowych pod adresem <a
                                    href="mailto:dpo@evalian.co.uk">dpo@evalian.co.uk</a>. Zajmiemy się odpowiednio
                                    wnioskiem.
                                </p>
                                <p>
                                    7.4.2 Każdemu użytkownikowi przysługuje prawo wniesienia skargi do odpowiedniego
                                    organu ochrony danych odpowiedzialnego za firmę CamDiab, jeśli użytkownik uważa, że
                                    przetwarzanie danych osobowych nie jest zgodne z przepisami dotyczącymi ochrony
                                    danych. Ponadto, użytkownik ma prawo wnieść skargę do organu nadzorczego w państwie
                                    członkowskim UE, którego jest rezydentem lub w którym znajduje się jego miejsce
                                    pracy, lub które to państwo jest miejscem podejrzewanego naruszenia.
                                </p>

                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

