/**
 * Common texts - what is CamAPS FX
 */
export const whatIsCamAPSFX = {

    linkFindOutMore: 'Scopri di più',
    body:
        '<h2>Che cos’è CamAPS FX?</h2> <p> CamAPS FX è un’app mobile per gestire i livelli della glicemia nelle persone con diabete di tipo 1, basata su un approccio terapeutico ibrido a sistema chiuso, avanzato e adattativo. </p>'
};

/**
 * Common texts - what is CamAPS FX
 */
export const userReviewsIntro = {
    linkMoreReviews: 'Altre testimonianze',
    readMore: 'Approfondisci',
    header:
        'Testimonianze degli utilizzatori di CamAPS FX',
    text:
        'Storie vere di persone vere che utilizzano l’app CamAPS FX'
};