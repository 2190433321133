import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import Routes from "./Routes";
import {Helmet} from 'react-helmet';
import {homePageText as homePageText_en} from "./libs/en/texts-home-page-lib";
import {homePageText as homePageText_en_ca} from "./libs/en-ca/texts-home-page-lib";
import {homePageText as homePageText_de} from "./libs/de/texts-home-page-lib";
import {homePageText as homePageText_fr} from "./libs/fr/texts-home-page-lib";
import {homePageText as homePageText_fr_ca} from "./libs/fr-ca/texts-home-page-lib";
import {homePageText as homePageText_nl} from "./libs/nl/texts-home-page-lib";
import {homePageText as homePageText_it} from "./libs/it/texts-home-page-lib";
import {homePageText as homePageText_cz} from "./libs/cz/texts-home-page-lib";
import {homePageText as homePageText_fi} from "./libs/fi/texts-home-page-lib";
import {homePageText as homePageText_pl} from "./libs/pl/texts-home-page-lib";
import {homePageText as homePageText_se} from "./libs/se/texts-home-page-lib";
import {homePageText as homePageText_no} from "./libs/no/texts-home-page-lib";
import {homePageText as homePageText_dk} from "./libs/dk/texts-home-page-lib";
import {homePageText as homePageText_es} from "./libs/es/texts-home-page-lib";
import parse from "html-react-parser";
// import "./App.css";
import './assets/css/bootstrap.min.css';
import './assets/css/style.css';


class App extends Component {
    constructor(props) {
        super(props);

        let language = window.navigator.language;
        let actualLanguage = 'EN';
        if (language) {
            language = language.toUpperCase().substring(0, 2);
            if ((language === 'DE')
                || (language === 'EN')
                || (language === 'FR')
                || (language === 'NL')
                || (language === 'IT')
                || (language === 'CZ')
                || (language === 'FI')
                || (language === 'PL')
                || (language === 'SE')
                || (language === 'NO')
                || (language === 'DK')
                || (language === 'ES')
            ) {
                actualLanguage = language;
            }
        }
        this.state = {
            isAuthenticated: false,
            isAuthenticating: true,
            isProcessing: false,
            isStopping: false,
            isNewPasswordRequired: false,
            currentUser: null,
            captchaToken: null,
            captchaKeyEval: null,
            language: actualLanguage,
        };

        this.events = [
            "load",
            "mousemove",
            "mousedown",
            "click",
            "scroll",
            "keypress"
        ];

        this.warn = this.warn.bind(this);
        this.logout = this.logout.bind(this);
        this.resetTimeout = this.resetTimeout.bind(this);

        let i;
        for (i in this.events) {
            window.addEventListener(this.events[i], this.resetTimeout);
        }
        this.setTimeout();

        // get captcha every 100 seconds
        const self = this;
        setInterval(function () {
            self.setState({captchaKeyEval: Math.random()});
        }, 100 * 1000);
    }

    setSiteLanguage = language => {
        this.setState({language: language});
    };

    clearTimeout() {
        if (this.warnTimeout) {
            clearTimeout(this.warnTimeout);
        }
        if (this.logoutTimeout) {
            clearTimeout(this.logoutTimeout);
        }
    }

    setTimeout() {
        const warnTime = 60;    // in minutes
        const logoutTime = 60;  // in minutes
        this.warnTimeout = setTimeout(this.warn, warnTime * 60 * 1000);
        this.logoutTimeout = setTimeout(this.logout, logoutTime * 60 * 1000);
    }

    resetTimeout() {
        this.clearTimeout();
        this.setTimeout();
    }

    warn() {
        // do not provide any warning - just logout
        // alert("You will be logged out automatically in 1 minute.");
    }

    async logout() {
        // Send a logout request to the API
        console.log("Sending a logout request to the API...");
        this.destroy(); // Cleanup
        await this.handleLogout();
    }

    destroy() {
        this.clearTimeout();
        let i;
        for (i in this.events) {
            window.removeEventListener(this.events[i], this.resetTimeout);
        }
    }

    async componentDidMount() {
        try {
            // await Auth.currentSession();
            // this.userHasAuthenticated(true);

        } catch (e) {
            if (e !== 'No current user') {
                alert(e);
            }
        }
        // this.setState({isAuthenticating: false});
    }

    userHasAuthenticated = authenticated => {
        this.setState({isAuthenticated: authenticated});
    };

    userNeedsNewPassword = newPassword => {
        this.setState({isNewPasswordRequired: newPassword});
    };

    setCurrentUser = currentUser => {
        this.setState({currentUser: currentUser});
    };

    setIsStopping = stopping => {
        this.setState({isStopping: stopping});
    };

    setCaptchaToken = captchaToken => {
        this.setState({captchaToken: captchaToken});
    };

    setCaptchaKeyEval = captchaKeyEval => {
        this.setState({captchaKeyEval: captchaKeyEval});
    };

    getNewCaptchaToken = captchaKeyEval => {
        this.setState({captchaKeyEval: Math.random()});
    };

    handleLogout = async event => {
        // await Auth.signOut();
        //
        // this.userHasAuthenticated(false);
        // this.props.history.push("/login");
    };

    render() {
        const childProps = {
            isAuthenticated: this.state.isAuthenticated,
            userHasAuthenticated: this.userHasAuthenticated,
            isNewPasswordRequired: this.state.isNewPasswordRequired,
            userNeedsNewPassword: this.userNeedsNewPassword,
            currentUser: this.state.currentUser,
            setCurrentUser: this.setCurrentUser,
            isProcessing: this.state.isProcessing,
            setIsProcessing: this.setIsProcessing,
            isStopping: this.state.isStopping,
            setIsStopping: this.setIsStopping,
            captchaToken: this.state.captchaToken,
            setCaptchaToken: this.setCaptchaToken,
            captchaKeyEval: this.state.captchaKeyEval,
            setCaptchaKeyEval: this.setCaptchaKeyEval,
            getNewCaptchaToken: this.getNewCaptchaToken,
            language: this.state.language,
            setSiteLanguage: this.setSiteLanguage,
        };

        let homePageText;
        if (this.state.language === "DE") {
            homePageText = homePageText_de;
        } else if ((this.state.language === "FR") || (this.state.language === "FR-FR")){
            homePageText = homePageText_fr;
        } else if (this.state.language === "FR-CA") {
            homePageText = homePageText_fr_ca;
        } else if (this.state.language === "EN_CA") {
            homePageText = homePageText_en_ca;
        } else if (this.state.language === "NL") {
            homePageText = homePageText_nl;
        } else if (this.state.language === "IT") {
            homePageText = homePageText_it;
        } else if (this.state.language === "CZ") {
            homePageText = homePageText_cz;
        } else if (this.state.language === "FI") {
            homePageText = homePageText_fi;
        } else if (this.state.language === "PL") {
            homePageText = homePageText_pl;
        } else if (this.state.language === "SE") {
            homePageText = homePageText_se;
        } else if (this.state.language === "NO") {
            homePageText = homePageText_no;
        } else if (this.state.language === "DK") {
            homePageText = homePageText_dk;
        } else if (this.state.language === "ES") {
            homePageText = homePageText_es;
        } else {
            homePageText = homePageText_en;
        }
        return (
            <div className="App">
                <Helmet>
                    <title> {parse(homePageText.homePageTitle)}</title>
                    <meta name="title" content={parse(homePageText.homePageTitleMeta)}/>
                    <meta name="description"
                          content={parse(homePageText.homePageDescriptionMeta)}/>
                    <meta name="keywords" content={homePageText.keywords}/>
                    <meta charSet="utf-8"/>
                    <meta property="og:locale" content={homePageText.localeMeta}/>
                </Helmet>
                <div className="Routes">
                    <Routes childProps={childProps}/>
                </div>
            </div>
        );
    }
}

export default withRouter(App);